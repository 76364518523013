
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { iCategory, iInformation } from '../../../App/Utility/source';
import Image from '../../Image/Image';
import Tooltip from '@mui/material/Tooltip';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';

const CurriculumHistoryTableRow = ({ data, index }) => {

  const { student_id } = useParams();

  const navigateTo = useNavigate()

  return (
    <>
      <tr onClick={() => {
        data?.lesson_count > 0 &&  
        navigateTo(`/student/details/${student_id}/curriculum/history/details/${data?.category_id}`) 
      }}
        className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >
        <th className='m-2 font-normal text-center border-r py-s10 pl-s10'>
          <span className='mr-s12 dm:mrs12 md:mr-0'>{index + 1}</span>
        </th>

        <td className='border-r-[1px] py-s10 text-left px-s10 max-w-[100px] min-w-[120px] '>
          <div className='flex items-center min-w-[80px] sm:flex-col md:flex-row'>

            <div className='rounded-full w-s44 h-s44 py-s7 pr-s5 pl-s7 bg-cBackgroundAndCategory'>
              <Image cursorPointerClass="cursor-pointer" className='h-s27 w-s30'
                src={data?.category_icon} dummyImage={iCategory} isCategoryImage={true}
              />
            </div>

            <div className='justify-center my-s8 font-fw600 ml-s8 text-cMainBlack lg:text-fs14 sm:text-fs12'>
              {data?.category_name}</div>
          </div>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>{data?.lesson_count}</span>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>
          {data?.lesson_durations?toHoursAndMinutes(data?.lesson_durations):<CommonEmptyStatus />}</span>
        </td>


      </tr>
    </>
  )
}


export default CurriculumHistoryTableRow;