
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import useInvoiceStore from '../../App/Stores/InvoiceStore';
import useSchoolStore from '../../App/Stores/SchoolStore';
import { iCategoryCard, iClassesCard, iClassroomCard, iInformation, iInstructorCard, iInvoiceCard, iLicenseHistoryCard, iStudentCard } from '../../App/Utility/source';
import CommonCard from '../../Components/Card/CommonCard';
import Tooltip from '@mui/material/Tooltip';

const AdditionalInfoSchool = () => {

  const {
    schoolAdditionalCounter,
    schoolDetails,
    setSchoolInstructorPageUrl,
    setSchoolStudentType,setSchoolStudentPageUrl
  } = useSchoolStore();

  const { resetFilterSchoolInvoiceData } = useInvoiceStore();

  const { school_id } = useParams();

  return (
    <>



      <span className='text-fs14 font-fw600 py-s20 text-cBlack'>Additional Information</span>
      <div className="grid grid-cols-1 gap-5 px-0 w-full sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 pt-s20">


        {/* {!schoolAdditionalCounter?.instructor_counter > 0 ? 
        <Tooltip title="No Instructor">
          <NavLink
            onClick={() => { setSchoolInstructorPageUrl("") }}
            to={schoolAdditionalCounter?.instructor_counter > 0 && `/school/details/${school_id}/instructor`}>
            <CommonCard cardIcon={iInstructorCard} title={'Instructors'} note={`${schoolAdditionalCounter?.instructor_counter ?? "0"} Instructors`} />
          </NavLink>
        </Tooltip>
          :
          <NavLink
            onClick={() => { setSchoolInstructorPageUrl("") }}
            to={schoolAdditionalCounter?.instructor_counter > 0 && `/school/details/${school_id}/instructor`}>
            <CommonCard cardIcon={iInstructorCard} title={'Instructors'} note={`${schoolAdditionalCounter?.instructor_counter ?? "0"} Instructors`} />
          </NavLink>
        } */}

        <NavLink
          onClick={() => { setSchoolInstructorPageUrl("") }}
          to={`/school/details/${school_id}/instructor`}>
          <CommonCard cardIcon={iInstructorCard} title={'Instructors'} note={`${schoolAdditionalCounter?.instructor_counter ?? "0"} Instructors`} />
        </NavLink>

        <NavLink
          onClick={() => {
            setSchoolStudentType('active')
            setSchoolStudentPageUrl("")
          }}
          to={schoolAdditionalCounter?.student_active_counter + schoolAdditionalCounter?.student_pending_counter
            + schoolAdditionalCounter?.student_history_counter > 0 ? `/school/details/${school_id}/student` : ''}>
          <CommonCard cardIcon={iStudentCard} title={'Students'}
            note={`${schoolAdditionalCounter?.student_active_counter ?? "0"} Active, ${schoolAdditionalCounter?.student_pending_counter ?? "0"} Pending, ${schoolAdditionalCounter?.student_history_counter ?? "0"} History`} />
        </NavLink>

        {/* e invoice    */}
        <NavLink
          // r reset section data    
          onClick={() => {
            resetFilterSchoolInvoiceData();
            localStorage.setItem("schoolInvoiceType", "school_instructor");
            localStorage.setItem("schoolInvoicePaginationURL", "");
            localStorage.setItem("schoolInvoiceTake", 10);

          }}
          to={
            schoolAdditionalCounter?.school_invoice_pending_count + schoolAdditionalCounter?.school_invoice_history_count > 0 ? `/school/details/${school_id}/invoice/${schoolDetails?.data?.school?.user_id}` : ""
          }>
          <CommonCard cardIcon={iInvoiceCard} onClick={() => { }} title={'Invoices'} note={`
                ${(schoolAdditionalCounter?.school_invoice_pending_count + schoolAdditionalCounter?.school_invoice_history_count) > 0 ? (schoolAdditionalCounter?.school_invoice_pending_count + schoolAdditionalCounter?.school_invoice_history_count) : "0"} Total,${schoolAdditionalCounter?.school_invoice_pending_count ?? "0"} Pending,${schoolAdditionalCounter?.school_invoice_history_count ?? "0"} History
              `} />
        </NavLink>

        <NavLink to={`/school/details/${school_id}/category`}>
          <CommonCard cardIcon={iCategoryCard} title={'Categories'} note={`${schoolAdditionalCounter?.category_count ?? "0"} Categories`} />
        </NavLink>

        {/* <NavLink to={`/school/details/${id}/classes`}> */}
        <CommonCard cardIcon={iClassesCard} title={'Classes'} note={`${schoolAdditionalCounter?.classes_count ?? "0"} Classes`} />
        {/* </NavLink> */}
        {/* <NavLink to={`/school/details/${id}/classroom`}> */}
        <CommonCard cardIcon={iClassroomCard} title={'Classroom'} note={`${schoolAdditionalCounter?.classroom_count ?? "0"} Classrooms`} />
        {/* </NavLink> */}
        {/* <NavLink to="/school/license/history"> */}
        <CommonCard cardIcon={iLicenseHistoryCard} title={'License History'} note={`${schoolAdditionalCounter?.license_history_count ?? "0"} License History`} />
        {/* </NavLink> */}
      </div>
    </>
  );
};

export default AdditionalInfoSchool;