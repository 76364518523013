import axios from "axios";
import BaseUrl from "./Url";



const AxiosHeader = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  axios.defaults.baseURL = BaseUrl;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.common['role'] = 'sa';
  // set access control allow origin
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};

export default AxiosHeader;
