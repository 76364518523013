import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useSchoolStore, { schoolCategoriesLessonDeleteFn } from '../../../../App/Stores/SchoolStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

function SchoolCategoryLessonDelete() {

    const {setLessonDeleteID,lessonDeleteID, setShowCategoryLessonDetailsModal,setShowSchoolCategoryDeleteModal,showSchoolCategoryDeleteModal } = useSchoolStore();

    const navigateTo = useNavigate();

    const {school_category_id}=useParams()

    const deleteCategory = async () => {
        let delSuccess = await schoolCategoriesLessonDeleteFn(lessonDeleteID,school_category_id);
        if (delSuccess) {
           await setShowSchoolCategoryDeleteModal(false)
           setLessonDeleteID("")
           setTimeout(function () {
            setShowCategoryLessonDetailsModal(false)
            //your code to be executed after 1 second
          }, 1000);
        //    return navigateTo("/category");
        }

       
    }
    return (
        <>
            <CommonModal
                showModal={showSchoolCategoryDeleteModal}
                setShowModal={setShowSchoolCategoryDeleteModal}
                modalTitle="Conformation"
                mainContent={
                    <div className='flex flex-col items-center'>
                        <div className="pt-5">Are you sure you want to delete this Lesson ?</div>
                        <div className="pt-5">
                            <CommonButton btnLabel='Delete' colorType='danger' 
                            onClick={() => deleteCategory()} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default SchoolCategoryLessonDelete