import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useLicenseStore, { deleteLicense } from '../../../App/Stores/LicenseStore'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal'

function DeleteLicenseModal() {
    const { showDeleteLicenseModal, setShowDeleteLicenseModal, licenseDeleteID, setShowEditLicense } = useLicenseStore();

    const location = useLocation();

    const {license_id}=useParams();

    const navigateTo=useNavigate()

    return (
        <>
            <CommonModal
                showModal={showDeleteLicenseModal}
                setShowModal={setShowDeleteLicenseModal}
                modalTitle="Confirmation"
                mainContent={
                    <div className='flex flex-col items-center'>
                        <div className="pt-5">Are you sure you want to delete this license ?</div>
                        <div className="pt-5">
                            <CommonButton btnLabel='Delete' colorType='danger' onClick={async () => {
                                let delSuccess = deleteLicense(licenseDeleteID);
                                if (delSuccess) {
                                    setShowDeleteLicenseModal(false);
                                    setShowEditLicense(false);
                                    location.pathname=`/license/details/${license_id}` && navigateTo("/license")
                                }
                            }} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default DeleteLicenseModal