import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useInvoiceStore, { getInvoiceDetails } from '../../App/Stores/InvoiceStore.js';
import useLayoutStore from '../../App/Stores/LayoutStore.js';
import { iShareBlue, iShareWhite } from '../../App/Utility/source.js';
import { dateDiffCalendar, PageTitle, roughLicenseDurationFormatter } from '../../App/Utility/UtilityFunctions.js';
import CommonButtonOutlined from '../../Components/Button/CommonButtonOutlined.jsx';
import LessonsCard from '../../Components/CommonDetailPage/LessonsCard.jsx';
import InvoiceComment2 from '../../Components/CommonInvoice/InvoiceComment2';
import InvoiceProfile from '../../Components/CommonInvoice/InvoiceProfile';
import InvoiceStatus from '../../Components/CommonInvoice/InvoiceStatus';
import BackLink from '../../Components/Pagination/BackLink';
import CommonTitle from '../../Components/Title/CommonTitle';
import { formatDate } from '../../Utility/UtilityFunctions.js';
import InvoiceReply from './InvoiceReply.jsx';

const InvoiceSchoolDetail = () => {

    const { setBarTitle } = useLayoutStore();

    const { invoiceDetailsData, setShowInvoiceShareModal, setInvoiceShareID, setInvoiceShareForm, resetInvoiceShareForm } = useInvoiceStore();

    const [liDuration, setLiDuration] = useState(0);

    const { invoice_id, instructor_id, student_id, school_id, school_user_id } = useParams();

    const location = useLocation();

    useEffect(() => {
        FetchInvoiceDetails();
    }, [invoice_id])

    const FetchInvoiceDetails = async () => {
        // get invoice details data
        await getInvoiceDetails(invoice_id);
    }

    useEffect(() => {
        PageTitle(t("Invoice Details"));
        setBarTitle("Invoice Details");
        resetInvoiceShareForm();
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        setLiDuration(dateDiffCalendar(invoiceDetailsData?.purchase_lisence?.start_time ?? new Date(), invoiceDetailsData?.purchase_lisence?.end_time));
    }, [invoiceDetailsData])

    return (
        <div>
            <div>
                <div className="mx-s12 md:mx-s32">
                    <div className="flex justify-between items-baseline">
                        <CommonTitle title="Invoice Details" onClick={() => console.log("invoiceDetailsData:::", invoiceDetailsData)}>
                            {
                                location.pathname === `/invoice/details/${invoice_id}` && <BackLink linksArray={[
                                    { label: "Invoice", linkTo: "/invoice" },
                                    { label: "Invoice Details", linkTo: "" }
                                ]} />
                            }
                            {
                                location.pathname === `/instructor/details/${instructor_id}/invoice/${invoice_id}` && <BackLink linksArray={[
                                    { label: "Instructor ", linkTo: "/instructor" },
                                    { label: "Instructor Details", linkTo: `/instructor/details/${instructor_id}` },
                                    { label: "Invoices", linkTo: `/instructor/details/${instructor_id}/invoice` },
                                    { label: "Invoices Details", linkTo: `` }
                                ]}
                                />
                            }
                            {
                                location.pathname === `/student/details/${student_id}/invoice/${invoice_id}` && <BackLink linksArray={[
                                    { label: "Student", linkTo: "/student" },
                                    { label: "Student Details", linkTo: `/student/details/${student_id}` },
                                    { label: "Invoices", linkTo: `/student/details/${student_id}/invoice` },
                                    { label: "Invoices Details", linkTo: `` }
                                ]}
                                />
                            }

                            {
                                location.pathname === `/school/details/${school_id}/invoice/${school_user_id}/details/${invoice_id}` && <BackLink linksArray={[
                                    { label: "School ", linkTo: "/school" },
                                    { label: "School profile", linkTo: `/school/details/${school_id}` },
                                    { label: "Invoices", linkTo: `/school/details/${school_id}/invoice/${school_user_id}` },
                                    { label: "Invoices Details", linkTo: `` }
                                ]}
                                />
                            }
                        </ CommonTitle >

                        <CommonButtonOutlined
                            btnLabel='share invoice'
                            onClick={() => {
                                // resetInvoiceShareForm();
                                setShowInvoiceShareModal(true);
                                setInvoiceShareForm({
                                    id: invoiceDetailsData?.id,
                                    user_id: invoiceDetailsData?.purchase_lisence?.user_id,
                                    role: invoiceDetailsData?.role,
                                    type: "admin_invoice",
                                })
                                console.log("data:::", invoiceDetailsData);
                                setInvoiceShareID(invoice_id);
                            }}
                            width={"w-[180px]"}
                            colorType='primary'
                            iconRight={iShareBlue}
                            iconRightHover={iShareWhite}
                        />
                    </div>

                    {/* B:      main details part */}
                    <div className="bg-cBrandColor2 rounded-br20">
                        <div className="rounded-lg bg-cBrandColor2 px-s20 pt-s20">
                            {/* G:      profile details */}
                            <InvoiceProfile />

                            <div className='my-5 w-full bg-cBackgroundAndCategory h-s1'></div>

                            <InvoiceStatus />

                            <div className='my-5 w-full bg-cBackgroundAndCategory h-s1'></div>

                            <div className="pb-5 space-y-5 xl:flex xl:flex-row-reverse xl:justify-between xl:space-y-0">
                                <div>
                                    <div className="text-fs16 font-fw600 pb-s15">
                                        License Details
                                    </div>
                                    <LessonsCard
                                        showFullDetails={true}
                                        title={invoiceDetailsData?.lisence_title}
                                        status={invoiceDetailsData?.status}
                                        packageDuration={roughLicenseDurationFormatter(invoiceDetailsData?.duration_in_days)}
                                        price={invoiceDetailsData?.price}
                                        momsValue={invoiceDetailsData?.moms}
                                        includedMoms={true}
                                        startDate={invoiceDetailsData?.purchase_lisence?.start_time ? formatDate(invoiceDetailsData?.purchase_lisence?.start_time) : "NA"}
                                        endDate={invoiceDetailsData?.purchase_lisence?.end_time ? formatDate(invoiceDetailsData?.purchase_lisence?.end_time) : "NA"}
                                        purchaseDuration={liDuration ? liDuration : "NA"}
                                        licenseDetails={invoiceDetailsData?.lisence?.details}
                                    />
                                </div>

                                <div className='flex flex-col max-h-[5220px] w-s1 bg-cBackgroundAndCategory mx-5'></div>

                                <div className='pr-5 w-full max-w-[700px]'>
                                    <InvoiceComment2 />
                                     <div className='my-s20'></div> 
                                    <InvoiceReply />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};

export default InvoiceSchoolDetail;