import React from 'react';
import { useState } from 'react';
import ProfileImageUploader from '../../../Components/Image/ProfileImageUploader';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonButton from './../../../Components/Button/CommonButton';
import useInstructorStore, { editInstructorDetail, resetInstructorEditData } from './../../../App/Stores/InstructorStore';
import { useEffect } from 'react';
import { BaseUrlSrc } from '../../../App/Utility/Url';

const InstructorEditModal = () => {

  const {setEditFormData,EditFormData, showEditModal, setShowEditModal, instructorDetails, setShowInstructorChangePassModal } = useInstructorStore();

  const [id, setID] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [instructor_name, setInstructor_name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [address, setAddress] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault()
    if (id && email) {
      await submit()
      let value = await editInstructorDetail(EditFormData)
      if (value) {
        setID("")
        setInstructor_name("")
        setEmail("")
        setPhone_number("")
        setImage("")
        setAddress("")
        resetInstructorEditData()
        setShowEditModal(false)
      }
    }

  }

  useEffect(() => {
    setID(instructorDetails?.id)
    setInstructor_name(instructorDetails?.name)
    setEmail(instructorDetails?.email)
    setPhone_number(instructorDetails?.phone_number)
    setImage(instructorDetails?.profile_photo)
    setAddress(instructorDetails?.address)
  }, [instructorDetails])

  //console.log("instructorDetails",instructorDetails)

  const submit = () => {
    setEditFormData("id",id);
    setEditFormData("instructor_name", instructor_name);
    setEditFormData("email", email)
    setEditFormData("phone_number", phone_number)
    setEditFormData("address", address)

  }


  return (
    <>
      <CommonModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        modalTitle="Edit Instructor Profile"
        mainContent={
          <>

            <div className='flex justify-center'>
              <div>
                <div className='mt-s20'>
                  <ProfileImageUploader 
                  iImage={image ? BaseUrlSrc + image : ''} 
                  imageName="image" 
                  imageUploader={setEditFormData}
                  categoryImg={false} 
                  />
                  </div>

                {/* <div className="flex justify-center mt-s20"
                  onClick={() => { setShowInstructorChangePassModal(true) }}
                >
                  <span className="cursor-pointer font-fw600 text-fs14 text-cBrandColor">Change Password</span>
                </div> */}

              </div>
            </div>

            <form onSubmit={formSubmit}>
              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  max_input="50"
                  label="Name"
                  value={instructor_name}
                  onChange={(e) => { setInstructor_name(e.target.value) }}
                  placeholder="Name"

                />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="email"
                  label="Email"
                  value={email}
                  placeholder="Email"
                  disabled={true}
                  notEditable={true}
                />
              </div>


              <div className="mb-s20">
                <CommonInput
                  value={phone_number}
                  pattern="[+]{1}[0-9]{11,14}"
                  onChange={(e) => { setPhone_number(e.target.value) }}
                  type="number"
                  max_input="15"
                  label="Phone Number"
                  placeholder="Phone Number"
                />
              </div>


              <div className="mb-s20">
                <CommonInput
                  type="text"
                  label="Address"
                  max_input={255}
                  value={address}
                  onChange={(e) => { setAddress(e.target.value) }}
                  placeholder="Address" />
              </div>

              <div className='flex justify-center mt-s20'>
                {/* <CommonButton type="submit" btnLabel="Delete" colorType="warning" roundedFull="true" /> */}
                <CommonButton type="submit" btnLabel="Save Changes" colorType="primary" roundedFull="true" />
              </div>

            </form>

          </>
        }

      />
    </>
  );
};

export default InstructorEditModal;