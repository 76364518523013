import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonInput from '../../../Components/Input/CommonInput';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonCategoryDetails from '../../../Components/Profile/CommonCategoryDetails';
import CommonTable from '../../../Components/Table/CommonTable';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { t } from 'i18next';
import StudentSchoolLessonTableRow from '../../../Components/Table/Student/StudentSchoolLessonTableRow';
import CommonButton from '../../../Components/Button/CommonButton';
import useStudentStore, { studentFindSchoolAddFn } from '../../../App/Stores/StudentStore';
import CommonEmptyStatus from '../../../Components/CommonEmptyStatus/CommonEmptyStatus';
import { NACheck, PageTitle, percentageCalculate, PercentageCalculate } from '../../../Utility/UtilityFunctions';
import { useEffect } from 'react';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import StudentSchoolLessonApplyTableRow from '../../../Components/Table/Student/StudentSchoolLessonApplyTableRow';
import { Box } from '@mui/material';
import { Toastr } from '../../../App/Utility/UtilityFunctions';

const StudentSchoolCategoryDetails = () => {

    const {
        studentFindSchoolCategoryInfo,
        fullPackageChecked,
        setFullPackageChecked,
        setStudentFindSchoolLessonId,
        studentFindSchoolLessonId,
        studentSchoolPackagePrice,
        setStudentSchoolPackagePrice,
        studentSchoolMomsPrice,
        studentFindSchoolApply,
        setStudentFindSchoolApply

    } = useStudentStore();

    const [state, setstate] = useState(false);

    const [color, setColor] = useState('#000000');

    const [student_note, setStudent_note] = useState("");

    const { student_id, school_id } = useParams();

    const navigate = useNavigate();

    const schoolCategoryHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Duration") },
        { index: 4, name: t("Mandatory") },
        { index: 5, name: t("Price") },
    ];

    //console.log("studentFindSchoolCategoryInfo", studentFindSchoolCategoryInfo)

    const { setBarTitle } = useLayoutStore();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle("Student School Category");
        setBarTitle("Student School Category");
        setstate(fullPackageChecked)
    }, [fullPackageChecked, setBarTitle])


    useEffect(() => {
        if (state) {
            setColor('#FFFFFF')
        }
        else {
            setColor('#000000')
        }
    }, [state]);

    return (
        <div className='mx-s12 md:mx-s32'>


            {studentFindSchoolApply ? <CommonTitle CommonTitle title="Student School Category">
                <BackLink linksArray={[
                    { label: "Student", linkTo: "/student" },
                    { label: "Student Details", linkTo: `/student/details/${student_id}` },
                    { label: "Student School", linkTo: `/student/details/${student_id}/school` },
                    { label: "Student School Profile", linkTo: `/student/details/${student_id}/school/${school_id}` },
                    { label: "Student School Category", linkTo: `` }
                ]} />
            </CommonTitle> :

                <CommonTitle CommonTitle title="Student School Category Apply">
                    <BackLink linksArray={[
                        { label: "Student", linkTo: "/student" },
                        { label: "Student Details", linkTo: `/student/details/${student_id}` },
                        { label: "Student School", linkTo: `/student/details/${student_id}/school` },
                        { label: "Student School Profile", linkTo: `/student/details/${student_id}/school/${school_id}` },
                        {
                            label: "Student School Category",
                            linkTo: `/student/details/${student_id}/school/${school_id}/category`,
                            linkFn: () => { setStudentFindSchoolApply(true) }
                        },
                        { label: "Student School Apply", linkTo: `` }
                    ]} />
                </CommonTitle>
            }


            {
                studentFindSchoolCategoryInfo?.category_id ?
                    <div className="bg-cBrandColor2 rounded-br20 py-s20 overflow-hidden">
                        <div className='px-s20'>
                            <CommonCategoryDetails
                                categoriesImage={studentFindSchoolCategoryInfo?.icon}
                                categoryName={studentFindSchoolCategoryInfo?.category_name}
                                lessonDuration={studentFindSchoolCategoryInfo?.duration}
                                totalLesson={studentFindSchoolCategoryInfo?.lessons}
                            />
                        </div>


                        <div className="pt-s20 px-1">
                            <hr />
                        </div>

                        {studentFindSchoolApply ? <div>
                            <div className='px-s20'>
                                <div className={`${state ? 'bg-cBrand' : "bg-cInvoiceLesson"}  flex justify-between px-s20 rounded-br10 py-s5 text-center`}>
                                    <div className='flex justify-center items-center'>
                                        <div>
                                            <FormControlLabel
                                                label={
                                                    <Box component="div" fontSize={14} fontWeight={600} color={color}>
                                                        Buy full Package
                                                    </Box>
                                                }
                                                control={<Checkbox
                                                    style={{
                                                        color: color,
                                                    }}
                                                    checked={state}
                                                    onChange={async (e) => {
                                                        await setFullPackageChecked(!fullPackageChecked);
                                                        setstate(!state)
                                                        const item = studentFindSchoolCategoryInfo?.category_lessons.filter(value => value.is_mandatory)

                                                        var newArr = item?.map(function (val, index) {
                                                            return val?.id
                                                        })

                                                        if (!state) {
                                                            await setStudentFindSchoolLessonId(newArr)
                                                        }
                                                        else {
                                                            await setStudentFindSchoolLessonId([])

                                                            await setStudentSchoolPackagePrice("0")
                                                        }

                                                    }} />}
                                            />
                                        </div>
                                        {/* <span className='text-cBrandColor2 text-fs14 font-fw600'>Buy full package</span> */}
                                    </div>

                                    <div className={`text-fs15 font-fw600 ${state?'text-cCard':'caret-black'} flex justify-center items-center`}>
                                        DKK {studentFindSchoolCategoryInfo?.total_lesson_price
                                            ? studentFindSchoolCategoryInfo?.total_lesson_price : 'NA'}</div>
                                </div>
                            </div>

                            <CommonTable
                                headers={schoolCategoryHeaders}
                                tableTitle="Lessons"
                                shoSearchBox={false}
                                pagination={false}
                                showPageCountText={false}
                                showPagination={false}
                                items={
                                    <>
                                        {studentFindSchoolCategoryInfo?.category_lessons?.length > 0 ?
                                            studentFindSchoolCategoryInfo?.category_lessons?.map((item, index) => (
                                                <StudentSchoolLessonTableRow data={item} index={index} key={index} mandatory={state} />
                                            )) : <tr className='w-full'>
                                                <th colSpan={5} className="py-s10">
                                                    No Data Found !
                                                </th>
                                            </tr>
                                        }
                                    </>
                                }
                            />

                            <div className='mx-s20'>
                                <div>
                                    <div className='flex justify-between'>
                                        <span className='text-cLesson font-fw400 text-fs14'>MOMS</span>
                                        <span className='text-cLesson font-fw400 text-fs14'>
                                            DKK {` `}
                                            {state ? percentageCalculate(
                                                studentSchoolMomsPrice, studentFindSchoolCategoryInfo?.total_lesson_price)

                                                : studentFindSchoolLessonId.length > 0 ? percentageCalculate(
                                                    studentSchoolMomsPrice, studentSchoolPackagePrice) : ' 0'
                                            }


                                        </span>
                                    </div>

                                    <div className='flex justify-between mt-s5'>
                                        <span className='text-cBrand font-fw600 text-fs14'>Total</span>
                                        <span className='text-cBrand font-fw600 text-fs14'>
                                            DKK {` `}
                                            {
                                                state ? studentFindSchoolCategoryInfo?.total_lesson_price :
                                                    `DKK  ${studentFindSchoolLessonId.length > 0 ? percentageCalculate(
                                                        studentSchoolMomsPrice, studentSchoolPackagePrice) + studentSchoolPackagePrice : ' 0'} `}
                                        </span>
                                    </div>
                                </div>


                                <div className="pt-s20 px-1">
                                    <hr />
                                </div>

                                <div className='my-s20'>
                                    <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Description</span>
                                    <div className='mt-s5'>
                                        {studentFindSchoolCategoryInfo.description ? NACheck(studentFindSchoolCategoryInfo?.description) :
                                            <CommonEmptyStatus
                                                size='text-fs12'
                                                fontWeight='font-fw400'
                                                leading="leading-5"
                                            />}

                                    </div>
                                </div>
                                <div className='my-s20'>
                                    <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Requirement</span>
                                    <div className='mt-s5'>
                                        {studentFindSchoolCategoryInfo.requirements ?
                                            NACheck(studentFindSchoolCategoryInfo?.requirements) :
                                            <CommonEmptyStatus
                                                size='text-fs12'
                                                fontWeight='font-fw400'
                                                leading="leading-5"
                                            />}
                                    </div>
                                </div>


                                <div className='flex justify-center mt-s20'>
                                    <CommonButton
                                        onClick={() => {
                                            // const body = {
                                            //     "school_id": studentFindSchoolCategoryInfo?.school_id,
                                            //     "student_id": student_id,
                                            //     "school_category_id": studentFindSchoolCategoryInfo?.id,
                                            //     "lesson_ids": studentFindSchoolLessonId,
                                            //     "student_note": student_note
                                            // }
                                            // studentFindSchoolAddFn(body)
                                            if (studentFindSchoolLessonId.length > 0) {
                                                window.scrollTo(0, 0);
                                                setStudentFindSchoolApply(false)
                                            } else {
                                                Toastr({
                                                    message: "You Are Not Select Lesson", type: "warning"
                                                });
                                            }

                                        }}
                                        btnLabel='Next' />
                                </div>
                            </div>

                        </div>
                            :
                            <div>
                                <CommonTable
                                    headers={schoolCategoryHeaders}
                                    tableTitle="Lessons"
                                    shoSearchBox={false}
                                    pagination={false}
                                    showPageCountText={false}
                                    showPagination={false}
                                    items={
                                        <>
                                            {
                                                studentFindSchoolLessonId?.map((item, index) => (

                                                    <>
                                                        <StudentSchoolLessonApplyTableRow
                                                            data={studentFindSchoolCategoryInfo?.category_lessons?.filter(item2 => item2.id === item)}
                                                            index={index}
                                                            key={index}
                                                        />
                                                    </>
                                                ))
                                            }
                                        </>
                                    }
                                />
                                <div className='mx-s20'>
                                    <div>
                                        <div className='flex justify-between'>
                                            <span className='text-cLesson font-fw400 text-fs12'>MOMS</span>
                                            <span className='text-cLesson font-fw400 text-fs12'>
                                                DKK {` `}
                                                {state ? percentageCalculate(
                                                    studentSchoolMomsPrice, studentFindSchoolCategoryInfo?.total_lesson_price)

                                                    : studentFindSchoolLessonId.length > 0 ? percentageCalculate(
                                                        studentSchoolMomsPrice, studentSchoolPackagePrice) : ' 0'
                                                }


                                            </span>
                                        </div>

                                        <div className='flex justify-between mt-s5'>
                                            <span className='text-cBrand font-fw600 text-fs16'>Total</span>
                                            <span className='text-cBrand font-fw600 text-fs16'>
                                                DKK {` `}
                                                {
                                                    state ? studentFindSchoolCategoryInfo?.total_lesson_price :
                                                        `DKK  ${studentFindSchoolLessonId.length > 0 ? percentageCalculate(
                                                            studentSchoolMomsPrice, studentSchoolPackagePrice) + studentSchoolPackagePrice : ' 0'} `}
                                            </span>
                                        </div>
                                    </div>


                                    <div className="pt-s20 px-1">
                                        <hr />
                                    </div>

                                    <div className='my-s20'>
                                        <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Description</span>
                                        <div className='mt-s5'>
                                            {studentFindSchoolCategoryInfo.description ? NACheck(studentFindSchoolCategoryInfo?.description) :
                                                <CommonEmptyStatus
                                                    size='text-fs12'
                                                    fontWeight='font-fw400'
                                                    leading="leading-5"
                                                />}

                                        </div>
                                    </div>
                                    <div className='my-s20'>
                                        <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Requirement</span>
                                        <div className='mt-s5'>
                                            {studentFindSchoolCategoryInfo.requirements ?
                                                NACheck(studentFindSchoolCategoryInfo?.requirements) :
                                                <CommonEmptyStatus
                                                    size='text-fs12'
                                                    fontWeight='font-fw400'
                                                    leading="leading-5"
                                                />}
                                        </div>
                                    </div>

                                    <CommonInput
                                        textarea={true}
                                        label="Write Comment"
                                        type='text'
                                        placeholder="Write comment"
                                        value={student_note}
                                        onChange={(e) => { setStudent_note(e.target.value) }}
                                    />

                                    <div className='flex justify-center mt-s20'>
                                        <CommonButton
                                            onClick={() => {
                                                const body = {
                                                    "school_id": studentFindSchoolCategoryInfo?.school_id,
                                                    "student_id": student_id,
                                                    "school_category_id": studentFindSchoolCategoryInfo?.id,
                                                    "lesson_ids": studentFindSchoolLessonId,
                                                    "student_note": student_note
                                                }
                                                const success = studentFindSchoolAddFn(body)
                                                if (success) {
                                                    setTimeout(() => {
                                                        navigate(`/student/details/${student_id}`)
                                                    }, 1000);

                                                }
                                            }}
                                            btnLabel='Save' />
                                    </div>
                                </div>
                            </div>
                        }

                    </div> : <> No Data Found {navigate(`/student/details/${student_id}/school/${school_id}`)}</>
            }



        </div >
    );
};

export default StudentSchoolCategoryDetails;