import React from "react";
import { useState } from "react";
import useSettingsStore, { createCity } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";

const AddCityModal= () => {

  const { showAddCityModal, setShowAddCityModal } = useSettingsStore();

  const [addForm, setAddForm] = useState({
    name: "",
  });

  const formSubmit = async (e) => {
    e.preventDefault();

    if (addForm?.name) {
      let addSuccess = await createCity(addForm)
      if (addSuccess) {
         setAddForm({ name:"" });
        await setShowAddCityModal(false)
      }
    }
  };


  return (
    <div>
      <CommonModal
        showModal={showAddCityModal}
        setShowModal={setShowAddCityModal}
        modalTitle="Add City"
        mainContent={
          <>
            <form onSubmit={formSubmit}>
            <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Write City"
                  className3="cHighlightedTexts"
                  placeholder="Write City"
                  value={addForm?.name}
                  onChange={(e) => { setAddForm({ ...addForm, name: e.target.value }) }}
                />
              </div>

              <div className="flex justify-center">
                <CommonButton
                  type="submit"
                  btnLabel="Add"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default AddCityModal;
