import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSchoolStore, { schoolCategoriesEditFn } from '../../../../App/Stores/SchoolStore';
import { iCategory } from '../../../../App/Utility/source';
import { BaseUrlSrc } from '../../../../App/Utility/Url';
import { Toastr } from '../../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../../Components/Button/CommonButton';
import Image from '../../../../Components/Image/Image';
import ProfileImageUploader from '../../../../Components/Image/ProfileImageUploader';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';

const EditSchoolCategory = () => {

    const { school_category_id } = useParams();

    const { schoolCategoryShow, setShowEditSchoolCategoryModal, showEditSchoolCategoryModal } = useSchoolStore();

    const [updateFrom, setUpdateFrom] = useState({
        id: "",
        // name: "",
        price: '',
        description: "",
        requirement: ""
    })

    // const deleteCategory = async () => {
    //     await setDeleteCategoryForm(id);
    //     await setShowDeleteCategoryModal(true);
    // }

    useEffect(() => {
        setUpdateFrom({
            id: schoolCategoryShow?.id,
            // name: schoolCategoryShow?.category?.name,
            price: schoolCategoryShow?.price === 0 ? schoolCategoryShow?.package_price : schoolCategoryShow?.price,
            description: schoolCategoryShow?.description,
            requirement: schoolCategoryShow?.requirement
        })
    }, [schoolCategoryShow])

    const resetData = () => {
        setUpdateFrom({
            id: "",
            // name: schoolCategoryShow?.category?.name,
            price: "",
            description: "",
            requirement: ""
        })
    }

    //console.log("schoolCategoryShow", schoolCategoryShow)

    return (
        <div>
            <CommonModal
                showModal={showEditSchoolCategoryModal}
                setShowModal={setShowEditSchoolCategoryModal}
                modalTitle="Edit Category"
                mainContent={
                    <>
                        <div className='flex justify-center'>
                            <div className='mt-s25 mb-s25'>
                                <div className='rounded-full w-s155 h-s150 pr-s6 pl-s9 bg-cBackgroundAndCategory'>
                                    <Image className='h-s144 w-s150 p-s20' src={schoolCategoryShow?.category?.icon}
                                        dummyImage={iCategory} isCategoryImage={true} />
                                </div>
                            </div>

                        </div>

                        <div className='flex justify-center text-fs14 font-fw600 text-cHighlighted mb-s20'>
                            Category Name : {schoolCategoryShow?.category?.name}</div>

                        <form onSubmit={async (e) => {
                            e.preventDefault()
                            if (schoolCategoryShow?.package_price < updateFrom.price) {
                                Toastr({ message: "The Price Cannot be Higher then the Package Price", type: "error" });
                                setUpdateFrom({ ...updateFrom, price: schoolCategoryShow?.package_price })
                            } else {
                                let successCategoriesEdit = await schoolCategoriesEditFn(updateFrom)

                                if (successCategoriesEdit) {
                                    resetData()
                                    setShowEditSchoolCategoryModal(false)
                                }
                            }


                        }}>

                            <div className='mb-s20' >
                                <CommonInput required={true}
                                    type="text"
                                    label="Price"
                                    placeholder="Price"
                                    value={updateFrom.price}
                                    onChange={(e) => { setUpdateFrom({ ...updateFrom, price: e.target.value }) }}
                                />
                            </div>
                            <div className='mb-s20' >
                                <CommonInput
                                    type="text"
                                    label="Description"
                                    placeholder="Description"
                                    rows="3" textarea="true"
                                    value={updateFrom.description}
                                    onChange={(e) => { setUpdateFrom({ ...updateFrom, description: e.target.value }) }}
                                />
                            </div>

                            <div className='mb-s20' >
                                <CommonInput
                                    type="text"
                                    label="Edit Requirement"
                                    placeholder="Requirement"
                                    rows="3" textarea="true"
                                    value={updateFrom.requirement}
                                    onChange={(e) => { setUpdateFrom({ ...updateFrom, requirement: e.target.value }) }}
                                />
                            </div>
                            <div className='flex justify-center mt-s20'>
                                <CommonButton type="submit" btnLabel="Update" colorType="primary" roundedFull="true" />
                            </div>
                        </form>
                    </>
                }

            />
        </div>
    );
};

export default EditSchoolCategory;