import React from 'react'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';
import useSettingsStore, { deleteTermsCondition } from '../../../App/Stores/SettingsStore';
import { useNavigate } from 'react-router-dom';

function TermsAndConditionDeleteModal() {

    const navigate = useNavigate()

    const {
        showTermsAndConditionDeleteModal,
        setShowTermsAndConditionDeleteModal,
        setShowTermsAndConditionDetailsModal,
        TermsConditionDetail,
    } = useSettingsStore();

    //console.log("TermsConditionDetail", TermsConditionDetail[0])

    return (
        <>
            <CommonModal
                showModal={showTermsAndConditionDeleteModal}
                setShowModal={setShowTermsAndConditionDeleteModal}
                modalTitle="Confirmation"
                mainContent={
                    <>
                        <div className="flex justify-center pt-5">Are you sure you want to Delete this Terms Conditions?</div>
                        <div className="flex justify-center pt-5">
                            <CommonButton
                                colorType='danger'
                                btnLabel='Delete'
                                onClick={async () => {
                                    let deactivateSuccess = await deleteTermsCondition(TermsConditionDetail[0]?.id)
                                    if (deactivateSuccess) {
                                        setShowTermsAndConditionDeleteModal(false);
                                        setTimeout(async () => {
                                            navigate('/settings/terms-condition')
                                        }, 500);
                                    }
                                }}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default TermsAndConditionDeleteModal