import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import useStudentStore, { shareStudentCurriculum } from '../../../App/Stores/StudentStore';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonModal from '../../../Components/Modal/CommonModal';

function ShareCurriculumDetailsModal() {
    const {
        showShareCurriculumDetailsModal,
        setShowShareCurriculumDetailsModal,
        studentCurriculumDetailShareId,
        setStudentCurriculumDetailShareId
    } = useStudentStore();

    const [email, setEmail] = useState("")

    const {student_id}=useParams()

    const submitData=(e)=>{
        e.preventDefault();
        const body = {
            user_id:  studentCurriculumDetailShareId,
            student_id:  student_id,
            role: "student",
            type: "curriculum",
            email: email
        }
        // console.log("body", body)
        let sendSuccess = shareStudentCurriculum(body)
        
        if (sendSuccess) {
            setShowShareCurriculumDetailsModal(false);
            setStudentCurriculumDetailShareId("")
            setEmail("")
        }
    }

    return (
        <>
            <CommonModal
                showModal={showShareCurriculumDetailsModal}
                setShowModal={setShowShareCurriculumDetailsModal}
                modalTitle="Share Curriculum Details"
                mainContent={
                    <form onSubmit={submitData}>
                        <div className="py-5">
                            <CommonInput
                                label={"Email"}
                                required={true}
                                type="email"
                                placeholder="Enter a valid email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-center">
                            <CommonButton
                                btnLabel='share'
                                type='submit'
                               
                            />
                        </div>
                    </form>
                }
            />
        </>
    )
}

export default ShareCurriculumDetailsModal