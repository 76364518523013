import React from 'react'
import { useState } from 'react';

const CommonCard = ({ title, number, onClick,note,cardIcon }) => {
  const [color,setColor]=useState("text-cHighlighted");
  const [color2,setColor2]=useState("text-cImportantText");
  return (
    <div 
      onClick={onClick} onMouseOver={()=>{
      setColor("text-cLessImportant")
      setColor2("text-cLessImportant")
      }} 
      onMouseOut={()=>{
        setColor("text-cHighlighted")
        setColor2("text-cImportantText")
        }} className='flex relative flex-col justify-center items-center h-40 border cursor-pointer shadow-cShopItem bg-cBrandColor2 border-cChipBorder hover:border-cBrand rounded-br10 hover:bg-cBrandColor lg:h-44'>        
        {title && number && <div className=""></div>}
        <div className='flex justify-center items-center rounded-full bg-cBackgroundAndCategory w-s60 h-s60 mb-s8'>{cardIcon && <img className='w-s30 h-s30' src={cardIcon?cardIcon:''} alt=""/>}</div>
        {title && <div className={`text-fs16 ${note && "h-12"} h-h24 font-fw600  ${color2}`} >{title}</div>}
        {note && <div className={`text-fs12 h-h12 font-fw400 ${color2}`} >{note}</div>}
    </div>
  )
}

export default CommonCard