import React from 'react'
import useStudentStore, { studentToggleStatus } from './../../../App/Stores/StudentStore';
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonButton from './../../../Components/Button/CommonButton';

function StudentDeactivateModal() {

    const { showDeactivateStudent , setShowDeactivateStudent ,studentDetails} = useStudentStore();

    //console.log("studentDetails",studentDetails)

    return (
        <>
            <CommonModal
                showModal={showDeactivateStudent}
                setShowModal={setShowDeactivateStudent}
                modalTitle="Confirmation"
                mainContent={
                    <div className='flex flex-col items-center'>
                        <div className="pt-5">Are you sure you want to deactivate this student?</div>
                        <div className="pt-5">
                            <CommonButton
                                btnLabel='Deactivate'
                                colorType='danger'
                                onClick={async() => {
                                    let deactivateSuccess = await studentToggleStatus(studentDetails?.id);
                                    if (deactivateSuccess) setShowDeactivateStudent(false);
                                }} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default StudentDeactivateModal