
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { iCategory, iRightArrow } from '../../../App/Utility/source';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';

const SchoolCategoryTableRow = ({ data, index }) => {

    const navigate = useNavigate();

    const { school_id, school_category_id } = useParams()

    //console.log("CategoryData", data);

    return (
        <>
            <tr onClick={() => { navigate(`/school/details/${school_id}/category/details/${data?.id}`) }}
                className={`border-b border-collapse cursor-pointer  border-cNmSelect hover:bg-cGridView`} >
                <th className='font-normal text-center border-r py-s10'>
                    <span className="">
                        {index + 1}
                    </span>
                </th>

                <td className='border-r-[1px] py-s10 text-center px-s10 max-w-[120px] min-w-[100px] '>
                    <div className='flex min-w-[80px] sm:flex-col md:flex-row'>
                        <div className='rounded-full w-s44 h-s44 py-s11 pr-s10 pl-s9 bg-cBackgroundAndCategory'>
                            <Image cursorPointerClass="cursor-pointer" src={data?.category?.icon} className='h-s22 w-s24' dummyImage={iCategory} isCategoryImage={true} />
                        </div>
                        <div className='items-center flex justify-center font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12'>
                            {data?.category?.name ? data?.category?.name : <CommonEmptyStatus />}
                        </div>
                    </div>
                </td>

                <td className='p-s10 border-r-[1px] text-fs14 text-center text-cTextBody truncate'>
                    <span className='font-fw400 text-cImportantText text-fs14'>
                        {data?.package_lesson ? data?.package_lesson : <CommonEmptyStatus />}

                    </span>
                </td>

                <td className='py-s10 border-r-[1px] text-fs14 text-center text-cTextBody'>
                    <span className='font-fw400 text-cImportantText text-fs14'>
                        {data?.package_duration ? toHoursAndMinutes(data?.package_duration) : <CommonEmptyStatus />}
                    </span>
                </td>

                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 font-fw400 text-center text-cTextBody'>
                    <span className='font-fw400 text-cImportantText text-fs14'>
                        {
                           data?.price === 0 ? data?.package_price ===0 || 
                           data?.package_price === null? 'NA': `${data?.package_price} DKK`: `${data?.price} DKK`
                        }
                    </span>
                </td>

                <td className='py-s10 border-r-[1px] px-s10 text-center'>
                    <span className='flex justify-between'>
                        <span></span>
                        <span className='justify-center my-s8 font-fw400 ml-s10 text-cImportantText lg:text-fs14 sm:text-fs12'>
                            <span className={`font-fw600 text-fs14 ${data?.is_active ? "text-cPassed" : "text-cCancelled"}`}>{data?.is_active ? "Active" : "Deactivate"}</span>
                        </span>

                        <span className=""><img className="mt-s12" src={iRightArrow} alt=""></img> </span> </span>
                </td>
            </tr>
        </>
    )
}


export default SchoolCategoryTableRow;