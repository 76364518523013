import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSettingsStore from "../../../App/Stores/SettingsStore";
import { iAddItem, iBackArrow, iBackArrowActive, iCross } from "../../../App/Utility/source";
import CommonButton from "../../../Components/Button/CommonButton";
import ProfileImageUploader from "../../../Components/Image/ProfileImageUploader";
import SelectInput from "../../../Components/Input/SelectInput";
import RichTextEditor from "../../../Components/TextEditor/RichTextEditor";
import Settings from "../Settings";
import { editTermsCondition } from './../../../App/Stores/SettingsStore';
import { BaseUrlSrc } from './../../../App/Utility/Url';

const EditGDPRTerms = () => {

    const navigate = useNavigate()

    const [is_mouse_over_back_arrow, setIsMouseOverBackArrow] = useState(false);

    const { setShowWriteTermsAndConditionModal,
        TermsConditionDetail,
        setTermsConditionEditFormData,
        updateTermsData,
        TermsConditionEditFormData
    } = useSettingsStore();

    const [addForm, setAddForm] = useState({
        id: "",
        app_type: '',
        image: ''
    });


    const userArray = [
        {
            title: 'student',
            value: 'student',
            selected: addForm?.app_type === 'student' ? true : false
        },

        {
            title: 'instructor',
            value: 'instructor',
            selected: addForm?.app_type === 'instructor' ? true : false
        },
        {
            title: 'school',
            value: 'school',
            selected: addForm?.app_type === 'school' ? true : false
        },
    ]


    const TermsConditionEdit = async (e) => {
        e.preventDefault();
        // setTermsConditionEditFormData("content",addForm.content)
        setTermsConditionEditFormData("app_type", addForm.app_type)
        setTermsConditionEditFormData("id", addForm.id)

        if (addForm?.id && addForm?.app_type) {
            let addSuccess = await editTermsCondition()
            if (addSuccess) {
                await setAddForm({ description: "", app_type: "" });
                await navigate('/settings/GdprTerms/details')
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setAddForm({
                id: TermsConditionDetail[0]?.id,
                content: TermsConditionDetail[0]?.content,
                app_type: TermsConditionDetail[0]?.app_type,
                image: TermsConditionDetail[0]?.image
            })
            updateTermsData(TermsConditionDetail[0]?.content)
        }, 500);

    }, [TermsConditionDetail])
    return (

        <Settings>
            {TermsConditionDetail[0] ?
                <div>
                    <div className="flex justify-between items-center">
                        <div className="flex ">
                            <img
                                onMouseLeave={() => { setIsMouseOverBackArrow(false) }}
                                onMouseOver={() => { setIsMouseOverBackArrow(true); }}
                                onClick={() => navigate("/settings/GdprTerms/details")}
                                src={is_mouse_over_back_arrow ? iBackArrowActive : iBackArrow}
                                alt=""
                                className="cursor-pointer mr-s10"
                            />

                            <h1 className="font-semibold text-fs18">GDPR & Terms</h1>
                        </div>
                        <div className="contents cursor-pointer w-s250">
                            <CommonButton
                                colorType="primary"
                                btnLabel="GDPR & Terms"
                                roundedFull="true"
                                onClick={() => {
                                    setShowWriteTermsAndConditionModal(true);
                                }}
                                icon={
                                    <div className="mr-s5">
                                        <img className="w-s20 h-s15" src={iAddItem} alt="" />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <hr className="my-s10" />
                    <form onSubmit={TermsConditionEdit}>
                        <div className="flex justify-center my-s20">
                            <ProfileImageUploader rounded={false} iImage={TermsConditionDetail[0]?.image ? BaseUrlSrc + TermsConditionDetail[0]?.image : ''} imageUploader={setTermsConditionEditFormData} imageName="image" />
                        </div>
                        <div className="">
                            <SelectInput
                                required={true}
                                label="Choose User"
                                placeholder="Choose User"
                                dataArray={userArray}
                                selectOptionOnChange={(e) => setAddForm({ ...addForm, app_type: e })}
                            />
                        </div>

                        <div className="my-s30 h-[320px]">
                            <RichTextEditor
                                required={true}
                                label="Write Description"
                                textarea="true"
                                placeholder="Write here..."
                                onChange={(e) => updateTermsData(e)}
                                value={TermsConditionEditFormData?.content}
                                height="250px"
                            />
                        </div>
                        <div className="flex justify-center mt-s20">
                            <CommonButton
                                type="submit"
                                btnLabel="Update"
                                colorType="primary"
                                roundedFull="true"
                            />
                        </div>
                    </form>
                </div>
                : <> No Data Found{navigate('/settings/terms-condition')}</>}
        </Settings>
    );
};

export default EditGDPRTerms;