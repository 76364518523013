import { Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { iRightArrow } from '../../../App/Utility/source';
import { valueCheck } from '../../../Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';

const SchoolInstructorTableRow = ({ data, index }) => {

    const navigate = useNavigate();

    const { school_id } = useParams();

    return (
        <>
            <tr onClick={() => {
                navigate("/school/details/" + school_id + "/instructor/" + data?.id);
            }} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView m-s10`} >
                <th className='font-normal text-center border-r p-s10'>
                    <span>{index}</span>
                </th>

                <td className='border-r-[1px] text-center p-s10 min-w-[200px] max-w-[200px] truncate'>
                    <div className='flex items-center sm:flex-col md:flex-row'>
                        <div className='ml-s4 min-w-[50px] max-w-[50px]'>
                            <Image
                                cursorPointerClass="cursor-pointer"
                                className='w-w44 h-h44 rounded-full'
                                src={data?.instructor?.profile_photo}
                                alt="" />
                        </div>
                        <div className='capitalize items-center font-fw600 ml-s8 text-cMainBlack lg:text-fs14 sm:text-fs12 truncate' >
                            {data?.instructor?.name ?
                                <Tooltip title={data?.instructor?.name}>
                                    <span className='truncate'>{valueCheck(data?.instructor?.name)} </span>
                                </Tooltip>
                                : <CommonEmptyStatus />}
                        </div>
                    </div>
                </td>

                <td className='truncate py-s10 border-r-[1px] p-s10 text-fs14 text-center text-cTextBody min-w-[150px] max-w-[150px]'>
                    <Tooltip title={data?.instructor?.email}>
                        <span className='font-fw400 text-cImportantText text-fs14'>
                            {data?.instructor?.email ? valueCheck(data?.instructor?.email) : <CommonEmptyStatus />}
                        </span>
                    </Tooltip>
                </td>

                <td className='truncate py-s10 border-r-[1px] p-s10 text-fs14 text-center text-cTextBody min-w-[150px]'>
                    <span className='font-fw400 text-cImportantText text-fs14'>
                        {data?.instructor?.phone_number ? valueCheck(data?.instructor?.phone_number) : <CommonEmptyStatus />}
                    </span>
                </td>

                {/* <td className='p-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[200px] max-w-[200px] 
                truncate'>
                    <span className='text-center font-fw400 text-cImportantText text-fs14 flex justify-center'>
                        {
                            data?.app_categories?.length > 0 ?
                                data?.app_categories?.map((item, index) => (
                                    <div key={index} className='truncate'>
                                        <Clamp withTooltip lines={1}>{item?.school_category.category?.name}
                                        {`${data?.app_categories?.length === 1 &&
                                                data?.app_categories?.length === index + 1 ?
                                                '' : `${data?.app_categories?.length  === index + 1 ?
                                                    "." : ','}`}`} 
                                        </Clamp>
                                    </div>
                                ))
                                : 'NA'
                        }
                    </span>
                </td> */}

                <td className='p-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[250px]
                truncate'>
                    <Tooltip title={
                        data?.app_categories?.length > 3 ? data?.app_categories?.map((item, indexExp) => (indexExp + 1 < data?.app_categories?.length) ? item?.school_category?.category?.name + ", " : item?.school_category?.category?.name + "") : ""
                    } >
                        <span className='text-center font-fw400 text-cImportantText text-fs14 flex justify-center items-center space-x-2.5'>
                            {
                                data?.app_categories?.length > 0 ?
                                    data?.app_categories?.length > 3 ?
                                        data?.app_categories?.map((item, indexCat) => (
                                            indexCat < 3 ? <div key={indexCat} className='truncate border-[2px] border-cBrandColor w-[30px] h-[30px] rounded-full text-fs12 font-semibold flex items-center justify-center text-cBrandColor'>
                                                {item?.school_category?.category?.name}
                                            </div> : ""
                                        ))
                                        : data?.app_categories?.map((item, indexCat) => (
                                            <div key={indexCat} className='truncate border-[2px] border-cBrandColor w-[30px] h-[30px] rounded-full text-fs12 font-semibold flex items-center justify-center text-cBrandColor'>
                                                {item?.school_category?.category?.name}
                                            </div>
                                        ))

                                    : 'NA'
                            }
                            {data?.app_categories?.length > 3 ?
                                <span className='font-semibold rounded-full flex items-center justify-center w-[30px] h-[30px] bg-cBrandColor text-fs12 text-white'>{"+" + (data?.app_categories?.length - 3)}</span>
                                : ""}
                        </span>
                    </Tooltip>
                </td>

                <td className='py-s10 border-r-[1px] px-s15 text-fs14 text-center text-cTextBody min-w-[150px]'>
                    <div className='flex justify-center space-x-2.5 items-center'>
                        <span className="capitalize font-fw400 text-cImportantText text-fs14">
                            {data?.status ? valueCheck(data?.status) : <CommonEmptyStatus />}
                        </span>
                        <span className=""><img className="" src={iRightArrow} alt=""></img> </span>
                    </div>
                </td>
            </tr>
        </>

    );
};

export default SchoolInstructorTableRow;