import React from "react";
import { useState } from "react";
import useStudentStore, { editStudentDetail } from "../../../App/Stores/StudentStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import ProfileImageUploader from "../../../Components/Image/ProfileImageUploader";
import { useEffect } from "react";
import { BaseUrlSrc } from "../../../App/Utility/Url";
import { valueCheck } from "../../../Utility/UtilityFunctions";

const EditStudentProfileModal = () => {
  const {
    showStudentEditModal,
    setShowStudentEditModal,
    setShowStudentChangePassModal,
    studentDetails,
    setEditFormData,
    EditFormData
  } = useStudentStore();

  const [id, setID] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [student_name, setStudent_name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [address, setAddress] = useState("");


  const formSubmit=async(e)=>{

    e.preventDefault()
   await submit()
    if(id && email){
      let value=await editStudentDetail(EditFormData)
      if(value){

        setShowStudentEditModal(false)
        setID("")
        setStudent_name("")
        setEmail("")
        setPhone_number("")
        setImage("")
        setAddress("") 
        reSetData()
      }
    }

  }
  useEffect(() => {
    setID(studentDetails?.id);
    setStudent_name(studentDetails?.name);
    setEmail(studentDetails?.email);
    setPhone_number(valueCheck(studentDetails?.phone_number));
    setImage(valueCheck(studentDetails?.profile_photo));
    setAddress(valueCheck(studentDetails?.address));
  }, [studentDetails]);

  //console.log("studentDetails", studentDetails);

  const submit = () => {
    setEditFormData("id",id);
    setEditFormData("student_name", student_name);
    setEditFormData("email", email)
    setEditFormData("phone_number", phone_number)
    setEditFormData("address", address)
  }

  const reSetData = () => {
    setEditFormData("id","");
    setEditFormData("student_name", "");
    setEditFormData("email", "")
    setEditFormData("phone_number", '')
    setEditFormData("address", "")
    setEditFormData("image", "")
  }

  return (
    <>
      <CommonModal
        showModal={showStudentEditModal}
        setShowModal={setShowStudentEditModal}
        modalTitle="Edit Student Profile"
        mainContent={
          <>
            <div className="flex justify-center">
              <div>
                <div className="my-s20">
                <ProfileImageUploader iImage={image ? BaseUrlSrc + image : ''} imageName="image" 
                  imageUploader={setEditFormData}/>
                </div>
                {/* <div
                  className="flex justify-center mb-s20"
                  onClick={() => {
                    setShowStudentChangePassModal(true);
                  }}
                >
                  <span className="cursor-pointer font-fw600 text-fs14 text-cBrandColor">
                    Change Password
                  </span>
                </div> */}
              </div>
            </div>

            <form onSubmit={formSubmit}>
              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  label="Name"
                  max_input="50"
                  value={student_name}
                  onChange={(e) => {
                    setStudent_name(e.target.value);
                  }}
                  placeholder="Name"
                />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="email"
                  label="Email"
                  value={email}
                  placeholder="Email"
                  disabled={true}
                  notEditable={true}
                />
              </div>

                
                <div className="mb-s20">
                  <CommonInput
                    value={phone_number}
                    onChange={(e) => {
                      setPhone_number(e.target.value);
                    }}
                    type="number"
                    max_input="15"
                    label="Phone Number"
                    placeholder="Phone Number"
                    
                  />
                </div>
           

              <div className="mb-s20">
                <CommonInput
                  type="text"
                  label="Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  placeholder="Address"
                  max_input={255}
                />
              </div>

              <div className="flex justify-center mt-s20">
                {/* <CommonButton
                  type="submit"
                  btnLabel="Delete Student"
                  colorType="warning"
                  roundedFull="true"
                /> */}
                <CommonButton
                  type="submit"
                  btnLabel="Save Changes"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
              </form>
          </>
        }
      />
    </>
  );
};

export default EditStudentProfileModal;