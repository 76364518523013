import React, { useState } from "react";
import Clamp from "react-multiline-clamp";
import useBannerStore from "../../App/Stores/bannerStore";
import Image from "../Image/Image";
import BannerCardCategoryType from "./BannerCardCategoryType";
import { iImageIogo } from "./../../App/Utility/source";
import CommonEmptyStatus from "../CommonEmptyStatus/CommonEmptyStatus";

const CommonBannerCard = ({ item }) => {
  const [color, setColor] = useState("text-cHighlightedTexts");
  const { setshowBannerDetailsModal, setGetSingleBannerDetails } =
    useBannerStore();

  return (
    <div
      onClick={() => {
        setshowBannerDetailsModal(true);
        setGetSingleBannerDetails(item);
      }}
      onMouseOver={() => {
        setColor("text-cBrandColor2");
      }}
      onMouseOut={() => {
        setColor("text-cHighlightedTexts");
      }}
      className="cursor-pointer rounded-br10 hover:bg-cBrandColor hover:text-cBrandColor2 my-s20"
    >
      <div className="w-full h-s280 flex flex-col gap-y-s10 p-s16 border-[2px] hover:border-cBrandColor border-cBorderBottom  rounded-br10">
        <Image
          cursorPointerClass="cursor-pointer"
          className="rounded-br10 object-cover w-full h-[135px] "
          src={item?.image}
          dummyImage={iImageIogo}
        />

        <span className="text-fs16 font-fw600">
          <Clamp withTooltip lines={1}>
            {item?.name ? item?.name : <CommonEmptyStatus size="text-fs16" fontWeight="font-fw600"/>}
          </Clamp >
        </span>


        <div className="flex justify-between">
        <BannerCardCategoryType
          categoryType={item?.app_type ? item?.app_type : <CommonEmptyStatus/>}
        />
          <div className={`${item?.status ? 'text-cPassed' : 'text-cFailed'} mt-s2 text-fs10 font-fw400`}>
            {item?.status ? 'Active' : <span className=''>Deactivate</span>} </div>
        </div>
        <div className={`w-full ${color}`}>
          <Clamp withTooltip lines={2}>
            <p>{item?.content ? item?.content : <CommonEmptyStatus/>}</p>
          </Clamp>
        </div>
      </div>
    </div>
  );
};

export default CommonBannerCard;
