import React from 'react';
import CommonModal from '../../../Components/Modal/CommonModal';
import CommonButton from '../../../Components/Button/CommonButton';
import useBannerStore, { bannerToggleStatus } from './../../../App/Stores/bannerStore';

const BannerDeactivateModal = () => {

    const { showBannerDeactivateModal, setShowBannerDeactivateModal, BannerDeactivateID,setshowBannerDetailsModal } = useBannerStore();

    return (
        <div>
            <CommonModal
                showModal={showBannerDeactivateModal}
                setShowModal={setShowBannerDeactivateModal}
                modalTitle="Warning"
                mainContent={
                    <>
                        <div className='text-fs14 font-fw600 text-cHighlighted leading-5 flex justify-center mt-s24 m-s40'>
                            Are you sure you want to deactivate this banner?
                        </div>
                        <div className='flex justify-center'>
                            <CommonButton btnLabel="Deactivate" colorType="warning" roundedFull="true" onClick={async () => {
                                let activateSuccess = await bannerToggleStatus(BannerDeactivateID);
                                if (activateSuccess) {
                                    
                                    setTimeout(() => {
                                        // setshowBannerDetailsModal(false)
                                         setShowBannerDeactivateModal(false)
                                    }, 500);

                                }
                            }} />
                        </div>
                    </>
                }

            />
        </div>
    );
};

export default BannerDeactivateModal;