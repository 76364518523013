import React from "react";
import useSettingsStore, { deleteFaq } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";

const DeleteFaqModal = () => {
  const { showFaqDeleteModal, setShowFaqDeleteModal,faqDetail,setShowEditFaqModal } = useSettingsStore();

  return (
    <div className="">
      <CommonModal
        showModal={showFaqDeleteModal}
        setShowModal={setShowFaqDeleteModal}
        modalTitle="Confirmation"
        widthClass="w-s591"
        mainContent={
          <>
            <div className="">
              <div className="">
                {/* <ProfileImageUploader iImage={iImageIogo} /> */}
                <h1 className="text-center mb-s22 my-5">
                  Are you sure you want to delete this frequently asked
                  questions (FAQ).
                </h1>
              </div>
              <div className="flex justify-center">
                <CommonButton
                  onClick={async() => {

                    const value=await deleteFaq(faqDetail[0]?.id)
                    if(value){
                      await setShowFaqDeleteModal(false)
                      await setShowEditFaqModal(false)
                    }
                  

                  }}
                  btnLabel="Delete"
                  colorType="danger"
                  //width="w-s172"
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default DeleteFaqModal;
