import React from 'react'
import CommonModal from './../../../Components/Modal/CommonModal';
import useStudentStore from './../../../App/Stores/StudentStore';
import { iProfile } from '../../../App/Utility/source';
import CommonButton from './../../../Components/Button/CommonButton';
import InvoiceLessons from './../../../Components/CommonInvoice/InvoiceLessons';
import { iProfile2 } from './../../../App/Utility/source';

function StudentShareReportModal() {

    const { showShareReportModal, setShowShareReportModal } = useStudentStore()

    return (
        <div>
            <CommonModal
                showModal={showShareReportModal}
                setShowModal={setShowShareReportModal}
                modalTitle="Student’s Report"
                mainContent={
                    <>
                        <div className=" mt-s35">
                            <div className='grid grid-cols-2 divide-x'>

                                <div>
                                <div className='mb-s15 text-fs16 font-fw600 text-cBlack'>Student’s Details</div>
                                <div className="flex">
                                    <img className="w-s88 h-s88 rounded-full mb-s18" src={iProfile2} alt="" />
                                    <div className="ml-s15 mt-0">
                                        <div className="font-fw600 text-fs20 text-cHighlighted mb-s2 leading-9">Shahid Sumon</div>
                                        <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">Denmark Driving School</div>
                                        <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">shahidsumon@gmail.com</div>
                                        <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">joined:5 jun 2022</div>
                                    </div>
                                </div>
                                </div>

                                <div className='pl-s40'>
                                <div className='mb-s15 text-fs16 font-fw600 text-cBlack'>School’s Details</div>
                                    <div className="flex">
                                        <img className="w-s88 h-s88 rounded-full mb-s18" src={iProfile} alt="" />
                                        <div className="ml-s15 mt-0">
                                            <div className="font-fw600 text-fs20 text-cHighlighted mb-s2 leading-9">Shahid Sumon</div>
                                            <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">Denmark Driving School</div>
                                            <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">shahidsumon@gmail.com</div>
                                            <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">joined:5 jun 2022</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="py-s20 px-0">
                                <hr />
                            </div>

                            <div className='font-fw600 text-fs16 text-cHighlighted'>Curriculum Details</div>
                            <div className='bg-cBackgroundAndCategory p-s16 rounded-br10 mt-s14'>
                                <InvoiceLessons title="Category" time="A - Bike" />
                                <InvoiceLessons title="Lessons" time="20" />
                                <InvoiceLessons title="Total Hours" time="48" />
                                <InvoiceLessons title="Pending" time="17" />
                                <InvoiceLessons title="Completed" time="03" />

                                <div className='flex justify-between text-cLessImportantText text-fs14 font-fw600 mt-s15'>
                                    <div>MOMS</div>
                                    <div>350 DKK</div>
                                </div>

                                <div className='flex justify-between text-cBrandColor text-fs16 font-fw600 mt-s10'>
                                    <div>Total</div>
                                    <div>1500 DKK</div>
                                </div>
                            </div>

                        </div>

                        <div className='flex justify-center mt-s40'>
                            <CommonButton onClick={() => { setShowShareReportModal(false) }} type="submit" btnLabel="Share Report" colorType="primary" roundedFull="true" />
                        </div>
                    </>
                }

            />
        </div>
    )
}

export default StudentShareReportModal
