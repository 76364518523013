import React from 'react';
import { Outlet } from 'react-router-dom';

const StudentParent = () => {
    return (
        <>
           <Outlet/> 
        </>
    );
};

export default StudentParent;