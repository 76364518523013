import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import useStudentStore, { studentSchoolIndex } from '../../../App/Stores/StudentStore';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import { iSchoolCard, iStudentCard, iWhiteSchoolCard } from '../../../App/Utility/source';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonTable from '../../../Components/Table/CommonTable';
import StudentSchoolTableRow from '../../../Components/Table/Student/StudentSchoolTableRow';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { PageTitle } from '../../../Utility/UtilityFunctions';


const StudentSchool = () => {

    const {
        setShowAddStudentSchoolModal,
        studentSchoolListAll,
        setStudentFindSchoolValue,
        setStudentSchoolFindListAll
        
    } = useStudentStore();

    const location = useLocation();

    const { setLoading } = useUtilityStore();

    const { student_id } = useParams()

    const studentHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("CVR") },
        { index: 4, name: t("Email") },
        { index: 5, name: t("Phone") },
    ];

    // const [searchValue] = useDebounce(studentSearch, 500);

    useEffect(() => {
        SmartSearchAllStudent()
    }, [student_id]);

    const SmartSearchAllStudent = async () => {

        await studentSchoolIndex(student_id)
    }

    const { setBarTitle } = useLayoutStore();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle(t("Student School"));
        setBarTitle("Student School");
    }, []);

    // console.log("studentSchoolListAll", studentSchoolListAll)

    return (
        <div className='mx-s12 md:mx-s32'>

            <CommonTitle title="Student School" >
                <BackLink linksArray={[
                    { label: "Student", linkTo: "/student" },
                    { label: "Student Details", linkTo: `/student/details/${student_id}` },
                    { label: "Student School", linkTo: `` }
                ]} />
            </CommonTitle>

            {/* idea  api call issue */}

            <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
                {studentSchoolListAll?.id ?
                    <CommonTable
                        headers={studentHeaders}
                        tableTitle=""
                        paginationObject={studentSchoolListAll}
                        shoSearchBox={false}
                        showPagination={false}
                        items={<StudentSchoolTableRow data={studentSchoolListAll} index="1" />}
                        titleComponent={
                            <div className='mb-s20'>
                                <CommonButtonOutlined
                                    onClick={() => {
                                        setStudentSchoolFindListAll([])
                                        setStudentFindSchoolValue('')
                                        setShowAddStudentSchoolModal(true);
                                    }}
                                    colorType='primary'
                                    width='w-[180px]'
                                    iconLeft={iWhiteSchoolCard}
                                    iconLeftHover={iSchoolCard}
                                    icon={iWhiteSchoolCard}
                                    btnLabel="Join New School"
                                />
                            </div>
                        }
                    />

                    :
                    <div className="h-[486px] flex items-center justify-center">
                        <div className=''>
                            <div className='text-fs18 font-fw600 text-cHighlightedTexts mb-s10 ml-s7'>No School available</div>
                            <CommonButtonOutlined
                                onClick={() => {
                                    setStudentSchoolFindListAll([])
                                    setStudentFindSchoolValue("")
                                    setShowAddStudentSchoolModal(true);
                                  
                                }}
                                colorType='primary'
                                width='w-[180px]'
                                iconLeft={iWhiteSchoolCard}
                                iconLeftHover={iSchoolCard}
                                icon={iWhiteSchoolCard}
                                btnLabel="Join New School"
                            />
                        </div>
                    </div>}


            </div>
        </div>

    );
};

export default StudentSchool;
