
import React from 'react';
import { useNavigate } from 'react-router';
import useLicenseStore from '../../../App/Stores/LicenseStore';
import { roughLicenseDurationFormatter } from '../../../App/Utility/UtilityFunctions';
import BannerCardCategoryType from '../../bannerCard/BannerCardCategoryType';

const LicenseTableRow = ({ data, index }) => {

  const navigate = useNavigate()

  const { setLicenseDetailsSearchKey, setLicenseDetailsPageUrl } = useLicenseStore()

  return (
    <>
      {/* todo: cursor normal temporarily */}
      <tr onClick={() => {
        navigate(`/license/details/list/${data?.id}`)
        setLicenseDetailsSearchKey("")
        setLicenseDetailsPageUrl("")

      }}
        className={`border-b border-collapse text-center border-cNmSelect cursor-pointer hover:bg-cGridView`}>
        <th className='font-normal text-center border-r'>
          <span className=''>{index}</span>
        </th>


        <td className='border-r-[1px] py-s10 text-left px-s10 min-w-[150px] max-w-[250px]'>
          <div className='flex sm:flex-col md:flex-row'>
            <div className='justify-center truncate max-w-[300px] my-s8 font-fw600 mx-s10 capitalize
            text-cMainBlack lg:text-fs14 sm:text-fs12'>{data?.title}</div>
          </div>
        </td>

        <td className='py-s10 border-r-[1px]  px-s10 truncate text-center text-fs14 text-cTextBody  min-w-[80px]'>
          <span className='font-fw400 text-cImportantText text-fs14'>{roughLicenseDurationFormatter(data?.duration)}</span>
        </td>

        <td className='py-s10 border-r-[1px] mx-s50 text-fs14 text-center text-cTextBody min-w-[150px] '>
          <span className='capitalize font-fw400 text-cImportantText text-fs14 flex justify-center'>
            <BannerCardCategoryType categoryType={data?.type} textSize="text-fs10" width="w-[75px]" />
          </span>
        </td>

        <td className='py-s10 border-r-[1px] px-s10 text-fs14 text-center text-cTextBody min-w-[100px]'>
          <span className='font-fw400 text-cImportantText text-fs14'>DKK {data?.price}</span>
        </td>

        <td className='py-s10 border-r-[1px] px-s10 text-fs14 text-center text-cTextBody'>
          <span className={`font-fw600 text-fs14 ${data?.is_active ? "text-cPassed" : "text-cCancelled"}`}>{data?.is_active ? "Active" : "Deactivate"}</span>
        </td>
      </tr>
    </>
  )
}


export default LicenseTableRow;