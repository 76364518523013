import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStudentStore, { getStudentDetails, shareStudentCurriculum } from '../../../App/Stores/StudentStore';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonModal from '../../../Components/Modal/CommonModal';

function ShareCurriculumHistoryModal() {
    const {
        showShareCurriculumHistoryModal,
        setShowShareCurriculumHistoryModal,
        setStudentCurriculumHistoryShareId,
        studentCurriculumHistoryShareId,
        studentDetails
    } = useStudentStore();

    const [email, setEmail] = useState("")

    const { student_id } = useParams();

    useEffect(() => {
        getStudentDetails(student_id)
    }, [])

    const SubmitData = (e) => {
        e.preventDefault();
        const body = {
            user_id: studentDetails?.user_id,
            student_id: student_id,
            role: "student",
            type: "curriculum_history",
            email: email
        }
        // console.log("body", body)
        let sendSuccess = shareStudentCurriculum(body)

        if (sendSuccess) {
            setShowShareCurriculumHistoryModal(false);
            setStudentCurriculumHistoryShareId("")
            setEmail("")
        }
    }

    // console.log("studentDetails", student_id)

    return (
        <>
            <CommonModal
                showModal={showShareCurriculumHistoryModal}
                setShowModal={setShowShareCurriculumHistoryModal}
                modalTitle="Share Curriculum History"
                mainContent={
                    <form onSubmit={SubmitData}>
                        <div className="py-5">
                            <CommonInput
                                label={"Email"}
                                required={true}
                                type="email"
                                placeholder="Enter a valid email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-center">
                            <CommonButton
                                btnLabel='share'
                                type='submit'
                               
                            />
                        </div>
                    </form>
                }
            />
        </>
    )
}

export default ShareCurriculumHistoryModal