import { Tooltip } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { iImageIogo, iRightArrow } from '../../../App/Utility/source';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';


const SchoolStudentTableRow = ({ data, index }) => {

    const navigate = useNavigate();

    const location = useLocation();

    const { school_id } = useParams();

    const Click = () => {
        
        navigate(`/school/details/${school_id}/student/details/${data?.student_id}`)
    }

    //console.log("data", data)

    return (
        <>
            <tr onClick={Click} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >

                <th className='font-normal text-center border-r p-s10'>
                    {index}
                </th>

                {/* name with image */}
                <td className='border-r-[1px] p-s10 text-left px-s10 min-w-[180px] 2xl:min-w-[250px]'>
                    <div className='flex flex-row items-center'>
                        <div className='min-w-[50px] max-w-[50px]'>
                            <Image cursorPointerClass="cursor-pointer" className='rounded-full w-w44 h-h44 grow-0' src={data?.student_image} />
                        </div>
                        <Tooltip title={data?.student_name}>
                            {data?.student_name ? <div className='font-semibold max-w-[130px] 2xl:max-w-[200px] truncate'>{data?.student_name}</div> :
                                <CommonEmptyStatus />}
                        </Tooltip>
                    </div>
                </td>

                {/* email address */}
                <td className='py-s10 px-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[150px] 2xl:min-w-[200px]'>
                    <Tooltip title={data?.student_email}>
                        <div className='font-fw400 text-cImportantText text-fs14 max-w-[150px] 2xl:max-w-[200px] truncate'>
                            {data?.student_email ? data?.student_email : <CommonEmptyStatus />}
                        </div>
                    </Tooltip>
                </td>

                {/* phone number */}
                <td className='p-s10 border-r-[1px] text-fs14 font-fw400 text-center  text-cTextBody min-w-[180px]'>
                    <span className='font-fw400 text-cImportantText text-fs14 truncate'>
                        {data?.student_phone === "null" || data?.student_phone === null ? <CommonEmptyStatus /> : data?.student_phone}
                    </span>
                </td>

                {/* curriculum */}
                <td className='p-s10 border-r-[1px] text-left min-w-[100px]'>

                    {data?.category ? data?.category ?
                        <div className='flex justify-center items-center space-x-2.5 font-fw600'>
                            <div className='rounded-full w-w44 h-h44'>
                                <Image className='object-contain p-2' src={data?.category_icon} dummyImage={iImageIogo} />
                            </div>
                            <div className='justify-center my-s8 ml-s8 text-cMainBlack lg:text-fs14 sm:text-fs12'>
                                {data?.category ?? <CommonEmptyStatus />}
                            </div>
                        </div> : <CommonEmptyStatus /> : ""}

                </td>

                {/* status of the account */}
                {/* <td className='capitalize p-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[150px]'>
                    <div className='flex justify-center space-x-2.5 items-center'>
                        <div className={`font-fw600 text-fs14
                         `
                         }>
                        {data?.status ? data?.status : "NA"}</div>
                        <img className="" src={iRightArrow} alt=""></img></div>
                </td> */}
            </tr>
        </>
    )
}

export default SchoolStudentTableRow;