import React from 'react'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from './../../../Components/Modal/CommonModal';
import useSettingsStore, { faqToggleStatus } from './../../../App/Stores/SettingsStore';


function FaqDeactivateModal() {

    const { showFaqDeactivateModal, setShowFaqDeactivateModal,FaqActiveID } = useSettingsStore();

    return (
        <>
            <CommonModal
                showModal={showFaqDeactivateModal}
                setShowModal={setShowFaqDeactivateModal}
                modalTitle="conform"
                mainContent={
                    <>
                        <div className="flex justify-center pt-5">Are you sure you want to deactivate this FAQ?</div>
                        <div className="flex justify-center pt-5">
                            <CommonButton
                                colorType='danger'
                                btnLabel='Deactivate'
                                onClick={() => {
                                    let deactivateSuccess = faqToggleStatus(FaqActiveID);
                                    if(deactivateSuccess) setShowFaqDeactivateModal(false);
                                }}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default FaqDeactivateModal