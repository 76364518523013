import React, { useEffect, useState } from 'react';
import { iCloseRed } from '../../App/Utility/source';
import { Toastr } from '../../App/Utility/UtilityFunctions';
import CommonButton from '../Button/CommonButton';
import useContactStore, { getContactUsList } from './../../App/Stores/ContactStore';
import CommonInput from './../Input/CommonInput';
import CommonModal from './CommonModal';

function MessageFilterModal() {

    const { showFilterMessage, setShowFilterMessage, setFilterMode, filterMode, setContactUsSearch, filterForm, setFilterForm, customDateShow, setCustomDateShow } = useContactStore();

    return (
        <>
            <CommonModal
                showModal={showFilterMessage}
                setShowModal={setShowFilterMessage}
                modalTitle="Filter Contact Us"
                widthClass='w-[700px]'

                mainContent={
                    <form onSubmit={(e) => e.preventDefault()} className='text-cChipText'>

                        {/* g: user type */}
                        <div className="pt-5 font-semibold text-fs14">Select User Type</div>
                        <div className="flex pt-s10 space-x-s10">
                            <FilterChip
                                onclick={() => {
                                    console.log("all")
                                    setFilterForm({ ...filterForm, status: "" });
                                }}
                                label={"all"}
                                active={filterForm.status === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, status: "school" });

                                }}
                                label={"Schools"}
                                active={filterForm.status === "school" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, status: "instructor" });

                                }}
                                label={"Instructors"}
                                active={filterForm.status === "instructor" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, status: "student" });

                                }}
                                label={"Students"}
                                active={filterForm.status === "student" ? true : false}
                            />

                            {/* <FilterChip
                                onclick={() => {
                                    console.log("guest")
                                }}
                                label={"guest"}
                            /> */}
                        </div>

                        {/* g: message type */}
                        <div className="pt-5 font-semibold text-fs14">Select Message Type</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_seen: null });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterForm.is_seen === null ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_seen: 1 });
                                    console.log("read");
                                }}
                                label={"read"}
                                active={filterForm.is_seen === 1 ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_seen: 0 });
                                    console.log("unread");
                                }}
                                label={"unread"}
                                active={filterForm.is_seen === 0 ? true : false}
                            />
                        </div>

                        {/* g: reply status */}
                        <div className="pt-5 font-semibold text-fs14">Select Reply Status</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_reply: null });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterForm.is_reply === null ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_reply: 1 });
                                    console.log("replied");
                                }}
                                label={"replied"}
                                active={filterForm.is_reply === 1 ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_reply: 0 });
                                    console.log("not_replied");
                                }}
                                label={"not replied"}
                                active={filterForm.is_reply === 0 ? true : false}
                            />
                        </div>

                        {/* g: select date */}
                        <div className="pt-5 font-semibold text-fs14 pb-s5">Select Date</div>
                        {!customDateShow ? <div className="flex pt-s10 space-x-s10">
                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "" });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterForm.date === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "weekly" });
                                    console.log("weekly");
                                }}
                                label={"weekly"}
                                active={filterForm.date === "weekly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "monthly" });
                                    console.log("monthly");
                                }}
                                label={"monthly"}
                                active={filterForm.date === "monthly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "custom" });
                                    setCustomDateShow(true)
                                }}
                                label={"custom date"}
                            />
                        </div>
                            :
                            <div className='flex justify-between items-center w-full bg-cLine rounded-br10'>
                                <div className='p-5 rounded-full'>
                                    <CommonInput
                                        // required={customDateShow ? true : false}
                                        type='date'
                                        value={filterForm.start_date}
                                        startDate={"1901-01-01"}
                                        label="start date"
                                        allowPastDates={true}
                                        onChange={(e) => {
                                            // console.log((e.target.value));
                                            const startDate = new Date(e.target.value);
                                            const endDate = new Date(filterForm.end_date);

                                            // console.log("startDate::::", startDate, "; endDate::::", endDate);

                                            if (startDate > endDate) {
                                                setFilterForm({ ...filterForm, end_date: "", start_date: e.target.value });
                                            } else {
                                                setFilterForm({ ...filterForm, start_date: e.target.value });
                                            }
                                            // setFilterForm({ ...filterForm, start_date: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className='flex items-center'>
                                    <div className='pr-s15'>
                                        <CommonInput
                                            // required={customDateShow ? true : false}
                                            type='date'
                                            label="end date"
                                            value={filterForm.end_date}
                                            // disabled={!filterForm.start_date}
                                            startDate={filterForm.start_date ? filterForm?.start_date : "1901-01-01"}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setFilterForm({ ...filterForm, end_date: (e.target.value) });
                                            }}
                                        />
                                    </div>
                                    <img
                                        onClick={() => {
                                            setFilterForm({ ...filterForm, start_date: "", end_date: (""), date: "" });
                                            setCustomDateShow(false)
                                        }}
                                        src={iCloseRed}
                                        alt=""
                                        className='cursor-pointer pr-s15 w-s40 h-s30'
                                    />
                                </div>
                            </div>
                        }

                        <div className="flex justify-between pt-5">
                            <CommonButton
                                onClick={() => {
                                    setCustomDateShow(false);
                                    setFilterForm({
                                        status: "", // user type [Schools,Instructors, Students], for all use "" empty string
                                        is_reply: null,
                                        is_seen: null,
                                        date: "", //[weekly, monthly, custom]
                                        custom_date: "",
                                        start_date: "",
                                        end_date: "",
                                    });
                                }}
                                fullRounded={true}
                                btnLabel='Clear'
                                colorType="FilterClearButton"
                                text="fs16"
                            />
                            <CommonButton
                                onClick={async () => {

                                    if (!filterMode) setFilterMode(true);
                                    if (customDateShow) {
                                        // if (filterForm.start_date && filterForm.end_date) {
                                        Toastr({ message: "Your filter successfully Apply", type: "success" });
                                        let filterSuccess = await getContactUsList("", filterForm);
                                        if (filterSuccess) setShowFilterMessage(false);
                                        // }
                                    } else {
                                        let filterSuccess = await getContactUsList("", filterForm);
                                        if (filterSuccess) {
                                            Toastr({ message: "Your filter successfully Apply", type: "success" });
                                            setContactUsSearch("")
                                            setShowFilterMessage(false);
                                        }
                                    }

                                }}
                                type="submit"
                                fullRounded={true}
                                btnLabel='apply'
                                colorType='primary'
                                roundedFull="true" />
                        </div>

                    </form>
                }
            />
        </>
    )
}

export default MessageFilterModal

const FilterChip = ({ label, active = false, onclick }) => {

    return (
        <div
            onClick={onclick}
            className={`
                capitalize rounded-full border border-cChipBorder hover:border-cBrand py-s5 px-5 text-medium hover:text-white hover:bg-cBrand select-none cursor-pointer
                ${active ? "text-white bg-cBrand border-cBrand" : "bg-white text-cChipText border-cChipBorder"}
            `}
        >
            {label}
        </div>
    )
}
