import React from 'react';
import CommonTitle from './../../../Components/Title/CommonTitle';
import { iProfile } from '../../../App/Utility/source';
import { iEdiItIcon } from './../../../App/Utility/source';
import GreenSwitch from '../../../Components/Switch/GreenSwitch';
import { useState } from 'react';
import CommonCard from './../../../Components/Card/CommonCard';
import useCategoryStore from './../../../App/Stores/CategoryStore';

const SchoolStudentProfile = () => {

    const [enabled,setEnabled]=useState(true)

    const {setShowSchoolEditModal}=useCategoryStore();

    return (
        <div className='cursor-pointer mx-s12 md:mx-s32'>
            <CommonTitle title="Basic Details"/>

            <div className="bg-cBrandColor2 rounded-br20 px-s20 py-s20">

            <div className="bg-cBackgroundAndCategory rounded-br20 py-s20 px-s20 flex justify-between">

             <div className="flex ">
             <div className="">
             <img className="w-s88 h-s88 rounded-full mb-s18" src={iProfile} alt=""/>
             <div className='ml-s15'>
             <GreenSwitch enabled={enabled} setEnabled={setEnabled} />
             <div className={`${enabled?'text-cPassed':'text-cFailed'} mt-s5 text-fs14 font-fw400`}>{enabled?'Active':'Deactivate'} </div> 
             </div>
             
            </div>
            <div className="ml-s15 mt-25">
            <div className="font-fw600 text-fs24 text-cHighlighted mb-s2 leading-9">Denmark Driving School</div>
            <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">CVR:123456</div>
            <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">@denmarkdrivingschool@gmail.com</div>
            <div className="font-fw500 text-fs12 text-cImportantText leading-3 pb-s6">+44 000 000000</div>
            <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">joined:5 jun 2022</div>
            </div>
            </div>
  
             <img onClick={()=>{setShowSchoolEditModal(true)}} src={iEdiItIcon} alt=""/>
           </div>

           <div className="py-s20 px-1">
        <hr />
        </div>

        <span className='text-fs14 font-fw600 py-s20 text-cBlack'>Aditional Informations</span>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-5 px-0 pt-s20">
          <CommonCard title={'Invoices'} note={'5 Invoices'} />
          <CommonCard title={'Upcoming'} note={'5 lessons'} />
          <CommonCard title={'Driving'} note={'2 Pending'} />
          <CommonCard title={'Classroom'} note={'5 Pending'} />
        </div>
            </div> 
        </div>
    );
};

export default SchoolStudentProfile;