import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useSettingsStore from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import ProfileImageUploader from "../../../Components/Image/ProfileImageUploader";
import CommonInput from "../../../Components/Input/CommonInput";
import SelectInput from "../../../Components/Input/SelectInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import RichTextEditor from "../../../Components/TextEditor/RichTextEditor";
import { createTermsCondition } from './../../../App/Stores/SettingsStore';

const WriteTermsAndConditionModal = () => {

  const navigate =useNavigate()

  const {
    showWriteTermsAndConditionModal,
    setShowWriteTermsAndConditionModal,
  } = useSettingsStore();

  const [image, setImage] = useState("")
  const [addForm, setAddForm] = useState({
    content: "",
    app_type: ''
  });

  const userArray = [
    {
      title: "Student",
      value: "student",
      selected: false,
    },
    {
      title: "Instructor",
      value: "instructor",
      selected: false,
    },
    {
      title: "School",
      value: "school",
      selected: false,
    },
  ];
  //createTermsCondition

  const addTermsCondition = async (e) => {
    e.preventDefault();

    if (addForm?.content && addForm?.app_type) {
      const data = { "content": addForm.content, "app_type": addForm.app_type, image: image }
      let addSuccess = await createTermsCondition(data)
      if (addSuccess) {
        await setAddForm({ description: "", app_type: "" });
        setImage("")
        navigate("/settings/terms-condition")
        await setShowWriteTermsAndConditionModal(false)
      }
    }
  }

  return (
    <div>
      <CommonModal
        showModal={showWriteTermsAndConditionModal}
        setShowModal={setShowWriteTermsAndConditionModal}
        modalTitle="Add GDPR & Terms"
        mainContent={
          <>
            <form onSubmit={addTermsCondition}>
              <div className="flex justify-center mt-s20">
                <ProfileImageUploader iImage={image} setImage={setImage} rounded={false} />
              </div>

              <div className="my-s20">
                <p className="text-center text-cWarningText text-fs14">
                For better experience, Choose image of max-width 1200px,
                  max-height 600px.
                </p>
              </div>
              <div className="mt-s20">
                <SelectInput
                  required={true}
                  label="Choose User"
                  placeholder="Choose User"
                  dataArray={userArray}
                  value={addForm.app_type}
                  selectOptionOnChange={(e) => setAddForm({ ...addForm, app_type: e })}
                />
              </div>

              <div className="mt-s20">
                <RichTextEditor
                  required={true}
                  label="Write Description"
                  textarea="true"
                  placeholder="Write here..."
                  onChange={(e) => setAddForm({ ...addForm, content: e })}
                  value={addForm.content}
                />
              </div>

              <div className="flex justify-center mt-s60">
                <CommonButton
                  type="submit"
                  btnLabel="Add"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default WriteTermsAndConditionModal;
