import React, { useEffect } from 'react';
import useLicenseStore from '../../../App/Stores/LicenseStore';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import { Toastr } from '../../../App/Utility/UtilityFunctions';
import CommonSearchBox from '../../../Components/Input/CommonSearchBox';
import CommonModal from '../../../Components/Modal/CommonModal';
import useSchoolStore, { getSchoolList } from './../../../App/Stores/SchoolStore';
import CommonButton from './../../../Components/Button/CommonButton';
import SelectInput from './../../../Components/Input/SelectInput';

const FilterSchoolModal = () => {
    const { setFilterSchoolActive, showSchoolFilterModal, setShowSchoolFilterModal, licenseArray, setLicenseArray, filterData, setFilterData, setSchoolSearchKey } = useSchoolStore()
    const { licenseIndex } = useLicenseStore();
    const { setLoading } = useUtilityStore();

    const UpdateLicenseArray = () => {
        let t_array = [];
        licenseIndex?.length && licenseIndex?.map((item) => t_array.push({
            title: item?.title.toLowerCase(),
            value: parseInt(item?.id),
            selected: parseInt(filterData?.license_id) === parseInt(item?.id) ? true : false
        }));
        setLicenseArray(t_array);
    }

    const ResetLicenseArray = () => {
        let t_array = [];
        licenseIndex?.length && licenseIndex?.map((item) => t_array.push({
            title: item?.title.toLowerCase(),
            value: parseInt(item?.id),
            selected: false
        }));
        setLicenseArray(t_array);
    }

    const HandleResetLicenseStatus = () => {
        setFilterData({
            search: "",
            status: -1,
            license_id: -1,
            payment_status: [],
        });

        ResetLicenseArray();
    }

    const formSubmit = async (e) => {
        e.preventDefault();
        console.log(filterData);
        // return
        setLoading(true);
        let filterSuccess = await getSchoolList("", filterData, "", false);
        if (filterSuccess) {
            Toastr({ message: "Your filter successfully Apply", type: "success" });
            setShowSchoolFilterModal(false);
            setSchoolSearchKey("");
            if (filterData.search === '' && isNaN(filterData.status) === true && filterData.license_id === -1 && filterData.payment_status.length === 0) {
                setFilterSchoolActive(false)
            }
        }
        setLoading(false);

    }


    useEffect(() => {
        UpdateLicenseArray();
    }, [licenseIndex, filterData]);

    return (
        <div>
            <CommonModal
                showModal={showSchoolFilterModal}
                setShowModal={setShowSchoolFilterModal}
                modalTitle="Filter School"
                mainContent={
                    <>
                        <form onSubmit={formSubmit}>
                            <div className="my-5">
                                <CommonSearchBox
                                    fullWidth={true}
                                    value={filterData?.search}
                                    onChange={(e) => setFilterData({ ...filterData, search: e.target.value })}
                                    withClearSearch={true}
                                    onSearchClear={() => setFilterData({ ...filterData, search: "" })}
                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    label="Choose Status"
                                    placeholder="Choose Status"
                                    selectOptionOnChange={(e) => {
                                        if (e === '') setFilterData({ ...filterData, status: -1 });
                                        else {
                                            e === "2" && setFilterData({ ...filterData, status: parseInt(1) })
                                            e === "3" && setFilterData({ ...filterData, status: parseInt(0) })
                                        }
                                    }}
                                    dataArray={[
                                        {
                                            title: "Active",
                                            value: 2,
                                            selected: filterData?.status === 1 ? true : false
                                        },
                                        {
                                            title: "Deactivate",
                                            value: 3,
                                            selected: filterData?.status === 0 ? true : false
                                        },
                                    ]}
                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    label="License Type"
                                    placeholder="Choose License Type"
                                    selectOptionOnChange={(e) => {
                                        if (e === '') setFilterData({ ...filterData, license_id: -1 });
                                        else setFilterData({ ...filterData, license_id: parseInt(e) });
                                    }}
                                    dataArray={licenseArray ?? []}
                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    label="Payment Status"
                                    placeholder="Choose Payment Status"
                                    selectOptionOnChange={(e) => {
                                        if (e === "paid") setFilterData({ ...filterData, payment_status: ["paid1", "paid2"] });
                                        else if (e === "missing") setFilterData({ ...filterData, payment_status: ["missing1", "missing2"] });
                                        else if (e === "pending") setFilterData({ ...filterData, payment_status: ["requested", "created", "paid1", "paid2", "missing1", "missing2"] });
                                        else if (e === '') setFilterData({ ...filterData, payment_status: [] });
                                        else setFilterData({ ...filterData, payment_status: [e] });
                                    }}
                                    dataArray={[
                                        {
                                            title: "accepted",
                                            value: "accepted",
                                            selected: filterData?.payment_status[0] === "accepted" ? true : false
                                        },
                                        {
                                            title: "requested",
                                            value: "requested",
                                            selected: filterData?.payment_status[0] === "requested" ? true : false
                                        },
                                        // {
                                        //     title: "cancelled",
                                        //     value: "cancelled",
                                        //     selected: filterData?.payment_status[0] === "cancelled" ? true : false
                                        // },
                                        {
                                            title: "created",
                                            value: "created",
                                            selected: filterData?.payment_status[0] === "created" ? true : false
                                        },
                                        {
                                            title: "paid",
                                            value: "paid",
                                            selected: filterData?.payment_status[0] === "paid1" ? true : false
                                        },
                                        {
                                            title: "missing payment",
                                            value: "missing",
                                            selected: filterData?.payment_status[0] === "missing1" ? true : false
                                        },

                                        // {
                                        //     title: "rejected",
                                        //     value: "rejected",
                                        //     selected: filterData?.payment_status[0] === "rejected" ? true : false
                                        // },
                                        {
                                            title: "expired",
                                            value: "expired",
                                            selected: filterData?.payment_status[0] === "expired" ? true : false
                                        },
                                        {
                                            title: "pending",
                                            value: "pending",
                                            selected: filterData?.payment_status[0] === "requested" && filterData?.payment_status[1] === "created" ? true : false
                                        },
                                    ]}
                                />
                            </div>


                            <div className='flex justify-between mt-s20'>
                                <CommonButton onClick={() => {
                                    HandleResetLicenseStatus();
                                }} btnLabel="Clear" colorType="FilterClearButton" />
                                <CommonButton
                                    onClick={() => {
                                        console.log(filterData);
                                    }}
                                    type="submit"
                                    btnLabel="Apply"
                                    colorType="primary"
                                    roundedFull="true"
                                />
                            </div>

                        </form>

                    </>
                }

            />
        </div>
    );
};

export default FilterSchoolModal;