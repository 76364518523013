import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import useContactStore, { getWebContactUsList } from '../../../App/Stores/ContactStore';
import { iFilter, iFilterWhite } from '../../../App/Utility/source';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import CommonTable from '../../../Components/Table/CommonTable';
import CommonTitle from '../../../Components/Title/CommonTitle';
import ContactWebTableRow from './ContactWebTableRow';

const ContactWebTable = () => {
    const {
        contactWebUsListAll,
        setContactWebPageUrl,
        contactWebPageUrl,
        setContactWebSearch,
        contactWebSearch,
        FilterContactWebActive,
        setShowFilterWebMessage,
        filterFormWeb,
    } = useContactStore();

    const contactUsHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 21, name: t("phone") },
        { index: 3, name: t("Subject") },
        { index: 4, name: t("Message") },
        // { index: 5, name: t("User Type") },
        { index: 6, name: t("Date & Time") },
        { index: 7, name: t("Last Action") },
    ];

    useEffect(() => {
        fetchWebContactList();
    }, []);

    const fetchWebContactList = () => {
        getWebContactUsList(contactWebPageUrl, filterFormWeb, contactWebSearch);
    }

    const [searchValue] = useDebounce(contactWebSearch, 500);
    useEffect(() => {
        getWebContactUsList("", filterFormWeb, contactWebSearch);
    }, [searchValue])

    return (
        <div
            // onClick={() => LogWarning("contactWebUsListAll:::", contactWebUsListAll)}
            className='mx-s12 md:mx-s32'>
            <CommonTitle title="Contact Us Web" ></CommonTitle>

            <CommonTable
                topRightComponent={<TakeItem />}
                headers={contactUsHeaders}
                tableTitle=""
                showPageCountText={true}
                autoManageRow={true}
                paginationObject={contactWebUsListAll?.data}
                TableRowComponent={ContactWebTableRow}
                paginationOnClick={async (url) => {
                    await setContactWebPageUrl("url");
                    await getWebContactUsList(url, filterFormWeb, contactWebSearch);
                    console.log("url", url);
                }}
                shoSearchBox={true}
                searchValue={contactWebSearch}
                withClearSearch={true}
                onSearchClear={() => setContactWebSearch("")}
                searchOnChange={(e) => { setContactWebSearch(e.target.value) }}

                titleComponent={
                    <>
                        <CommonButtonOutlined
                            iconLeft={iFilterWhite}
                            iconLeftHover={iFilter}
                            isFilterDot={FilterContactWebActive}
                            colorType='primary'
                            btnLabel="Filter"
                            onClick={() => { setShowFilterWebMessage(true) }}
                        />
                    </>
                }
            />
        </div>
    )
}

export default ContactWebTable



const TakeItem = () => {
    const { contactWebTakeList, contactWebPageUrl, filterFormWeb, contactWebSearch, setContactWebTakeList } = useContactStore();
    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select id="cars"
                value={contactWebTakeList.take ? contactWebTakeList.take : 10}
                onChange={async (e) => {
                    await setContactWebTakeList("take", e.target.value);
                    await getWebContactUsList(contactWebPageUrl, filterFormWeb, contactWebSearch);

                }} className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={contactWebTakeList.take === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={contactWebTakeList.take === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={contactWebTakeList.take === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={contactWebTakeList.take === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}