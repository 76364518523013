import React, { useEffect } from 'react';
import CommonStudentProfile from './../../Components/Profile/CommonStudentProfile';
import useStudentStore, { getStudentDetails } from './../../App/Stores/StudentStore';
import useLayoutStore from '../../App/Stores/LayoutStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';

const StudentDetail = () => {

    const { setShowShareReportModal } = useStudentStore()

    const { setBarTitle } = useLayoutStore();

    const { student_id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle(t("Student Details"));
        setBarTitle("Student Details");
    }, []);

    useEffect(() => {
        FetchStudentDetails();
    }, [student_id]);

    const FetchStudentDetails = () => {
        if (student_id) getStudentDetails(student_id);
    }

    return (
        <div>
            <CommonStudentProfile shareReport={() => { setShowShareReportModal(true) }} />
        </div>
    );
};

export default StudentDetail;