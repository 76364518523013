import React from 'react'
import { useNavigate } from 'react-router-dom';
import useCategoryStore, { deleteCategoryData } from '../../../App/Stores/CategoryStore'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal'

function ConfirmDeleteCategory() {
    const { showDeleteCategoryModal, setShowDeleteCategoryModal, setShowEditModal } = useCategoryStore();

    const navigateTo = useNavigate();

    const deleteCategory = async () => {
        let delSuccess = await deleteCategoryData();
        if (delSuccess) {
           await setShowDeleteCategoryModal(false)
           setTimeout(function () {
            setShowEditModal(false)
            //your code to be executed after 1 second
          }, 1000);
           return navigateTo("/category");
        }

       
    }
    return (
        <>
            <CommonModal
                showModal={showDeleteCategoryModal}
                setShowModal={setShowDeleteCategoryModal}
                modalTitle="Conformation"
                mainContent={
                    <div className='flex flex-col items-center'>
                        <div className="pt-5">Are you sure you want to delete this category ?</div>
                        <div className="pt-5">
                            <CommonButton btnLabel='Delete' colorType='danger' 
                            onClick={() => deleteCategory()} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default ConfirmDeleteCategory