import React from 'react';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';
import useMessageStore from '../../../App/Stores/MessageStore';
import CommonInput from '../../../Components/Input/CommonInput';
import { iPerson } from './../../../App/Utility/source';

const NewMessageModal = () => {

  const { showMessageModal, setShowMessageModal } = useMessageStore();
  
  return (
    <>
      <CommonModal
        showModal={showMessageModal}
        setShowModal={setShowMessageModal}

        modalTitle="Message Details"
        mainContent={
          <>
            <div className='my-s25'>
              <div className="bg-cBackgroundAndCategory rounded-br10 h-h65 sm:h-h65 lg:h-h65 flex justify-between">
                <div className="flex col-span-9 md:col-span-9 py-s12 px-s16">
                  <img className="w-w44 h-h44 rounded-full" src={iPerson} alt="" />
                  <div className="ml-s12 py-s10">
                    <div className="font-fw600 text-fs14  text-cMainBlack leading-3 pb-s4 ">Maruf Moua </div>
                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 ">drivingschool@gmail.com</div>
                  </div>
                </div>

                <div className="cursor-pointer my-s16 mr-s20">
                  <div className="bg-cBrandColor rounded-full w-s105 h-h34 text-cBrandColor2 py-s5 pl-s15 text-fs14 font-fw500">
                    View Profile
                  </div>
                </div>
              </div>

              <div>
                <div className="mt-s10"><span className="text-fs14 font-fw600 text-cHighlighted">Subject</span>
                  <span className="text-fs14 font-fw400 text-cHighlighted">: Maway is not responding.</span>
                </div>

                <div className='flex justify-between mt-s20 mb-s10'>
                  <div className="text-fs14 font-fw600 text-cHighlighted">Message details</div>
                  <div className='text-fs12 font-fw400 text-cIconColor2'> 22 July, 2022, 10:00</div>
                </div>

                <div className="text-fs14 font-fw400 text-cTextGray mb-s20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu aliquet feugiat suspendisse tincidunt vel in. Nulla ultrices faucibus feugiat libero semper consequat, felis. Cras aliquet sem enim ante in velit ullamcorper morbi. Tincidunt ornare elementum erat ut risus quis odio fusce.</div>

                <span className="text-fs14 font-fw600 text-cHighlighted mb-s10">Reply</span>

                <CommonInput type="text" textarea="true" />

              </div>
            </div>
          </>
        }
        singleButton={
          <>
            <CommonButton
              roundedFull="true"
              btnLabel="Send Reply"
              colorType="primary"
              onClick={() => setShowMessageModal(false)}
            />
          </>
        }
      />
    </>
  );
};

export default NewMessageModal;