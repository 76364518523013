import React from 'react'
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Toastr, toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import useStudentStore from '../../../App/Stores/StudentStore';
import { useEffect } from 'react';
import { arraysEqual } from '../../../Utility/UtilityFunctions';
import { useState } from 'react';
import { iClassroomCard, iDrivingCard, iExternalCard } from '../../../App/Utility/source';

function StudentSchoolLessonTableRow({ data, index, mandatory }) {

  const {
    setStudentFindSchoolLessonId,
    studentFindSchoolLessonId,
    fullPackageChecked,
    studentFindSchoolCategoryInfo,
    setFullPackageChecked,
    setStudentSchoolPackagePrice
  } = useStudentStore();

  const [state, setstate] = useState(false);

  useEffect(() => {
    setstate(mandatory)
  }, [mandatory, state])


  const handleChange1 = (event, id, price) => {

    if (!mandatory) {
      if (studentFindSchoolLessonId !== []) {
        setStudentFindSchoolLessonId([...studentFindSchoolLessonId, id])
      }
    } else {
      // Toastr({
      //   message:
      //     "Only Mandatory Lessons are selectable for full package, unchecked full package to select individual lesson",
      //     type: "warning"
      // });
    }
    studentFindSchoolLessonId?.map(item => {
      if (item === id) {
        const a = studentFindSchoolLessonId.filter(value => value !== id)
        setStudentFindSchoolLessonId(a)
      }
    })
    autoFullPackageSelect()
  };

  //console.log('studentFindSchoolLessonId', studentFindSchoolLessonId)

  const autoFullPackageSelect = async () => {
    const item = await studentFindSchoolCategoryInfo?.category_lessons?.filter(value => value.is_mandatory)

    const value = await item.map(value2 => {
      return [value2?.id]
    })

    const value2 = await studentFindSchoolLessonId?.map(id => {
      return [id]
    })

    const success = arraysEqual(JSON.stringify(value), JSON.stringify(value2))
    if (success) {
      setFullPackageChecked(true);
    } else {
      setFullPackageChecked(false);
    }

    let sum = 0;
    studentFindSchoolLessonId?.map(item => {
      let price = studentFindSchoolCategoryInfo?.category_lessons?.filter(item2 => item2.id === item)
      sum += price[0]?.price;
      price ? setStudentSchoolPackagePrice(sum) : setStudentSchoolPackagePrice("0")
    })
  }

  useEffect(() => {
    autoFullPackageSelect();
  }, [studentFindSchoolLessonId, fullPackageChecked])


  console.log("data",data)

  return (
    <>
      <tr 
        className={`border-b border-collapse cursor-pointer border-cNmSelect 
        ${studentFindSchoolLessonId?.includes(data?.id) && 'bg-cInvoiceLesson'}
        `} >
        <th className='m-2 font-normal text-center border-r py-s10 '>
          <span className='mr-s12 dm:mrs12 md:mr-0'>{index + 1}</span>
        </th>

        <td className='border-r-[1px] py-s10 text-left px-s10 max-w-[120px] min-w-[120px] truncate'>
          <div className='flex sm:flex-col md:flex-row'>
            <div className='h-[48px] max-w-[48px] min-w-[48px] bg-cBrandColor2 rounded-full'>
              <img className='w-s38 h-s38 pl-s9 pt-s9 grow-0 flex items-center' 
               src={data?.type==='driving' && iDrivingCard || data?.type==='external' && iExternalCard || data?.type==='classroom' && iClassroomCard} alt="" />
            </div>
            <div className='capitalize truncate flex items-center font-fw600 mx-s10  text-cMainBlack lg:text-fs14 sm:text-fs12 '>
              {data?.name ? data.name : <CommonEmptyStatus />}
            </div>
          </div>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody max-w-[80px] min-w-[80px]'>
          <span className='font-fw400 text-cMainBlack text-fs14'>
            {data?.duration ? toHoursAndMinutes(data?.duration) : <CommonEmptyStatus />}
          </span>
        </td>


        <td className='px-s10 border-r-[1px] text-fs14 text-center text-cTextBody max-w-[80px] min-w-[80px] '>
          <span className='font-fw400 text-cMainBlack text-fs14'>
            {data?.is_mandatory ? 'Yes' : 'No'}
          </span>
        </td>

        <td className='py-s10 border-r-[1px] pl-s30 text-fs14 text-center text-cTextBody max-w-[100px] min-w-[100px]' >
          <div className='flex justify-between'>
            <div></div>
            <span className='ml-s20 font-fw400 text-cMainBlack text-fs14 mt-s12 '>
              {fullPackageChecked && data?.is_mandatory ? '0 DKK' : data?.price ? `${data?.price} DKK` : <CommonEmptyStatus />}
            </span>
            <div className=''>
              <FormControlLabel
                control={<Checkbox
                  style={{
                    color: "#2257AA",
                }}
                  // checked={checked}
                  checked={studentFindSchoolLessonId?.includes(data?.id)}
                  onChange={(e) => { handleChange1(e, data?.id, data?.price) }} />}
              />
            </div>
          </div>
        </td>

      </tr>
    </>
  )
}

export default StudentSchoolLessonTableRow
