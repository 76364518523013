import React from "react";
import Clamp from 'react-multiline-clamp';
import useSettingsStore, { TermsConditionToggleStatus } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import Image from "../../../Components/Image/Image";
import CommonModal from "../../../Components/Modal/CommonModal";
import { iImageIogo } from './../../../App/Utility/source';
import GreenSwitch from './../../../Components/Switch/GreenSwitch';

const TermsAndConditionDetailsModal = () => {

  const {
    showTermsAndConditionDetailsModal,
    setShowTermsAndConditionDetailsModal,
    setShowTermsAndConditionDeactivateModal,
    setTermsConditionDeactivateID,
    setShowEditTermsAndConditionModal,
    setShowTermsAndConditionDeleteModal,
    TermsConditionDetail
  } = useSettingsStore();

  const toggleStatus = async () => {
    setTermsConditionDeactivateID(TermsConditionDetail[0]?.id)
    if (TermsConditionDetail[0]?.status === 0 || TermsConditionDetail[0]?.status === '0') {
      await TermsConditionToggleStatus(TermsConditionDetail[0]?.id)
    } else if (TermsConditionDetail[0]?.status === 1 || TermsConditionDetail[0]?.status === '1') {
      await setShowTermsAndConditionDeactivateModal(true)
    }
  }

  //console.log("TermsConditionDetail", TermsConditionDetail[0])

  return (
    <div>
      <CommonModal
        showModal={showTermsAndConditionDetailsModal}
        setShowModal={setShowTermsAndConditionDetailsModal}
        modalTitle="GDPR & Terms"
        mainContent={
          <>
            {TermsConditionDetail[0] ?
              <div>
                <div className="flex justify-center w-auto">
                  <div className="flex justify-center w-s460 h-s272">
                    <Image
                      className="my-s10 rounded-br10"
                      src={TermsConditionDetail[0]?.image}
                      dummyImage={iImageIogo}
                    />
                  </div>
                </div>

                <div className="flex justify-between my-s20">
                  <div>
                    <h1 className="leading-6 text-fs20 fw-600 mb-s10 text-cBlack">
                      User Type
                    </h1>
                    <p className="fw-400 text-fs16">{TermsConditionDetail[0]?.app_type}</p>
                  </div>

                  <div className="flex-col justify-center mt-s4">
                    <GreenSwitch onClick={toggleStatus} enabled={TermsConditionDetail[0]?.status} />
                    <div className={`${TermsConditionDetail[0]?.status ? 'text-cPassed' : 'text-cFailed'} mt-s10 text-fs14 font-fw400`}>
                      {TermsConditionDetail[0]?.status ? 'Active' : <span className=''>Deactivate</span>} </div>
                  </div>
                </div>
                <div className="mb-s20">
                  <div className="text-fs20 fw-600 mb-s10 text-cHighlightedTexts">
                    Description
                  </div>

                  <Clamp
                    lines={5}
                    maxLines={100}
                    withToggle
                    showMoreElement={({ toggle }) => (
                      <button className="cursor-pointer text-cBrandColor mt-s10 text-fs16" type="button" onClick={toggle}>
                        Show more
                      </button>
                    )}
                    showLessElement={({ toggle }) => (
                      <span className="cursor-pointer text-cBrandColor mt-s10 text-fs16" type="button" onClick={toggle}>
                        See Less
                      </span>
                    )}
                  >
                    <div className="prose prose-lg" dangerouslySetInnerHTML={{ __html: TermsConditionDetail[0]?.content }} ></div>
                    {/* <div>{TermsConditionDetail[0]?.content}</div> */}
                  </Clamp>
                </div>
              </div>
              : ''}
            <div className="flex justify-between mt-s20">

              <CommonButton
                type="submit"
                btnLabel="Delete"
                colorType="warning"
                roundedFull="true"
                onClick={() => {
                  setShowTermsAndConditionDeleteModal(true);
                }}
              />

              <CommonButton
                type="submit"
                btnLabel="Edit"
                colorType="primary"
                roundedFull="true"
                onClick={() => {
                  setShowEditTermsAndConditionModal(false);

                }}
              />
            </div>
          </>
        }
      />
    </div>
  );
};

export default TermsAndConditionDetailsModal;
