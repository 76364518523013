import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getCategoryList } from '../../App/Stores/CategoryStore';
import { filterLicense } from '../../App/Stores/LicenseStore';
import { getSchoolList } from '../../App/Stores/SchoolStore';
import useUtilityStore from '../../App/Stores/UtilityStore';
import { iFilter, iFilterWhite } from '../../App/Utility/source';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonButtonOutlined from '../../Components/Button/CommonButtonOutlined';
import BackLink from '../../Components/Pagination/BackLink';
import useInstructorStore, { getInstructorIndex, searchInstructorList } from './../../App/Stores/InstructorStore';
import useLayoutStore from './../../App/Stores/LayoutStore';
import CommonTable from './../../Components/Table/CommonTable';
import InstructorTableRow from './../../Components/Table/Instructor/InstructorTableRow';
import CommonTitle from './../../Components/Title/CommonTitle';
import InstructorFilterModal from './InstructorModal/InstructorFilterModal';

const Instructor = () => {

  const {
    instructorSearchKey,
    setInstructorSearchKey,
    instructorIndex,
    setShowInstructorFilterModal,
    filterData,
    instructorPageUrl,
    FilterInstructorActive,
    instructorTakeList,
    setInstructorPageUrl } = useInstructorStore()

  const location = useLocation();
  const { setLoading } = useUtilityStore();

  const { id } = useParams()

  const InstructorHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Email") },
    { index: 4, name: t("Phone") },
    { index: 45, name: t("Expertise Area") },
    { index: 5, name: t("License") },
    { index: 6, name: t("Status") },
    // { index: 5, name: t("Expertise Area") },
  ];


  const [searchValue] = useDebounce(instructorSearchKey, 500);

  useEffect(() => {
    SmartSearchAllInstructor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const SmartSearchAllInstructor = async () => {
    setLoading(true);
    if (searchValue) {
      setLoading(false);
      await searchInstructorList(instructorPageUrl, instructorTakeList.take);
    } else {
      if (instructorIndex.current_page) {
        let urlToLoad = instructorIndex.path + "?page=" + instructorIndex.current_page;
        // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        setInstructorSearchKey("")
        await getInstructorIndex(urlToLoad, filterData, "", false);
      } else {
        setInstructorSearchKey("")
        await getInstructorIndex(instructorPageUrl, filterData, "", false);
      }
    }
    setLoading(false);
  }

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("Instructor"));
    setBarTitle("Instructor");

    fetchInstructor();

  }, []);

  const fetchInstructor = async () => {
    setLoading(true);
    await getCategoryList("", 500, false);
    await getSchoolList("", {}, 5, false);
    await filterLicense("instructor", false);

    if (instructorSearchKey) {
      setLoading(false);
      await searchInstructorList(instructorPageUrl, instructorTakeList.take);
    }
    else await getInstructorIndex(instructorPageUrl, filterData, "", false);
    setLoading(false);
  }

  return (
    <div className='mx-s12 md:mx-s32'>

      <CommonTitle title="Instructor Management" >
        {
          location.pathname === `/category/details/${id}/instructor` && <BackLink linksArray={[
            { label: "Category", linkTo: "/category" },
            { label: "Category Details", linkTo: `/category/details/${id}` },
            { label: "Instructor Management", linkTo: `` }
          ]} />
        }
      </CommonTitle>

      {/* idea:     api call issue */}
      <InstructorFilterModal />


      <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
        <CommonTable
          topRightComponent={<TakeItem />}
          headers={InstructorHeaders}
          tableTitle=""
          showPageCountText={true}
          showPagination={true}

          paginationObject={instructorIndex}
          TableRowComponent={InstructorTableRow}
          autoManageRow={true}
          paginationOnClick={async (url) => {
            setLoading(true);
            console.log(url);
            await setInstructorPageUrl(url);
            if (searchValue) {
              setLoading(false);
              await searchInstructorList(url, instructorTakeList.take);
            } else await getInstructorIndex(url, filterData, "", false);
            setLoading(false);
          }}

          shoSearchBox={true}
          withClearSearch={true}
          onSearchClear={() => setInstructorSearchKey("")}
          searchValue={instructorSearchKey}
          searchOnChange={(e) => setInstructorSearchKey(e.target.value)}

          titleComponent={
            <>
              <CommonButtonOutlined
                iconLeft={iFilterWhite}
                iconLeftHover={iFilter}
                isFilterDot={FilterInstructorActive}
                colorType='primary'
                btnLabel="Filter"
                onClick={() => { setShowInstructorFilterModal(true) }}
              />
            </>
          }
        />
      </div>
    </div>
  );
};

export default Instructor;


const TakeItem = () => {
  const { setInstructorTakeList, instructorTakeList, instructorSearchKey, filterData, instructorPageUrl, setInstructorPageUrl } = useInstructorStore();
  const { setLoading } = useUtilityStore();

  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select
        value={instructorTakeList.take ? instructorTakeList.take : 10}
        onChange={async (e) => {
          setLoading(true);
          setInstructorPageUrl("");

          await setInstructorTakeList("take", e.target.value);
          if (instructorSearchKey) {
            setLoading(false);
            await searchInstructorList("", "")
          } else {
            await getInstructorIndex("", filterData, "", false);
          }
          setLoading(false);

        }} className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={instructorTakeList.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={instructorTakeList.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={instructorTakeList.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={instructorTakeList.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>

    </div>
  )
}