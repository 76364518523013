import React, { useEffect, useState } from "react";
import useBannerStore, { bannerToggleStatus } from "../../../App/Stores/bannerStore";
import { iImageIogo } from "../../../App/Utility/source";
import CommonButton from "../../../Components/Button/CommonButton";
import Image from "../../../Components/Image/Image";
import CommonModal from "../../../Components/Modal/CommonModal";
import GreenSwitch from './../../../Components/Switch/GreenSwitch';
const BannerDetailsModal = () => {
  const {
    showBannerDetailsModal,
    setshowBannerDetailsModal,
    setshowEditBannerModal,
    setshowDeleteBannerModal,
    getSingleBannerDetails,
    setEditFormData,
    setShowBannerDeactivateModal, 
    setBannerDeactivateID
  } = useBannerStore();

  // bannerToggleStatus 

  const [enabled, setEnabled] = useState(false);

  const HandleDeactivate = async () => {
    await setBannerDeactivateID(getSingleBannerDetails?.id)
    if (enabled === true) {
      await setShowBannerDeactivateModal(true);
    } else {
      let activateSuccess = await bannerToggleStatus(getSingleBannerDetails?.id);
      if (activateSuccess) setEnabled(true);
    }
  }

  useEffect(() => {
    setEnabled(getSingleBannerDetails?.status);
    setText(getSingleBannerDetails?.content)
  }, [getSingleBannerDetails]);

  const [showMore, setShowMore] = useState(false);

  const [text, setText] = useState("");

  const resetBannerEditData=()=>{
        setEditFormData("name", "");
        setEditFormData("content", "");
        setEditFormData("app_type", "");
        setEditFormData("id", "");
        setEditFormData("image", "");
  }


  const getText = () => {
    if (text?.length <= 210) return getSingleBannerDetails?.content;
    if (text?.length > 210 && showMore) {
      return (
        <>
          <p>{getSingleBannerDetails?.content}</p>
          <button
            className="cursor-pointer text-cBrandColor mt-s5 text-fs14 font-fw400"
            onClick={() => setShowMore(false)}>
            Show Less
          </button>
        </>
      );
    }
    if (text?.length > 210) {
      return (
        <>
          <p>{getSingleBannerDetails?.content.slice(0, 210)} ...</p>
          <button
            className="cursor-pointer text-cBrandColor mt-s5 text-fs14 font-fw400"
            onClick={() => setShowMore(true)}>
            Show more
          </button>
        </>
      );
    }
  };


  return (
    <div>
      <CommonModal
        showModal={showBannerDetailsModal}
        setShowModal={setshowBannerDetailsModal}
        modalTitle="Banner Details"
        mainContent={
          <>
            <div className="flex justify-center w-auto">
              <div className="flex justify-center w-s460 h-s272">
                <Image className="my-s10 rounded-br10" src={getSingleBannerDetails?.image} dummyImage={iImageIogo} />
              </div>
            </div>
            <div className="flex justify-center mt-s10">
              <GreenSwitch enabled={enabled} setEnabled={() => HandleDeactivate()} />
              <div className={`${enabled ? 'text-cPassed' : 'text-cFailed'} ml-s10 text-fs14 font-fw400`}>
                {enabled ? 'Active' : <span className=''>Deactivate</span>} </div>
            </div>

            <div className="flex justify-between my-s20">
              <div>
                <h1 className="text-fs20 fw-600 mb-s10 text-cHighlightedTexts">Title</h1>
                <p className="fw-400 text-fs14 text-cBlack max-w-[540px]">
                  {getSingleBannerDetails.name}
                </p>
              </div>
              <div>
                <h1 className="capitalize leading-6 text-fs20 fw-600 mb-s10 text-cBlack">User Type</h1>
                <p className="capitalize fw-400 text-fs16">
                  {getSingleBannerDetails.app_type}
                </p>
              </div>
            </div>
            <div on className="mb-s20 h-full">
              <h1 className="text-fs20 fw-600 mb-s10 text-cHighlightedTexts">Description</h1>
              {getText()}
            </div>
            <div className="flex justify-between">
              <div>
                <CommonButton
                  colorType="danger"
                  btnLabel="Delete"
                  roundedFull="true"
                  //width="w-s280"
                  //className1="h-[48px]"
                  onClick={() => {
                    setshowDeleteBannerModal(true);
                  }}
                />
              </div>
              <div>
                <CommonButton
                  colorType="primary"
                  btnLabel="Edit"
                  roundedFull="true"
                  //width="w-s280"
                  //className1="h-[48px]"
                  onClick={() => {
                    resetBannerEditData()
                    //setShowEditBannerModal(true);
                    setshowEditBannerModal(true);
                    //console.log("Clicked");
                  }}
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default BannerDetailsModal;
