import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { calculateEndDateCalendar, dateDiffCalendar, smartFormattedDateDiff } from "../../App/Utility/UtilityFunctions";
import { formatDate } from "../../Utility/UtilityFunctions";

const Test = () => {

  const componentRef = useRef();
  const downloadRef = React.createRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div
        onClick={() => { console.log("mainObject"); }}
        className="flex flex-col justify-center items-center py-10 w-full h-[15vh] text-4xl font-thin shadow-lg text-cTextBlack bg-slate-300 rounded-br10">
        TEST - PAGE
      </div>

      <div className="px-10 py-10 h-[85vh] bg-cyan-400">
        <DateDifference />
      </div>

    </>
  );
};

export default Test;


const DateDifference = () => {

  let currentDateAbs = new Date();
  currentDateAbs.setHours(0, 0, 0, 0);

  let startDateTemp = new Date('2022-12-23');
  let result = new Date(calculateEndDateCalendar(6, startDateTemp));

  // console.log("currentDateAbs::::", startDateTemp);
  // console.log("6 months from now::::", result);

  return (
    <div className="flex flex-col space-y-5 text-fs36">
      <h1>Date difference:</h1>
      {/* <p>{diffYears} years, {diffMonths} months</p> */}
      <p>6 months from({formatDate(startDateTemp)}) {"==>"} {formatDate(result)}</p>
      <p>DIFFERENCE: {smartFormattedDateDiff(startDateTemp, result)} remaining...</p>

      <p>NEW_DIFFERENCE: {dateDiffCalendar(startDateTemp, result)} </p>
      <p>NEW_DIFFERENCE: {dateDiffCalendar(new Date(), result)} remaining from today..</p>
    </div>
  );
};