import React from "react";
import useSettingsStore from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";
import { deleteCity } from './../../../App/Stores/SettingsStore';

const DeleteCityModal = () => {
  const { setShowEditCityModal, showCityDeleteModal, setShowCityDeleteModal, deleteCityID,setDeleteCityID } = useSettingsStore();
  return (
    <div className="">
      <CommonModal
        showModal={showCityDeleteModal}
        setShowModal={setShowCityDeleteModal}
        modalTitle="Confirmation"
        widthClass="w-s591"
        mainContent={
          <>
            <div className="">
              <div className="">
                <h1 className="text-center mb-s22 my-5">
                  Are you sure you want to delete this city.
                </h1>
              </div>
              <div className="flex justify-center">
                <CommonButton
                  onClick={async () => {
                   const value= await deleteCity(deleteCityID)
                   if(value){
                    setShowCityDeleteModal(false)
                    setTimeout(() => {
                      setShowEditCityModal(false)
                    }, 500);
                   
                    setDeleteCityID("")
                   }
                  }
                  }
                  btnLabel="Delete"
                  colorType="danger"
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default DeleteCityModal;
