import React from 'react';
import CommonTitle from './../../Components/Title/CommonTitle';
import CommonTable from './../../Components/Table/CommonTable';
import CommonButton from './../../Components/Button/CommonButton';
import { iFilter } from '../../App/Utility/source';
import { t } from 'i18next';
import useInstructorStore from './../../App/Stores/InstructorStore';
import DrivingLessonsTableRow from './../../Components/Table/Instructor/DrivingLessonsTableRow';
import BackLink from './../../Components/Pagination/BackLink';
import useLayoutStore from './../../App/Stores/LayoutStore';
import { useEffect } from 'react';
import { PageTitle } from './../../App/Utility/UtilityFunctions';
import { useParams } from 'react-router-dom';

const DrivingLessons = () => {

    const InstructorWorkPlaceHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Start Date & Time") },
        { index: 4, name: t("End Date & Time") },
        { index: 5, name: t("Last Action") },
        { index: 6, name: t("Status") },
      ];

      const {setShowInstructorFilterModal}=useInstructorStore()

      const { setBarTitle } = useLayoutStore();

      const {id}=useParams();

      useEffect(() => {
        PageTitle(t("Driving Lessons"));
        setBarTitle("Driving Lessons");
      }, [setBarTitle]);

    return (
        <div className='mx-s12 md:mx-s32'>
  
        <CommonTitle title="Driving Lessons" >
        <BackLink linksArray={[
         { label: "Instructor", linkTo: "/instructor" },
         { label: "Instructor Details", linkTo: `/instructor/details/${id}` },
         { label: "Driving Lessons", linkTo: "" },
        ]} />
      </CommonTitle>
        <div className='bg-cBrandColor2 rounded-br20 overflow-hidden'>
        <CommonTable
        headers={InstructorWorkPlaceHeaders}
        tableTitle=""
        showPageCountText={false}
        showPagination={true}
        shoSearchBox={true}
        titleComponent={
            <div className='mb-s20 border-2 border-cBrand rounded-full'>
            <CommonButton 
              onClick={()=>{setShowInstructorFilterModal(true)}}
              colorType="BackgroundAndCategory" 
              btnLabel="Filter"
              roundedFull="true"
              icon={<div className="mr-s5"><img className="w-s20 h-s15" src={iFilter} alt=""/></div>}
              />
            </div>
           }
      items={
        <>
          <DrivingLessonsTableRow/>
          <DrivingLessonsTableRow/>
          <DrivingLessonsTableRow/>
          <DrivingLessonsTableRow/>
          <DrivingLessonsTableRow/>
          <DrivingLessonsTableRow/>
        </>
      }
    /> 
        </div> 
    </div>
    );
};

export default DrivingLessons;