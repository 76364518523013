import { data } from "autoprefixer";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { iAddItem, iEdiItIcon, ISaveIcon, iSettingsEditIcon, } from "../../../App/Utility/source";
import { isLettersOnly } from "../../../App/Utility/UtilityFunctions";
import RichTextEditor from "../../../Components/TextEditor/RichTextEditor";
import Settings from "../Settings";
import useSettingsStore, { editGeneralSettingsIndex, getGeneralSettingsIndex } from './../../../App/Stores/SettingsStore';

const HowTo = () => {

  const { generalSettingsIndex } = useSettingsStore()

  const { t } = useTranslation();

  const [richText, setRichText] = useState();
  const [editNow, setEditNow] = useState("");

  useEffect(() => {
    fetchGeneralSettings()
    setRichText(generalSettingsIndex?.how_to)
  }, [generalSettingsIndex?.how_to])

  const fetchGeneralSettings = async () => {
    await getGeneralSettingsIndex()
  }

  //console.log("generalSettingsIndex",generalSettingsIndex)

  return (
    <div>
      <Settings>
        {editNow === "howto" ? (
          <div>
            <div className="flex justify-between items-center mb-s23">
              <div>
                <h1 className="font-semibold text-fs18">How To</h1>
              </div>
              <div className="flex gap-3">
                <div>
                  <img
                    src={ISaveIcon}
                    alt=""
                    onClick={async (e) => {
                      e.preventDefault();
                      setEditNow("");
                      const data = { "how_to": richText }
                      await editGeneralSettingsIndex(data)
                      // onclick(getText(value));
                      //updateFaqModal(true, item);
                    }}
                    className="w-s20 h-s20 object-cover ml-s5 mr-s10 cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-7">
              <RichTextEditor
                placeholder="Write how to..."
                value={richText}
                height="500px"
                onChange={(e) => setRichText(e)}
              />
            </div>
            <div className="flex justify-between items-center py-5"></div>
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-center mb-s23">
              <div>
                <h1 className="font-semibold text-fs18">How To</h1>
              </div>
              <div className="flex gap-3">
                <div>
                  <img
                    src={iSettingsEditIcon}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      setEditNow("howto");
                    }}
                    className="w-s20 h-s20 object-cover ml-s5 mr-s10 cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <hr />
            {/* <div className=" h-[318px] mt-7 p-5 border rounded-br10 overflow-y-auto">
              <div className="prose prose-lg" /> */}
            {/* <div className="mt-s10" dangerouslySetInnerHTML={{ __html: richText }} /> */}
            {/* 
            <RichTextEditor
              textarea="true"
              value={richText}
              readOnly={true}
              height="h-full"
              onChange={()=>{}}
            /> */}

            <div onKeyDown={(e) => { e.preventDefault() }}
              onMouseDown={(e) => { e.preventDefault() }}
              className="h-[500px] relative overflow-hidden rounded-br10 border-2 border-cBgColor">
              <div className="absolute -top-[57px] h-full -left-[3px] -right-[3px]">
                <RichTextEditor
                  textarea="true"
                  value={richText}
                  onChange={() => { }}
                  height="500px"
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        )}
      </Settings>
    </div>
  );
};
// {JSON.stringify(value, null, 2)}
export default HowTo;
