import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import BackLink from '../../Components/Pagination/BackLink';
import useLayoutStore from './../../App/Stores/LayoutStore';
import useSchoolStore, { getSchoolDetail } from './../../App/Stores/SchoolStore';
import { PageTitle } from './../../App/Utility/UtilityFunctions';
import CommonDetailPage from './../../Components/CommonDetailPage/CommonDetailPage';
import CommonTitle from './../../Components/Title/CommonTitle';
import AdditionalInfoSchool from './AdditionalInfoSchool';

const SchoolDetail = () => {

  const { setSchoolPageUrl, setShowEditSchoolModal, schoolDetails } = useSchoolStore()

  const location = useLocation();

  const { school_id, invoice_id } = useParams()

  useEffect(() => {
    fetchSchoolDetailData(school_id)
  }, [school_id])

  const fetchSchoolDetailData = async (school_id) => {
    await getSchoolDetail(school_id);
  }

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("School Profile"));
    setBarTitle("School Profile");
  }, [setBarTitle]);

  // console.log("schoolDetails",schoolDetails)

  return (
    <div
      onClick={() => console.log("schoolDetails::: ", schoolDetails?.data)}
      className="mx-s12 md:mx-s32">
      <CommonTitle title="School Profile" >
        {location.pathname === `/category/school/profile/${school_id}` && <BackLink linksArray={[
          { label: "Category", linkTo: "/category" },
          { label: "Category Details", linkTo: `/category/${school_id}` },
          { label: "School", linkTo: `/category/school/${school_id}` },
          { label: "School Profile", linkTo: `` }
        ]} />}

        {location.pathname === `/school/details/${school_id}` && <BackLink linksArray={[
          { label: "school", linkTo: "/school" },
          { label: "School Profile", linkTo: "" },
        ]} />}

        {location.pathname === `/invoice/${invoice_id}/${school_id}` && <BackLink linksArray={[
          { label: "Invoice", linkTo: "/invoice" },
          { label: "Invoice Details", linkTo: `/invoice/details/${invoice_id}` },
          { label: "School Profile", linkTo: "" },
        ]} />}

        {location.pathname === `/contact-us/school-profile/details/${school_id}` && <BackLink linksArray={[
          { label: "Contact Us", linkTo: "/contact-us" },
          { label: "School Profile", linkTo: `` }
        ]} />}
      </CommonTitle>

      <div className="overflow-hidden bg-cBrandColor2 rounded-br20 px-s20 py-s20">
        <CommonDetailPage data={schoolDetails?.data} showCommonEditModal={() => { setShowEditSchoolModal(true) }} description={true} />
        <div className="px-1 py-s20">
          <hr />
        </div>
        <AdditionalInfoSchool />
      </div>
    </div>
  );
};

export default SchoolDetail;