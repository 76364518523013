import { t } from 'i18next';
import React, { useEffect } from 'react';
import CommonTable from '../../Components/Table/CommonTable';
import CommonTitle from '../../Components/Title/CommonTitle';
import CommonButton from './../../Components/Button/CommonButton';
import { iAddItem, iGroup, iGroupList2, iBlueListGroup2, iGroupGrid } from './../../App/Utility/source';
import useLicenseStore, { getLicenseIndex } from '../../App/Stores/LicenseStore';
import LicenseTableRow from './../../Components/Table/License/LicenseTableRow';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useLayoutStore from './../../App/Stores/LayoutStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';

const License = () => {

  const { setShowAddLicenseModal, licenseIndex } = useLicenseStore()

  const location = useLocation();

  const LicenseHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Duration") },
    { index: 4, name: t("User") },
    { index: 5, name: t("Price") },
    { index: 6, name: t("Status") },
  ];


  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    PageTitle(t("License"));
    setBarTitle("License");
    getLicenseIndex();
  }, []);

  return (
    <div className='mx-s12 md:mx-s32'>
      <div className='flex justify-between'>
        <CommonTitle title="License" />
        <div className='flex mt-s20'>
          <NavLink to="/license"><img className='w-s25 h-s25 mr-s15' src={location.pathname === "/license" ? iGroup : iGroupGrid} alt="" /></NavLink>
          <NavLink to="/license/list"><img className='w-s25 h-s25' src={location.pathname === "/license/list" ? iBlueListGroup2 : iGroupList2} alt="" /></NavLink>
        </div>
      </div>


      <div className="overflow-hidden rounded-lg bg-cBrandColor2">
        <CommonTable
          headers={LicenseHeaders}
          shoSearchBox={false}
          showPageCountText={true}
          showPagination={true}

          titleComponent={
            <div className='mb-s20'>
              <CommonButton
                colorType="primary"
                btnLabel="Add License"
                roundedFull="true"
                onClick={() => { setShowAddLicenseModal(true) }}
                icon={<div className="mr-s5"><img className="w-s20 h-s15" src={iAddItem} alt="" /></div>}
              />
            </div>
          }

          paginationObject={licenseIndex}
          autoManageRow={true}
          TableRowComponent={LicenseTableRow}
        />
      </div>
    </div>
  );
};

export default License;