import create from "zustand";

const useMessageStore = create((set) => ({
  showMessageModal: false,
  setShowMessageModal: (value) => set({ showMessageModal: value }),

}));


export default useMessageStore;
