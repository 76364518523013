import React, { useState } from 'react';
import ProfileImageUploader from '../../../Components/Image/ProfileImageUploader';
import useCategoryStore, { createCategory } from './../../../App/Stores/CategoryStore';
import CommonButton from './../../../Components/Button/CommonButton';
import CommonInput from './../../../Components/Input/CommonInput';
import CommonModal from './../../../Components/Modal/CommonModal';

const AddCategoryModal = () => {

    const { addingNewCategory, setAddingNewCategory } = useCategoryStore();

    const [name, setName] = useState("")
    const [image, setImage] = useState("")

    const submit = async (e) => {
        e.preventDefault()
        const data = { "name": name, "icon": image }
        let value = await createCategory(data);
        await setName('')
        await setImage('')
        if (value) return setAddingNewCategory(false)
    }

    return (
        <>
            <CommonModal
                showModal={addingNewCategory}
                setShowModal={setAddingNewCategory}
                modalTitle="Add Category"
                mainContent={
                    <>
                        <form onSubmit={submit}>
                            <div className='flex justify-center'>
                                <div className='my-s20'>
                                    <ProfileImageUploader  iImage={image} setImage={setImage} /></div>
                            </div>
                            <div className="mb-s20">
                                <p className="text-center text-cWarningText text-fs14">
                                 Only png , jpg , psd Image upload
                                </p>
                            </div>
                            <CommonInput type="text" required={true} value={name} label="Write Category Name" onChange={(e) => { setName(e.target.value) }} className2="mb-s20" placeholder="Write category name" />

                            {/* <CommonInput type="text"  label="Description" rows="4" textarea="true" className2="mb-s20" placeholder="Write description" /> */}

                            <div className='flex justify-center mt-s20'>
                                <CommonButton type="submit" btnLabel="Add Category" colorType="primary" roundedFull="true" />
                            </div>
                        </form>
                    </>
                }

            />
        </>
    );
};

export default AddCategoryModal;
