import HTMLReactParser from "html-react-parser";
import React, { useEffect } from "react";
import { useState } from "react";
import Clamp from "react-multiline-clamp";
import { Navigate, useNavigate } from "react-router-dom";
import useSettingsStore, { getTermsConditionDetail } from "../../../App/Stores/SettingsStore";
import { iImageIogo } from "../../../App/Utility/source";
import BannerCardCategoryType from "../../../Components/bannerCard/BannerCardCategoryType";
import Image from "../../../Components/Image/Image";
import { htmlToPlainText } from "../../../Utility/UtilityFunctions";

const TermsAndConditionCard = ({ data }) => {

  const [color, setColor] = useState("text-cTextColor");
  const [isHoverState, setIsHoverState] = useState(false);


  const navigate=useNavigate()
  
  const parser = input =>
    HTMLReactParser(input, {
      replace: domNode => {
        if (domNode instanceof Element && domNode.attribs.class === 'remove') {
          return <></>;
        }
      }
    });

  return (
    <div
      onMouseEnter={() => setIsHoverState(true)}
      onMouseLeave={() => setIsHoverState(false)}
      onClick={async () => {
       navigate('/settings/GdprTerms/details');
        await getTermsConditionDetail(data?.id)
      }}
      onMouseOver={() => {
        setColor("text-cBrandColor2");
      }}
      onMouseOut={() => {
        setColor("text-cHighlightedTexts");
      }}
      className={`cursor-pointer rounded-br10 hover:bg-cBrandColor w-full hover:text-cBrandColor2 my-s20 ${data?.status === 0 ? '' : ""}`}
    >
      <div className=" w-full h-[264px] flex flex-col gap-y-s10 p-s16 border-[2px] border-cBorderBottom hover:border-cBrandColor rounded-br10">
        <Image
          cursorPointerClass="cursor-pointer"
          className="object-cover w-full rounded-br10 h-s121 grow-0"
          src={data?.image}
          dummyImage={iImageIogo}
        />

        <div className="flex justify-between">
          <span className="text-fs14 font-fw600">GDPR & Terms</span>
          <div className={`${data?.status ? 'text-cPassed' : 'text-cFailed'} mt-s5 text-fs10 font-fw400`}>
            {data?.status ? 'Active' : <span className=''>Deactivate</span>} </div>
        </div>

        <BannerCardCategoryType categoryType={data?.app_type} width="w-[75px]"/>
        <div className={`w-full leading-tight ${color}`}>
          <Clamp withTooltip lines={2}>
            <div
              className={`${isHoverState ? "text-white force-white-text" : "text-black"} `}
            // dangerouslySetInnerHTML={{ __html: data?.content }} 
            >
              {htmlToPlainText(data?.content)}
            </div>
          </Clamp>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionCard;
