/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import AxiosHeader from "./App/Utility/AxiosHeader";
import Layout from "./Components/Layout/Layout";
import OverlayModalArea from "./Components/Layout/OverlayModalArea";
import LoadingModal from "./Components/Modal/LoadingModal";
import Error404 from "./Components/Others/Error404";
import Login from "./Pages/Auth/Login";
import LoginScreen from "./Pages/Auth/LoginScreen";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Test from "./Pages/Test/Test";
import { useEffect, useState } from "react";
import useAuthStore, { verifyAuthUser } from "./App/Stores/authStore";
import ProtectedRoute from "./App/Utility/ProtectedRoute";
import SplashScreen from "./Components/Layout/SplashScreen";
import Banner from "./Pages/banner/Banner";
import Category from "./Pages/Category/Category";
import CategoryDetails from "./Pages/Category/CategoryDetails";
import ContactUs from "./Pages/ContactUs/ContactUs";
import DrivingLessons from "./Pages/Instructor/DrivingLessons";
import ExternalLessons from "./Pages/Instructor/ExternalLessons";
import Instructor from "./Pages/Instructor/Instructor";
import InstructorDetail from "./Pages/Instructor/InstructorDetail";
import UpcomingBookings from "./Pages/Instructor/UpcomingBookings";
import WorkPlace from "./Pages/Instructor/WorkPlace";
import Invoice from "./Pages/Invoice/Invoice";
import InvoiceDetail from "./Pages/Invoice/InvoiceDetail";
import LicenseInstructor from "./Pages/License/Instructor/LicenseInstructor";
import License from "./Pages/License/License";
import License2 from "./Pages/License/License2";
import SchoolClasses from "./Pages/School/Classes/SchoolClasses";
import SchoolClassroom from "./Pages/School/Classrooms/SchoolClassroom";
import School from "./Pages/School/School";
import SchoolDetail from "./Pages/School/SchoolDetail";
import SchoolStudentProfile from "./Pages/School/Student/SchoolStudentProfile";
import StudentBasicDetails from "./Pages/School/Student/StudentBasicDetails";
import CurriculumDetails from "./Pages/Student/Curriculum/CurriculumDetails";
import CurriculumHistoryDetails from "./Pages/Student/Curriculum/CurriculumHistoryDetails";
import StudentSchoolProfile from "./Pages/Student/School/StudentSchoolProfile";
import Student from "./Pages/Student/Student";
import StudentDetail from "./Pages/Student/StudentDetail";
import useUtilityStore from "./App/Stores/UtilityStore";
import CategoryParent from "./Pages/Category/CategoryParent";
import InvoiceParent from "./Pages/Invoice/InvoiceParent";
import SchoolParent from "./Pages/School/SchoolParent";
import InstructorParent from "./Pages/Instructor/InstructorParent";
import StudentParent from "./Pages/Student/StudentParent";
import ContactUsParent from "./Pages/ContactUs/ContactUsParent";
import LicenseParent from "./Pages/License/LicenseParent";
import HowTo from "./Pages/settings/Components/HowTo";
import SettingsParent from "./Pages/settings/SettingsParent";
import FaqManagement from "./Pages/settings/Components/FaqManagement";
import TermsAndCondition from "./Pages/settings/Components/TermsAndCondition";
import ChangePassword from "./Pages/settings/Components/ChangePassword";
import Moms from "./Pages/settings/Components/Moms";
import CityManagement from "./Pages/settings/Components/CityManagement";
import DeleteMessage from "./Pages/settings/Components/DeleteMessage";
import CurriculumHistory from "./Pages/Student/Curriculum/CurriculumHistory";
import EditGDPRTerms from "./Pages/settings/Components/EditGDPRTerms";
import GDPRTermsDetails from "./Pages/settings/Components/GDPRTermsDetails";
import SchoolInvoiceTable from "./Pages/School/Invoices/SchoolInvoiceTable";
import InstructorInvoiceTable from "./Pages/Instructor/InstructorInvoiceTable";
import SecondaryInvoiceDetails from "./Pages/Invoice/SecondaryInvoiceDetails";
import StudentInvoiceTable from "./Pages/Student/Invoice/StudentInvoiceTable";
import LicenseDetails from "./Pages/License/LicenseDetails";
import SchoolCategory from "./Pages/School/Category/SchoolCategory";
import SchoolCategoryDetails from "./Pages/School/Category/SchoolCategoryDetails";
import SchoolInstructor from "./Pages/School/Instructor/SchoolInstructor";
import SchoolInstructorDetails from "./Pages/School/Instructor/SchoolInstructorDetails";
import SchoolInstructorFindDetails from "./Pages/School/Instructor/SchoolInstructorFindDetails";
import ContactWebTable from "./Pages/ContactUs/Web/ContactWebTable";
import SchoolStudent from "./Pages/School/Student/SchoolStudent";
import StudentSchool from "./Pages/Student/School/StudentSchool";
import StudentSchoolCategoryDetails from "./Pages/Student/School/StudentSchoolCategoryDetails";

if (localStorage.maway_token) {
  AxiosHeader(localStorage.maway_token);
} else {
  if (localStorage.postcard_token) {
    AxiosHeader(localStorage.maway_token);
  } else {
    AxiosHeader(null);
  }
}

function App(props) {
  const { setIsLoggedIn } = useAuthStore();
  const { loggedUser, setLoggedUser } = useUtilityStore();
  const [loading, setLoading] = useState(true);

  const navigateTo = useNavigate();
  const token = localStorage.getItem("maway_token");

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
      verifyIfRealUser();
    } else {
      setIsLoggedIn(false);
      // console.log("LOGGED OUT: REDIRECTING LOGIN...");
      navigateTo("/login");
    }
  }, [token]);

  const verifyIfRealUser = async () => {
    await verifyAuthUser();

  }

  useEffect(() => {

    if (loggedUser?.name === "") {
      let local_data = "";
      local_data = localStorage.getItem("user");
      local_data && setLoggedUser(JSON.parse(local_data));
    }

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="bg-cLayoutBg text-cTextBlack">
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
            theme="dark"
            limit={2}
          />

          <LoadingModal />
          <OverlayModalArea />

          <Routes>
            {/* red 404 not found */}
            <Route exact path="/*">
              <Route
                path="*"
                element={
                  <Layout>
                    <Error404 />
                  </Layout>
                }
              />
            </Route>

            {/* red: login */}
            <Route exact path="/login" element={<Login />} />
            {/* blue: dashboard */}
            <Route exact path="/" element={<ProtectedRoute />}>
              <Route
                exact
                path="/"
                element={
                  <Layout {...props}>
                    <Dashboard />
                  </Layout>
                }
              ></Route>


              {/********************** Category Start ***********************/}
              <Route
                exact
                path="/Category"
                element={
                  <Layout {...props}>
                    <CategoryParent />
                  </Layout>
                }
              >
                <Route index element={<Category />} />
                <Route path="details/:id" element={<CategoryDetails />} />
                <Route path="details/:id/school" element={<School />} />
                <Route path="details/:id/student" element={<Student />} />
                <Route path="details/:id/instructor" element={<Instructor />} />
                <Route path="details/:id/instructor/:instructor_id" element={<InstructorDetail />} />
              </Route>
              {/********************** Category End ***********************/}


              {/********************** School Start ***********************/}
              <Route
                exact
                path="/school"
                element={
                  <Layout {...props}>
                    <SchoolParent />
                  </Layout>
                }
              >
                <Route index element={<School />} />
                <Route path="details/:school_id" element={<SchoolDetail />} />
                <Route path="details/:school_id/category" element={<SchoolCategory />} />
                <Route path="details/:school_id/category/details/:school_category_id" element={<SchoolCategoryDetails />} />
                <Route path="details/:school_id/classes" element={<SchoolClasses />} />
                <Route path="details/:school_id/classroom" element={<SchoolClassroom />} />
                <Route path="details/:school_id/instructor" element={<SchoolInstructor />} />
                <Route path="details/:school_id/instructor/:school_instructor_id" element={<SchoolInstructorDetails />} />
                <Route path="details/:school_id/instructor/:school_instructor_id/find" element={<SchoolInstructorFindDetails />} />
                <Route path="details/:school_id/student" element={<SchoolStudent />} />
                <Route path="details/:school_id/student/details/:student_id" element={<StudentDetail />} />
                <Route path="details/:school_id/student/profile" element={<SchoolStudentProfile />} />
                <Route path="details/:school_id/invoice/:school_user_id" element={<SchoolInvoiceTable />} />
                <Route path="details/:school_id/invoice/:school_user_id/details/:invoice_id/:invoice_type" element={<SecondaryInvoiceDetails />} />
              </Route>

              {/********************** School End ***********************/}


              {/********************** Instructor Start ***********************/}

              <Route
                exact
                path="/instructor"
                element={
                  <Layout {...props}>
                    <InstructorParent />
                  </Layout>
                }
              >
                <Route index element={<Instructor />} />
                <Route path="details/:instructor_id" element={<InstructorDetail />} />
                <Route path="details/:instructor_id/workplace" element={<WorkPlace />} />
                <Route path="details/:instructor_id/upcoming/bookings" element={<UpcomingBookings />} />
                <Route path="details/:instructor_id/driving/lessons" element={<DrivingLessons />} />
                <Route path="details/:instructor_id/external/lessons" element={<ExternalLessons />} />
                <Route path="details/:instructor_id/invoice/:instructor_user_id" element={<InstructorInvoiceTable />} />
                {/* /school/details/${school_id}/invoice/${school_user_id}/details/${data?.id} */}
                <Route path="details/:instructor_id/invoice/:instructor_user_id/details/:invoice_id/:invoice_type" element={<SecondaryInvoiceDetails />} />
              </Route>

              {/**********************  Instructor End ***********************/}


              {/********************** Student Start ***********************/}

              <Route
                exact
                path="/student"
                element={
                  <Layout {...props}>
                    <StudentParent />
                  </Layout>
                }
              >
                <Route index element={<Student />} />
                <Route path="details/:student_id" element={<StudentDetail />} />
                <Route path="details/:student_id/curriculum/details" element={<CurriculumDetails />} />
                <Route path="details/:student_id/curriculum/history" element={<CurriculumHistory />} />
                <Route path="details/:student_id/curriculum/history/details/:category_id" element={<CurriculumHistoryDetails />} />
                <Route path="details/:student_id/invoice" element={<StudentInvoiceTable />} />
                <Route path="details/:student_id/school" element={<StudentSchool />} />
                <Route path="details/:student_id/school/:school_id" element={<StudentSchoolProfile />} />
                <Route path="details/:student_id/school/:school_id/category" element={<StudentSchoolCategoryDetails />} />

                <Route path="details/:student_id/invoice/details/:invoice_id/:invoice_type" element={<SecondaryInvoiceDetails />} />
              </Route>

              {/********************** Student End ***********************/}



              {/********************** license Start ***********************/}

              <Route
                exact
                path="/license"
                element={
                  <Layout {...props}>
                    <LicenseParent />
                  </Layout>
                }
              >
                <Route index element={<License2 />} />
                <Route path="list" element={<License />} />
                <Route path="details/:license_id" element={<LicenseDetails />} />
                <Route path="details/list/:license_id" element={<LicenseDetails />} />
                <Route path="instructor/profile" element={<LicenseInstructor />} />
              </Route>

              {/**********************  license End ***********************/}


              {/********************** Invoice Start ***********************/}
              <Route
                exact
                path="/invoice"
                element={
                  <Layout {...props}>
                    <InvoiceParent />
                  </Layout>
                }
              >
                <Route index element={<Invoice />} />
                <Route path="details/:invoice_id" element={<InvoiceDetail />} />
                <Route path=":invoice_id/:school_id" element={<SchoolDetail />} />
                <Route path=":invoice_id/:instructor_id" element={<InstructorDetail />} />
              </Route>
              {/********************** Invoice End ***********************/}



              {/********************** Contact Us Start ***********************/}
              <Route
                exact
                path="/contact-us"
                element={
                  <Layout {...props}>
                    <ContactUsParent />
                  </Layout>
                }
              >
                <Route index element={<ContactUs />} />
                <Route path="student-profile/details/:student_id" element={<StudentDetail />} />
                <Route path="instructor-profile/details/:instructor_id" element={<InstructorDetail />} />
                <Route path="school-profile/details/:school_id" element={<SchoolDetail />} />
                
                {/* l   contact web */}
                <Route path="web" element={<ContactWebTable />} />
              </Route>              

              {/********************** Contact Us End ***********************/}



              <Route
                exact
                path="/banner"
                element={
                  <Layout>
                    <Banner />
                  </Layout>
                }
              >
                <Route
                  index
                  element={
                    <Layout>
                      <Banner />
                    </Layout>
                  }
                />
              </Route>

              {/********************** Settings Start ***********************/}
              <Route
                exact
                path="/settings"
                element={
                  <Layout {...props}>
                    <SettingsParent />
                  </Layout>
                }
              >
                <Route index element={<FaqManagement />} />
                <Route path="how-to" element={<HowTo />} />
                <Route path="terms-condition" element={<TermsAndCondition />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="moms" element={<Moms />} />
                <Route path="city" element={<CityManagement />} />
                <Route path="delete-message" element={<DeleteMessage />} />
                <Route path="editGdprTerms" element={<EditGDPRTerms />} />
                <Route path="GdprTerms/details" element={<GDPRTermsDetails />} />

              </Route>
              {/********************** Settings End ***********************/}

              {/* <Route
                exact
                path="/settings"
                element={
                  <Layout>
                    <Settings />
                  </Layout>
                }
              >
                <Route
                  index
                  element={
                    <Layout>
                      <Settings />
                    </Layout>
                  }
                />
              </Route> */}

              {/* <Route
                exact
                path="/SchoolProfile"
                element={
                  <Layout>
                    <CategorySchoolProfile />
                  </Layout>
                }
              >
                <Route
                  index
                  element={
                    <Layout>
                      <CategorySchoolProfile />
                    </Layout>
                  }
                />
              </Route> */}


            </Route>

            {/* blue: testing */}
            <Route
              exact
              path="/test"
              element={
                // <Layout>
                <Test />
                // </Layout>
              }
            ></Route>

            {/* blue: testing */}
            <Route exact path="/login2" element={<LoginScreen />}></Route>
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
