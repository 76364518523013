import React from 'react';
import useStudentStore from '../../../App/Stores/StudentStore';
import { iClassroomCard, iDrivingCard, iExternalCard } from '../../../App/Utility/source';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';

const StudentSchoolLessonApplyTableRow = ({ data, index }) => {

    const { studentFindSchoolCategoryInfo, fullPackageChecked } = useStudentStore();

    console.log("data", data);

    return (

        <>
            <tr className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >
                <th className='m-2 font-normal text-center border-r py-s10 '>
                    <span className='mr-s12 dm:mrs12 md:mr-0'>{index + 1}</span>
                </th>

                <td className='border-r-[1px] py-s10 text-left px-s10 max-w-[120px] min-w-[120px] truncate'>
                    <div className='flex sm:flex-col md:flex-row'>
                        <div className='max-w-[40px] min-w-[40px] '>
                            <img className='w-s38 h-s38 grow-0 flex items-center'
                                src={data[0]?.type === 'driving' && iDrivingCard || data[0]?.type === 'external' && iExternalCard || data[0]?.type === 'classroom' && iClassroomCard} alt="" />
                        </div>
                        <div className='capitalize truncate flex items-center font-fw600 mx-s10  text-cMainBlack lg:text-fs14 sm:text-fs12 '>
                            {data[0]?.name ? data[0].name : <CommonEmptyStatus />}
                        </div>
                    </div>
                </td>

                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody max-w-[80px] min-w-[80px]'>
                    <span className='font-fw400 text-cImportantText text-fs14'>
                        {data[0]?.duration ? toHoursAndMinutes(data[0]?.duration) : <CommonEmptyStatus />}
                    </span>
                </td>


                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody max-w-[70px] min-w-[70px]'>
                    <span className='font-fw400 text-cImportantText text-fs14'>
                        {data[0]?.is_mandatory ? 'Yes' : 'No'}
                    </span>
                </td>

                <td className='px-s10 border-r-[1px] text-fs14 text-center text-cTextBody max-w-[100px] min-w-[100px]'>
                    <span className='font-fw400 text-cImportantText text-fs14 mt-s12 '>
                        {data[0]?.price ? `${data[0]?.price} DKK` : <CommonEmptyStatus />}
                    </span>
                </td>
            </tr>
        </>

    );
};

export default StudentSchoolLessonApplyTableRow;