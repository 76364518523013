import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import useInvoiceStore, { getStudentInvoiceIndex } from '../../../App/Stores/InvoiceStore';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import { iFilter, iFilterWhite } from '../../../App/Utility/source';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonTable from '../../../Components/Table/CommonTable';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { PageTitle } from '../../../Utility/UtilityFunctions';
import StudentInvoiceFilter from './StudentInvoiceFilter';
import StudentInvoiceRow from './StudentInvoiceRow';

function StudentInvoiceTable() {

    const {
        setShowStudentInvoiceFilterModal,
        invoiceIndexDataStudent,
        studentInvoiceSearchKey,
        setStudentInvoiceSearchKey,
        filterStudentInvoiceData,
        resetFilterStudentInvoiceData,
        setStudentID,
        setStudentUID,
        setStudentInvoiceHistoryMode,
        studentInvoiceFilterActive
    } = useInvoiceStore();

    const InvoiceHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: localStorage.getItem("studentInvoiceType") === "history" ? "Name" : "School Name" },
        { index: 3, name: t("Email") },
        { index: 4, name: t("Invoice ID") },
        { index: 5, name: t("User Type") },
        { index: 6, name: t("Created On") },
        { index: 7, name: t("Last Action") },
        { index: 8, name: t("Status") },
    ];

    const [searchValue] = useDebounce(studentInvoiceSearchKey, 500);

    const { setBarTitle } = useLayoutStore();

    const { student_id, instructor_user_id } = useParams();

    useEffect(() => {
        SmartSearchStudentInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const SmartSearchStudentInvoice = async () => {
        if (searchValue) {
            await getStudentInvoiceIndex(student_id, localStorage.getItem("studentInvoiceType") ?? "school_student", "", searchValue, filterStudentInvoiceData);
        } else {
            setStudentInvoiceSearchKey("")
            await getStudentInvoiceIndex(student_id, localStorage.getItem("studentInvoiceType") ?? "school_student", "", "", filterStudentInvoiceData);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (localStorage.getItem("studentInvoiceType") === "history") setStudentInvoiceHistoryMode(true);
        else setStudentInvoiceHistoryMode(false);

        if (!localStorage.getItem("studentInvoiceType")) localStorage.setItem("studentInvoiceType", "school_student");

        fetchInvoice();
        PageTitle(t("Student Invoice"));
        setBarTitle("Student Invoice");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("studentInvoiceType")]);

    useEffect(() => {
        setStudentUID(instructor_user_id);
        setStudentID(student_id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructor_user_id, student_id]);

    const fetchInvoice = async () => {
        if (studentInvoiceSearchKey) {
            await getStudentInvoiceIndex(student_id, localStorage.getItem("studentInvoiceType") ?? "school_student", localStorage.getItem("studentInvoicePaginationURL") ?? "", studentInvoiceSearchKey, filterStudentInvoiceData);
        } else {
            await getStudentInvoiceIndex(student_id, localStorage.getItem("studentInvoiceType") ?? "school_student", localStorage.getItem("studentInvoicePaginationURL") ?? "", "", filterStudentInvoiceData);
        }
    }

    return (
        <div className='mx-s12 md:mx-s32'>
            <CommonTitle title="Student Invoice">
                <BackLink linksArray={[
                    { label: "Student", linkTo: "/student" },
                    { label: "Student Profile", linkTo: `/student/details/${student_id}` },
                    { label: "Student Invoice", linkTo: `` }
                ]} />
            </CommonTitle>

            {/* idea:       api call issue */}
            <StudentInvoiceFilter />

            <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
                <CommonTable
                    currentTakeAmount={localStorage.getItem("studentInvoiceTake") ?? 10}
                    topRightComponent={<TakeItem />}
                    headers={InvoiceHeaders}
                    tableTitle=""
                    showPagination={true}
                    showChip={true}
                    chipWidth="min-w-[150px]"
                    chipArray={[
                        {
                            title: "School",
                            selected: localStorage.getItem("studentInvoiceType") === "school_student" ? true : false,
                            action: async () => {
                                console.log("School");
                                setStudentInvoiceHistoryMode(false);
                                localStorage.setItem("studentInvoicePaginationURL", "");
                                localStorage.setItem("studentInvoiceType", "school_student");
                                await resetFilterStudentInvoiceData();
                                if (localStorage.getItem("studentInvoiceType") === "school_student") await getStudentInvoiceIndex(student_id, "school_student", localStorage.getItem("studentInvoicePaginationURL") ?? "", "", {});
                                else await getStudentInvoiceIndex(student_id, "school_student", localStorage.getItem("studentInvoicePaginationURL") ?? "", "", filterStudentInvoiceData);
                            },
                        },

                        {
                            title: "History",
                            selected: localStorage.getItem("studentInvoiceType") === "history" ? true : false,
                            action: async () => {
                                console.log("History");
                                setStudentInvoiceHistoryMode(true);
                                localStorage.setItem("studentInvoicePaginationURL", "");
                                localStorage.setItem("studentInvoiceType", "history");
                                await resetFilterStudentInvoiceData();
                                if (localStorage.getItem("studentInvoiceType") === "history") await getStudentInvoiceIndex(student_id, "history", localStorage.getItem("studentInvoicePaginationURL") ?? "", "", {});
                                else await getStudentInvoiceIndex(student_id, "history", localStorage.getItem("studentInvoicePaginationURL") ?? "", "", filterStudentInvoiceData);
                            },
                        },
                    ]}

                    shoSearchBox={true}
                    searchValue={studentInvoiceSearchKey}
                    withClearSearch={true}
                    onSearchClear={() => {
                        setStudentInvoiceSearchKey("");
                    }}
                    searchOnChange={(e) => {
                        console.log("SEARCH", e.target.value);
                        localStorage.setItem("studentInvoicePaginationURL", "");
                        setStudentInvoiceSearchKey(e.target.value);
                    }}

                    titleComponent={
                        <>
                            <CommonButtonOutlined
                                iconLeft={iFilterWhite}
                                iconLeftHover={iFilter}
                                isFilterDot={studentInvoiceFilterActive}
                                colorType='primary'
                                btnLabel="Filter"
                                onClick={() => { setShowStudentInvoiceFilterModal(true) }}
                            />
                        </>
                    }

                    autoManageRow={true}
                    TableRowComponent={StudentInvoiceRow}
                    paginationObject={invoiceIndexDataStudent}

                    paginationOnClick={async (url) => {
                        console.log(url);
                        await localStorage.setItem("studentInvoicePaginationURL", url);
                        await getStudentInvoiceIndex(student_id, localStorage.getItem("studentInvoiceType") ?? "school_student", url, studentInvoiceSearchKey, filterStudentInvoiceData);
                    }}

                />
            </div>
        </div>
    );
};

const TakeItem = () => {

    const { studentInvoiceSearchKey, filterStudentInvoiceData } = useInvoiceStore();
    const { student_id } = useParams();

    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                defaultValue={localStorage.getItem("studentInvoiceTake") ?? 10}
                onChange={async (e) => {
                    await localStorage.setItem("studentInvoicePaginationURL", "");
                    await localStorage.setItem("studentInvoiceTake", e.target.value);
                    console.log("studentInvoiceTake", e.target.value);
                    await getStudentInvoiceIndex(student_id, localStorage.getItem("studentInvoiceType") ?? "school_student", "", studentInvoiceSearchKey, filterStudentInvoiceData);
                }}
                className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("studentInvoiceTake") === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("studentInvoiceTake") === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("studentInvoiceTake") === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={localStorage.getItem("studentInvoiceTake") === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}



export default StudentInvoiceTable