import axios from "axios";
import create from "zustand";
import { dashboardDataUrl, dashboardPendingActivityUrl, getAllSchoolListUrl, instructorFilterUrl, studentFilterSearchUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useDashboardStore = create((set) => ({
  recentOrderData: [
    {
      orderedProduct: "Lovable Gift Card",
      qty: "3",
      customerName: "Subrata halder",
      paymentMethod: "COD",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "5",
      customerName: "Subrata halder",
      paymentMethod: "Stripe",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "8",
      customerName: "Subrata halder",
      paymentMethod: "Pay Pal",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "1",
      customerName: "Subrata halder",
      paymentMethod: "Debit Card",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
    {
      orderedProduct: "Lovable Gift Card",
      qty: "3",
      customerName: "Subrata halder",
      paymentMethod: "COD",
      phone: "01684-585279",
      address: "014-396, Tokyo city, Tokyo",
      price: "$150",
      isActive: true,
    },
  ],

  topSellingData: [
    {
      serialNumber: "1",
      cardName: "LX Gift Card",
      price: "$50",
      totalOrder: "34,666 Piece",
      totalSales: "$3,46,660",
      isActive: true,
    },
    {
      serialNumber: "2",
      cardName: "ASP Gift Card",
      price: "$20",
      totalOrder: "20,000 Piece",
      totalSales: "$1,20,000",
      isActive: true,
    },
    {
      serialNumber: "3",
      cardName: "EUG Gift Card",
      price: "$50",
      totalOrder: "15,000 Piece",
      totalSales: "$1,20,000",
      isActive: true,
    },
    {
      serialNumber: "4",
      cardName: "JPX Gift Card",
      price: "$40",
      totalOrder: "10,000 Piece",
      totalSales: "$1,50,000",
      isActive: true,
    },
    {
      serialNumber: "5",
      cardName: "AZR Gift Card",
      price: "$70",
      totalOrder: "10,000 Piece",
      totalSales: "$3,00,000",
      isActive: true,
    },
  ],

  pendingClickable: false,
  setPendingClickable: (data) => set({ pendingClickable: data }),

  selected_option: "invoice",
  setSelectedOption: (data) => set({ selected_option: data }),

  dashboard_data: {},
  setDashboardData: (data) => set({ dashboard_data: data }),

  newSignUpData: {},
  setNewSignUpData: (data) => set({ newSignUpData: data }),

  pending_activity_data: [],
  setPendingActivityData: (data) => set({ pending_activity_data: data }),

  recentOrders: [],
  setRecentOrders: (value) => set({ recentOrders: value }),

  topSellingCards: [],
  setTopSellingCards: (value) => set({ topSellingCards: value }),

  analytics: {},
  setAnalytics: (value) => set({ analytics: value }),

  dashboardBoxes: {},
  setDashboardBoxes: (value) => set({ dashboardBoxes: value }),

  totalCard: "",
  setTotalCard: (value) => set({ totalCard: value }),

  totalShop: "",
  setTotalShop: (value) => set({ totalShop: value }),

  totalCategory: "",
  setTotalCategory: (value) => set({ totalCategory: value }),

  totalCustomer: "",
  setTotalCustomer: (value) => set({ totalCustomer: value }),
}));

export default useDashboardStore;

/* 
    API Functions
 */


export const getDashboardData = async (url) => {
  try {
    // setLoading(true);
    const res = await axios.get(url ?? dashboardDataUrl);
    console.log("getDashboardData res.data:::: ", res);

    if (res.data.success) {
      useDashboardStore.getState().setDashboardData(res.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    // setLoading(false);
    return;
  } catch (error) {
    console.log("getDashboardData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    // setLoading(false);
    return;
  }
};


export const getDashboardPendingActivityData = async (type = 'invoice') => {
  try {
    // setLoading(true);
    const res = await axios.get(dashboardPendingActivityUrl + '?type=' + type);
    const res_dash = await axios.get(dashboardDataUrl);
    console.log("getDashboardPendingActivityData res.data:::: ", res.data);

    if (res.data.success) {
      useDashboardStore.getState().setPendingActivityData(res.data.data);
      useDashboardStore.getState().setDashboardData(res_dash.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    // setLoading(false);
  } catch (error) {
    console.log("getDashboardPendingActivityData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    // setLoading(false);
  }
};


export const getNewSignUpData = async (type) => {
  try {
    // setLoading(true);
    if (!type) return console.log("TYPE IS REQUIRED FOR getNewSignUpData !!!");
    else console.log("newSignUpData TYPE::: ", type);
    useDashboardStore.getState().setNewSignUpData({});
    const res = await axios.post(type === 'school' ? getAllSchoolListUrl : type === 'student' ? studentFilterSearchUrl : type === 'instructor' ? instructorFilterUrl : "", { flag: "new", take: 15 });
    const res_dash = await axios.get(dashboardDataUrl);
    console.log("getNewSignUpData res.data:::: ", res.data);

    if (res.data.success) {
      useDashboardStore.getState().setNewSignUpData(res.data.data);
      useDashboardStore.getState().setDashboardData(res_dash.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    // setLoading(false);
  } catch (error) {
    console.log("getNewSignUpData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    // setLoading(false);
  }
};

