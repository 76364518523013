import create from "zustand";
import useUtilityStore from "./UtilityStore";
import { addCategoryUrl, editCategoryUrl, getAllCategoryUrl, deleteCategoryUrl, toggleStatusCategoryUrl, detailCategoryUrl } from './../Utility/Url';
import { Toastr } from "../Utility/UtilityFunctions";
import axios from "axios";
const { setLoading } = useUtilityStore.getState();

const useCategoryStore = create((set) => ({

  categoryListAll: [],
  setCategoryListAll: (value) => set({ categoryListAll: value }),

  categoryDetails: [],
  setCategoryDetails: (value) => set({ categoryDetails: value }),

  categoryListPagination: {},
  setCategoryListPagination: (value) => set({ categoryListPagination: value }),

  categoryListAllTemp: [],
  setCategoryListAllTemp: (value) => set({ categoryListAllTemp: value }),

  categoryFormData: { name: "", icon: "" },
  setCategoryFormData: (name, value) =>
    set((state) => (state.categoryFormData[name] = value)),

  EditFormData: { name: "", icon: "" },
  setEditFormData: (name, value) =>
    set((state) => (state.EditFormData[name] = value)),

  resetCategoryFormData: () =>
    set((state) => (state.categoryFormData = { name: "", icon: "" })),

  updateImg: false,
  setUpdateImg: (value) => set({ updateImg: value }),

  updateName: false,
  setUpdateName: (value) => set({ updateName: value }),

  deleteCategoryForm: { id: "" },
  setDeleteCategoryForm: (value) =>
    set((state) => (state.deleteCategoryForm["id"] = value)),

  DeactivateCategoryForm: {
    id: -1,
  },
  setDeactivateCategoryForm: (value) =>
    set((state) => (state.DeactivateCategoryForm["id"] = value)),

  searchKey: "",
  setSearchKey: (value) => set({ searchKey: value }),


  categoryPageUrl: "",
  setCategoryPageUrl: (value) => set({ categoryPageUrl: value }),

  searchCategoryKey: "",
  setSearchCategoryKey: (value) => set({ searchCategoryKey: value }),

  temporaryCategoryList: [],
  setTemporaryCategoryList: (value) => set({ temporaryCategoryList: value }),

  categoryTakeList: { take: 10 },
  setCategoryTakeList: (name, value) => set((state) => (state.categoryTakeList[name] = value)),

  // Modals
  showDeleteModal: false,
  setShowDeleteModal: (value) => set({ showDeleteModal: value }),

  showDeleteCategoryModal: false,
  setShowDeleteCategoryModal: (value) => set({ showDeleteCategoryModal: value }),

  showEditModal: false,
  setShowEditModal: (value) => set({ showEditModal: value }),

  showStudentEditModal: false,
  setShowStudentEditModal: (value) => set({ showStudentEditModal: value }),

  showSchoolEditModal: false,
  setShowSchoolEditModal: (value) => set({ showSchoolEditModal: value }),

  showInstructorEditModal: false,
  setShowInstructorEditModal: (value) => set({ showInstructorEditModal: value }),

  showExpertiseAreaEditModal: false,
  setShowExpertiseAreaEditModal: (value) => set({ showExpertiseAreaEditModal: value }),

  showEditLicenseModal: false,
  setShowEditLicenseModal: (value) => set({ showEditLicenseModal: value }),

  showAddClassModal: false,
  setShowAddClassModal: (value) => set({ showAddClassModal: value }),

  showEditID: 0,
  setShowEditID: (value) => set({ showEditID: value }),

  showDeactivateModal: false,
  setShowDeactivateModal: (value) => set({ showDeactivateModal: value }),

  addingNewCategory: false,
  setAddingNewCategory: (value) => set({ addingNewCategory: value }),

  citySearch: false,
  setCitySearch: (value) => set({ citySearch: value }),

}));

export default useCategoryStore;

const { categoryTakeList, setCategoryListAll, deleteCategoryForm, EditFormData, setCategoryDetails, setTemporaryCategoryList } = useCategoryStore.getState();

export const getCategoryList = async (url = "", take = 500, showLoader = true) => {
  try {
    if (showLoader) setLoading(true);
    getTemporaryCategory()
    const res = await axios.get(url === "" ? getAllCategoryUrl : url, { params: { take: take } });
    console.log("CategoryListData res.data:::: ", res);

    if (res.data.success) {
      setCategoryListAll(res.data);
      if (showLoader) setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      if (showLoader) setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("CategoryListData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    if (showLoader) setLoading(false);
    return false;
  }
};


const getTemporaryCategory = async (url = "") => {
  try {
    setLoading(true);
    const res = await axios.get(url === "" ? getAllCategoryUrl : url, { params: { take: 1000 } });
    //console.log("CategoryListData res.data:::: ", res);

    if (res.data.success) {
      setTemporaryCategoryList(res.data);
      setLoading(false);
      return true;
    }

  } catch (error) {

  }
};

export const createCategory = async (data) => {

  try {
    setLoading(true);
    const res = await axios.post(addCategoryUrl, data);
    console.log("CreateCategory res.data:::: ", res.data);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getCategoryList()
      // await resetCategoryFormData("name", '')
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log(error);
    //Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};



export const editCategoryData = async () => {

  try {
    setLoading(true);
    const res = await axios.post(editCategoryUrl, EditFormData);
    console.log("editCategoryData res.data:::: ", res);

    if (res.status === 200) {
      setCategoryListAll(res.data);
      Toastr({ message: res.data.message, type: "success" });
      await getCategoryDetail(res.data.data.id)
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("editCategoryData:", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


export const deleteCategoryData = async () => {

  try {
    setLoading(true);
    const res = await axios.post(deleteCategoryUrl, deleteCategoryForm);
    console.log("deleteCategoryData res.data:::: ", res);

    if (res.status === 200) {
      Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false
    }

  } catch (error) {
    console.log("deleteCategoryData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false
  }
};



export const categoryToggleStatus = async (id) => {
  try {
    setLoading(true);
    const res = await axios.post(toggleStatusCategoryUrl, { id });
    console.log("CategoryToggleStatus:::: ", res);

    if (res.status === 200) {
      await getCategoryDetail(id)
      //Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true;
    } else {
      //Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("CategoryToggleStatus: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


export const getCategoryDetail = async (id) => {

  try {
    setLoading(true);
    const res = await axios.get(detailCategoryUrl, { params: { "id": id } });
    console.log("categoryDetail res.data:::: ", res.data);

    if (res.status === 200) {
      setCategoryDetails(res.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("categoryDetail: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// Search Category
export const searchCategoryList = async (event) => {

  const result = await useCategoryStore.getState().temporaryCategoryList?.data?.data.filter((item) => {
    if (item) {
      let name = "";
      name = item.name ?? "";

      if (name.toLowerCase().includes(event.toLowerCase())) {
        return item;
      } else {
        return null;
      }
    } else {
      return null;
    }
  });
  console.log("search result: ", result);
  const data = { data: result }
  await useCategoryStore.getState().setCategoryListAll({ data: data });

};
