import React from 'react';
import useCategoryStore, { categoryToggleStatus } from './../../App/Stores/CategoryStore';
import { iEdiItIcon, iCategory } from './../../App/Utility/source';
import GreenSwitch from './../../Components/Switch/GreenSwitch';
import Image from './../../Components/Image/Image';

const CategoryProfile = ({ data }) => {

    const { setShowEditModal, setShowDeactivateModal,setEditFormData } = useCategoryStore();

    const toggleStatus = async () => {
        if (data?.status === false) {
            await categoryToggleStatus(data?.id)
        } else if (data?.status === true) {
            await setShowDeactivateModal(true)
        }
    }

    const resetEditCategoryData=()=>{
        setEditFormData('id', "")
        setEditFormData('name', "")
        setEditFormData("icon","")
   }
   
    return (
        <>
            <div className="bg-cBackgroundAndCategory rounded-br20 overflow-hidden h-s250 sm:h-h250 md:h-full 
            md:flex justify-between">
                <div className="flex col-span-11 my-s20 mx-s20">
                    <div>
                        <div className='rounded-full w-s100 h-s88 pr-s10 pl-s9 bg-cBackgroundAndCategory'>
                            <Image className='h-s65 w-s80' src={data?.icon} dummyImage={iCategory} isCategoryImage={true} />
                        </div>

                            <div className='flex justify-center'>
                                <GreenSwitch
                                    onClick={toggleStatus}
                                    enabled={data?.status}
                                />
                            </div>
                            <div className={`text-center ${data?.status ? 'text-cPassed' : 'text-cFailed'} mt-s5 text-fs14 font-fw400`}>
                                {data?.status ? 'Active' : 'Deactivate'}
                            </div>

                    </div>
                    <div className="">
                        <div className="font-fw600 text-fs24 text-cHighlighted mt-s20 leading-9 ml-s15">{data?.name}</div>
                    </div>
                </div>
                <div onClick={() => { 
                    resetEditCategoryData()
                    setShowEditModal(true)
                     }} className='cursor-pointer w-w28 h-h28 md:mt-s20 mr-s20 ml-s20 md:ml-0 '>
                    <img className="" src={iEdiItIcon} alt="" />
                </div>
            </div>
        </>
    );
};

export default CategoryProfile;