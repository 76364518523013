/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore, { handleUserLogin } from '../../App/Stores/authStore';
import { iAPP_LOGO } from '../../App/Utility/source';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonButton from '../../Components/Button/CommonButton';
import CommonInput from '../../Components/Input/CommonInput';

export default function Login() {
  const { login_form, isLoggedIn, changeLoginFormValue, resetLoginFormValue } = useAuthStore();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }

  }, [isLoggedIn]);

  useEffect(() => {
    PageTitle(t("Login"));
    resetLoginFormValue();

    window.scrollTo(0, 0);

  }, []);

  return (
    <div className="h-screen w-full flex justify-between relative font-montserrat">
      <LeftSideLogo />

      <LoginForm
        login_form={login_form}
        changeLoginFormValue={changeLoginFormValue}
      />
    </div>
  );
}

function LoginForm(props) {
  const { t } = useTranslation();



  return (
    <div className="bg-cBGLiteGrey w-full relative h-screen min-h-[600px]  flex flex-col justify-center items-center">
      <div className="flex justify-center md:hidden">
        <img src={iAPP_LOGO} alt="app-logo" className="w-s150 h-s130 pb-s4" />
      </div>


      {/* right side */}
      <div className="flex w-full justify-center py-5">
        <div className="w-full lg:px-s100 sm:px-s60 px-5">
          <div className="w-full text-center pb-5 text-fs32 text-cTextBlack font-fw600 text-cMainBlack">
            {t("Admin Login")}
          </div>
          <form
            onSubmit={(e) => {
              handleUserLogin(e);
            }}
          >
            <div className="mb-5">
              <CommonInput
                type="email"
                label={t("email")}
                placeholder={t("Enter email address")}
                name={"email"}
                required={true}
                value={props.login_form.email}
                onChange={props.changeLoginFormValue}
              />
            </div>

            <div className="mb-5">
              <CommonInput
                type="password"
                togglePasswordBtn={true}
                label={t("password")}
                placeholder={t("Enter password")}
                name="password"
                required={true}
                value={props.login_form.password}
                onChange={props.changeLoginFormValue}
              />
            </div>

            <div className="w-full flex justify-center pt-4">
              <CommonButton colorType="primary" type="submit" width={"w-full"} btnLabel={t("Login")} />
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

function LeftSideLogo() {
  return (
    <div className="bg-cLoginBgLeft w-full h-screen min-h-[650x] items-center justify-center md:flex md:flex-col hidden bg-cBGLiteGrey">
      <div className="py-1">
        <img src={iAPP_LOGO} alt="app-logo" className="w-s150 " />
      </div>

      <div className="p-2">
        <span className='flex justify-center text-fs36 font-fw700 text-cMainBlack'>Admin Login</span>
        <span className='flex justify-center text-cMainBlack'>Please login to continue</span>
      </div>
    </div>
  );
}
