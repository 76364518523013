import axios from "axios";
import create from "zustand";

import {
  addCityUrl,
  addFaqUrl,
  allFaqListUrl,
  allFeesUrl,
  changeLanguageUrl,
  changePasswordUrl,
  CityIndexUrl,
  createDeleteMessageUrl,
  deleteAccountUrl,
  deleteCityUrl,
  deleteMessageUrl,
  editCityUrl,
  editDeleteMessageUrl,
  editGeneralSettingsIndexUrl,
  editTermsConditionUrl,
  faqDetailUrl,
  getPrivacyUrl,
  getProfileUrl,
  getTermsUrl,
  settingsChangePasswordUrl,
  toggleAllNotificationUrl,
  toggleStatusDeleteMessageUrl,
  toggleStatusFaqUrl,
  updateFeesUrl,
  updatePrivacyUrl,
  updateProfileUrl,
  updateRecommendedCategoryUrl,
  updateTermsUrl,
} from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import UseTestStore from "./TestStore";
import useUtilityStore from "./UtilityStore";
import { updateFaqUrl, deleteFaqUrl, generalSettingsIndexUrl, toggleStatusCityUrl, detailTermsConditionUrl, allTermsConditionUrl, addTermsConditionUrl, deleteTermsConditionUrl, toggleStatusTermsConditionUrl } from './../Utility/Url';

const { setLoading } = useUtilityStore.getState();

const useSettingsStore = create((set) => ({
  selectedFiler: false,
  setSelectedFiler: (value) => set({ selectedFiler: value }),

  appLanguage: [
    { name: "English", code: "en" },
    { name: "Danish", code: "da" },
  ],

  activeLang: "",
  setActiveLanguage: (value) => set({ activeLang: value }),

  lang_code: "",
  setLang_code: (lang_code) => set({ lang_code }),
  app_lang_code: "en",
  setAppLangCode: (langCode) => set((state) => ({ app_lang_code: langCode })),

  userData: {
    name: "",
    email: "",
    phone: "",
    image_url: "",
  },
  setUserData: (e) =>
    set((state) => (state.userData[e.target.name] = e.target.value)),

  finalUpdateUserData: {},
  setFinalUpdateUserData: (value) => set({ finalUpdateUserData: value }),

  deleteAccountWarn: false,
  setDeleteAccountWarn: (value) => set({ deleteAccountWarn: value }),

  dataWithImage: false,
  setDataWithImage: (value) => set({ dataWithImage: value }),

  privacyEditState: false,
  setPrivacyEditState: (value) => set({ privacyEditState: value }),

  termsEditState: false,
  setTermsEditState: (value) => set({ termsEditState: value }),

  authFee: 0,
  setAuthFee: (value) => set({ authFee: value }),

  shippingFee: 0,
  setShippingFee: (value) => set({ shippingFee: value }),

  privacyData: "",
  setPrivacyData: (value) => set({ privacyData: value }),

  termsData: "",
  setTermsData: (value) => set({ termsData: value }),

  //...........Settings page states...............//

  faqListAll: [],
  setFaqListAll: (value) => set({ faqListAll: value }),

  faqDetail: [],
  setFaqDetails: (value) => set({ faqDetail: value }),

  FaqActiveID: "",
  setFaqActiveID: (value) => set({ FaqActiveID: value }),

  isActive: "",
  setIsActive: (value) => set({ isActive: value }),

  activeComponent: "",
  setActiveComponent: (value) => set({ activeComponent: value }),

  DeleteMessageList: "",
  setDeleteMessageList: (value) => set({ DeleteMessageList: value }),

  deleteMessageDetail: "",
  setDeleteMessageDetail: (value) => set({ deleteMessageDetail: value }),

  DeleteMessageDeactivateID: "",
  setDeleteMessageDeactivateID: (value) => set({ DeleteMessageDeactivateID: value }),

  generalSettingsIndex: "",
  setGeneralSettingsIndex: (value) => set({ generalSettingsIndex: value }),

  cityListAll: [],
  setCiyListAll: (value) => set({ cityListAll: value }),

  deleteCityID: "",
  setDeleteCityID: (value) => set({ deleteCityID: value }),

  cityDetail: [],
  setCityDetail: (value) => set({ cityDetail: value }),

  cityDeactivateID: "",
  setCityDeactivateID: (value) => set({ cityDeactivateID: value }),

  TermsConditionList: [],
  setTermsConditionList: (value) => set({ TermsConditionList: value }),

  TermsConditionDetail: {},
  setTermsConditionDetail: (value) => set({ TermsConditionDetail: value }),

  TermsConditionEditFormData: {
    id: "",
    content: "",
    app_type: '',
    image: ''
  },
  setTermsConditionEditFormData: (name, value) =>
    set((state) => (state.TermsConditionEditFormData[name] = value)),

  updateTermsData: (value) =>
    set((state) => ({
      TermsConditionEditFormData: { ...state.TermsConditionEditFormData.content, content: value, }
    })),

  TermsConditionDeactivateID: "",
  setTermsConditionDeactivateID: (value) => set({ TermsConditionDeactivateID: value }),

  citySearchKey: "",
  setCitySearchKey: (value) => set({ citySearchKey: value }),

  temporaryCityList: [],
  setTemporaryCityList: (value) => set({ temporaryCityList: value }),


  // Modals
  showAddDeleteMessage: false,
  setShowAddDeleteMessage: (value) => set({ showAddDeleteMessage: value }),

  showEditDeleteMessage: false,
  setShowEditDeleteMessage: (value) => set({ showEditDeleteMessage: value }),

  showAddFaqModal: false,
  setShowAddFaqModal: (value) => set({ showAddFaqModal: value }),

  showEditFaqModal: false,
  setShowEditFaqModal: (value) => set({ showEditFaqModal: value }),

  showFaqDeleteModal: false,
  setShowFaqDeleteModal: (value) => set({ showFaqDeleteModal: value }),

  showCityDeleteModal: false,
  setShowCityDeleteModal: (value) => set({ showCityDeleteModal: value }),

  showDeleteMessageModal: false,
  setShowDeleteMessageModal: (value) => set({ showDeleteMessageModal: value }),

  showFaqDeactivateModal: false,
  setShowFaqDeactivateModal: (value) => set({ showFaqDeactivateModal: value }),

  showDeleteMessageDeactivateModal: false,
  setShowDeleteMessageDeactivateModal: (value) => set({ showDeleteMessageDeactivateModal: value }),

  showAddCityModal: false,
  setShowAddCityModal: (value) => set({ showAddCityModal: value }),

  showEditCityModal: false,
  setShowEditCityModal: (value) => set({ showEditCityModal: value }),

  showCityDeactivateModal: false,
  setShowCityDeactivateModal: (value) => set({ showCityDeactivateModal: value }),

  showWriteTermsAndConditionModal: false,
  setShowWriteTermsAndConditionModal: (value) => set({ showWriteTermsAndConditionModal: value }),

  showTermsAndConditionDetailsModal: false,
  setShowTermsAndConditionDetailsModal: (value) => set({ showTermsAndConditionDetailsModal: value }),

  showEditTermsAndConditionModal: false,
  setShowEditTermsAndConditionModal: (value) => set({ showEditTermsAndConditionModal: value }),

  showTermsAndConditionDeactivateModal: false,
  setShowTermsAndConditionDeactivateModal: (value) => set({ showTermsAndConditionDeactivateModal: value }),

  showTermsAndConditionDeleteModal: false,
  setShowTermsAndConditionDeleteModal: (value) => set({ showTermsAndConditionDeleteModal: value }),

  //...........Settings page states end...........//

  selectedOption: "",
  setSelectedOption: (option) =>
    set((state) => (state.selectedOption = option)),

  changePasswordForm: {
    old_password: "",
    password: "",
    password_confirmation: "",
  },
  setChangePasswordForm: (e) =>
    set((state) => (state.changePasswordForm[e.target.name] = e.target.value)),
  resetChangePasswordForm: (e) =>
    set(
      (state) =>
      (state.changePasswordForm = {
        old_password: "",
        password: "",
        password_confirmation: "",
      })
    ),
}));

export default useSettingsStore;


// ====================================================
//          API Fn
// ====================================================

// get all shop list
export const getAllFees = async (paginationUrl = "") => {
  try {
    setLoading(true);

    const res = await axios.get(allFeesUrl);

    console.log("getALlFees res.data:::: ", res.data.data);

    if (res.data.success) {
      useSettingsStore.getState().setAuthFee(res.data.data.auth_fee.value);
      useSettingsStore.getState().setShippingFee(res.data.data.ship_fee.value);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllFees: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// update shipping fee
export const updateShippingFee = async (urlToLoad = "url...") => {
  try {
    setLoading(true);

    const res = await axios.post(updateFeesUrl, {
      type: "ship_fee",
      value: useSettingsStore.getState().shippingFee,
    });

    console.log("updateShippingFee::::", res.data);

    if (res.data.success) {
      await getAllFees();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateShippingFee: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// update Auth fee
export const updateAuthFee = async (urlToLoad = "url...") => {
  try {
    setLoading(true);

    const res = await axios.post(updateFeesUrl, {
      type: "auth_fee",
      value: useSettingsStore.getState().authFee,
    });

    console.log("updateAuthFee::::", res.data);

    if (res.data.success) {
      await getAllFees();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateAuthFee: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// update Auth fee
export const updateRecommendedCategory = async (catID) => {
  try {
    setLoading(true);

    const res = await axios.post(updateRecommendedCategoryUrl, {
      category_id: catID,
    });

    console.log("updateRecommendedCategory::::", res.data);

    if (res.data.success) {
      // await getAllCategory();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateRecommendedCategory: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// get privacy
export const getPrivacyData = async () => {
  try {
    setLoading(true);

    const res = await axios.get(getPrivacyUrl);

    console.log("getPrivacyData res.data:::: ", res.data);

    if (res.data.success) {
      useSettingsStore.getState().setPrivacyData(res.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getPrivacyData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// update privacy
export const updatePrivacyData = async () => {
  try {
    setLoading(true);

    const res = await axios.post(updatePrivacyUrl, {
      type: "privacy",
      description: useSettingsStore.getState().privacyData,
    });

    console.log("updatePrivacyData res.data:::: ", res.data);

    if (res.data.success) {
      await getPrivacyData();
      useSettingsStore.getState().setTermsData(res.data.data);
      useSettingsStore.getState().setPrivacyEditState(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("updatePrivacyData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// get Terms
export const getTermsData = async () => {
  try {
    setLoading(true);

    const res = await axios.get(getTermsUrl);

    console.log("getTermsData res.data:::: ", res.data);

    if (res.data.success) {
      useSettingsStore.getState().setTermsData(res.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getTermsData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// update Terms
// export const updateTermsData = async () => {
//   try {
//     setLoading(true);

//     const res = await axios.post(updateTermsUrl, {
//       type: "tc",
//       description: useSettingsStore.getState().termsData,
//     });

//     console.log("updateTermsData res.data:::: ", res.data);

//     if (res.data.success) {
//       await getTermsData();
//       useSettingsStore.getState().setTermsData(res.data.data);
//       useSettingsStore.getState().setTermsEditState(false);
//     } else {
//       Toastr({ message: res.data.message, type: "error" });
//     }
//     setLoading(false);
//   } catch (error) {
//     console.log("updateTermsData: ", error);
//     Toastr({ message: "An error occurred!", type: "error" });
//     setLoading(false);
//   }
// };

// get profile data
export const getProfileData = async () => {
  try {
    setLoading(true);

    const res = await axios.get(getProfileUrl);

    console.log("getProfileData res.data:::: ", res.data.data);

    if (res.data.success) {
      // useSettingsStore.getState().setTermsData(res.data.data)
      useUtilityStore.getState().setLoggedUser(res.data.data);
      localStorage.setItem("user", JSON.stringify(res.data.data));
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getProfileData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// update Profile
export const updateProfileData = async () => {
  try {
    setLoading(true);
    const { userData, dataWithImage } = useSettingsStore.getState();
    let res = {};
    if (dataWithImage) {
      res = await axios.post(updateProfileUrl, userData);
    } else {
      res = await axios.post(updateProfileUrl, {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
      });
    }

    console.log("updateProfileData res.data:::: ", res.data);

    if (res.data.success) {
      await getProfileData();
      // useSettingsStore.getState().setProfileData(res.data.data)
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateProfileData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//  change password
export const changePassword = async () => {
  try {
    const { changePasswordForm } = useSettingsStore.getState();
    if (
      changePasswordForm.password !== changePasswordForm.password_confirmation
    ) {
      Toastr({
        message: "Password and confirm password do not match!",
        type: "error",
      });
      return false;
    }

    setLoading(true);

    const res = await axios.post(changePasswordUrl, changePasswordForm);

    console.log("changePassword res.data:::: ", res.data.data);

    if (res.data.success) {
      UseTestStore.getState().setShowEditPassModal(false);
      useSettingsStore.getState().resetChangePasswordForm();
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("changePassword: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

//  toggle AllNotification
export const toggleAllNotification = async (type = "") => {
  try {
    setLoading(true);

    const res = await axios.post(toggleAllNotificationUrl, {
      type: type,
    });

    console.log("toggleAllNotification res.data:::: ", res.data.data);

    if (res.data.success) {
      await getProfileData();
      setLoading(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("toggleAllNotification: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

//  change Language
export const changeLanguage = async (code = "") => {
  try {
    setLoading(true);

    const res = await axios.post(changeLanguageUrl, {
      code: code,
    });

    console.log("changeLanguage res.data:::: ", res.data.data);

    if (res.data.success) {
      localStorage.setItem("lang_code", code);
      await getProfileData();
      setLoading(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("changeLanguage: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// delete Account
export const deleteAccount = async () => {
  try {
    setLoading(true);

    const res = await axios.post(deleteAccountUrl);

    console.log("deleteAccount res.data:::: ", res.data.data);

    if (res.data.success) {
      setLoading(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("deleteAccount: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// ==============================
//              FAQ
// ==============================

//get list FAQ
export const getFaList = async () => {
  const { setFaqListAll } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(allFaqListUrl);
    console.log("getFaList res.data:::: ", res);

    if (res?.data?.success) {
      setFaqListAll(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("getFaList: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//Create FAQ
export const createFaq = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(addFaqUrl, body);
    console.log("createFaq res.data:::: ", res.data);

    if (res.data.success) {
      Toastr({ message: res.data.message, type: "success" });
      await getFaList();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log(error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//edit FAQ
export const editFaq = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(updateFaqUrl, body);
    console.log("editFaq res.data:::: ", res.data);

    if (res.data.success) {
      Toastr({ message: res.data.message, type: "success" });
      await getFaList();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log(error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


//FAQ DETAIL
export const getFaqDetail = async (id) => {
  const { setFaqDetails } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(faqDetailUrl, { params: { id: id } });
    console.log("getFaqDetail res.data:::: ", res.data);

    if (res?.data?.success) {
      setFaqDetails(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("getFaqDetail: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//delete faq
export const deleteFaq = async (id) => {
  try {
    setLoading(true);
    const res = await axios.post(deleteFaqUrl, { id: id });
    console.log("deleteFaq res.data:::: ", res.data);

    if (res?.data?.success) {
      Toastr({ message: res.data.message, type: "success" });
      await getFaList();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("deleteFaq: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

export const faqToggleStatus = async (id) => {
  try {
    setLoading(true);
    const res = await axios.post(toggleStatusFaqUrl, { id: id });
    console.log("faqToggleStatus:::: ", res);

    if (res.data.success) {
      await getFaList();
      //Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("faqToggleStatus: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// ==============================
//              FAQ
// ==============================

// ==============================
//       Terms & Conditions
// ==============================

//get list FAQ
export const getTermsConditionsList = async () => {
  // const { setFaqListAll } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(allFaqListUrl);
    console.log("getTermsConditionsList res.data:::: ", res);

    if (res?.data?.success) {
      // setFaqListAll(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("getFaList: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};
// ==============================
//       Terms & Conditions
// ==============================




// ==============================
//       Delete Message
// ==============================

//deleteMessage List
export const getDeleteMessageIndex = async () => {
  const { setDeleteMessageList } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(deleteMessageUrl);
    console.log("getDeleteMessageIndex res.data:::: ", res);

    if (res?.data?.success) {
      setDeleteMessageList(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("getDeleteMessageIndex: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//create delete message
export const createDeleteMessage = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(createDeleteMessageUrl, body);
    console.log("createDeleteMessage res.data:::: ", res.data);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getDeleteMessageIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("createDeleteMessage res.data::::", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//edit DeleteMessage
export const editDeleteMessage = async (body) => {

  try {
    setLoading(true);
    const res = await axios.post(editDeleteMessageUrl, body);
    console.log("editDeleteMessage res.data:::: ", res.data);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getDeleteMessageIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log(error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


//delete delete message
// export const deleteMessage = async (id) => {

//   try {
//     setLoading(true);
//     const res = await axios.post(deleteFaqUrl, { "id": id });
//     console.log("deleteFaq res.data:::: ", res.data);

//     if (res?.data?.success) {
//       Toastr({ message: res.data.message, type: "success" });
//       await getFaList()
//       setLoading(false);
//       return true;
//     } else {
//       Toastr({ message: res?.data?.message, type: "error" });
//       setLoading(false);
//       return false;
//     }

//   } catch (error) {
//     console.log("deleteFaq: ", error);
//     Toastr({ message: "An error occurred!", type: "error" });
//     setLoading(false);
//     return false;
//   }
// };


export const deleteMessageToggleStatus = async (id) => {
  const { DeleteMessageDeactivateID } = useSettingsStore.getState();

  try {
    setLoading(true);
    const res = await axios.post(toggleStatusDeleteMessageUrl, { "id": id });
    console.log("deleteMessageToggleStatus:::: ", res);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getDeleteMessageIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("deleteMessageToggleStatus: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// ==============================
//       Delete Message
// ==============================




// ==============================
//       generalSettings
// ==============================

//Get generalSettings
export const getGeneralSettingsIndex = async () => {
  const { setGeneralSettingsIndex } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(generalSettingsIndexUrl);
    console.log("generalSettingsIndex res.data:::: ", res.data);

    if (res?.data?.success) {
      // Toastr({ message: res.data.message, type: "success" });
      setGeneralSettingsIndex(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("generalSettingsIndex: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


//EDIT getGeneralSettings
export const editGeneralSettingsIndex = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(editGeneralSettingsIndexUrl, body);
    console.log("generalSettingsIndex res.data:::: ", res);

    if (res?.data?.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getGeneralSettingsIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("generalSettingsIndex: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};
// ==============================
//       generalSettings
// ==============================



// ==============================
//            City
// ==============================

//Get City List
export const getCityIndex = async () => {
  const { setCiyListAll,setTemporaryCityList } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(CityIndexUrl);
    console.log("getCity res.data:::: ", res.data);

    if (res?.data?.success) {
      setCiyListAll(res?.data?.data);
      setTemporaryCityList(res?.data?.data)
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("getCity: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


//create City
export const createCity = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(addCityUrl, body);
    console.log("createCity res.data:::: ", res.data);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getCityIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("createCity res.data::::", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};




//delete city
export const deleteCity = async (id) => {

  try {
    setLoading(true);
    const res = await axios.post(deleteCityUrl, { "id": id });
    console.log("deleteCity res.data:::: ", res.data);

    if (res?.data?.success) {
      Toastr({ message: res.data.message, type: "success" });
      await getCityIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("deleteCity: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//edit city
export const editCity = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(editCityUrl, body);
    console.log("editCity res.data:::: ", res);

    if (res?.data?.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getCityIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("editCity: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//City Toggle Status
export const cityToggleStatus = async (id) => {
  try {
    setLoading(true);
    const res = await axios.post(toggleStatusCityUrl, { "id": id });
    console.log("cityToggleStatus:::: ", res);

    if (res?.data?.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getCityIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("cityToggleStatus: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// Search city
export const searchCityList = async (event) => {
  const { cityListAll, setCiyListAll, temporaryCityList } = useSettingsStore.getState()

  if (event === '') return getCityIndex()

  const result = await temporaryCityList.filter((item) => {
    if (item) {
      let name = "";
      name = item.name ?? "";

      if (name.toLowerCase().includes(event.toLowerCase())) {
        return item;
      } else {
        return null;
      }
    } else {
      return null;
    }
  });
  console.log("search result: ", result);
  await setCiyListAll(result);
};

// ==============================
//            City
// ==============================



// ==============================
//        Change Password
// ==============================


export const settingsChangePassword = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(settingsChangePasswordUrl, body);
    console.log("settingsChangePassword res.data:::: ", res);

    if (res?.data?.success) {
      Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("settingsChangePassword: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// ==============================
//        Change Password
// ==============================







// ==============================
//       Terms & Conditions
// ==============================
//Terms & Condition detail

export const getTermsConditionsIndex = async () => {
  const { setTermsConditionList } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(allTermsConditionUrl);
    console.log("getTermsConditionsIndex res.data:::: ", res.data);

    if (res?.data?.success) {
      setTermsConditionList(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("getTermsConditionsIndex: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


//Create Terms & Condition
export const createTermsCondition = async (body) => {
  try {
    setLoading(true);
    const res = await axios.post(addTermsConditionUrl, body);
    console.log("createTermsCondition res.data:::: ", res.data);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getTermsConditionsIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log(error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};



//Terms & Condition detail
export const getTermsConditionDetail = async (id) => {
  const { setTermsConditionDetail } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(detailTermsConditionUrl, { params: { "id": id } });
    console.log("getTermsConditionDetail res.data:::: ", res.data);

    if (res?.data?.success) {
      setTermsConditionDetail(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("getTermsConditionDetail: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};



export const editTermsCondition = async () => {
  const { setTermsConditionDetail, TermsConditionEditFormData } = useSettingsStore.getState();
  try {
    setLoading(true);
    const res = await axios.post(editTermsConditionUrl, TermsConditionEditFormData);
    console.log("editTermsCondition res.data:::: ", res.data.data);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getTermsConditionsIndex()
      await setTermsConditionDetail(res?.data?.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log(error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};




//delete TermsCondition
export const deleteTermsCondition = async (id) => {

  try {
    setLoading(true);
    const res = await axios.post(deleteTermsConditionUrl, { "id": id });
    console.log("deleteTermsCondition res.data:::: ", res.data);

    if (res?.data?.success) {
      Toastr({ message: res.data.message, type: "success" });
      await getTermsConditionsIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("deleteTermsCondition: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


//Terms Condition ToggleStatus
export const TermsConditionToggleStatus = async (id) => {
  try {
    setLoading(true);
    const res = await axios.post(toggleStatusTermsConditionUrl, { "id": id });
    console.log("TermsConditionToggleStatus:::: ", res);

    if (res?.data?.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getTermsConditionDetail(id)
      await getTermsConditionsIndex()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("TermsConditionToggleStatus: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};
// ==============================
//       Terms & Conditions
// ==============================