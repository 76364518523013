import React from 'react'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';
import useSettingsStore from '../../../App/Stores/SettingsStore';
import { TermsConditionToggleStatus } from './../../../App/Stores/SettingsStore';

function TermsAndConditionDeactivateModal() {

    const { setTermsConditionDeactivateID,
        showTermsAndConditionDeactivateModal,
        setShowTermsAndConditionDeactivateModal,
        TermsConditionDeactivateID
    } = useSettingsStore();

    return (
        <>
            <CommonModal
                showModal={showTermsAndConditionDeactivateModal}
                setShowModal={setShowTermsAndConditionDeactivateModal}
                modalTitle="Confirmation"
                mainContent={
                    <>
                        <div className="flex justify-center pt-5">Are you sure you want to deactivate this Terms Conditions?</div>
                        <div className="flex justify-center pt-5">
                            <CommonButton
                                colorType='danger'
                                btnLabel='Deactivate'
                                onClick={async () => {
                                    let deactivateSuccess = await TermsConditionToggleStatus(TermsConditionDeactivateID)
                                    if (deactivateSuccess) {

                                        setTimeout(async () => {
                                            await setTermsConditionDeactivateID("")
                                            await setShowTermsAndConditionDeactivateModal(false);
                                        }, 100);
                                    }
                                }}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default TermsAndConditionDeactivateModal