import React from 'react'
import useInstructorStore, { getInstructorToggle } from '../../../App/Stores/InstructorStore'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal'

function DeactivateInstructor() {
    const { showDeactivateModal, setShowDeactivateModal } = useInstructorStore();
    return (
        <>
            <CommonModal
                showModal={showDeactivateModal}
                setShowModal={setShowDeactivateModal}
                modalTitle="conform"
                mainContent={
                    <>
                        <div className="flex justify-center pt-5">Are you sure you want to deactivate this instructor?</div>
                        <div className="flex justify-center pt-5">
                            <CommonButton
                                colorType='danger'
                                btnLabel='Deactivate'
                                onClick={() => {
                                    let deactivateSuccess = getInstructorToggle();
                                    if(deactivateSuccess) setShowDeactivateModal(false);
                                }}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default DeactivateInstructor