import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useSettingsStore from "../../App/Stores/SettingsStore";
import { RIGHT_ARROW } from "../../App/Utility/source";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonTitle from "../../Components/Title/CommonTitle";
import Option from "./Components/Option";

const Settings = (props) => {

  const { isActive, activeComponent,setIsActive } = useSettingsStore();


  const [forceCloseSection, setForceCloseSection] = useState(false);

  const { setBarTitle } = useLayoutStore();

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    PageTitle(t("Settings"));
    setBarTitle("Settings");

  
  }, [setBarTitle]);


  return (
    <div className="mx-s12 md:mx-s32">
      <div>
        <CommonTitle title="Settings" />
      </div>
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <div className="col-span-12 lg:col-span-4">
          <div className="bg-white p-s10 md:p-5 shadow rounded-xl flex-col">
            <Option
              onClick={() => {
                navigate('/settings')
                
              }}
              label={t("FAQ Management")}
              source={RIGHT_ARROW}
              className={"mb-[5px] mt-[5px]"}
              isActive={location.pathname === '/settings' && true }
            />
            <Option
              onClick={() => {
                navigate('/settings/how-to')
              }}
              label={t("How To")}
              source={RIGHT_ARROW}
              className={"mb-[5px] mt-[5px]"}
              isActive={location.pathname === '/settings/how-to' && true }
            />
            <Option
              onClick={() => {
                navigate('/settings/terms-condition')
              }}
              label={t("GDPR & Terms")}
              source={RIGHT_ARROW}
              className={"mb-[5px] mt-[5px]"}
              isActive={location.pathname === '/settings/terms-condition' || 
              location.pathname === '/settings/GdprTerms/details' ||location.pathname === '/settings/editGdprTerms'
              && true }
            />
            <Option
              onClick={() => {
                navigate('/settings/change-password')
              }}
              label={t("Change Password")}
              source={RIGHT_ARROW}
              className={"mb-[5px] mt-[5px]"}
              isActive={location.pathname === '/settings/change-password' && true }
            />
            <Option
              onClick={() => {
                navigate('/settings/moms')
              }}
              label={t("MOMS")}
              source={RIGHT_ARROW}
              className={"mb-[5px] mt-[5px]"}
              isActive={location.pathname === '/settings/moms' && true }
            />
            <Option
              onClick={() => {
                navigate('/settings/city')
              }}
              label={t("City Management")}
              source={RIGHT_ARROW}
              className={"mb-[5px] mt-[5px]"}
              isActive={location.pathname === '/settings/city' && true }
            />
            <Option
              onClick={() => {
                navigate('/settings/delete-message')
                setIsActive("delete_message");
              }}
              label={t("Delete Message")}
              source={RIGHT_ARROW}
              className={"mb-[5px] mt-[5px]"}
              isActive={location.pathname === '/settings/delete-message' && true }
            />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <div className="bg-white p-s10 md:p-5 shadow rounded-xl">
            {/* {activeComponent} */}
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
