import React from "react";
import { useState } from "react";
import useSettingsStore, { deleteMessageToggleStatus } from "../../../App/Stores/SettingsStore";
import { iSettingsEditIcon } from "../../../App/Utility/source";
import CommonAccordion from "../../../Components/Accordion/CommonAccordion";
import CommonEmptyStatus from "../../../Components/CommonEmptyStatus/CommonEmptyStatus";
import CommonInput from "../../../Components/Input/CommonInput";
import GreenSwitch from "../../../Components/Switch/GreenSwitch";

const ListDeleteMessage = ({ data }) => {

  const { setDeleteMessageDeactivateID, setShowDeleteMessageDeactivateModal, setShowEditDeleteMessage, setDeleteMessageDetail, DeleteMessageDeactivateID } = useSettingsStore();

  const [editNow, setEditNow] = useState("");

  const toggleStatus = async () => {
    setDeleteMessageDeactivateID(data?.id)
    if (data?.status === 0) {
        await deleteMessageToggleStatus(data?.id)
    } else if (data?.status === 1) {
        await setShowDeleteMessageDeactivateModal(true)
    }
}
  //console.log("data", data)
  //console.log("enabled",enabled)

  return (
    <div>
      <div>
        <CommonAccordion
          header={
            <div className="card flex flex-row justify-between align-middle w-full">

              <span>
                <span onClick={() => { }} className="text-fs16 font-semibold pb-2 capitalize">
                {data?.app_type ? data?.app_type : <CommonEmptyStatus/>} Delete Message
                </span>
                {/* <span className='uppercase ml-s10 text-fs8 border-cBrandColor border rounded-full px-s3  text-cBrandColor font-normal'>{data?.app_type ? data?.app_type : 'NA'}</span> */}
              </span>
            </div>
          }
          rightIconWidth="w-[115px]"
          rightEndIcon={
            <div className="flex items-center justify-end">
              <img
                src={iSettingsEditIcon}
                alt=""
                onClick={async (e) => {
                  e.preventDefault();
                  await setDeleteMessageDetail(data)
                  setShowEditDeleteMessage(true);

                }}
                className="w-s20 h-s20 object-cover ml-s5 mr-s10"
              />

              <div>
                <GreenSwitch 
                onClick={toggleStatus}
                enabled={data?.status}
                width= 'w-s30'
                height='h-s16'
                toggleButtonWidth="w-s12"
                toggleButtonHeight="h-s10"
                />
                </div>

            </div>
          }
          body={
            <div className="max-h-s250  overflow-y-auto text-fs14 text-cTextBody">
              {editNow === "edit" ? (
                <div>

                  <CommonInput
                    textarea={true}
                    placeholder="Please Enter Delete Message."
                  />
                </div>
              ) : (
                <div className="max-h-s250 overflow-y-auto text-fs14 bg-cBrandColor2 text-cTextBody">
                  <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ListDeleteMessage;
