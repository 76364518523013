import React from 'react';
import useCategoryStore from '../../../App/Stores/CategoryStore';
import { iEdiItIcon } from '../../../App/Utility/source';
import Carousel from 'react-grid-carousel'
import InstructorCard from '../../Card/InstructorCard';
import useSchoolStore from '../../../App/Stores/SchoolStore';

const InstructorExpertiseArea = ({
    data,
    edit = true,
    title,
    editModal
    }) => {

    const { setShowExpertiseAreaEditModal } = useCategoryStore();

    // dotColorActive ="#795548" dotColorInactive='#ccc'
    //console.log("data",data)
    return (
        <>
            {data?.length > 0 ?
                <div>
                    <div className='flex justify-between mt-s20 md:mt-0'>
                        <span className='text-fs14 font-fw600 py-s20 text-cBlack'>{title}</span>
                        {edit && <img
                            className="cursor-pointer"
                            onClick={() => {
                                editModal();
                            }}
                            src={iEdiItIcon} alt="">
                        </img>}
                    </div>
                    <div className=''>
                        <Carousel  mobileBreakpoint={767} cols={5} rows={1} gap={10} loop>
                            {
                                data?.map((item, index) => (
                                    <Carousel.Item>
                                        <InstructorCard data={item} index={index} key={index} />
                                    </Carousel.Item>
                                ))
                            }
                        </Carousel>
                    </div>
                </div> : ''}
        </>
    );
};

export default InstructorExpertiseArea;