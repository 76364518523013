import React, { useEffect, useState } from 'react';
import useSchoolStore, { editSchoolCategoriesLessonFn } from '../../../../App/Stores/SchoolStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import SelectInput from '../../../../Components/Input/SelectInput';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { DownOutlined } from '@ant-design/icons';
import { TimePicker } from 'antd';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { MinToHour, valueCheck } from '../../../../Utility/UtilityFunctions';
import { Toastr, toHoursAndMinutes } from '../../../../App/Utility/UtilityFunctions';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";

const EditCategoryLessonDetails = () => {

    const { schoolCategoryLessonDetails, showEditCategoryLessonDetailsModal, setShowEditCategoryLessonDetailsModal } = useSchoolStore();

    const { school_id, school_category_id } = useParams();

    const [updateForm, setUpdateForm] = useState({

        id: "",
        name: "",
        type: "",
        duration: "00:00",
        is_mandatory: false,
        price: "",
        description: "",
        requirements: ""
    });
    //   setAddForm({ ...addForm, name: e.target.value });

    const dataArray = [
        {
            title: "Driving",
            value: "driving",
            selected: updateForm?.type === "driving" ? true : false
        },
        {
            title: "Classroom",
            value: "classroom",
            selected: updateForm?.type === "classroom" ? true : false
        },
        {
            title: "External",
            value: "external",
            selected: updateForm?.type === "external" ? true : false
        },
    ]

    //console.log("SchoolCategoryLessonDetails", schoolCategoryLessonDetails)


    const submitUpdateSchoolCategoryLesson = async (e) => {
        console.log("updateForm.duration", updateForm.duration);
        e.preventDefault()
        if (updateForm.school_category_id === ""
            && updateForm.school_id === "" &&
            updateForm.name === "" && updateForm.type === "" &&

            // addForm.is_mandatory === ""
            // &&
            updateForm.price === ""
        ) {
            Toastr({ message: "Data is required", type: "error" });
        }
        else {
            if (updateForm.duration == "00:00") {
                Toastr({ message: "Time is required", type: "error" });
            } else {
                const value = editSchoolCategoriesLessonFn(updateForm, school_category_id)
                if (value) {
                    setUpdateForm({
                        school_category_id: "",
                        school_id: "",
                        name: "",
                        type: "",
                        duration: "00:00",
                        is_mandatory: false,
                        price: "",
                        description: "",
                        requirement: ""
                    })
                    setTimeout(() => {
                        setShowEditCategoryLessonDetailsModal(false)
                    }, 1000);
                }
            }
        }
    }

    useEffect(() => {

        setUpdateForm({
            id: schoolCategoryLessonDetails?.id,
            name: schoolCategoryLessonDetails?.name ? schoolCategoryLessonDetails?.name : schoolCategoryLessonDetails?.lesson_name,
            type: schoolCategoryLessonDetails?.type ? schoolCategoryLessonDetails?.type : schoolCategoryLessonDetails?.lesson_type,
            duration: schoolCategoryLessonDetails?.duration ? 
                 MinToHour(schoolCategoryLessonDetails?.duration)
                : MinToHour(schoolCategoryLessonDetails?.duration_time),
            is_mandatory: schoolCategoryLessonDetails?.is_mandatory,
            price: schoolCategoryLessonDetails?.price,
            description: schoolCategoryLessonDetails?.description ? valueCheck(schoolCategoryLessonDetails?.description) : valueCheck(schoolCategoryLessonDetails?.lesson_description),
            requirements: schoolCategoryLessonDetails?.requirements ? valueCheck(schoolCategoryLessonDetails?.requirements) : valueCheck(schoolCategoryLessonDetails?.lesson_requirements)
        })
    }, [schoolCategoryLessonDetails])


    return (
        <div>
            <CommonModal
                showModal={showEditCategoryLessonDetailsModal}
                setShowModal={setShowEditCategoryLessonDetailsModal}
                modalTitle="Edit Lesson Details"
                mainContent={
                    <>
                        <div className='mt-s20 '>
                            <form onSubmit={submitUpdateSchoolCategoryLesson}>
                                {/* <div className="my-s20">
                                    <CommonInput
                                        required={true}
                                        type="text"
                                        label="Category"
                                        placeholder="Category"
                                        value={updateForm?.title}
                                        onChange={(e) => setUpdateForm({ ...updateForm, title: e.target.value })}
                                    />
                                </div> */}

                                <div className="my-s20">
                                    <SelectInput
                                        required={true}
                                        type="text"
                                        label="Select Lesson Type"
                                        placeholder=" Lesson Type"
                                        dataArray={dataArray}
                                        selectOptionOnChange={(e) => setUpdateForm({ ...updateForm, type: e })}
                                    />
                                </div>

                                <div className="my-s20">
                                    <CommonInput
                                        required={true}
                                        type="text"
                                        label="Lesson Name"
                                        placeholder="Lesson Name"
                                        value={updateForm?.name}
                                        onChange={(e) => setUpdateForm({ ...updateForm, name: e.target.value })}
                                    />
                                </div>


                                <div className="my-s20">
                                    <div className={`text-cHighlighted text-fs14 font-fw600 mb-s15 w-full req-field`}>Duration</div>
                                    <TimePicker
                                        style={{ width: '100%', backgroundColor: "#F4F4F4" }}
                                        size='large'
                                        format="HH:mm"
                                        showNow={false}
                                        value={moment(updateForm?.duration, "HH:mm")}
                                        suffixIcon={<DownOutlined />}
                                        onSelect={(value) => {
                                            const timeString = moment(value).format("HH:mm");
                                            setUpdateForm({ ...updateForm, duration: timeString })
                                            // console.log(timeString);
                                        }} />
                                </div>

                                <div className="my-s20">
                                    <CommonInput
                                        // required={true}
                                        type="text"
                                        label="Description"
                                        placeholder="Description"
                                        textarea={true}
                                        rows={4}
                                        value={updateForm?.description}
                                        onChange={(e) => setUpdateForm({ ...updateForm, description: e.target.value })}
                                    />
                                </div>

                                <div className="my-s20">
                                    <CommonInput
                                        // required={true}
                                        type="text"
                                        label="Requirement"
                                        placeholder="Requirement"
                                        textarea={true}
                                        rows={4}
                                        value={updateForm?.requirements}
                                        onChange={(e) => setUpdateForm({ ...updateForm, requirements: e.target.value })}
                                    />
                                </div>

                                <FormControl className="w-full">
                                    {/* <FormLabel id="demo-row-radio-buttons-group-label"> */}
                                    <h1 className="w-full text-cHighlighted text-fs14 font-fw600 mb-s5">
                                        Is lesson mandatory for package? <span className="text-cWarning">*</span>
                                    </h1>
                                    {/* </FormLabel> */}
                                    <RadioGroup
                                        className="w-full"
                                        required={true}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <div className="flex w-full">
                                            <div className="flex justify-center  ">
                                                <FormControlLabel
                                                    value="yes"
                                                    control={<Radio required={true} />}
                                                    label="Yes"
                                                    onChange={(e) =>
                                                        setUpdateForm({ ...updateForm, is_mandatory: true })
                                                    }
                                                    checked={
                                                        updateForm?.is_mandatory === "true" || updateForm?.is_mandatory === true ? true : false
                                                    }
                                                />
                                            </div>
                                            <div className="flex justify-center ">
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio required={true} />}
                                                    label="No"
                                                    onChange={(e) =>
                                                        setUpdateForm({ ...updateForm, is_mandatory: false })
                                                    }
                                                    checked={
                                                        updateForm?.is_mandatory === "false" || updateForm?.is_mandatory === false ? true : false
                                                    }
                                                />
                                            </div>

                                        </div>
                                    </RadioGroup>
                                </FormControl>


                                <div className='flex justify-between mt-s20'>
                                    <CommonButton colorType='warning' btnLabel="Delete" roundedFull="true" />
                                    <CommonButton onClick={() => {
                                        // if (updateForm.school_category_id === ""
                                        //     && updateForm.school_id === "" &&
                                        //     updateForm.name === "" && updateForm.type === "" &&
                                        //     updateForm.duration == "00:00" &&
                                        //     // addForm.is_mandatory === ""
                                        //     // &&
                                        //     updateForm.price === ""
                                        // ) {
                                        //     Toastr({ message: "Data is required", type: "error" });
                                        // }
                                        // else {
                                        //     const value = editSchoolCategoriesLessonFn(updateForm, school_category_id)
                                        //     if (value) {
                                        //         setUpdateForm({
                                        //             school_category_id: "",
                                        //             school_id: "",
                                        //             name: "",
                                        //             type: "",
                                        //             duration: "00:00",
                                        //             is_mandatory: false,
                                        //             price: "",
                                        //             description: "",
                                        //             requirement: ""
                                        //         })
                                        //         setTimeout(() => {
                                        //             setShowEditCategoryLessonDetailsModal(false)
                                        //         }, 1000);
                                        //     }
                                        // }
                                    }} btnLabel="Save" roundedFull="true" type='submit' />
                                </div>
                            </form>
                        </div>

                    </>
                }

            />
        </div>
    );
};

export default EditCategoryLessonDetails;