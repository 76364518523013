import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSchoolStore, { schoolInstructorsFindShowFn, } from '../../../App/Stores/SchoolStore';
import { dateDiffCalendar, roughLicenseDurationFormatter } from '../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../Components/Button/CommonButton';
import InstructorExpertiseArea from '../../../Components/CommonDetailPage/CommonInstructor/InstructorExpertiseArea';
import LessonsCard from '../../../Components/CommonDetailPage/LessonsCard';
import NoLicense from '../../../Components/CommonDetailPage/NoLicense';
import CommonEmptyStatus from '../../../Components/CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../../Components/Image/Image';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { formatDate, NACheck } from '../../../Utility/UtilityFunctions';

const SchoolInstructorFindDetails = () => {

    const {
        schoolInstructorsFindDetails,
        setShowSchoolInstructorsAddExpertiseArea,
        setShowSchoolInstructorAppliedCategories,
        setAppliedCategoriesSuccessId
    } = useSchoolStore();

    const { school_id, school_instructor_id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        fetchDataSchoolInstructor()
    }, [school_id, school_instructor_id])


    const fetchDataSchoolInstructor = async () => {
        const body = { "instructor_id": school_instructor_id, "school_id": school_id }
        await schoolInstructorsFindShowFn(body)
    }

    //console.log("schoolInstructorsFindDetails", schoolInstructorsFindDetails?.school_category_info)

    return (
        <div className='mx-s12 md:mx-s32'>
            <CommonTitle title="Instructor School Details" >
                <BackLink linksArray={[
                    { label: "School", linkTo: "/school" },
                    { label: "School Details", linkTo: `/school/details/${school_id}` },
                    { label: "Instructor School", linkTo: `/school/details/${school_id}/instructor` },
                    { label: "Instructor School Details", linkTo: `` }
                ]} />

            </CommonTitle>
            <div className="bg-cBrandColor2 rounded-br20 px-s20 py-s20 ">
                {schoolInstructorsFindDetails ?
                    <div className="flex w-full ">
                        <div className="w-full pr-5">
                            <div className="bg-cBackgroundAndCategory min-h-[350px] rounded-br10 py-s20 px-s20">


                                <div className='flex justify-between'>
                                    <div className="flex w-full">
                                        <div className='mt-s5'>
                                            <Image className='rounded-full w-w88 h-h88 grow-0 mb-s18'
                                                src={schoolInstructorsFindDetails?.instructor_info?.profile_photo} />
                                            <div className='mt-s15'>
                                                {/* <CommonButton
                                                    width='w-[80px]'
                                                    colorType='warning'
                                                    btnLabel="Remove"
                                                    roundedFull="true" /> */}
                                            </div>
                                        </div>

                                        <div className="ml-s15">
                                            <div className="capitalize leading-9 font-fw600 text-fs24 text-cHighlighted mb-s2">
                                                {schoolInstructorsFindDetails?.instructor_info?.name ?
                                                    NACheck(schoolInstructorsFindDetails?.instructor_info?.name)
                                                    : <CommonEmptyStatus
                                                        leading='leading-9'
                                                        size="text-fs24"
                                                        fontWeight="font-fw600"
                                                        textColor='text-cHighlighted'
                                                    />}
                                            </div>
                                            <div className="leading-3 font-fw500 text-fs12 text-cImportantText mb-s6">
                                                Email : {schoolInstructorsFindDetails?.instructor_info?.email ?
                                                    NACheck(schoolInstructorsFindDetails?.instructor_info?.email)
                                                    : <CommonEmptyStatus
                                                        leading='leading-3'
                                                        size="text-fs12"
                                                        fontWeight="font-fw500"
                                                        textColor='text-cImportantText'
                                                    />}
                                            </div>
                                            <div className="leading-3 font-fw500 text-fs12 text-cImportantText pb-s6">
                                                Phone : {schoolInstructorsFindDetails?.instructor_info?.phone_number ?
                                                    NACheck(schoolInstructorsFindDetails?.instructor_info?.phone_number)
                                                    : <CommonEmptyStatus
                                                        leading='leading-3'
                                                        size="text-fs12"
                                                        fontWeight="font-fw500"
                                                        textColor='text-cImportantText'
                                                    />}
                                            </div>

                                            <div className="capitalize leading-3 font-fw500 text-fs12 text-cImportantText">
                                                Address : {schoolInstructorsFindDetails?.instructor_info?.address
                                                    ? NACheck(schoolInstructorsFindDetails?.instructor_info?.address)
                                                    : <CommonEmptyStatus
                                                        leading='leading-3'
                                                        size="text-fs12"
                                                        fontWeight="font-fw500"
                                                        textColor='text-cImportantText'
                                                    />}
                                            </div>

                                            <div className="capitalize leading-3 font-fw500 text-fs12 text-cImportantText pt-s6">
                                                About : {schoolInstructorsFindDetails?.instructor_info?.about
                                                    ? NACheck(schoolInstructorsFindDetails?.instructor_info?.about)
                                                    : <CommonEmptyStatus
                                                        leading='leading-3'
                                                        size="text-fs12"
                                                        fontWeight="font-fw500"
                                                        textColor='text-cImportantText'
                                                    />}
                                            </div>

                                        </div>
                                    </div>

                                    <div className=''>
                                        <CommonButton
                                            onClick={() => { navigate(`/instructor/details/${schoolInstructorsFindDetails?.instructor_info?.id}`) }}
                                            width='w-[120px]'
                                            btnLabel="View Profile"
                                            roundedFull="true" />
                                    </div>
                                </div>


                            </div>
                        </div>

                        {
                            schoolInstructorsFindDetails?.instructor_info?.lisence_duration === 'null' ||
                                schoolInstructorsFindDetails?.instructor_info?.lisence_duration === '' ? <NoLicense height='h-s350' />
                                : <div className='min-w-[400px]'>
                                    <LessonsCard
                                        licenseOf="instructor"
                                        withEditOption={false}
                                        title={schoolInstructorsFindDetails?.instructor_info?.lisence?.lisence?.title}
                                        price={schoolInstructorsFindDetails?.instructor_info?.lisence?.lisence?.price}
                                        packageDuration={roughLicenseDurationFormatter
                                            (schoolInstructorsFindDetails?.instructor_info?.lisence?.lisence?.duration)}
                                        momsValue={parseInt(schoolInstructorsFindDetails?.instructor_info?.lisence?.lisence?.price) * ((25) / 100)}
                                        startDate={formatDate(schoolInstructorsFindDetails?.instructor_info?.lisence?.start_time)}
                                        endDate={formatDate(schoolInstructorsFindDetails?.instructor_info?.lisence?.end_time)}
                                        purchaseDuration={dateDiffCalendar(schoolInstructorsFindDetails?.instructor_info?.lisence?.start_time
                                            ?? new Date(), schoolInstructorsFindDetails?.instructor_info?.lisence?.end_time
                                        )}
                                        licenseDetails={schoolInstructorsFindDetails?.instructor_info?.lisence_details}
                                        status={schoolInstructorsFindDetails?.instructor_info?.lisence?.status}
                                    />
                                </div>
                        }
                    </div>
                    : ''}

                {schoolInstructorsFindDetails?.school_category_info &&
                    <InstructorExpertiseArea
                        title="Applied Area"
                        edit={true}
                        editModal={() => {
                            setShowSchoolInstructorAppliedCategories(true)
                            setAppliedCategoriesSuccessId("")
                        }}
                        data={schoolInstructorsFindDetails?.school_category_info}
                    />
                }

                {schoolInstructorsFindDetails?.instructor_info &&
                    <InstructorExpertiseArea
                        title="Expertise Area"
                        edit={false}
                        // editModal={() => { setShowSchoolInstructorsAddExpertiseArea(true) }}
                        data={schoolInstructorsFindDetails?.instructor_info?.expertise}
                    />
                }

            </div>

        </div>
    );
};

export default SchoolInstructorFindDetails;