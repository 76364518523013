import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import useSettingsStore, { editFaq } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import SelectInput from "../../../Components/Input/SelectInput";
import CommonModal from "../../../Components/Modal/CommonModal";

const EditFaqModal = () => {

  const { showEditFaqModal, setShowEditFaqModal, setShowFaqDeleteModal, faqDetail } = useSettingsStore();

  const [addForm, setAddForm] = useState({
    id:"",
    title: "",
    description: "",
    app_type: ''
  });

  const formSubmit = async (e) => {
    e.preventDefault();

     if (addForm?.title && addForm?.description && addForm?.app_type) {
      let addSuccess = await editFaq(addForm)
      if (addSuccess) {
        await setAddForm({
          id:'',
          title: "",
          description: "",
          app_type: "",
        });
        await setShowEditFaqModal(false)
      }
    }
  };


  const durationArray = [
    {
      title: 'student',
      value: 'student',
      selected: addForm?.app_type === 'student' ? true : false
    },

    {
      title: 'instructor',
      value: 'instructor',
      selected: addForm?.app_type === 'instructor' ? true : false
    },
    {
      title: 'school',
      value: 'school',
      selected: addForm?.app_type === 'school' ? true : false
    },
  ]
  useEffect(() => {
    setAddForm({
      id:faqDetail[0]?.id,
      title: faqDetail[0]?.title,
      description: faqDetail[0]?.description,
      app_type: faqDetail[0]?.app_type
    })
  }, [faqDetail])

  //console.log("faqDetail", faqDetail)

  return (
    <div>
      <CommonModal
        showModal={showEditFaqModal}
        setShowModal={setShowEditFaqModal}
        modalTitle="Edit FAQ"
        mainContent={
          <>
            <form onSubmit={formSubmit}>
              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Question"
                  className3="cHighlightedTexts"
                  placeholder="Question"
                  value={addForm?.title}
                  onChange={(e) => { setAddForm({ ...addForm, title: e.target.value }) }}
                />
              </div>

              <div className="">
                <SelectInput
                  required={true}
                  label="Choose User"
                  placeholder="Choose user"
                  dataArray={durationArray}
                  selectOptionOnChange={(e) => setAddForm({ ...addForm, app_type: e })}
                />
              </div>

              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Write Answer"
                  rows="4"
                  textarea="true"
                  placeholder="Write here..."
                  value={addForm?.description}
                  onChange={(e) => { setAddForm({ ...addForm, description: e.target.value }) }}
                />
              </div>

              <div className="flex justify-between">
                <div>
                  <CommonButton
                    colorType="danger"
                    btnLabel="Delete"
                    roundedFull="true"
                    type="button"
                    //width="w-s280"
                    //className1="h-[48px]"
                    onClick={() => {
                      setShowFaqDeleteModal(true);
                    }}
                  />
                </div>
                <div>
                  <CommonButton
                    colorType="primary"
                    btnLabel="Save"
                    roundedFull="true"
                    type="submit"
                    //width="w-s280"
                    //className1="h-[48px]"
                    onClick={() => {
                      //console.log("Clicked");
                    }}
                  />
                </div>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditFaqModal;
