import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useInstructorStore, { getInstructorIndex, searchInstructorList } from '../../../App/Stores/InstructorStore';
import useInvoiceStore, { getSchoolInvoiceIndex } from '../../../App/Stores/InvoiceStore';
import useStudentStore, { getStudentList, searchStudentList } from '../../../App/Stores/StudentStore';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';
import SelectInput from '../../../Components/Input/SelectInput';
import CommonModal from '../../../Components/Modal/CommonModal';
import AsyncSingleCombobox from '../../../Components/Select/AsyncSingleCombobox';
import { Toastr } from '../../../Utility/UtilityFunctions';

function SchoolInvoiceFilter() {
    const {
        showSchoolInvoiceFilterModal,
        setShowSchoolInvoiceFilterModal,
        filterSchoolInvoiceData,
        setFilterSchoolInvoiceData,
        resetFilterSchoolInvoiceData,
        schoolID,
        invoiceSearchKey,
        invoiceFilterInsSel,
        setInvoiceFilterInsSel
    } = useInvoiceStore();

    const { instructorIndex, setInstructorSearchKey, instructorSearchKey } = useInstructorStore();
    const { studentSearch, setStudentSearch, studentListAll } = useStudentStore();

    const [studentDataArray, setStudentDataArray] = useState([]);
    const [instructorDataArray, setInstructorDataArray] = useState([]);

    const [searchValueIns] = useDebounce(instructorSearchKey, 500);
    const [searchValueST] = useDebounce(studentSearch, 500);


    useEffect(() => {
        SmartSearchInstructor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValueIns]);

    const SmartSearchInstructor = async () => {
        // e    instructor search       
        if (searchValueIns) {
            await searchInstructorList("", 500);
        } else {
            await getInstructorIndex();
        }
    }

    useEffect(() => {
        SmartSearchStudent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValueST]);

    const SmartSearchStudent = async () => {
        // l    student search      
        if (searchValueST) {
            console.log('SEARCH STUDENT');
            await searchStudentList();
        } else {
            console.log('GET ALL STUDENT');
            await getStudentList();
        }
    }

    useEffect(() => {
        let t_array = []
        if (instructorIndex?.data?.length) {
            instructorIndex?.data?.map((item) => t_array.push({ label: item?.name, value: item?.id }));
            setInstructorDataArray(t_array);
        }
    }, [instructorIndex?.data]);

    useEffect(() => {
        let t_array = []
        if (studentListAll?.data?.length) {
            studentListAll?.data?.map((item) => t_array.push({ label: item?.name, value: item?.id }));
            setStudentDataArray(t_array);
        }
    }, [studentListAll?.data])

    return (
        <div>
            <CommonModal
                showModal={showSchoolInvoiceFilterModal}
                setShowModal={setShowSchoolInvoiceFilterModal}
                modalTitle="Filter School Invoice"
                mainContent={
                    <>
                        <form onSubmit={(e) => e.preventDefault()}>

                            {/* g:          payment status */}
                            <div className="my-s20">
                                <SelectInput
                                    label="Payment Status"
                                    placeholder="Choose Payment Status"
                                    selectOptionOnChange={(e) => {
                                        if (e === "paid") setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, status: ["paid1", "paid2"] });
                                        else if (e === "missing") setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, status: ["missing1", "missing2"] });
                                        else if (e === '') setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, status: [] });
                                        else setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, status: [e] });
                                    }}
                                    dataArray={[
                                        {
                                            title: "accepted",
                                            value: localStorage.getItem("schoolInvoiceType") === "history" ? "accepted" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "accepted" ? true : false
                                        },
                                        {
                                            title: "requested",
                                            value: localStorage.getItem("schoolInvoiceType") !== "history" ? "requested" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "requested" ? true : false
                                        },
                                        {
                                            title: "cancelled",
                                            value: localStorage.getItem("schoolInvoiceType") === "history" ? "cancelled" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "cancelled" ? true : false
                                        },
                                        {
                                            title: "created",
                                            value: localStorage.getItem("schoolInvoiceType") !== "history" ? "created" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "created" ? true : false
                                        },
                                        {
                                            title: "paid",
                                            value: localStorage.getItem("schoolInvoiceType") !== "history" ? "paid" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "paid1" ? true : false
                                        },
                                        {
                                            title: "missing payment",
                                            value: localStorage.getItem("schoolInvoiceType") !== "history" ? "missing" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "missing1" ? true : false
                                        },

                                        {
                                            title: "rejected",
                                            value: localStorage.getItem("schoolInvoiceType") === "history" ? "rejected" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "rejected" ? true : false
                                        },
                                        {
                                            title: "expired",
                                            value: localStorage.getItem("schoolInvoiceType") === "history" ? "expired" : "",
                                            selected: filterSchoolInvoiceData?.status[0] === "expired" ? true : false
                                        }
                                    ]}
                                />
                            </div>

                            {/* y           instructor based filter */}
                            {localStorage.getItem("schoolInvoiceType") === "school_instructor" ?
                                <div className="my-s20">
                                    <AsyncSingleCombobox
                                        label="filter by instructor"
                                        placeholder={invoiceFilterInsSel === "" ? 'select or search an instructor' : invoiceFilterInsSel}
                                        onSearchItem={(value) => setInstructorSearchKey(value)}
                                        optionArray={instructorDataArray}
                                        onChangeLabel={(label) => {
                                            setInvoiceFilterInsSel(label);
                                            console.log("SELECTED INS-LABEL: ", label);
                                        }}
                                        onChange={(value) => {
                                            console.log("SELECTED INS-ID: ", value);
                                            setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, instructor_id: value });
                                        }}
                                    />
                                </div> : ""}

                            {/* y           student based filter */}
                            {localStorage.getItem("schoolInvoiceType") === "school_student" ?
                                <div className="my-s20">
                                    <AsyncSingleCombobox
                                        label="filter by student"
                                        placeholder='Select or search a student'
                                        onSearchItem={(value) => setStudentSearch(value)}
                                        optionArray={studentDataArray}
                                        onChange={(value) => {
                                            console.log("SELECTED ST-ID: ", value);
                                            setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, student_id: value });
                                        }}
                                    />
                                </div> : ""}

                            {/*b         start date and date */}
                            <div className='flex justify-between items-center w-full'>
                                <div className='p-0 rounded-full'>
                                    <CommonInput
                                        type='date'
                                        value={filterSchoolInvoiceData?.start_date}
                                        startDate={"1901-01-01"}
                                        label="start date"
                                        allowPastDates={true}
                                        onChange={(e) => {
                                            // console.log((e.target.value));
                                            const startDate = new Date(e.target.value);
                                            const endDate = new Date(filterSchoolInvoiceData?.end_date);

                                            // console.log("startDate::::", startDate, "; endDate::::", endDate);

                                            if (startDate > endDate) {
                                                setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, end_date: "", start_date: e.target.value });
                                            } else {
                                                setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, start_date: e.target.value });
                                            }
                                            // setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, start_date: e.target.value });
                                        }}
                                    />
                                </div>

                                <div className='flex items-center'>
                                    <div className='pr-0'>
                                        <CommonInput
                                            // required={filterSchoolInvoiceData?.start_date ? true : false}
                                            type='date'
                                            label="end date"
                                            value={filterSchoolInvoiceData?.end_date}
                                            // disabled={!filterSchoolInvoiceData?.start_date}
                                            startDate={filterSchoolInvoiceData?.start_date ? filterSchoolInvoiceData?.start_date : "1901-01-01"}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setFilterSchoolInvoiceData({ ...filterSchoolInvoiceData, end_date: (e.target.value) });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*r         action buttons  */}
                            <div className='flex justify-between mt-s20'>
                                <CommonButton onClick={() => {
                                    resetFilterSchoolInvoiceData();
                                }}
                                    fullRounded={true}
                                    btnLabel='Clear'
                                    colorType="FilterClearButton" text="fs16" />
                                <CommonButton
                                    onClick={async () => {
                                        console.log(filterSchoolInvoiceData);
                                        // return
                                        // if (filterSchoolInvoiceData?.start_date) {
                                        //     if (!filterSchoolInvoiceData?.end_date) {
                                        //         return;
                                        //     }
                                        // }

                                        localStorage.setItem("schoolInvoicePaginationURL", "");

                                        let filterSuccess = await getSchoolInvoiceIndex(schoolID, localStorage.getItem("schoolInvoiceType") ?? "school_instructor", "", invoiceSearchKey, filterSchoolInvoiceData);
                                        if (filterSuccess) {
                                            Toastr({ message: "Your filter successfully Apply", type: "success" });
                                            setShowSchoolInvoiceFilterModal(false);
                                        }
                                    }}
                                    type="submit"
                                    btnLabel="Apply"
                                    colorType="primary"
                                    roundedFull="true"
                                />
                            </div>

                        </form>

                    </>
                }

            />
        </div>
    );
};

export default SchoolInvoiceFilter