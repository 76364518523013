import React, { useState } from "react";
import useSchoolStore from "../../../App/Stores/SchoolStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";

function SchoolChangePassModal() {

const {showSchoolChangePassModal,setShowSchoolChangePassModal}=useSchoolStore()

  const [focused, setFocused] = useState(false);

  const [cpForm, setCpForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const handleCpChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCpForm({ ...cpForm, [name]: value });
  };

  return (
    <div>
      <CommonModal
        showModal={showSchoolChangePassModal}
        setShowModal={setShowSchoolChangePassModal}
        modalTitle="School Change Password"
        mainContent={
          <>
            <form onSubmit={(e) => e.preventDefault()}>
              <hr className="mt-s16 mb-s8" />
              <CommonInput
                label={("Current Password")}
                name="currentPassword"
                placeholder={("Type Current Password")}
                value={cpForm?.currentPassword}
                onChange={handleCpChange}
                type="password"
                togglePasswordBtn={true}
                required={true}
                onFocus={() => setFocused(true)}
              />
              <div className="py-5">
                <CommonInput
                  label={("New Password")}
                  name="newPassword"
                  placeholder={("Type New Password")}
                  value={cpForm?.newPassword}
                  onChange={handleCpChange}
                  type="password"
                  togglePasswordBtn={true}
                  required={true}
                />
              </div>
              <CommonInput
                label={("Confirm New Password")}
                name="confirmNewPassword"
                placeholder={("Type New Password")}
                value={cpForm?.confirmNewPassword}
                onChange={handleCpChange}
                type="password"
                togglePasswordBtn={true}
                required={true}
              />

              <div className="flex flex-row justify-center pt-5">
                <CommonButton
                  roundedFull="true"
                  type="submit"
                  btnLabel={("Change Password")}
                  onClick={async () => {
                    console.log("working");
                    // const is_success = await submitChangePassword(cpForm);
                    // if (is_success)
                    //     setCpForm({
                    //         currentPassword: "",
                    //         newPassword: "",
                    //         confirmNewPassword: "",
                    //     });
                  }}
                  colorType={
                    cpForm?.currentPassword &&
                    cpForm?.newPassword &&
                    cpForm?.confirmNewPassword &&
                    cpForm?.newPassword === cpForm?.confirmNewPassword
                      ? "primary"
                      : "basic"
                  }
                  isDisabled={
                    cpForm?.currentPassword &&
                    cpForm?.newPassword &&
                    cpForm?.confirmNewPassword &&
                    cpForm?.newPassword === cpForm?.confirmNewPassword
                      ? false
                      : true
                  }
                  width="w-40"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
}

export default SchoolChangePassModal;