import React from 'react';
import CommonInput from '../../../Components/Input/CommonInput';
import useStudentStore from './../../../App/Stores/StudentStore';
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonButton from './../../../Components/Button/CommonButton';

const EditCurriculumLessonModal = () => {

    const { showEditCurriculumLessonModal, setShowEditCurriculumLessonModal } = useStudentStore()

    return (
        <div>
            <CommonModal
                showModal={showEditCurriculumLessonModal}
                setShowModal={setShowEditCurriculumLessonModal}
                modalTitle="Edit Lesson"
                mainContent={
                    <>
                        <from>
                            <div className='mb-s20'>
                                <CommonInput label="Select Category" type="text" placeholder="Select Category" />
                            </div>

                            <div className='mb-s20'>
                                <CommonInput label="Select Lesson Type" type="text" placeholder="Select Lesson Type" />
                            </div>

                            <div className='mb-s20'>
                                <CommonInput label="Type Lesson Name" type="text" placeholder="Type Lesson Name" />
                            </div>

                            <div className='flex justify-between'>
                                <div className='mb-s20'>
                                    <CommonInput label="Select Duration" type="time" className2="w-s300" placeholder="Select Duration" />
                                </div>
                                <div className='mb-s20'> <CommonInput label="Type Amount" type="Number" className2="w-s300" placeholder="Type Amount" /></div>
                            </div>
                            <div className='flex'>
                                <CommonInput type="checkbox"></CommonInput>
                                <span className='text-fs14 font-fw600 text-cBlack ml-s10 pt-s15'>Lesson mandatory for package</span>
                            </div>

                            <div className='flex justify-center mt-s40'>
                                <CommonButton type="submit" btnLabel="Edit" colorType="primary" roundedFull="true" />
                            </div>

                        </from>

                    </>
                }

            />
        </div>
    );
};

export default EditCurriculumLessonModal;