import React, { useEffect } from "react";
import { useState } from "react";

const BannerCardCategoryType = ({ 
  categoryType,
  width="w-[75px]",
  textSize="text-fs10"
}) => {
  const [bgColorCode, setBgColorCode] = useState("bg-white");
  const [textColorCode, setTextColorCode] = useState("text-cWarning");
  const [color, setcolor] = useState("text-cTextColor");

  const categoryTypeCheck = (categoryType) => {
    switch (categoryType) {
      case "student":
        setBgColorCode("bg-[#FBE7FE]");
        setTextColorCode("text-[#C832E0]");
        break;
      case "school":
        setBgColorCode("bg-[#CFF2D9]");
        setTextColorCode("text-[#2DBB55]");
        break;
      case "instructor":
        setBgColorCode("bg-[#EDF1F8]");
        setTextColorCode("text-[#2257AA]");
        break;
      default:
        setBgColorCode("bg-black");
        setTextColorCode("text-white");
        break;
    }
  };

  useEffect(() => {
    categoryTypeCheck(categoryType);
  }, [categoryType]);

  return (
    <div
      // onMouseOver={() => {
      //   setTextColorCode("text-white");
      // }}
      className={`${bgColorCode} px-s10 py-s1 ${width}  rounded-full

    `}
    >
      <p className={` ${textColorCode} text-center font-fw600	${textSize} capitalize`}>
        {categoryType}
      </p>
    </div>
  );
};

export default BannerCardCategoryType;
