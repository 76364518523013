import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import useInstructorStore from '../../App/Stores/InstructorStore';
import useInvoiceStore from '../../App/Stores/InvoiceStore';
import { iClassroomCard, iDrivingCard, iExternalCard, iInvoiceCard, iLicenseHistoryCard, iSchoolCard, iUpcomingLessonCard } from '../../App/Utility/source';
import CommonCard from './../../Components/Card/CommonCard';

const AdditionalInfoInstructor = () => {

  const { instructorAdditionalCounter, instructorDetails } = useInstructorStore();

  const { setInvoiceType, setInvoiceUserType, resetFilterInstructorInvoiceData } = useInvoiceStore();

  const { instructor_id } = useParams();

  return (
    <div>
      <div className="px-1 pb-s20 pt-s10">
        <hr />
      </div>

      <span className='text-fs14 font-fw600 py-s20 text-cBlack'>Additional Information</span>
      <div className="grid grid-cols-1 gap-5 px-0 w-full sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 pt-s20">
        {/* <NavLink to={`/instructor/details/${id}/workplace`}> */}
        <CommonCard cardIcon={iSchoolCard} title={'Work Place'} note={`${instructorAdditionalCounter?.workplace_count ?? 0} Workplace`} />
        {/* </NavLink> */}

        {/* e   invoice    */}
        <NavLink
          // r    reset section data defaults   
          onClick={() => {
            resetFilterInstructorInvoiceData();

            localStorage.setItem("instructorInvoiceType", "school_instructor");
            localStorage.setItem("instructorInvoicePaginationURL", "");
            localStorage.setItem("instructorInvoiceTake", 10);
          }}

          to={instructorAdditionalCounter?.invoice_count > 0 ? `/instructor/details/${instructor_id}/invoice/${instructorDetails?.user_id}` : ""}>
          <CommonCard cardIcon={iInvoiceCard} onClick={() => { setInvoiceType(1); setInvoiceUserType('admin') }} title={'Invoices'} note={`${instructorAdditionalCounter?.invoice_count ?? "0"} Invoices`} />
        </NavLink>

        {/* <NavLink to={`/instructor/details/${id}/upcoming/bookings`}> */}
        <CommonCard cardIcon={iUpcomingLessonCard} title={'Upcoming'} note={`${instructorAdditionalCounter?.upcoming_count ?? "0"} lessons`} />
        {/* </NavLink> */}
        {/* <NavLink to={`/instructor/details/${id}/driving/lessons`}> */}
        <CommonCard cardIcon={iDrivingCard} title={'Driving'} note={`${instructorAdditionalCounter?.driving_count ?? "0"} Pending`} />
        {/* </NavLink> */}

        <CommonCard cardIcon={iClassroomCard} title={'Classroom'} note={`${instructorAdditionalCounter?.available_class_count ?? "0"} Available`} />

        {/* <NavLink to={`/instructor/details/${id}/external/lessons`}> */}
        <CommonCard cardIcon={iExternalCard} title={'External'} note={`${instructorAdditionalCounter?.external_count ?? "0"} Pending`} />
        {/* </NavLink> */}
        {/* <NavLink to="/license/history"> */}
        <CommonCard cardIcon={iLicenseHistoryCard} title={'License History'} note={`${instructorAdditionalCounter?.license_history_count ?? "0"} License`} />
        {/* </NavLink> */}
      </div>
    </div>
  );
};

export default AdditionalInfoInstructor;