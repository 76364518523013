import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useSettingsStore, { editCity } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";

const EditCityModal = () => {

  const {setShowCityDeleteModal,setDeleteCityID, showEditCityModal, setShowEditCityModal, cityDetail } = useSettingsStore();

  const [addForm, setAddForm] = useState({
    id: "",
    name: "",
  });

  const formSubmit = async (e) => {
    e.preventDefault();
    if (addForm?.name) {
      let addSuccess = await editCity(addForm)
      if (addSuccess) {
        setAddForm({ name: "", id: "" });
        await setShowEditCityModal(false)
      }
    }
  };

  useEffect(() => {
    setAddForm({ id: cityDetail?.id, name: cityDetail?.name, })
  }, [cityDetail])

  //console.log("cityDetail",cityDetail)

  return (
    <div>
      <CommonModal
        showModal={showEditCityModal}
        setShowModal={setShowEditCityModal}
        modalTitle="Edit City"
        mainContent={
          <>
            <form onSubmit={formSubmit}>
              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="City"
                  className3="cHighlightedTexts"
                  placeholder="City"
                  value={addForm?.name}
                  onChange={(e) => { setAddForm({ ...addForm, name: e.target.value }) }}
                />
              </div>

              <div className="flex justify-between">
                <CommonButton
                  type="button"
                  btnLabel="city"
                  colorType="warning" 
                  roundedFull="true"
                  onClick={async () => {
                    await setDeleteCityID(cityDetail?.id)
                    setShowCityDeleteModal(true);
                }}
                />

                <CommonButton
                  type="submit"
                  btnLabel="update"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditCityModal;
