import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { getCityIndex, searchCityList } from "../../../App/Stores/SettingsStore";
import { iAddItem } from "../../../App/Utility/source";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonSearchBox from "../../../Components/Input/CommonSearchBox";
import Settings from "../Settings";
import CityList from './CityList';
import { useDebounce } from 'use-debounce';

const CityManagement = () => {
  const { cityListAll, setShowAddCityModal, citySearchKey, setCitySearchKey } = useSettingsStore();

  const { t } = useTranslation();

  useEffect(() => {
    fetchCityData()
  }, [])

  const fetchCityData = async () => {
    await getCityIndex()
  }

  const [citySearchValue] = useDebounce(citySearchKey, 500);

  useEffect(() => {
    citySearchFn()
  }, [citySearchValue])

  const citySearchFn = async () => {
    await searchCityList(citySearchValue)
  }

  return (
    <Settings>
      <div className="text-2xl font-bold mb-5">{t("City Management")}</div>
      <hr className="mt-s16 mb-s8" />
      <div className="flex justify-between my-s10">
        <div className="mb-s10 ">
          <CommonSearchBox
            value={citySearchKey}
            rounded="rounded-full"
            onChange={async (e) => {
              await setCitySearchKey(e.target.value)
            }}
            onSearchClear={() => { setCitySearchKey("") }}
            withClearSearch={true}
          />
        </div>
        <div>
          <CommonButton
            colorType="primary"
            btnLabel="Add City"
            roundedFull="true"
            onClick={() => { setShowAddCityModal(true) }}
            icon={
              <div className="mr-s5">
                <img className="w-s20 h-s15" src={iAddItem} alt="" />
              </div>
            }
          />
        </div>
      </div>
      {
        cityListAll ?
          cityListAll?.map((item, index) => (
            <CityList item={item} />
          ))
          : <span className="my-s20"> No City Found</span>
      }
    </Settings>
  );
};

export default CityManagement;