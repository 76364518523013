
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';

const CurriculumHistoryLessonTableRow = ({ data, index }) => {

  const navigate = useNavigate();

  // const { setShowEditCurriculumLessonModal } = useStudentStore()

  //console.log("CurriculumHistoryLessonTableRow",data)

  return (
    <>
      <tr onClick={() => { navigate("") }} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >
        <th className='m-2 font-normal text-center border-r py-s10'>
          {index + 1}
        </th>

        <td className='border-r-[1px] py-s10 text-center px-s10 max-w-[100px] min-w-[200px] '>
            <div className='truncate justify-center font-fw500 ml-s10 text-cImportantText lg:text-fs14 sm:text-fs12'>{data?.lesson_name}</div>
        </td>

        <td className='border-r-[1px] py-s10 text-center px-s10 max-w-[100px] min-w-[200px] '>
            <div className='truncate justify-center font-fw400 ml-s10 text-cImportantText lg:text-fs14 sm:text-fs12'>{data?.school_name?data?.school_name:<CommonEmptyStatus />}</div>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody min-w-[150px]'>
          <span className=' truncate font-fw400 text-cImportantText text-fs14'>{data?.lesson_duration?toHoursAndMinutes(data?.lesson_duration):<CommonEmptyStatus />}</span>
        </td>

        <td className='border-r-[1px] py-s10 text-center px-s10 max-w-[100px] min-w-[150px] '>
            <div className='capitalize truncate justify-center font-fw400 ml-s10 text-cImportantTex lg:text-fs14 sm:text-fs12'>{data?.type?data?.type:<CommonEmptyStatus />}</div>
        </td>

        <td className='py-s10 border-r-[1px] px-s10 text-center min-w-[200px]'>
          <div className='flex justify-around items-center ml-s4'>
            <span className='capitalize font-fw400 ml-s8 text-cImportantTex text-fs14'>{data?.status}</span>
            {/* <img onClick={() => { setShowEditCurriculumLessonModal(true) }} className='rounded-full w-w24 h-h24 grow-0' src={iEdiItIcon} alt="" /> */}
          </div>
        </td>
      </tr>
    </>
  )
}

export default CurriculumHistoryLessonTableRow;