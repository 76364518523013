import React from "react";
import { iAddItem } from "../../../App/Utility/source";
import CommonButton from "../../../Components/Button/CommonButton";
import useSettingsStore, {
  getDeleteMessageIndex,
} from "../../../App/Stores/SettingsStore";
import { useEffect } from "react";
import ListDeleteMessage from "./ListDeleteMessage";
import Settings from "../Settings";

const DeleteMessage = () => {
  const { setShowAddDeleteMessage, DeleteMessageList } = useSettingsStore();

  useEffect(() => {
    fetchDeleteMessageData();
  }, []);

  const fetchDeleteMessageData = async () => {
    await getDeleteMessageIndex();
  };

 // console.log("DeleteMessageList", DeleteMessageList);

  return (
    <Settings>
      <div className="flex justify-between">
        <div>
          <h1 className="font-semibold text-fs18">Delete Message</h1>
        </div>
        <div>
          <div>
            <CommonButton
              colorType="primary"
              btnLabel="Add Message"
              roundedFull="true"
              onClick={() => {
                setShowAddDeleteMessage(true);
              }}
              icon={
                <div className="mr-s5">
                  <img className="w-s20 h-s15" src={iAddItem} alt="" />
                </div>
              }
            />
          </div>
        </div>
      </div>
      <hr className="my-s15" />
      {DeleteMessageList.length > 0
        ? DeleteMessageList?.map((item, index) => (
            <div key={index}>
              <ListDeleteMessage data={item} />
            </div>
          ))
        : <span className="my-s20"> No Delete Message Found</span>}
    </Settings>
  );
};

export default DeleteMessage;
