import React, { useState } from "react";
import Clamp from 'react-multiline-clamp';
import useSettingsStore, { TermsConditionToggleStatus } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import Image from "../../../Components/Image/Image";
import { iAddItem, iBackArrow, iBackArrowActive, iCross, iImageIogo } from './../../../App/Utility/source';
import GreenSwitch from './../../../Components/Switch/GreenSwitch';
import Settings from '../Settings'
import { useNavigate } from "react-router-dom";
import CommonTitle from "../../../Components/Title/CommonTitle";
import RichTextEditor from "../../../Components/TextEditor/RichTextEditor";

function GDPRTermsDetails() {

    const navigate = useNavigate();

    const [is_mouse_over_back_arrow, setIsMouseOverBackArrow] = useState(false);

    const {
        setShowTermsAndConditionDeactivateModal,
        setTermsConditionDeactivateID,
        setShowWriteTermsAndConditionModal,
        setShowTermsAndConditionDeleteModal,
        TermsConditionDetail
    } = useSettingsStore();

    const toggleStatus = async () => {
        setTermsConditionDeactivateID(TermsConditionDetail[0]?.id)
        if (TermsConditionDetail[0]?.status === 0 || TermsConditionDetail[0]?.status === '0') {
            await TermsConditionToggleStatus(TermsConditionDetail[0]?.id)
        } else if (TermsConditionDetail[0]?.status === 1 || TermsConditionDetail[0]?.status === '1') {
            await setShowTermsAndConditionDeactivateModal(true)
        }
    }

    //console.log("TermsConditionDetail", TermsConditionDetail[0])

    return (
        <Settings>
            <>
                <div className="flex justify-between items-center">
                    <div className="flex">
                        <img
                            onMouseLeave={() => { setIsMouseOverBackArrow(false) }}
                            onMouseOver={() => { setIsMouseOverBackArrow(true); }}
                            onClick={() => navigate("/settings/terms-condition")}
                            src={is_mouse_over_back_arrow ? iBackArrowActive : iBackArrow}
                            alt=""
                            className="cursor-pointer mr-s10"
                        />
                        <h1 className="font-semibold text-fs18">GDPR & Terms</h1>
                    </div>
                    <div className="contents cursor-pointer w-s250">
                        <CommonButton
                            colorType="primary"
                            btnLabel="GDPR & Terms"
                            roundedFull="true"
                            onClick={() => {
                                setShowWriteTermsAndConditionModal(true);
                            }}
                            icon={
                                <div className="mr-s5">
                                    <img className="w-s20 h-s15" src={iAddItem} alt="" />
                                </div>
                            }
                        />
                    </div>
                </div>
                <hr className="my-s10" />
                {TermsConditionDetail[0] ?
                    <div>
                        <div>
                            <div className="flex justify-center w-auto">
                                <div className="flex justify-center w-s460 h-s272">
                                    <Image
                                        className="my-s10 rounded-br10"
                                        src={TermsConditionDetail[0]?.image}
                                        dummyImage={iImageIogo}
                                    />
                                </div>
                            </div>

                            <div className="flex justify-between my-s20">
                                <div>
                                    <h1 className="leading-6 text-fs20 fw-600 mb-s10 text-cBlack capitalize">
                                        User Type
                                    </h1>
                                    <p className="fw-400 text-fs16 capitalize">{TermsConditionDetail[0]?.app_type}</p>
                                </div>

                                <div className="flex-col justify-center mt-s4">
                                    <GreenSwitch
                                        onClick={toggleStatus}
                                        enabled={TermsConditionDetail[0]?.status}
                                    />
                                    <div className={`${TermsConditionDetail[0]?.status ? 'text-cPassed' : 'text-cFailed'} mt-s10 text-fs14 font-fw400`}>
                                        {TermsConditionDetail[0]?.status ? 'Active' : <span className=''>Deactivate</span>} </div>
                                </div>
                            </div>
                            <div className="mb-s20">
                                <div className="text-fs20 fw-600 mb-s10 text-cHighlightedTexts">
                                    Description
                                </div>
                                {/* 
                            <Clamp
                                lines={3}
                                maxLines={100}
                                withToggle
                                showMoreElement={({ toggle }) => (
                                    <button className="cursor-pointer text-cBrandColor mt-s10 text-fs16" type="button" onClick={toggle}>
                                        Show more
                                    </button>
                                )}
                                showLessElement={({ toggle }) => (
                                    <span className="cursor-pointer text-cBrandColor mt-s10 text-fs16" type="button" onClick={toggle}>
                                        See Less
                                    </span>
                                )}
                            >
                                <div className="prose prose-lg" dangerouslySetInnerHTML={{ __html: TermsConditionDetail[0]?.content }} ></div>

                                <div>{TermsConditionDetail[0]?.content}</div>
                            </Clamp> */}
                                <div onKeyDown={(e) => { e.preventDefault() }}
                                    onMouseDown={(e) => { e.preventDefault() }}
                                    className="p-s5 h-[300px] relative overflow-hidden rounded-br10 border-2 border-cBgColor">
                                    <div className="absolute -top-[57px] h-[300px] -left-[3px] -right-[3px]">
                                        <RichTextEditor
                                            textarea="true"
                                            value={TermsConditionDetail[0]?.content}
                                            // readOnly={true}
                                            onChange={() => { }}
                                            height="300px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between mt-s20">

                            <CommonButton
                                type="submit"
                                btnLabel="Delete"
                                colorType="warning"
                                roundedFull="true"
                                onClick={() => {
                                    setShowTermsAndConditionDeleteModal(true);
                                }}
                            />

                            <CommonButton
                                type="submit"
                                btnLabel="Edit"
                                colorType="primary"
                                roundedFull="true"
                                onClick={() => {
                                    navigate('/settings/editGdprTerms')
                                }}
                            />
                        </div>
                    </div> : <> No Data Found {navigate('/settings/terms-condition')}</>}
            </>
        </Settings>
    )
}

export default GDPRTermsDetails
