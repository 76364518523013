import React from "react";
import AddCategoryModal from "../../Pages/Category/CategoryModal/AddCategoryModal";
import CategoryDeactivateModal from "../../Pages/Category/CategoryModal/CategoryDeactivateModal";
import ConfirmDeleteCategory from "../../Pages/Category/CategoryModal/ConfirmDeleteCategory";
import ExpertiseAreaModal from "../../Pages/Category/CategoryModal/Instructor/ExpertiseAreaModal";

import NewMessageModal from "../../Pages/Dashboard/DashboardModal/NewMessageModal";
import UpcomingExternalBookingModal from "../../Pages/Instructor/InstructorModal/UpcomingExternalBookingModal";
import DeactivateLicense from "../../Pages/License/LicenseModal/DeactivateLicense";
import DeleteLicenseModal from "../../Pages/License/LicenseModal/DeleteLicenseModal";
import EditLicenseModal from "../../Pages/License/LicenseModal/EditLicenseModal";
import EditClassDetailModal from "../../Pages/School/SchoolModal/EditClassDetailModal";
import InstructorExpertiseAreaModal from "../../Pages/School/SchoolModal/InstructorExpertiseAreaModal";
import CategoryEditModal from "./../../Pages/Category/CategoryModal/CategoryEditModal";
import ContactUsChatModal from "./../../Pages/ContactUs/ContactUsModal/ContactUsChatModal";
import SchoolProfileEdit from "./../../Pages/School/SchoolModal/SchoolProfileEdit";
import EditClassRoomDetailModal from "./../../Pages/School/SchoolModal/EditClassRoomDetailModal";
import AddLicenseModal from "./../../Pages/License/LicenseModal/AddLicenseModal";
import InstructorFilterModal from "./../../Pages/Instructor/InstructorModal/InstructorFilterModal";
import UpcomingClassroomBookingModal from "./../../Pages/Instructor/InstructorModal/UpcomingClassroomBooking";
import {
  default as UpcomingClassroomBooking,
  default as UpcomingDrivingBookingModal,
} from "./../../Pages/Instructor/InstructorModal/UpcomingDrivingBookingModal";
import InvoiceFilterModal from "./../../Pages/Invoice/InvoiceModal/InvoiceFilterModal";
import AddClassDetailModal from "./../../Pages/School/SchoolModal/AddClassDetailModal";
import AddClassRoomDetailModal from "./../../Pages/School/SchoolModal/AddClassRoomDetailModal";
import AddCurriculumLessonModal from "./../../Pages/Student/StudentModal/AddCurriculumLessonModal";
import EditCurriculumLessonModal from "./../../Pages/Student/StudentModal/EditCurriculumLessonModal";
import StudentShareReportModal from "./../../Pages/Student/StudentModal/StudentShareReportModal";
import MessageFilterModal from "./../Modal/MessageFilterModal";
import InstructorEditModal from "../../Pages/Instructor/InstructorModal/InstructorEditModal";
import StudentDeactivateModal from "./../../Pages/Student/StudentModal/StudentDeactivateModal";
import SchoolDeactivateModal from "../../Pages/School/SchoolModal/SchoolDeactivateModal";
import FilterSchoolModal from "./../../Pages/School/SchoolModal/FilterSchoolModal";
import AddBannerModal from "../../Pages/banner/bannerModal/AddBannerModal";
import BannerDetailsModal from "../../Pages/banner/bannerModal/BannerDetailsModal";
import DeleteBannerModal from "../../Pages/banner/bannerModal/DeleteBannerModal";
import EditBannerModal from "../../Pages/banner/bannerModal/EditBannerModal";
import AddDeleteMessageModal from "../../Pages/settings/modals/AddDeleteMessageModal";
import AddFaqModal from "../../Pages/settings/modals/AddFaqModal";
import DeleteCityModal from "../../Pages/settings/modals/DeleteCityModal";
import DeleteDltMessageModal from "../../Pages/settings/modals/DeleteDltMessageModal";
import DeleteFaqModal from "../../Pages/settings/modals/DeleteFaqModal";
import EditDeleteMessageModal from "../../Pages/settings/modals/EditDeleteMessageModal";
import EditFaqModal from "../../Pages/settings/modals/EditFaqModal";
import DeactivateInstructor from '../../Pages/Instructor/InstructorModal/DeactivateInstructor';
import FaqDeactivateModal from './../../Pages/settings/modals/FaqDeactivateModal';
import BannerDeactivateModal from '../../Pages/banner/bannerModal/BannerDeactivateModal';
import DeleteMessageDeactivateModal from '../../Pages/settings/modals/DeleteMessageDeactivateModal';
import AddCityModal from './../../Pages/settings/modals/AddCityModal';
import EditCityModal from './../../Pages/settings/modals/EditCityModal';
import CityDeactivateModal from './../../Pages/settings/modals/CityDeactivateModal';
import FilterStudentTable from "../../Pages/Student/StudentModal/FilterStudentTable";
import WriteTermsAndConditionModal from './../../Pages/settings/modals/WriteTermsAndConditionModal';
import TermsAndConditionDetailsModal from './../../Pages/settings/modals/TermsAndConditionDetailsModal';
import EditTermsAndConditionModal from './../../Pages/settings/modals/EditTermsAndConditionModal';
import TermsAndConditionDeactivateModal from './../../Pages/settings/modals/TermsAndConditionDeactivateModal';
import TermsAndConditionDeleteModal from './../../Pages/settings/modals/TermsAndConditionDeleteModal';
import SchoolChangePassModal from "../../Pages/School/SchoolModal/SchoolChangePassModal";
import InstructorChangePassModal from "../../Pages/Instructor/InstructorModal/InstructorChangePassModal";
import StudentChangePassModal from "../../Pages/Student/StudentModal/StudentChangePassModal";
import EditStudentProfileModal from "../../Pages/Student/StudentModal/EditStudentProfileModal";
import AddSchoolModal from "../../Pages/School/SchoolModal/AddSchoolModal";
import SchoolAddPassModal from "../../Pages/School/SchoolModal/SchoolAddPassModal";
import AddInstructorModal from "../../Pages/Instructor/InstructorModal/AddInstructorModal";
import InstructorAddPassModal from "../../Pages/Instructor/InstructorModal/InstructorAddPassModal";
import AddStudentModal from "../../Pages/Student/StudentModal/AddStudentModal";
import StudentAddPassModal from "../../Pages/Student/StudentModal/StudentAddPassModal";
import ShareInvoiceModal from "../../Pages/Invoice/InvoiceModal/ShareInvoiceModal";
import LogoutModal from "./component/LogoutModal";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { handleUserLogout } from "../../App/Stores/authStore";
import SchoolInvoiceFilter from "../../Pages/School/Invoices/SchoolInvoiceFilter";
import InstructorInvoiceFilter from "../../Pages/Instructor/InstructorInvoiceFilter";
import StudentInvoiceFilter from "../../Pages/Student/Invoice/StudentInvoiceFilter";
import ImagePreviewPopup from "../Image/ImagePreviewPopup";
import EditLicenseDuration from "../CommonDetailPage/EditLicenseDuration";
import ShareCurriculumDetailsModal from "../../Pages/Student/Curriculum/ShareCurriculumDetailsModal";
import ShareCurriculumHistoryModal from "../../Pages/Student/Curriculum/ShareCurriculumHistoryModal";
import CategoryLessonDetailsModal from "../../Pages/School/SchoolModal/SchoolCategory/CategoryLessonDetailsModal";
import EditCategoryLessonDetails from "../../Pages/School/SchoolModal/SchoolCategory/EditCategoryLessonDetails";
import AddSchoolCategoryLesson from "../../Pages/School/SchoolModal/SchoolCategory/AddSchoolCategoryLesson";
import SchoolCategoryDeactivateModal from "../../Pages/School/SchoolModal/SchoolCategory/SchoolCategoryDeactivateModal";
import SchoolCategoryLessonDelete from "../../Pages/School/SchoolModal/SchoolCategory/SchoolCategoryLessonDelete";
import EditSchoolCategory from "../../Pages/School/SchoolModal/SchoolCategory/EditSchoolCategory";
import AddSchoolInstructorModal from "../../Pages/School/SchoolModal/SchoolInstructor/AddSchoolInstructorModal";
import AddSchoolExpertiseAreaModal from "../../Pages/School/SchoolModal/SchoolInstructor/AddSchoolExpertiseAreaModal";
import AppliedCategoriesSchoolInstructor from "../../Pages/School/SchoolModal/SchoolInstructor/AppliedCategoriesSchoolInstructor";
import ContactDetailsModal from "../../Pages/ContactUs/Web/ContactWebModals/ContactDetailsModal";
import FilterContactWebMessage from "../../Pages/ContactUs/Web/ContactWebModals/FilterContactWebMessage";
import AddStudentSchoolModal from "../../Pages/Student/School/AddStudentSchoolModal";
import StudentSchoolFilterModal from "../../Pages/Student/School/StudentSchoolFilterModal";

export default function CommonModalArea() {
  const { setShowLogoutModal, showLogoutModal } = useLayoutStore();
  return (
    <>
      {/* System Modal */}
      <LogoutModal
        showLogoutModal={showLogoutModal}
        setShowLogoutModal={setShowLogoutModal}
        handleUserLogout={handleUserLogout}
      />
      <ImagePreviewPopup />

      <NewMessageModal />

      {/* category modal */}
      <AddCategoryModal />
      <CategoryDeactivateModal />
      <CategoryEditModal />

      <ConfirmDeleteCategory />

      {/* student modal */}
      <StudentAddPassModal />
      <AddStudentModal />
      <EditStudentProfileModal />
      <StudentChangePassModal />
      <StudentDeactivateModal />
      <ShareCurriculumDetailsModal />
      <ShareCurriculumHistoryModal />
      <SchoolCategoryLessonDelete />
      <EditSchoolCategory />
      <AddStudentSchoolModal/>
      <StudentSchoolFilterModal/>
      {/* bug <FilterStudentTable /> */}
      {/* bug <StudentInvoiceFilter /> */}


      {/* school modal */}
      <SchoolAddPassModal />
      <AddSchoolModal />
      <SchoolChangePassModal />
      <SchoolDeactivateModal />
      <SchoolProfileEdit />

      {/* school category */}
      <CategoryLessonDetailsModal />
      <EditCategoryLessonDetails />
      <AddSchoolCategoryLesson />
      <SchoolCategoryDeactivateModal />

      {/* school Instructor */}
      <AddSchoolInstructorModal />
      <AddSchoolExpertiseAreaModal/>
      <AppliedCategoriesSchoolInstructor/>
      {/*bug <FilterSchoolModal /> */}
      {/* bug <SchoolInvoiceFilter /> */}


      <ExpertiseAreaModal />
      <InstructorExpertiseAreaModal />
      <EditClassDetailModal />
      <EditClassRoomDetailModal />
      <MessageFilterModal />

      {/* license modals */}
      <AddLicenseModal />
      <DeactivateLicense />
      <DeleteLicenseModal />
      <EditLicenseDuration />

      {/* Instructor modals */}
      <InstructorAddPassModal />
      <AddInstructorModal />
      <InstructorChangePassModal />
      <InstructorEditModal />
      {/* bug <InstructorFilterModal /> */}
      <UpcomingClassroomBookingModal />
      <UpcomingDrivingBookingModal />
      <UpcomingExternalBookingModal />
      <DeactivateInstructor />
      {/* bug <InstructorInvoiceFilter /> */}

      <StudentShareReportModal />
      <AddCurriculumLessonModal />
      <EditCurriculumLessonModal />
      <AddClassRoomDetailModal />
      <AddClassDetailModal />
      <UpcomingClassroomBooking />
      <EditLicenseModal />

      {/* invoice modals */}
      <InvoiceFilterModal />
      <ShareInvoiceModal />

      {/* Contact-us Modal */}
      <ContactUsChatModal />
      <ContactDetailsModal />
      <FilterContactWebMessage />

      {/* Banner */}
      <AddBannerModal />
      <BannerDetailsModal />
      <EditBannerModal />
      <DeleteBannerModal />
      <BannerDeactivateModal />

      {/* City Management */}
      <DeleteCityModal />

      {/* FAQ */}
      <AddFaqModal />
      <FaqDeactivateModal />
      <DeleteFaqModal />
      <EditFaqModal />

      {/* DeleteMessage */}
      <DeleteMessageDeactivateModal />
      <AddDeleteMessageModal />
      <EditDeleteMessageModal />
      <DeleteDltMessageModal />

      {/* city */}
      <AddCityModal />
      <EditCityModal />
      <CityDeactivateModal />

      {/* TermsAndCondition */}
      <WriteTermsAndConditionModal />
      <TermsAndConditionDetailsModal />
      <EditTermsAndConditionModal />
      <TermsAndConditionDeactivateModal />
      <TermsAndConditionDeleteModal />
    </>
  );
}
