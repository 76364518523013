import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { iInstructorCard, iLessonCard, iSchoolCard, iStudentCard } from '../../App/Utility/source';
import CommonCard from './../../Components/Card/CommonCard';

const AdditionalInfo = ({ data }) => {

  const { id } = useParams()

  return (
    <>
      <span className='text-fs14 font-fw600 py-s20 text-cBlack'>Additional Information</span>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-5 px-0 pt-s20">
        {/* <NavLink to={`school`}> */}
          <CommonCard cardIcon={iSchoolCard} title={"School"} note={`${data?.school_info?.total} Active , ${data?.school_info?.inactive} Deactivate`} />
        {/* </NavLink> */}
        {/* <NavLink to={`student`}> */}
          <CommonCard cardIcon={iStudentCard} title={'Students'} note={`${data?.student_info?.total} Active`} />
        {/* </NavLink> */}
        {/* <NavLink to={`instructor`}> */}
          <CommonCard cardIcon={iInstructorCard} title={'Instructors'} note={`${data?.instruction_info?.total} Active , ${data?.instruction_info?.inactive} Deactivate`} />
        {/* </NavLink> */}
        <CommonCard cardIcon={iLessonCard} title={'Lessons'} note={`${data?.lesson_info?.total} Total`} />
      </div>
    </>
  );
};

export default AdditionalInfo;