import React from 'react';
import { Outlet } from 'react-router-dom';
import Category from './Category';

const CategoryParent = () => {
    return (
        <>
           {/* <Category/> */}
           <Outlet/> 
        </>
    );
};

export default CategoryParent;