import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSchoolStore, { addSchoolInstructorsFn } from '../../../../App/Stores/SchoolStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import InstructorCard from '../../../../Components/Card/InstructorCard';
import CommonModal from '../../../../Components/Modal/CommonModal';

const AppliedCategoriesSchoolInstructor = () => {

    const {
        setShowSchoolInstructorAppliedCategories,
        schoolInstructorsFindDetails,
        showSchoolInstructorAppliedCategories,
        schoolInstructorAppliedCategoriesValue,
        appliedCategoriesSuccessId
    } = useSchoolStore();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const { school_id, school_instructor_id } = useParams();

    //console.log("schoolInstructorAppliedCategoriesValue",schoolInstructorAppliedCategoriesValue)

    const submitAppliedCategories = async () => {

        const selectedValue = schoolInstructorAppliedCategoriesValue.filter(item =>
            item.classroom === 1 ||
            item?.driving === 1 ||
            item?.external === 1
        )
        //console.log("selectedValue", selectedValue)

        const value = {
            "school_id": school_id,
            "instructor_id": school_instructor_id,
            "applied_categories": selectedValue
        }
        const addSuccess = await addSchoolInstructorsFn(value)
        if (addSuccess) {
            setShowSchoolInstructorAppliedCategories(false)
            await navigate(`/school/details/${school_id}/instructor/${appliedCategoriesSuccessId}`)
        }
    }

    return (
        <>
            <CommonModal
                showModal={showSchoolInstructorAppliedCategories}
                setShowModal={setShowSchoolInstructorAppliedCategories}
                modalTitle="Add Applied Categories"
                mainContent={
                    <>
                        <div className='mt-s20'>
                            <div className='grid grid-flow-row-dense grid-cols-3 grid-rows-auto'>

                                {schoolInstructorsFindDetails?.school_category_info ?
                                    schoolInstructorsFindDetails?.school_category_info.map((item, index) => (
                                        <div className=''>
                                            <InstructorCard
                                                width='w-[190px]'
                                                click={true}
                                                title="Expertise Area"
                                                data={item}
                                            /></div>
                                    ))
                                    : 'NO Data'}
                            </div>

                            <div className='flex justify-center '>
                                <CommonButton
                                    onClick={submitAppliedCategories}
                                    type="submit" btnLabel="Save Changes"
                                    colorType="primary"
                                    roundedFull="true"
                                />
                            </div>
                        </div>
                    </>
                }

            />
        </>
    );
};

export default AppliedCategoriesSchoolInstructor;
