
import { Tooltip } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { iRightArrow } from '../../../App/Utility/source';
import { dateDiffCalendar } from '../../../App/Utility/UtilityFunctions';
import { formatDate } from '../../../Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';

const LicenseDetailsTableRow = ({ data, index }) => {

  const navigate = useNavigate()

  const profileOnclick = () => {

    if (data?.user?.role === 'student') {
      navigate(`/student/details/${data?.user?.user_table_id}`)
    } else if (data?.user?.role === 'instructor') {
      navigate(`/instructor/details/${data?.user?.user_table_id}`)
    } else if (data?.user?.role === 'school') {
      navigate(`/school/details/${data?.user?.user_table_id}`)
    }
  }

  return (
    <>
      <tr onClick={() => { profileOnclick() }} className={`border-b cursor-pointer border-collapse border-cNmSelect hover:bg-cGridView`} >
        <th className='font-normal py-s10 border-r text-center  m-s8'>
          <span className='mr-s12 dm:mrs12 md:mr-0 '>{index}</span>
        </th>


        <td className='border-r-[1px] text-center p-s10 min-w-[230px] max-w-[230px] truncate '>
          <div className='flex items-center min-w-[30px] sm:flex-col md:flex-row'>
            <div className='min-w-[45px] max-w-[45px]'>
              <Image className='cursor-pointer w-w44 h-h44 rounded-full ' src={data?.user?.image} alt="" />
            </div>

            {data?.user?.name ?
              <Tooltip title={data?.user?.name}>
                <div className='flex justify-center font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12 truncate capitalize' >
                  {data?.user?.name}
                </div>
              </Tooltip> : <CommonEmptyStatus />}
          </div>
        </td>

        <td className='p-s10 border-r-[1px] truncate text-center text-fs14 text-cTextBody min-w-[200px] max-w-[200px]'>

          {data?.user?.email ? <Tooltip title={data?.user?.email}>
            <span className='font-fw400 text-cImportantText text-fs14 truncate'>
              {data?.user?.email}</span>
          </Tooltip> : <CommonEmptyStatus />}
        </td>

        <td className='py-s10 border-r-[1px] px-s15 text-fs14 text-center text-cTextBody min-w-[150px] max-w-[150px]'>
          <span className='font-fw400 text-cImportantText text-fs14 capitalize'>
            {data?.start_time ? formatDate(data?.start_time) : <CommonEmptyStatus />}</span>
        </td>

        <td className='py-s10 border-r-[1px] px-s15 text-fs14 font-fw400 text-center  text-cTextBody min-w-[200px] max-w-[200px]'>
          <span className='font-fw400 text-cBlack text-fs14'>{formatDate(data?.end_time)}</span>
          <br></br><span className='font-fw400 text-cImportantText text-fs10'>
            ({data?.status === "expired" ? "0 Day" : dateDiffCalendar(new Date(), new Date(data?.end_time))}{" remaining"} )
          </span>
        </td>

        <td className='py-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[100px] max-w-[100px]'>
          <div className='flex justify-center items-center space-x-2.5 capitalize'>
            <div className={`font-fw600 text-fs10`}>{data?.status ? data?.status : <CommonEmptyStatus />}
            </div>
            <img className='' src={iRightArrow} alt=""></img>
          </div>
        </td>

      </tr>
    </>
  )
}

export default LicenseDetailsTableRow;