import React from "react";
import useBannerStore, { deleteBannerData } from "../../../App/Stores/bannerStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";

const DeleteBannerModal = () => {
  const { showDeleteBannerModal, setshowDeleteBannerModal, getSingleBannerDetails, setshowBannerDetailsModal } = useBannerStore();
  return (
    <div className="">
      <CommonModal
        showModal={showDeleteBannerModal}
        setShowModal={setshowDeleteBannerModal}
        modalTitle="Confirmation"
        widthClass="w-s591"
        mainContent={
          <>
            <div className="">
              <div className="">
                {/* <ProfileImageUploader iImage={iImageIogo} /> */}
                <h1 className="my-5 text-center mb-s22">
                  Are you sure you want to delete this banner? This banner is
                  currently using to {`${getSingleBannerDetails?.app_type}`}.
                </h1>
              </div>
              <div className="flex justify-center">
                <CommonButton
                  onClick={() => {
                    var delayInMilliseconds = 1000; //1 second

                    const value = deleteBannerData(getSingleBannerDetails?.id);
                    if (value) {
                      setshowDeleteBannerModal(false)
                      setTimeout(function () {
                        setshowBannerDetailsModal(false)
                        //your code to be executed after 1 second
                      }, delayInMilliseconds);
                    }
                  }}
                  btnLabel="Delete"
                  colorType="danger"
                  roundedFull="true"
                //width="w-s172"
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default DeleteBannerModal;
