/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "../Stores/authStore";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    //if (!isLoggedIn) navigate('/login');
  }, [isLoggedIn]);

  // return <Outlet />;
  return !isLoggedIn ? <Navigate to="/login" /> : <Outlet />;
};

export default ProtectedRoute;
