import React from 'react';

const NoLicense = ({height="h-s317"}) => {
    return (
        <div>
            <div className={`bg-cBrandColor w-w350 rounded-br10 my-s3 ${height}`}>
                <div className='flex justify-center items-center h-s317'>
                    <div className='text-center'>
                        <div className='text-fs20 font-fw600 text-cBrandColor2 mb-s10'>No Active License</div>
                        {/* <div className='bg-cBrandColor2 text-cBrandColor text-fs12 rounded-br100 s48'>
                            <span className='flex justify-center items-center cursor-pointer h-s48'>Apply For License</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoLicense;