import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import useSchoolStore, { schoolInstructorListIndexFn } from '../../../App/Stores/SchoolStore';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import { iAddItem, iBlueAddItem, iWhiteAddItem } from '../../../App/Utility/source';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonTable from '../../../Components/Table/CommonTable';
import SchoolInstructorTableRow from '../../../Components/Table/School/SchoolInstructorTableRow';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { PageTitle } from '../../../Utility/UtilityFunctions';


const SchoolInstructor = () => {

    const {
        schoolInstructorList,
        schoolInstructorPageUrl,
        setSchoolInstructorPageUrl,
        setShowAddSchoolInstructorModal,
        setSchoolInstructorsSearchValue,
        setSchoolInstructorsFindValue
    } = useSchoolStore();

    const { school_id } = useParams();


    const location = useLocation();

    const { setLoading } = useUtilityStore();

    const { id } = useParams()

    const InstructorHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Email") },
        { index: 4, name: t("Phone") },
        { index: 5, name: t("Applied Area") },
        { index: 6, name: t("Status") },
        // { index: 5, name: t("Expertise Area") },
    ];


    //   const [searchValue] = useDebounce(instructorSearchKey, 500);

    //   useEffect(() => {
    //     SmartSearchAllInstructor();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [searchValue]);

    const SmartSearchAllInstructor = async () => {
        // setLoading(true);
        // if (searchValue) {
        //   setLoading(false);
        //   await searchInstructorList(instructorPageUrl, instructorTakeList.take);
        // } else {
        //   if (instructorIndex.current_page) {
        //     let urlToLoad = instructorIndex.path + "?page=" + instructorIndex.current_page;
        //     // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        //     setInstructorSearchKey("")
        //     await getInstructorIndex(urlToLoad, filterData, "", false);
        //   } else {
        //     setInstructorSearchKey("")
        //     await getInstructorIndex(instructorPageUrl, filterData, "", false);
        //   }
        // }
        // setLoading(false);
    }

    const { setBarTitle } = useLayoutStore();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle(t("School Instructor"));
        setBarTitle("School Instructor");
    }, []);

    useEffect(() => {
        fetchSchoolInstructor()
    }, [school_id])


    const fetchSchoolInstructor = async () => {
        await schoolInstructorListIndexFn(schoolInstructorPageUrl, school_id);
    }

    //console.log("schoolInstructorListAll",schoolInstructorList?.data)

    return (
        <div className='mx-s12 md:mx-s32'>

            <CommonTitle title=" Instructor School " >
                {
                    <BackLink linksArray={[
                        { label: "School", linkTo: "/school" },
                        { label: "School Details", linkTo: `/school/details/${school_id}` },
                        { label: "Instructor School", linkTo: `` }
                    ]} />
                }
            </CommonTitle>

            <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
                <CommonTable
                    headers={InstructorHeaders}
                    tableTitle=""
                    showPageCountText={true}
                    showPagination={true}
                    paginationObject={schoolInstructorList}
                    TableRowComponent={SchoolInstructorTableRow}
                    autoManageRow={true}
                    paginationOnClick={async (url) => {
                        setSchoolInstructorPageUrl(url)
                        schoolInstructorListIndexFn(url, school_id)
                        // setLoading(true);
                        // console.log(url);
                        // await setInstructorPageUrl(url);
                        // if (searchValue) {
                        //   setLoading(false);
                        //   await searchInstructorList(url, instructorTakeList.take);
                        // } else await getInstructorIndex(url, filterData, "", false);
                        // setLoading(false);
                    }}

                    shoSearchBox={false}
                    withClearSearch={true}
                    //   onSearchClear={() => setInstructorSearchKey("")}
                    //   searchValue={instructorSearchKey}
                    //   searchOnChange={(e) => setInstructorSearchKey(e.target.value)}

                    titleComponent={
                        <div className='mb-s20'>
                            <CommonButtonOutlined
                                iconLeft={iWhiteAddItem}
                                iconLeftHover={iBlueAddItem}
                                colorType='primary'
                                btnLabel="Add Instructor"
                                onClick={() => {
                                    setShowAddSchoolInstructorModal(true)
                                    setSchoolInstructorsFindValue([])
                                    setSchoolInstructorsSearchValue("") 
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default SchoolInstructor;