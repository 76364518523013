import React from "react";
import { useState } from "react";
import useSettingsStore, { createDeleteMessage } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import SelectInput from "../../../Components/Input/SelectInput";
import CommonLabel from "../../../Components/Label/CommonLabel";
import CommonModal from "../../../Components/Modal/CommonModal";
import RichTextEditor from "../../../Components/TextEditor/RichTextEditor";

const AddDeleteMessageModal = () => {

  const { showAddDeleteMessage, setShowAddDeleteMessage } = useSettingsStore();

  const [addForm, setAddForm] = useState({
    content: "",
    app_type: ''
  });

  const formSubmit = async (e) => {
    e.preventDefault();

    if (addForm?.content && addForm?.app_type) {
      let addSuccess = await createDeleteMessage(addForm)
      if (addSuccess) {
        await setAddForm({ content: "", app_type: "" });
        await setShowAddDeleteMessage(false)
      }
    }
  };

  const durationArray = [
    {
      title: "Student",
      value: 'student',
      selected: false
    },
    {
      title: "instructor",
      value: 'instructor',
      selected: false
    },
    {
      title: "school",
      value: 'school',
      selected: false
    },
  ]


  return (
    <div>
      <CommonModal
        showModal={showAddDeleteMessage}
        setShowModal={setShowAddDeleteMessage}
        modalTitle="Add Message"
        mainContent={
          <>
            <form onSubmit={formSubmit}>

            <div className="mt-s20">
                <SelectInput
                  required={true}
                  label="Choose User"
                  placeholder="Choose User"
                  dataArray={durationArray}
                  selectOptionOnChange={(e) => setAddForm({ ...addForm, app_type: e })}
                />
              </div>
              <div className="my-s20">
                <RichTextEditor
                  label="Write Content "
                  required={true}
                  placeholder="Write your business Terms & Condition..."
                  value={addForm.content}
                  onChange={(e) => setAddForm({ ...addForm, content: e })}

                />
              </div>

              <div className="flex justify-center mt-s60">
                <CommonButton
                  type="submit"
                  btnLabel="Add"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default AddDeleteMessageModal;
