import React from 'react';
import { useParams } from 'react-router-dom';
import useSchoolStore, { schoolCategoriesToggleStatus } from '../../../../App/Stores/SchoolStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

const SchoolCategoryDeactivateModal = () => {

    const {setShowSchoolCategoryDeactivateModal,showSchoolCategoryDeactivateModal}=useSchoolStore();

    const{school_category_id,school_id}=useParams();

    const toggleStatus=async ()=>{
    
        let value=await schoolCategoriesToggleStatus(school_category_id,school_id)
        if(value) return setShowSchoolCategoryDeactivateModal(false)
    }

    return (
        <div>
            <CommonModal
            showModal={showSchoolCategoryDeactivateModal}
            setShowModal={setShowSchoolCategoryDeactivateModal}  
            modalTitle="Conformation"
            mainContent={
               <>
                 <div className='text-fs14 font-fw600 text-cHighlighted leading-5 flex justify-center my-s20'>
                    Are you sure you want to deactivate this category?
                </div>
               <div className='flex justify-center'>
               <CommonButton onClick={()=>{toggleStatus()}} btnLabel="Deactivate" colorType="warning" roundedFull="true" />
               </div>
               </>
            }
                 
            />
        </div>
    );
};

export default SchoolCategoryDeactivateModal ;