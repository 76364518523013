import React, { useEffect, useState } from 'react';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonModal from '../../../Components/Modal/CommonModal';
import { valueCheck } from '../../../Utility/UtilityFunctions';
import useSchoolStore, { editSchoolData, resetSchoolEditData } from './../../../App/Stores/SchoolStore';
import { BaseUrlSrc } from './../../../App/Utility/Url';
import ProfileImageUploader from './../../../Components/Image/ProfileImageUploader';

const SchoolProfileEdit = () => {

  const { 
    EditFormData,
    resetSchoolEditFormData,
    showEditSchoolModal,
    setShowEditSchoolModal,
    schoolDetails,
    setEditFormData,
    setShowSchoolChangePassModal
  } = useSchoolStore()

  // useEffect(() => {                                      
  //   setName("")
  //   setEmail("")
  //   setCvr("")
  //   setAddress("")
  //   setWebsite("")
  //   setPhone_number("")
  //   setContact_mail("")
  //   setDescription("")
  //   setImage("")
  //   setZipCode("")
  //   setCity("")
  //   }, [])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [cvr, setCvr] = useState("")
  const [address, setAddress] = useState("")
  const [website, setWebsite] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [contact_mail, setContact_mail] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("");
  const [zip_code, setZipCode] = useState("")
  const [city, setCity] = useState("");


  useEffect(() => {                                      
    setName(valueCheck(schoolDetails?.data?.school?.name))
    setEmail(valueCheck(schoolDetails?.data?.school?.email))
    setCvr(valueCheck(schoolDetails?.data?.school?.cvr))
    setAddress(valueCheck(schoolDetails?.data?.school?.address))
    setWebsite(valueCheck(schoolDetails?.data?.school?.website))
    setPhone_number(valueCheck(schoolDetails?.data?.school?.phone_number))
    setContact_mail(valueCheck(schoolDetails?.data?.school?.contact_mail))
    setDescription(valueCheck(schoolDetails?.data?.school?.long_description))
    setImage(valueCheck(schoolDetails?.data?.school?.cover))
    setZipCode(valueCheck(schoolDetails?.data?.school?.zip_code))
    setCity(valueCheck(schoolDetails?.data?.school?.city))
  }, [schoolDetails])


  const submit = () => {
    setEditFormData("id", schoolDetails?.data?.school?.id);
    setEditFormData("school_name", name);
    setEditFormData("email", email)
    setEditFormData("cvr", cvr)
    setEditFormData("phone_number", phone_number)
    setEditFormData("address", address)
    setEditFormData("contact_mail", contact_mail)
    setEditFormData("website", website)
    setEditFormData("l_description", description)
    // setEditFormData("image", image)
    setEditFormData("zip", zip_code)
    setEditFormData("city", city)
  }


  return (
    <>
      <CommonModal
        showModal={showEditSchoolModal}
        setShowModal={setShowEditSchoolModal}
        modalSpace={true}
        modalTitle="Edit School Profile"
        mainContent={
          <>

            <div className='flex justify-center'>
              <div>
                <div className='mt-s20'>
                  <ProfileImageUploader
                    iImage={image ? BaseUrlSrc + image : ''}
                    imageName="image"
                    imageUploader={setEditFormData} categoryImg={false} />
                </div>
                {/* <div className="flex justify-center mt-s20" onClick={() => { setShowSchoolChangePassModal(true) }}>
                  <span className="cursor-pointer font-fw600 text-fs14 text-cBrandColor">Change Password</span>
                </div> */}
              </div>
            </div>

            <form onSubmit={async (e) => {
              e.preventDefault()
              await submit()
              const value = await editSchoolData(EditFormData)
              if (value) {
                await resetSchoolEditData()
                setShowEditSchoolModal(false)
                resetSchoolEditFormData()
              }
            }}>
              <div className="my-s20">
                <CommonInput
                  required={true}
                  max_input="50"
                  type="text"
                  value={name}
                  onChange={(e) => { setName(e.target.value) }}
                  label="Name" placeholder="Name"
                />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="email"
                  value={email}
                  label="Email"
                  placeholder="Email"
                  disabled={true}
                  notEditable={true}
                />
              </div>

              <div className="flex justify-between mb-s20">

                <div className='w-s170 md:w-s270'>
                  <CommonInput
                    required={true}
                    value={cvr}
                    type="number"
                    label="CVR "
                    placeholder="cvr"
                    disabled={true}
                    notEditable={true}
                  />
                </div>

                <div className='w-s170 md:w-s280'>
                  <CommonInput
                    type="number"
                    value={phone_number}
                    max_input="15"
                    onChange={(e) => { setPhone_number(e.target.value) }}
                    label="Phone Number"
                    placeholder="Phone Number"
                  />
                </div>
              </div>


              <div className="flex justify-between mb-s20">

                <div className='w-s170 md:w-s270'>
                  <CommonInput
                    value={city}
                    type="text"
                    label="City"
                    max_input="20"
                    placeholder="city"
                    onChange={(e) => { setCity(e.target.value) }}
                  />
                </div>

                <div className='w-s170 md:w-s280'>
                  <CommonInput
                    type="number"
                    value={zip_code}
                    max_input="15"
                    onChange={(e) => { setZipCode(e.target.value) }}
                    label="Zip Code"
                    placeholder="Zip Code"
                  />
                </div>
              </div>



              <div className="mb-s20">
                <CommonInput
                  type="text"
                  value={address}
                  max_input="50"
                  label="Address"
                  onChange={(e) => { setAddress(e.target.value) }}
                  placeholder="Address"
                />
              </div>

              <div className="mb-s20">
                <CommonInput
                  type="text" value={website}
                  label="Website"
                  max_input="30"
                  onChange={(e) => { setWebsite(e.target.value) }}
                  placeholder="Website" />
              </div>

              <div className="mb-s20">
                <CommonInput
                  type="email"
                  onChange={(e) => { setContact_mail(e.target.value) }}
                  value={contact_mail} label="Contact Mail"
                  placeholder="Contact Mail" />
              </div>

              <div className="mb-s20">
                <CommonInput
                  value={description}
                  onChange={(e) => { setDescription(e.target.value) }}
                  type="text"
                  label="Description"
                  textarea="true"
                  rows="5"
                  max_input={255}
                  placeholder="Description" />
              </div>

              <div className='flex justify-center mt-s20'>
                {/* <CommonButton type="button" btnLabel="Delete School" colorType="warning" roundedFull="true" /> */}
                <CommonButton type="submit" btnLabel="Save Changes" colorType="primary" roundedFull="true" />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};

export default SchoolProfileEdit;