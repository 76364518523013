import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCategoryStore, { getCategoryDetail } from '../../App/Stores/CategoryStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import BackLink from '../../Components/Pagination/BackLink';
import CommonTitle from '../../Components/Title/CommonTitle';
import useLayoutStore from './../../App/Stores/LayoutStore';
import AdditionalInfo from './AdditionalInfo';
import CategoryProfile from './CategoryProfile';

const CategoryDetails = () => {

    const { categoryDetails} = useCategoryStore()

    const { setBarTitle } = useLayoutStore();

    const { id } = useParams();

    const fetchCategoryData = async () => {        
        await getCategoryDetail(id)
    }

    useEffect(() => {
        fetchCategoryData()
        PageTitle(t("Category Details"));
        setBarTitle("Category Details");
    }, [id,setBarTitle])

    return (
        <div className="mx-s12 md:mx-s32">
            <CommonTitle title="Category Details" >
                <BackLink linksArray={[
                    { label: "Category", linkTo: "/category" },
                    { label: "Category Details", linkTo: "" },
                ]} />
            </CommonTitle>
            <div className="overflow-hidden bg-cBrandColor2 rounded-br20 px-s20 py-s20">

                <CategoryProfile
                    data={categoryDetails?.data?.category_details}
                />

                <div className="px-1 py-s20">
                    <hr />
                </div>

                <AdditionalInfo data={categoryDetails?.data} />
            </div>
        </div>
    );
};

export default CategoryDetails;