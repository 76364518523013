import React from 'react';
import useSchoolStore from '../../../../App/Stores/SchoolStore';
import { toHoursAndMinutes } from '../../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonEmptyStatus from '../../../../Components/CommonEmptyStatus/CommonEmptyStatus';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { valueCheck } from '../../../../Utility/UtilityFunctions';

const CategoryLessonDetailsModal = () => {

    const { setLessonDeleteID, setShowSchoolCategoryDeleteModal, schoolCategoryLessonDetails, showCategoryLessonDetailsModal, setShowCategoryLessonDetailsModal, setShowEditCategoryLessonDetailsModal } = useSchoolStore();

    //console.log("choolCategoryLessonDetails?.is_mandatory", schoolCategoryLessonDetails?.is_mandatory)
    
    return (
        <div>
            <CommonModal
                showModal={showCategoryLessonDetailsModal}
                setShowModal={setShowCategoryLessonDetailsModal}
                modalTitle="Lesson Details"
                mainContent={
                    <>
                        {schoolCategoryLessonDetails.name ?
                            <div className='mt-s20 '>
                                <div className='bg-cBorderBottom p-s20 rounded-br10'>
                                    {/* <div className='flex justify-between'>
                                    <div className='text-fs14 text-CLesson font-fw400 leading-5'>Category</div>
                                    <div className='text-fs14 text-CLesson font-fw400 leading-5'>Category</div>
                                </div> */}
                                    <div className='flex justify-between my-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Lesson Type</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5 capitalize '>
                                            {schoolCategoryLessonDetails?.type ? schoolCategoryLessonDetails?.type :
                                                <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}
                                        </div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Lesson Name</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>
                                            {schoolCategoryLessonDetails?.name ? schoolCategoryLessonDetails?.name :
                                                <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}
                                        </div>
                                    </div>
                                    <div className='flex justify-between my-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Duration</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>{schoolCategoryLessonDetails?.duration ? toHoursAndMinutes(schoolCategoryLessonDetails?.duration) :
                                            <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}</div>
                                    </div>
                                    <div className='flex justify-between mb-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Price</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>
                                            {schoolCategoryLessonDetails?.price ? `DKK ${schoolCategoryLessonDetails?.price}` :
                                                <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}
                                        </div>
                                    </div>
                                    <div className='flex justify-between mb-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Mandatory Lesson</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>

                                            {schoolCategoryLessonDetails?.is_mandatory?
                                                `${schoolCategoryLessonDetails?.is_mandatory === 'true' ||
                                                    schoolCategoryLessonDetails?.is_mandatory === true && 'Yes'}`  
                                                :
                                                'NO'
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <div className='my-s20'>
                                        <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Description</span>
                                        <div className='mt-s5'>
                                            {schoolCategoryLessonDetails.description ? schoolCategoryLessonDetails?.description :
                                                <CommonEmptyStatus
                                                    size='text-fs12'
                                                    fontWeight='font-fw400'
                                                    leading="leading-5"
                                                />}
                                        </div>
                                    </div>
                                    <div className='my-s20'>
                                        <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Requirement</span>
                                        <div className='mt-s5'>
                                            {schoolCategoryLessonDetails.requirements ? schoolCategoryLessonDetails?.requirements :
                                                <CommonEmptyStatus
                                                    size='text-fs12'
                                                    fontWeight='font-fw400'
                                                    leading="leading-5"
                                                />}
                                        </div>
                                    </div>

                                    <div className='flex justify-between mt-s20'>
                                        <CommonButton
                                            colorType='warning'
                                            btnLabel="Delete"
                                            roundedFull="true"
                                            onClick={async () => {
                                                await setLessonDeleteID(schoolCategoryLessonDetails?.id)
                                                setShowSchoolCategoryDeleteModal(true)
                                            }}
                                        />
                                        <CommonButton
                                            onClick={() => { setShowEditCategoryLessonDetailsModal(true) }}
                                            btnLabel="Edit"
                                            roundedFull="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            :

                            <div className='mt-s20 '>
                                <div className='bg-cBorderBottom p-s20 rounded-br10'>
                                    {/* <div className='flex justify-between'>
                                <div className='text-fs14 text-CLesson font-fw400 leading-5'>Category</div>
                                <div className='text-fs14 text-CLesson font-fw400 leading-5'>Category</div>
                            </div> */}
                                    <div className='flex justify-between my-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Lesson Type</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5 capitalize '>
                                            {schoolCategoryLessonDetails?.lesson_type ? schoolCategoryLessonDetails?.lesson_type :
                                                <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}
                                        </div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Lesson Name</div>
                                        <div className='capitalize text-fs14 text-cLesson font-fw400 leading-5'>
                                            {schoolCategoryLessonDetails?.lesson_name ? schoolCategoryLessonDetails?.lesson_name :
                                                <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}
                                        </div>
                                    </div>
                                    <div className='flex justify-between my-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Duration</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>{schoolCategoryLessonDetails?.duration_time ? toHoursAndMinutes(schoolCategoryLessonDetails?.duration_time) :
                                            <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}</div>
                                    </div>
                                    <div className='flex justify-between mb-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Price</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>
                                            {schoolCategoryLessonDetails?.price ? `DKK ${schoolCategoryLessonDetails?.price}` :
                                                <CommonEmptyStatus fontWeight='font-fw400' leading='leading-5' size='text-fs14' textColor='text-cLesson' />}
                                        </div>
                                    </div>
                                    <div className='flex justify-between mb-s5'>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>Mandatory Lesson</div>
                                        <div className='text-fs14 text-cLesson font-fw400 leading-5'>
                                           
                                        {schoolCategoryLessonDetails?.is_mandatory ?
                                                `${schoolCategoryLessonDetails?.is_mandatory === 'true' ||
                                                    schoolCategoryLessonDetails?.is_mandatory === true && 'Yes'}`  
                                                :
                                                'NO'
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <div className='my-s20'>
                                        <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Description</span>
                                        <div className='mt-s5'>
                                            {schoolCategoryLessonDetails.lesson_description ? valueCheck(schoolCategoryLessonDetails?.lesson_description) :
                                                <CommonEmptyStatus
                                                    size='text-fs12'
                                                    fontWeight='font-fw400'
                                                    leading="leading-5"
                                                />}
                                        </div>
                                    </div>
                                    <div className='my-s20'>
                                        <span className='mr-s5 text-fs14 font-fw600 text-cBlack'>Requirement</span>
                                        <div className='mt-s5'>
                                            {schoolCategoryLessonDetails.lesson_requirements ? valueCheck(schoolCategoryLessonDetails?.lesson_requirements) :
                                                <CommonEmptyStatus
                                                    size='text-fs12'
                                                    fontWeight='font-fw400'
                                                    leading="leading-5"
                                                />}
                                        </div>
                                    </div>

                                    <div className='flex justify-between mt-s20'>
                                        <CommonButton
                                            colorType='warning'
                                            btnLabel="Delete"
                                            roundedFull="true"
                                            onClick={async () => {
                                                await setLessonDeleteID(schoolCategoryLessonDetails?.id)
                                                setShowSchoolCategoryDeleteModal(true)
                                            }}
                                        />
                                        <CommonButton
                                            onClick={() => { setShowEditCategoryLessonDetailsModal(true) }}
                                            btnLabel="Edit"
                                            roundedFull="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }

            />
        </div>
    );
};

export default CategoryLessonDetailsModal;