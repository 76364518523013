import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import useSchoolStore, { schoolStudentIndex } from '../../../App/Stores/SchoolStore';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonTable from '../../../Components/Table/CommonTable';
import SchoolStudentTableRow from '../../../Components/Table/School/SchoolStudentTableRow';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { PageTitle } from '../../../Utility/UtilityFunctions';


const SchoolStudent = () => {

    const { 
        schoolStudentList, 
        setSchoolStudentType, 
        schoolStudentType,
        schoolStudentPageUrl,
        setSchoolStudentPageUrl
     } = useSchoolStore();

    const location = useLocation();

    const { setLoading } = useUtilityStore();

    const { school_id } = useParams()

    const schoolStudentHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Email") },
        { index: 4, name: t("Phone") },
        { index: 5, name: t("Curriculum") },
        // { index: 6, name: t("Status") },
    ];

    //   const [searchValue] = useDebounce(studentSearch, 500);

    //   useEffect(() => {
    //     SmartSearchAllStudent(studentPaginationURL);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [searchValue]);


    const { setBarTitle } = useLayoutStore();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle(t("School Student"));
        setBarTitle("School Student");
        
        fetchStudentData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolStudentType]);

    const fetchStudentData = async () => {
        // setLoading(true);
        schoolStudentIndex(schoolStudentPageUrl,school_id, schoolStudentType)
        // if (studentSearch) {
        //   setLoading(false);
        //   await searchStudentList(studentPaginationURL)
        // } else {
        //   await getStudentList(studentPaginationURL, filterData, false);
        // }
        // setLoading(false);
    }

    useEffect(() => {
        // setSchoolStudentType('active')
    }, [])
    

    return (
        <div className='mx-s12 md:mx-s32'>

            <CommonTitle title="School Student" >
                <BackLink linksArray={[
                    { label: "School", linkTo: "/school" },
                    { label: "School Details", linkTo: `/school/details/${school_id}` },
                    { label: "School Student", linkTo: `` }
                ]} />


            </CommonTitle>

            <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
                <CommonTable
                    showChip={true}
                    chipWidth="min-w-[150px]"
                    chipArray={[
                        {
                            title: "Active",
                            selected: schoolStudentType === 'active'? true:false,
                            action: async () => {
                                setSchoolStudentType('active')
                                setSchoolStudentPageUrl("")
                            },
                        },
                        {
                            title: "Pending",
                            selected: schoolStudentType === 'pending'? true:false,
                            action: async () => {
                                setSchoolStudentType("pending")
                                setSchoolStudentPageUrl("")
                            },
                        },
                        {
                            title: "History",
                            selected: schoolStudentType === 'history'? true:false,
                            action: async () => {
                                setSchoolStudentType('history')
                                setSchoolStudentPageUrl("")
                            },
                        },
                    ]}
                    headers={schoolStudentHeaders}
                    tableTitle=""
                    paginationObject={schoolStudentList}
                    paginationOnClick={async (url) => {
                        setSchoolStudentPageUrl(url)
                        schoolStudentIndex(url,school_id, schoolStudentType)

                        // setLoading(true);
                        // console.log("url", url);
                        // // save pagination url    
                        // setStudentPaginationURL(url);
                        // if (searchValue) {
                        //     setLoading(false);
                        //     await searchStudentList(url);
                        // } else await getStudentList(url, filterData, false);
                        // setLoading(false);
                    }}

                    shoSearchBox={false}
                    // searchValue={studentSearch}
                    // withClearSearch={true}
                    // onSearchClear={() => setStudentSearch("")}
                    // searchOnChange={(e) => setStudentSearch(e.target.value)}

                    autoManageRow={true}
                    TableRowComponent={SchoolStudentTableRow}
                // titleComponent={
                //     <>
                //         <CommonButtonOutlined
                //             iconLeft={iFilterWhite}
                //             iconLeftHover={iFilter}
                //             isFilterDot={FilterStudentActive}
                //             colorType='primary'
                //             btnLabel="Filter"
                //             onClick={() => { setShowStudentFilterModal(true) }}
                //         />
                //     </>
                // }
                />
            </div>
        </div>

    );
};

export default SchoolStudent;