import React, { useEffect } from 'react'
import CommonTable from '../../Components/Table/CommonTable';
import CommonTitle from '../../Components/Title/CommonTitle';
import { t } from 'i18next';
import LicenseDetailsTableRow from '../../Components/Table/License/LicenseDetailsTableRow';
import useLicenseStore, { getLicenseeDetails } from '../../App/Stores/LicenseStore';
import { useLocation, useParams } from 'react-router';
import LicenseProfile from './LicenseProfile';
import { useDebounce } from 'use-debounce';
import useUtilityStore from '../../App/Stores/UtilityStore';
import BackLink from '../../Components/Pagination/BackLink';
import useLayoutStore from '../../App/Stores/LayoutStore';
import { PageTitle } from '../../Utility/UtilityFunctions';

function LicenseDetails() {

  const location = useLocation();

  const { licenseDetails, licenseDetailsSearchKey, setLicenseDetailsSearchKey, setLicenseDetailsPageUrl, licenseDetailsPageUrl } = useLicenseStore()

  const { license_id } = useParams()

  const { setLoading } = useUtilityStore();

  const [searchValue] = useDebounce(licenseDetailsSearchKey, 500);

  useEffect(() => {
    smartSearchLicenseDetails()
  }, [searchValue])

  const smartSearchLicenseDetails = async () => {
    if (searchValue) {
      await getLicenseeDetails(license_id, licenseDetailsPageUrl, searchValue);
    } else {
      setLoading(true);
      if (licenseDetails?.purchase_license?.current_page) {
        let urlToLoad = licenseDetails?.purchase_license?.path + "?page=" + licenseDetails?.purchase_license.current_page;
        await getLicenseeDetails(license_id, urlToLoad);
      } else {
        await getLicenseeDetails(license_id)
      }
      setLoading(false);
    }
  }

  const LicenseHeaders = [

    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Email") },
    { index: 4, name: t("Start Date") },
    { index: 5, name: t("Expire Date") },
    { index: 6, name: t("Status") },

  ];

  useEffect(() => {
    fetchLicenseDetails()
  }, [license_id])

  const fetchLicenseDetails = async () => {
    if (searchValue) {
      console.log("searchValue", searchValue, licenseDetailsPageUrl)
      await getLicenseeDetails(license_id, licenseDetailsPageUrl, searchValue);
    } else await getLicenseeDetails(license_id, licenseDetailsPageUrl)
  }

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("License Details"));
    setBarTitle("License Details");
  }, []);

  return (
    <div className='mx-s12 md:mx-s32'>
      <CommonTitle title="License Details">
        {
          location.pathname === `/license/details/list/${license_id}` && <BackLink linksArray={[
            { label: "License", linkTo: "/license/list" },
            { label: "License Details", linkTo: `` }
          ]} />
        }
        {
          location.pathname === `/license/details/${license_id}` && <BackLink linksArray={[
            { label: "License", linkTo: "/license" },
            { label: "License Details", linkTo: `` }
          ]} />
        }
      </CommonTitle>

      <div className="bg-cBrandColor2 rounded-br20 px-s20 py-s20">

        <LicenseProfile data={licenseDetails?.license} />

        <CommonTable
          headers={LicenseHeaders}
          tableTitle="User List"
          showPageCountText={true}
          showPagination={true}

          autoManageRow={true}
          TableRowComponent={LicenseDetailsTableRow}
          paginationObject={licenseDetails?.purchase_license}

          shoSearchBox={true}
          searchValue={licenseDetailsSearchKey}
          withClearSearch={true}
          onSearchClear={() => {
            setLicenseDetailsSearchKey("")
            setLicenseDetailsPageUrl("")
          }}
          searchOnChange={(e) => {
            setLicenseDetailsSearchKey(e.target.value)
            setLicenseDetailsPageUrl("")
          }
          }


          paginationOnClick={async (url) => {
            // setLoading(true);
            // console.log("url", url);
            setLicenseDetailsPageUrl(url)
            if (searchValue) {
              await getLicenseeDetails(license_id, url, searchValue);
            } else await getLicenseeDetails(license_id, url)
            // setLoading(false);
          }}
        />
      </div>
    </div>
  )
}

export default LicenseDetails
