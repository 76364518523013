
import { Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';

const CurriculumTableRow = ({ data, index }) => {

  const navigate = useNavigate();

  // const { setShowEditCurriculumLessonModal } = useStudentStore()

  return (
    <>
      <tr onClick={() => { navigate("") }} className={`border-b my-s10 border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >
        <th className='m-2 font-normal text-center border-r py-s10'>
          {index + 1}
        </th>

        <td className='border-r-[1px] py-s10 text-left px-s10 min-w-[180px] 2xl:min-w-[250px]'>
          <div className='flex items-center min-w-[80px] sm:flex-col md:flex-row'>
            <div className='ml-s4'>
              <Image cursorPointerClass="cursor-pointer" className='rounded-full w-w44 h-h44 grow-0' src={data?.image} />
            </div>
            <Tooltip title={data?.name}>
              <div className='capitalize font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12 max-w-[130px] 2xl:max-w-[200px] truncate'>{data?.name ?? <CommonEmptyStatus />}</div>
            </Tooltip>
          </div>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>{data?.lesson_duration ? toHoursAndMinutes(data?.lesson_duration) : ''}</span>
        </td>


        <td className='py-s10 border-r-[1px] px-s10 text-center'>
          <div className='flex justify-around items-center ml-s4'>
            <span className='capitalize font-fw400 ml-s8 text-cImportantText text-fs14'>{data?.status}</span>
            {/* <img onClick={() => { setShowEditCurriculumLessonModal(true) }} className='rounded-full w-w24 h-h24 grow-0' src={iEdiItIcon} alt="" /> */}
          </div>
        </td>
      </tr>
    </>
  )
}

export default CurriculumTableRow;