import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCategoryStore, { editCategoryData } from '../../../App/Stores/CategoryStore';
import CommonButton from '../../../Components/Button/CommonButton';
import ProfileImageUploader from '../../../Components/Image/ProfileImageUploader';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonModal from '../../../Components/Modal/CommonModal';
import { BaseUrlSrc } from './../../../App/Utility/Url';

const CategoryEditModal = () => {

    const { id } = useParams();

    const { showEditModal, setEditFormData, setShowEditModal, setDeleteCategoryForm, categoryDetails, setShowDeleteCategoryModal } = useCategoryStore();

    const [updateFrom, setUpdateFrom] = useState({
        id: 0,
        name: "",
        icon: ''
    })

    const deleteCategory = async () => {
        await setDeleteCategoryForm(id);
        await setShowDeleteCategoryModal(true);
    }

    useEffect(() => {
        setUpdateFrom({ id: id, name: categoryDetails?.data?.category_details?.name })
    }, [categoryDetails, id])

    const resetData=()=>{
         setEditFormData('id', "")
         setEditFormData('name', "")
         setEditFormData("icon","")
    }

    return (
        <div>
            <CommonModal
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                modalTitle="Edit Category"
                mainContent={
                    <>
                        <div className='flex justify-center'>
                            <div className='mt-s25 mb-s25'>
                            <ProfileImageUploader 
                             categoryImg={true} 
                             iImage={BaseUrlSrc + categoryDetails?.data?.category_details?.icon} 
                             imageName="icon" 
                             imageUploader={setEditFormData} 
                             /></div>
                        </div>

                        <form onSubmit={async (e) => {
                            e.preventDefault()
                            await setEditFormData('id', id)
                            await setEditFormData('name', updateFrom.name)
                            let value = await editCategoryData()
                            if (value){
                                 resetData()
                                 setShowEditModal(false)
                            }
                        }}>
                            <div className='mb-s20' >
                                <CommonInput required={true}
                                    value={updateFrom.name}
                                    type="text"
                                    label="Edit Category Name"
                                    onChange={(e) => { setUpdateFrom({ ...updateFrom, name: e.target.value }) }}
                                />
                            </div>
                            {/* <CommonInput type="text" label="Edit Description" rows="5" textarea="true" /> */}

                            <div className='flex justify-between mt-s20'>
                                <CommonButton onClick={() => { deleteCategory() }} btnLabel="Delete" colorType="warning" roundedFull="true" type="button" />
                                <CommonButton type="submit" btnLabel="Update" colorType="primary" roundedFull="true" />
                            </div>
                        </form>
                    </>
                }

            />
        </div>
    );
};

export default CategoryEditModal;