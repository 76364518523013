import React from 'react';
import { useState } from 'react';
import ProfileImageUploader from '../../../Components/Image/ProfileImageUploader';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonButton from './../../../Components/Button/CommonButton';
import useInstructorStore from './../../../App/Stores/InstructorStore';

const AddInstructorModal = () => {

  const { showAddInstructorModal, setShowAddInstructorModal, setShowInstructorAddPassModal } = useInstructorStore();

  const [id, setID] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [instructor_name, setInstructor_name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [address, setAddress] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault()
    // if(id && email && instructor_name && phone_number && address){
    //   const data={id: id,instructor_name: instructor_name,phone_number: phone_number,address: address,image: image }
    //   let value=await editInstructorDetail(data)
    //   if(value){
    //     setID("")
    //     setInstructor_name("")
    //     setEmail("")

    //     setPhone_number("")
    //     setImage("")
    //     setAddress("") 
    //     setShowEditModal(false)
    //   }
    // }

  }


  return (
    <>
      <CommonModal
        showModal={showAddInstructorModal}
        setShowModal={setShowAddInstructorModal}
        modalTitle="Add Instructor"
        mainContent={
          <>

            <div className='flex justify-center'>
              <div>
                <div className='my-s20'>
                  <ProfileImageUploader
                    iImage={image}
                    setImage={setImage}
                    categoryImg={false} />
                </div>
              </div>
            </div>

            <form onSubmit={formSubmit}>
              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  label="Name"
                  value={instructor_name}
                  // onChange={(e)=>{setInstructor_name(e.target.value)}} 
                  placeholder="Name" />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="email"
                  label="Email"
                  value={email}
                  placeholder="Email" />
              </div>


              <div className="mb-s20">
                <CommonInput
                  required={true}
                  value={phone_number}
                  //   onChange={(e)=>{setPhone_number(e.target.value)}} type="tel" 
                  label="Write Phone Number" placeholder="+44 000 000000" />
              </div>


              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  label="Address"
                  value={address}
                  // onChange={(e)=>{setAddress(e.target.value)}} 
                  placeholder="Address" />
              </div>

              <div className='flex justify-center mt-s20'>
                <CommonButton onClick={() => { setShowInstructorAddPassModal(true) }} type="submit" btnLabel="Next" colorType="primary" roundedFull="true" />
              </div>

            </form>

          </>
        }

      />
    </>
  );
};

export default AddInstructorModal;