import React from 'react';
import useLicenseStore, { addNewLicense } from '../../../App/Stores/LicenseStore';
import { Toastr } from '../../../App/Utility/UtilityFunctions';
import RichTextEditor from '../../../Components/TextEditor/RichTextEditor';
import { htmlToPlainText } from '../../../Utility/UtilityFunctions';
import CommonButton from './../../../Components/Button/CommonButton';
import CommonInput from './../../../Components/Input/CommonInput';
import SelectInput from './../../../Components/Input/SelectInput';
import CommonModal from './../../../Components/Modal/CommonModal';

const AddLicenseModal = () => {
    const {
        addForm,
        setAddForm,
        showAddLicenseModal,
        setShowAddLicenseModal } = useLicenseStore((state) => state);

    const HandleSubmit = async () => {
        if (
            addForm?.title &&
            addForm?.duration &&
            addForm?.license_type &&
            addForm?.price &&
            addForm?.price > 0 &&
            addForm?.price < 99999999 &&
            addForm?.type
        ) {
            console.log(addForm);
            if (htmlToPlainText(addForm?.details) === "") {
                // console.log("License Description is required !");
                Toastr({ message: "License Description is required !", type: "error" });
            } else {
                let addSuccess = await addNewLicense(addForm);
                if (addSuccess) {
                    setShowAddLicenseModal(false);
                    setTimeout(() => {
                        setAddForm({
                            title: "",
                            duration: "",
                            price: "",
                            details: "",
                            type: "",
                            license_type: ""
                        });
                        console.log("CLEAR !!");
                    }, 100);
                    console.log("add form cleared !!!!", addForm);
                }
            }
        }
    }

    const durationArray = [
        // all are distributed into months and then multiplied by 30.
        // ex: 1 year 6 months = 12+6 = 18 months = 18*30 = 540 days
        // ex: 1 year 0 months = 12 months = 12*30 = 360 days
        {
            title: "3 Months",
            value: 90,
            selected: addForm?.duration === 90 ? true : false
        },
        {
            title: "6 Months",
            value: 180,
            selected: addForm?.duration === 180 ? true : false
        },
        {
            title: "1 Year ",
            value: 360,
            selected: addForm?.duration === 360 ? true : false
        },
        {
            title: "1 Year 6 Months",
            value: 540,
            selected: addForm?.duration === 540 ? true : false
        },
        {
            title: "2 Years",
            value: 720,
            selected: addForm?.duration === 720 ? true : false
        },
    ];

    const userTypeArray = [
        {
            title: "School",
            value: "school",
            selected: addForm?.type === "school" ? true : false
        },
        {
            title: "Instructor",
            value: "instructor",
            selected: addForm?.type === "instructor" ? true : false
        },
    ]

    const licenseTypeArray = [
        {
            title: "Basic",
            value: "basic",
            selected: addForm?.license_type === "basic" ? true : false
        },
        {
            title: "Premium",
            value: "premium",
            selected: addForm?.license_type === "premium" ? true : false
        },
        {
            title: "Listing",
            value: "listing",
            selected: addForm?.license_type === "listing" ? true : false
        },
    ]

    return (
        <>
            <CommonModal
                showModal={showAddLicenseModal}
                setShowModal={setShowAddLicenseModal}
                modalSpace={true}
                modalTitle="Add New License"
                mainContent={
                    <div className='over'>
                        <form onSubmit={(e) =>{
                            e.preventDefault();
                            HandleSubmit()}
                        }>
                            <div className="my-s20">
                                <CommonInput
                                    required={true}
                                    type="text"
                                    label="Title"
                                    placeholder="Title"
                                    max_input={50}
                                    value={addForm?.title}
                                    onChange={(e) => setAddForm({ ...addForm, title: e.target.value })}
                                />
                            </div>
                            <div className="mb-s20">
                                <SelectInput
                                    required={true}
                                    label="Choose Duration"
                                    placeholder="Choose Duration"
                                    dataArray={durationArray}
                                    selectOptionOnChange={(e) => setAddForm({ ...addForm, duration: parseInt(e) })}
                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    required={true}
                                    label="Select User Type"
                                    placeholder="User Type"
                                    dataArray={userTypeArray}
                                    selectOptionOnChange={(e) => setAddForm({ ...addForm, type: e })}
                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    required={true}
                                    label="Select License Type"
                                    placeholder="License Type"
                                    dataArray={licenseTypeArray}
                                    selectOptionOnChange={(e) => setAddForm({ ...addForm, license_type: e })}
                                />
                            </div>
                            <div className="mb-s20">
                                <CommonInput
                                    required={true}
                                    type="number"
                                    label="Price"
                                    className2=""
                                    placeholder="Price"
                                    value={addForm?.price}
                                    min_number={1}
                                    max_number={9999999999}
                                    max_input={10}
                                    onChange={(e) => setAddForm({ ...addForm, price: e.target.value })}
                                />
                            </div>

                            <div className="h-[297px] mt-5">
                                <RichTextEditor
                                    label={"Details"}
                                    placeholder="Write how to..."
                                    required={true}
                                    value={addForm?.details}
                                    // onChange={(e) => setRichText(e)}
                                    onChange={(e) => setAddForm({ ...addForm, details: e })}
                                />
                            </div>

                            <div className='flex justify-center'>
                                <CommonButton
                                    type="submit"
                                    btnLabel="Add"
                                    colorType="primary"
                                    roundedFull="true"
                                    // onClick={() => { HandleSubmit() }}
                                />
                            </div>

                        </form>
                    </div>
                }

            />
        </>
    );
};

export default AddLicenseModal;
