import React from 'react'
import CommonModal from './../../../Components/Modal/CommonModal';
import useInstructorStore from './../../../App/Stores/InstructorStore';
import CommonButton from './../../../Components/Button/CommonButton';
import { iProfile } from '../../../App/Utility/source';
import BookingDetails from './../BookingDetails';

function UpcomingClassroomBookingModal() {

    const { showUpcomingClassroomBookingModal, setShowUpcomingClassroomBookingModal } = useInstructorStore();

    return (
        <div>
            <CommonModal
                showModal={showUpcomingClassroomBookingModal}
                setShowModal={setShowUpcomingClassroomBookingModal}
                modalTitle="Upcoming Classroom Booking"
                mainContent={
                    <>
                        <div className=" mt-s35">

                            <div className="flex">
                                <img className="w-s88 h-s88 rounded-full mb-s18" src={iProfile} alt="" />
                                <div className="ml-s15 mt-25">
                                    <div className="font-fw600 text-fs20 text-cHighlighted mb-s2 leading-9">Denmark Driving School</div>
                                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">CVR:123456</div>
                                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">@denmarkdrivingschool@gmail.com</div>
                                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">joined:5 jun 2022</div>
                                </div>
                            </div>

                            <div className="py-s20 px-0">
                              <hr />
                            </div>


                            <span >Booking Details</span>
                            <div className='bg-cBackgroundAndCategory rounded-br10 p-s20 mt-s10'>
                             <BookingDetails Status="Status" result="Accepted"/>
                             <BookingDetails Status="Category" result="A1"/>
                             <BookingDetails Status="Lesson Type" result="Classroom"/>
                             <BookingDetails Status="Lesson Name" result="Training to ride two-wheeler"/>
                             <BookingDetails Status="Classroom Address" result="Thorsvej 32A, 8680 Ry, Denmark"/>
                             <BookingDetails Status="Start Date" result="21 Nov, 2022"/>
                             <BookingDetails Status="Start Time" result="08:00  - 09:00"/>
                             <BookingDetails Status="Students" result="22/22 Students"/>
                             
                            </div>
                        </div>

                        <div className='flex justify-between mt-s20'>
                            <CommonButton onClick={() => { setShowUpcomingClassroomBookingModal(false) }} type="submit" btnLabel="Cancel" colorType="warning" roundedFull="true" />
                            <CommonButton onClick={() => { setShowUpcomingClassroomBookingModal(false) }} type="submit" btnLabel="Start Lesson" colorType="primary" roundedFull="true" />
                        </div>
                    </>
                }

            />
        </div>
    )
}

export default UpcomingClassroomBookingModal
