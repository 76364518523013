import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { TimePicker } from 'antd';
import moment from 'moment/moment';

const CommonTimePicker = ({
    format='HH:mm',
    showNow=false,
    size='large',
    value="00:00",
    onChange,
    label="",
    required=false
}) => {
    return (
        <div>
              <div className={`text-cHighlighted text-fs14 font-fw600 mb-s15 w-full ${required && 'req-field'}`}>{label}</div>
            <TimePicker
                style={{ width: '100%', backgroundColor: "#F4F4F4" }}
                format={format}
                showNow={showNow}
                size={size}
                value={moment(value, "HH:mm")}
                suffixIcon={<DownOutlined />}
                onSelect={onChange} 
                />
        </div>
    );
};

export default CommonTimePicker;