import React, { useEffect } from 'react';
import useStudentStore, { studentFindSchoolFilterOptionsFn, studentFindSchoolIndex } from '../../../App/Stores/StudentStore';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SelectMultipleCombo from '../../../Components/SelectMultipleCombo';

const StudentSchoolFilterModal = () => {

    const {
        setShowStudentSchoolFilterModal,
        showStudentSchoolFilterModal,
        studentSchoolCategoryArray,
        studentFindSchoolFilterValue,
        setStudentSchoolFilterAllRatting,
        studentSchoolFilterAllRatting
    } = useStudentStore();

    const [checked, setChecked] = React.useState([true, false]);
    // const [category, setCategory] = React.useState([]);
    // const [city, setCity] = React.useState([]);

    const [filterData, setFilterData] = React.useState({
        categories: [],
        rate: [],
        cities: []
    });

    const [Ratting1, setRatting1] = React.useState(false);
    const [Ratting2, setRatting2] = React.useState(false);
    const [Ratting3, setRatting3] = React.useState(false);

    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked]);
    };

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        await studentFindSchoolFilterOptionsFn()
    }

    //console.log("studentFindSchoolFilterValue",studentFindSchoolFilterValue)

    const categoryArray = studentFindSchoolFilterValue?.categories?.map(item => {
        return { label: item.name, value: item.id }
    })

    const cityArray = studentFindSchoolFilterValue?.cities?.map(item => {
        return { label: item.name, value: item.id }
    })



    return (
        <div>
            <CommonModal
                showModal={showStudentSchoolFilterModal}
                setShowModal={setShowStudentSchoolFilterModal}
                modalTitle="set Filter"
                mainContent={
                    <>
                        <div className="mb-s20">
                            <SelectMultipleCombo
                                label='Select Category'
                                selectedValue={filterData?.categories ?? []}
                                onChange={(valueArray) => {
                                    // console.log(value);
                                    // let s_array = [];
                                    // s_array.push(value);                                        
                                    setFilterData({ ...filterData, categories: valueArray });

                                }}
                                optionArray={categoryArray}

                            />
                        </div>

                        <div className="mb-s20">
                            <SelectMultipleCombo
                                label='Select City'
                                selectedValue={filterData?.cities ?? []}
                                onChange={(valueArray) => {
                                    // console.log(value);
                                    // let s_array = [];
                                    // s_array.push(value);                                        
                                    setFilterData({ ...filterData, cities: valueArray });

                                }}
                                optionArray={cityArray}

                            />
                        </div>

                        <span className='text-fs14 text-cBlack font-fw600 mb-s10'>Select Rating</span>


                        <div className='flex mt-s10'>

                            <div className={`mr-s10 ${Ratting1 ? 'bg-cInvoiceLesson' : 'bg-cCarousel'}  rounded-br100 px-s10`}>
                                <div className='mt-s5 ml-s10'><FormControlLabel
                                    label="3.0 Star"
                                    control={<Checkbox
                                        checked={Ratting1}
                                        onChange={() => {
                                            setStudentSchoolFilterAllRatting([...studentSchoolFilterAllRatting, 3])

                                            studentSchoolFilterAllRatting?.map(item => {
                                                if (item === 3) {
                                                    const a = studentSchoolFilterAllRatting.filter(value => value !== 3)
                                                    setStudentSchoolFilterAllRatting(a)
                                                }
                                            })

                                            setRatting1(!Ratting1)
                                        }}
                                    />}
                                /></div>
                            </div>

                            <div className={`mr-s10 ${Ratting2 ? 'bg-cInvoiceLesson' : 'bg-cCarousel'}  rounded-br100 px-s10`}>
                                <div className='mt-s5 ml-s10'><FormControlLabel
                                    label="4.0 Star"
                                    control={<Checkbox
                                        checked={Ratting2}
                                        onChange={() => {
                                            setRatting2(!Ratting2)
                                            setStudentSchoolFilterAllRatting([...studentSchoolFilterAllRatting, 4])

                                            studentSchoolFilterAllRatting?.map(item => {
                                                if (item === 4) {
                                                    const a = studentSchoolFilterAllRatting.filter(value => value !== 4)
                                                    setStudentSchoolFilterAllRatting(a)
                                                }
                                            })
                                        }}
                                    />}
                                /></div>
                            </div>

                            <div className={`mr-s10 ${Ratting3 ? 'bg-cInvoiceLesson' : 'bg-cCarousel'}  rounded-br100 px-s10`}>
                                <div className='mt-s5 ml-s10'><FormControlLabel
                                    label="5.0 Star"
                                    control={<Checkbox
                                        checked={Ratting3}
                                        onChange={() => {
                                            setRatting3(!Ratting3)
                                            setStudentSchoolFilterAllRatting([...studentSchoolFilterAllRatting, 5])

                                            studentSchoolFilterAllRatting?.map(item => {
                                                if (item === 5) {
                                                    const a = studentSchoolFilterAllRatting.filter(value => value !== 5)
                                                    setStudentSchoolFilterAllRatting(a)
                                                }
                                            })
                                        }}
                                    />}
                                /></div>
                            </div>

                        </div>

                        {/* <CommonCheckBox/> */}
                        <div className='flex justify-center mt-s20'>
                            <CommonButton
                                btnLabel='Apply'
                                onClick={() => {
                                    setFilterData({ ...filterData, rate: studentSchoolFilterAllRatting });

                                    const body = {
                                        categories:JSON.stringify(filterData?.categories),
                                        rate: JSON.stringify(filterData?.rate),
                                        cities: JSON.stringify(filterData?.cities)
                                    }


                                    const success = studentFindSchoolIndex(body)
                                    if (success) {
                                        setShowStudentSchoolFilterModal(false)
                                        setFilterData({ categories: [] });
                                        setFilterData({ rate: [] });
                                        setFilterData({ cities: [] });
                                        setRatting1(false)
                                        setRatting2(false)
                                        setRatting3(false)
                                    }
                                }}
                            />

                        </div>
                    </>
                }
            />
        </div>
    );
};

export default StudentSchoolFilterModal;