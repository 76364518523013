import React from 'react';

const BookingDetails = ({Status,result}) => {
    return (
        <div className='flex justify-between pb-s20'>
            <span className='text-fs14 font-fw400 text-cHighlighted'>{Status}</span>
            <span className='text-fs14 font-fw600 text-cHighlighted'>{result}</span>
        </div>
    );
};

export default BookingDetails;