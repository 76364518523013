import React, { useEffect, useState } from "react";
import useSettingsStore, { getTermsConditionsIndex } from "../../../App/Stores/SettingsStore";

import {
  iAddItem
} from "../../../App/Utility/source";
import CommonButton from "../../../Components/Button/CommonButton";
import Settings from "../Settings";
import TermsAndConditionCard from "./TermsAndConditionCard";

const TermsAndCondition = () => {

  const { setShowWriteTermsAndConditionModal, TermsConditionList } = useSettingsStore();

  useEffect(() => {
    fetchTermsConditions()
  }, [])

  const fetchTermsConditions = async () => {
    await getTermsConditionsIndex()
  }

  //console.log("TermsConditionList", TermsConditionList)

  return (
    <Settings>
      <div className="flex justify-between items-center">
        <div>
          <h1 className="font-semibold text-fs18">GDPR & Terms</h1>
        </div>
        <div className="contents cursor-pointer w-s250">
          <CommonButton
            colorType="primary"
            btnLabel="GDPR & Terms"
            roundedFull="true"
            onClick={() => {
              setShowWriteTermsAndConditionModal(true);
            }}
            icon={
              <div className="mr-s5">
                <img className="w-s20 h-s15" src={iAddItem} alt="" />
              </div>
            }
          />
        </div>
      </div>
      <hr className="my-s10" />
      <div className="grid gap-x-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
        {
          TermsConditionList?.data?.length > 0 ? (
            TermsConditionList?.data?.map((item, index) => (
              <TermsAndConditionCard key={index} data={item} />
            ))
          ) : <span className="my-s20">No Terms & Conditions Found</span>
        }
      </div>
    </Settings>
  );
};

export default TermsAndCondition;
