import React from 'react';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';
import InstructorCard from './../../../Components/Card/InstructorCard';
import useSchoolStore from './../../../App/Stores/SchoolStore';

const InstructorExpertiseAreaModal= () => {

    const {showExpertiseAreaModal,setShowExpertiseAreaModal}=useSchoolStore()

    return (
        <>
            <CommonModal
            showModal={showExpertiseAreaModal}
            setShowModal={setShowExpertiseAreaModal}  
            modalTitle="Edit Expertise Areas"
            mainContent={
               <>
                <div className='mt-s50 '>
                <div className='flex justify-between flex-wrap mb-s20'> 
                  <InstructorCard/> 
                  <InstructorCard/> 
                  <InstructorCard/>
                  <InstructorCard/> 
                  <InstructorCard/> 
                  <InstructorCard/>
                  <InstructorCard/> 
                  <InstructorCard/> 
                  <InstructorCard/>
                </div>
                <div className='flex justify-center'>
                    <CommonButton type="submit" btnLabel="Save Changes" colorType="primary" roundedFull="true" />
                </div>
                </div>
               </>
            }
                 
            />
        </>
    );
};

export default InstructorExpertiseAreaModal;