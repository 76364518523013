import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import useLayoutStore from '../../App/Stores/LayoutStore';
import useLicenseStore, { getLicenseIndex } from '../../App/Stores/LicenseStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonTitle from '../../Components/Title/CommonTitle';
import { iAddItem, iBlueListGroup2, iGroup, iGroupGrid, iGroupList2 } from './../../App/Utility/source';
import CommonButton from './../../Components/Button/CommonButton';
import PremiumCard from './../../Components/Card/PremiumCard';


const License2 = () => {
  const { setBarTitle } = useLayoutStore();
  const location = useLocation();
  const { setShowAddLicenseModal, licenseIndex, setShowEditLicense ,setLicenseDetailsSearchKey,setLicenseDetailsPageUrl} = useLicenseStore()

  useEffect(() => {
    getLicenseIndex();

    window.scrollTo(0, 0);
    PageTitle("License");
    setBarTitle("License");
  }, []);
  return (
    <div className='mx-s12 md:mx-s32'>
      <div className='flex justify-between'>
        <CommonTitle title="License" />
        <div className='flex mt-s20'>
          <NavLink to="/license"><img className='w-s25 h-s25 mr-s15' src={location.pathname === "license" ? iGroupGrid : iGroup} alt="" /></NavLink>
          <NavLink to="/license/list"><img className='w-s25 h-s25' src={location.pathname === "license/list" ? iBlueListGroup2 : iGroupList2} alt="" /></NavLink>
        </div>
      </div>

      <div className="overflow-hidden p-5 rounded-lg bg-cBrandColor2">
        <div className='flex flex-row-reverse justify-between'>
          {/* <CommonSearchBox /> */}
          <CommonButton
            colorType="primary"
            btnLabel="Add License"
            roundedFull="true"
            onClick={() => { setShowAddLicenseModal(true) }}
            icon={<div className="mr-s5"><img className="w-s20 h-s15" src={iAddItem} alt="" /></div>}
          />
        </div>

        <div className="py-5 font-bold text-fs24">School's License</div>

        <div className='grid grid-cols-3 gap-5' 
        onClick={()=>{
          setLicenseDetailsSearchKey("")
          setLicenseDetailsPageUrl("")
        }}
        >
          {licenseIndex?.data?.length > 0 ? licenseIndex?.data?.map((item, index) => item?.type === "school" ? <PremiumCard data={item} key={index} editOption="true" onClick={() => { setShowEditLicense(true) }} /> : ""
          )
            : "No License Found !"}
        </div>

        <div className="py-5 font-bold text-fs24">Instructor's License</div>

        <div className='grid grid-cols-3 gap-5' onClick={()=>{
          setLicenseDetailsSearchKey("")
          setLicenseDetailsPageUrl("")
          }}>
          {licenseIndex?.data?.length > 0 ? licenseIndex?.data?.map((item, index) => item?.type === "instructor" ? <PremiumCard data={item} key={index} editOption="true" onClick={() => { setShowEditLicense(true) }} /> : ""
          )
            : "No License Found !"}
        </div>
      </div>
    </div>
  );
};

export default License2;