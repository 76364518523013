import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useSettingsStore, { editDeleteMessage } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";
import RichTextEditor from "../../../Components/TextEditor/RichTextEditor";

const EditDeleteMessageModal = () => {

  const {showEditDeleteMessage,setShowEditDeleteMessage,setShowDeleteMessageModal,deleteMessageDetail} = useSettingsStore();
  
  const [addForm, setAddForm] = useState({
    id:'',
    content: "",
    app_type:""
  });

  const formSubmit = async (e) => {
    e.preventDefault();
console.log("kkkk")
    if (addForm?.id && addForm?.content ) {
      let addSuccess = await editDeleteMessage(addForm)
      if (addSuccess) {
        await setAddForm({id:'',content: "",app_type:""});
        await setShowEditDeleteMessage(false)
      }
    }
  };

  useEffect(() => {
    setAddForm({
       id:deleteMessageDetail?.id,
       content: deleteMessageDetail?.content,
       app_type:deleteMessageDetail?.app_type
    })
  }, [deleteMessageDetail])

  //console.log("deleteMessageDetail",deleteMessageDetail)

  return (
    <div>
      <CommonModal
        showModal={showEditDeleteMessage}
        setShowModal={setShowEditDeleteMessage}
        modalTitle="Edit Message"
        mainContent={
          <>
           <form onSubmit={formSubmit}>
           
              <div className="my-s20">
                <RichTextEditor
                   label="Write Content"
                   required={true}
                   placeholder="Write your business Terms & Condition..."
                   value={addForm.content}
                   onChange={(e) => setAddForm({ ...addForm, content: e })}
                 />
              </div>

              <div className="flex justify-center">
                {/* <div>
                  <CommonButton
                    colorType="danger"
                    btnLabel="Delete"
                    roundedFull="true"
                    //width="w-s280"
                    //className1="h-[48px]"
                    onClick={() => {
                      setShowDeleteMessageModal(true);
                    }}
                  />
                </div> */}
                <div className="mt-s40">
                  <CommonButton
                    type="submit"
                    colorType="primary"
                    btnLabel="update"
                    roundedFull="true"
                  />
                </div>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditDeleteMessageModal;
