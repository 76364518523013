import React from 'react';
import { Outlet } from 'react-router-dom';

const SettingsParent = () => {
    return (
        <>
          <Outlet/>  
        </>
    );
};

export default SettingsParent;