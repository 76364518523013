import React from 'react';
import CommonTitle from './../../../Components/Title/CommonTitle';
import { NavLink, useParams } from 'react-router-dom';
import CommonCard from './../../../Components/Card/CommonCard';
import CommonDetailPage from '../../../Components/CommonDetailPage/CommonDetailPage';
import CommonTable from '../../../Components/Table/CommonTable';
import { t } from 'i18next';
import StudentSchoolCategoryTableRow from '../../../Components/Table/Student/StudentSchoolCategoryTableRow';
import BackLink from '../../../Components/Pagination/BackLink';
import useStudentStore, { studentFindSchoolInfoFn } from '../../../App/Stores/StudentStore';
import { useEffect } from 'react';

const StudentSchoolProfile = () => {

    const { studentFindSchoolDetails, setStudentSchoolMomsPrice } = useStudentStore();

    const student_school_category = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Lessons") },
        { index: 4, name: t("Total Hours") },
        { index: 5, name: t("Price") },
        { index: 5, name: t("Status") },
    ];

    const { student_id, school_id } = useParams();

    const school_Details = {

    }

    useEffect(() => {
        fetchSchoolInfo()

    }, [school_id])

    const fetchSchoolInfo = async () => {
        await studentFindSchoolInfoFn(school_id)
    }

    //console.log("studentFindSchoolDetails", studentFindSchoolDetails?.moms_percentage)

    return (
        <div className='mx-s12 md:mx-s32'>
            <CommonTitle title="Student School Profile" >
                <BackLink linksArray={[
                    { label: "Student", linkTo: "/student" },
                    { label: "Student Details", linkTo: `/student/details/${student_id}` },
                    { label: "Student School", linkTo: `/student/details/${student_id}/school` },
                    { label: "Student School Profile", linkTo: `` }
                ]} />
            </CommonTitle>

            <div className="bg-cBrandColor2 rounded-br20 py-s20 px-s20 overflow-hidden">

                {studentFindSchoolDetails?.school && <CommonDetailPage
                    edit={false}
                    data={studentFindSchoolDetails}
                    // showCommonEditModal={() => { setShowEditSchoolModal(true) }}
                    description={true}
                />}

                <div className="py-s20 px-1">
                    <hr />
                </div>

                <CommonTable
                    headers={student_school_category}
                    tableTitle="Categories"
                    shoSearchBox={false}
                    pagination={false}
                    showPageCountText={false}
                    showPagination={false}
                    items={
                        <>
                            {studentFindSchoolDetails?.categories?.length > 0 ?
                                studentFindSchoolDetails?.categories?.map((item, index) => (
                                    <StudentSchoolCategoryTableRow key={index} data={item} index={index}
                                        moms_percentage={studentFindSchoolDetails?.moms_percentage}
                                    />
                                )) : <tr className='w-full'>
                                    <th colSpan={5} className="py-s10">
                                        No Data Found !
                                    </th>
                                </tr>
                            }
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default StudentSchoolProfile;