import React from "react";
import useSettingsStore from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";

const DeleteDltMessageModal = () => {
  const { showDeleteMessageModal, setShowDeleteMessageModal } =
    useSettingsStore();
  return (
    <div className="">
      <CommonModal
        showModal={showDeleteMessageModal}
        setShowModal={setShowDeleteMessageModal}
        modalTitle="Confirmation"
        widthClass="w-s591"
        mainContent={
          <>
            <div className="">
              <div className="">
                {/* <ProfileImageUploader iImage={iImageIogo} /> */}
                <h1 className="text-center mb-s22 my-5">
                  Are you sure you want to delete this Message.
                </h1>
              </div>
              <div className="flex justify-center">
                <CommonButton
                  onClick={() => {}}
                  btnLabel="Delete"
                  colorType="danger"
                  roundedFull="true"
                  //width="w-s172"
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default DeleteDltMessageModal;
