import React from 'react';
import useSchoolStore from './../../../App/Stores/SchoolStore';
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonInput from './../../../Components/Input/CommonInput';
import CommonButton from './../../../Components/Button/CommonButton';
import SelectInput from './../../../Components/Input/SelectInput';

const AddClassDetailModal = () => {

    const { showAddClassModal, setShowAddClassModal } = useSchoolStore()

    return (
        <div>
            <CommonModal
                showModal={showAddClassModal}
                setShowModal={setShowAddClassModal}
                modalTitle="Add Class"
                mainContent={
                    <>

                        <from>
                            <div>
                                <div className2="mb-s20">
                                    <SelectInput label="Select Category" placeholder="Select Category" />
                                </div>

                                <div className2="mb-s20">
                                    <SelectInput label="Select Lesson" placeholder="This is Lesson Title" />
                                </div>
                                
                                <div className2="mb-s20">
                                <SelectInput label="Select Classroom"  placeholder="3" />
                                </div>

                                <div className2="mb-s20">
                                <CommonInput label="Date" type="date" />
                                </div>
                                <div className='flex justify-between'>
                                    <CommonInput label="Time" className2="w-s300" type="time" />
                                    <CommonInput label="Time" className2="w-s300" type="time" />
                                </div>
                            </div>

                            <div className='flex justify-between mt-s40'>
                                <CommonButton type="submit" btnLabel="Delete User" colorType="warning" roundedFull="true" />
                                <CommonButton type="submit" btnLabel="Add Class" colorType="primary" roundedFull="true" />
                            </div>

                        </from>

                    </>
                }

            />
        </div>
    );
};

export default AddClassDetailModal;