import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import useSettingsStore, { cityToggleStatus } from '../../../App/Stores/SettingsStore';
import { iDeleteIcon, iSettingsEditIcon } from '../../../App/Utility/source';
import CommonEmptyStatus from '../../../Components/CommonEmptyStatus/CommonEmptyStatus';
import GreenSwitch from './../../../Components/Switch/GreenSwitch';

const CityList = ({ item }) => {
    const { setCityDeactivateID, setShowCityDeactivateModal, setShowCityDeleteModal, setDeleteCityID, setShowEditCityModal, setCityDetail } = useSettingsStore();
    
  const [enabled, setEnabled] = useState(false);

  const HandleDeactivate = async () => {
    await setCityDeactivateID(item?.id)
    if (item?.is_active === 1) {
      await setShowCityDeactivateModal(true)
    } else {
      let activateSuccess = await cityToggleStatus(item?.id)
      if (activateSuccess) setEnabled(true);
    }
  }

  useEffect(() => {
    setEnabled(item?.is_active);
  }, [item]);

  //console.log("item?.is_active",item?.is_active)

    return (
        <div>
            <div>
                <div className="flex justify-between">
                    <div>
                        <h1>{item?.name ? item?.name : <CommonEmptyStatus/>}</h1>
                    </div>
                    <div className="flex items-center justify-end">

                        <div className='mr-s10'>
                            <GreenSwitch 
                              enabled={enabled} 
                              setEnabled={() => 
                              HandleDeactivate()} 
                              width= 'w-s30'
                              height='h-s16'
                              toggleButtonWidth="w-s12"
                              toggleButtonHeight="h-s10"
                              /></div>
                        {/* <img
                            src={iDeleteIcon}
                            alt=""
                            onClick={async () => {
                                await setDeleteCityID(item?.id)
                                setShowCityDeleteModal(true);
                            }}
                            className="w-s18 object-cover ml-s5 mr-s10 cursor-pointer"
                        /> */}

                        <img
                            src={iSettingsEditIcon}
                            alt=""
                            onClick={async (e) => {
                                e.preventDefault();
                                await setCityDetail(item);
                                setShowEditCityModal(true);
                            }}
                            className="w-s20 h-s20 object-cover ml-s5 mr-s10 cursor-pointer"
                        />
                    </div>
                </div>

                <div className="my-s8"><hr /></div>
            </div>
        </div>
    );
};

export default CityList;