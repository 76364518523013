import React from 'react'
import { useNavigate } from 'react-router-dom';
import {iProfile} from '../../../App/Utility/source';

const WorkPlaceTableRow= (data) => {

const navigate=useNavigate();

  return (
    <>
    <tr onClick={()=>{navigate("")}} className={`border-b cursor-pointer border-collapse border-cNmSelect`} >
      <th className='font-normal py-s10 border-r text-left pl-s10 m-2'>
       <span className='mr-s12 dm:mrs12 md:mr-0 '>1</span>
      </th>
  
      <td className='border-r-[1px] py-s10 text-left px-s10 max-w-[100px] min-w-[160px] '>
        <div className='flex min-w-[80px] sm:flex-col md:flex-row'>
        <div className='ml-s4'><img className='w-w44 h-h44 rounded-full grow-0' src={iProfile} alt=""/></div>
        <div className='my-s8 font-fw600 ml-s8 justify-center text-cMainBlack lg:text-fs14 sm:text-fs12 '>DenMark Driving </div>
        </div>
      </td>

      <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
      <span className='font-fw400 text-cImportantText text-fs14'>12345678</span>
      </td>

      <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
      <span className='font-fw400 text-cImportantText text-fs14'>denmark@gmail.com</span>
      </td>

      <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
       <span className='font-fw400 text-cImportantText text-fs14'>+44 000 0000</span>
      </td>

      <td className='py-s10 border-r-[1px] px-s10 text-center'>        
      <span></span>
       <div className='ml-s10 '>
       <span className='text-fs14 font-fw500 text-cHighlighted'>Premium</span>
       <div className='font-fw400 text-cImportantText text-fs14'>(150 days remaining)</div> 
      </div>
      </td>
    </tr>
    </>
  )
}

export default WorkPlaceTableRow;