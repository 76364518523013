import axios from "axios";
import { useEffect } from "react";
import create from "zustand";
import {
  addNewBannerUrl,
  deleteBannerUrl,
  editBannerUrl,
  getAllBannerListUrl,
  toggleStatusBannerUrl,
} from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useBannerStore = create((set) => ({
  // Banner api calls
  allBannerList: [],
  setAllBannerList: (value) => set({ allBannerList: value }),

  bannerDetails: [],
  setBannerDetails: (value) => set({ bannerDetails: value }),

  getSingleBannerDetails: {},
  setGetSingleBannerDetails: (value) => set({ getSingleBannerDetails: value }),

  deleteBanner: { id: "" },
  setDeleteBanner: (value) =>
    set((state) => (state.deleteBanner["id"] = value)),

    BannerDeactivateID: [],
    setBannerDeactivateID: (value) => set({ BannerDeactivateID: value }),


  AddFormData: {
    name: "",
    content: "",
    app_type: '',
    image: '',
  },
  setAddFormData: (name, value) =>
    set((state) => (state.AddFormData[name] = value)),

  EditFormData: {
    id: "",
    name: "",
    content: "",
    app_type: '',
    image: '',
  },
  setEditFormData: (name, value) =>
    set((state) => (state.EditFormData[name] = value)),


  // bannerFormData: { app_type: "", name: "", content: "", image: "" },
  // setBannerFormData: (name, value) =>
  //   set((state) => (state.bannerFormData[name] = value)),

  // Modals
  showDeleteBannerModal: false,
  setshowDeleteBannerModal: (value) => set({ showDeleteBannerModal: value }),

  showEditBannerModal: false,
  setshowEditBannerModal: (value) => set({ showEditBannerModal: value }),

  showBannerDetailsModal: false,
  setshowBannerDetailsModal: (value) => set({ showBannerDetailsModal: value }),

  showAddBannerModal: false,
  setShowAddBannerModal: (value) => set({ showAddBannerModal: value }),
 
  showBannerDeactivateModal: false,
  setShowBannerDeactivateModal: (value) => set({ showBannerDeactivateModal: value }),
}));

export default useBannerStore;

const {AddFormData, bannerFormData, EditFormData, setGetSingleBannerDetails } = useBannerStore.getState();

// get all banner from api
export const getAllBannerList = async () => {
  try {
    setLoading(true);
    const res = await axios.get(getAllBannerListUrl);
    console.log("allBanner res.data:::: ", res?.data?.data?.data);

    if (res?.data?.success) {
      //console.log("All Banner List:", res?.data?.data);
      useBannerStore.getState().setAllBannerList(res?.data?.data);
    } else {
      Toastr({ message: res.data.message, type: "success" });
    }
    setLoading(false);
    return;
  } catch (error) {
    console.log("allBanner: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return;
  }
};

//add new banner
export const createNewBanner = async (data) => {
  try {
    setLoading(true);
    const res = await axios.post(addNewBannerUrl, data);
    console.log("CreateBanner res.data:::: ", res?.data);
    if (res?.data?.success) {
      //Toastr({ message: res?.data?.message, type: "success" });
      await getAllBannerList();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res?.data?.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log(error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// delete a banner
export const deleteBannerData = async (id) => {
  try {
    setLoading(true);
    const res = await axios.post(deleteBannerUrl, { "id": id });
    console.log("deleteCategoryData res.data:::: ", res);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await getAllBannerList()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("deleteCategoryData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};



//edit banner
export const editBanner = async (body) => {

  try {
    setLoading(true);
    const res = await axios.post(editBannerUrl, EditFormData);
    console.log("editBanner res.data:::: ", res.data);

    if (res.data.success) {
      //Toastr({ message: res.data.message, type: "success" });
      await setGetSingleBannerDetails(res?.data?.data)
      await getAllBannerList()
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log(error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

//banner Toggle
export const bannerToggleStatus = async (id) => {
  try {
    setLoading(true);
    const res = await axios.post(toggleStatusBannerUrl, { "id": id });
    console.log("bannerToggleStatus:::: ", res);

    if (res.data.success) {
       await setGetSingleBannerDetails(res?.data?.data)
      await getAllBannerList()
      //Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("bannerToggleStatus: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};