import { t } from 'i18next';
import React, { useEffect } from 'react';
import useCategoryStore, { searchCategoryList } from '../../App/Stores/CategoryStore';
import useUtilityStore from '../../App/Stores/UtilityStore';
import { iAddItem } from '../../App/Utility/source';
import CommonButton from '../../Components/Button/CommonButton';
import CommonTable from '../../Components/Table/CommonTable';
import { getCategoryList } from './../../App/Stores/CategoryStore';
import useLayoutStore from './../../App/Stores/LayoutStore';
import { PageTitle } from './../../App/Utility/UtilityFunctions';
import CategoryTableRow from './../../Components/Table/Category/CategoryTableRow';
import CommonTitle from './../../Components/Title/CommonTitle';

const Category = () => {

  const { setAddingNewCategory, categoryListAll, setCategoryPageUrl, categoryPageUrl, setSearchCategoryKey, searchCategoryKey } = useCategoryStore();
  const { setLoadingSearch } = useUtilityStore.getState();

  const categoryHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    // { index: 3, name: t("Lesson") },
    { index: 3, name: t("School") },
    { index: 4, name: t("Instructors") },
    { index: 5, name: t("Students") },
  ];

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    PageTitle(t("Category"));
    setBarTitle("Category");
    fetchCategoryData()
  }, [setBarTitle]);

  const fetchCategoryData = async () => {
    await getCategoryList(categoryPageUrl);
  }

  //   console.log("categoryListAll", categoryListAll)

  useEffect(() => {
    if (searchCategoryKey) {
      setLoadingSearch(true);
      setTimeout(() => {
        searchCategoryList(searchCategoryKey);
        setLoadingSearch(false);
      }, 350);
    } else {
      fetchCategoryData();
    }
  }, [searchCategoryKey]);

  return (
    <div className='mx-s12 md:mx-s32'>
      <CommonTitle title="Categories" ></CommonTitle>
      <div className='overflow-hidden bg-cBrandColor2 rounded-br10'>
        <CommonTable
          headers={categoryHeaders}
          tableTitle=""
          paginationObject={categoryListAll?.data}
          // paginationOnClick={(url) => {
          //   setCategoryPageUrl(url)
          //   console.log("url", url);
          //   getCategoryList(url)
          // }}
          totalResult={false}
          showPagination={false}
          shoSearchBox={true}
          withClearSearch={true}
          searchValue={searchCategoryKey}
          onSearchClear={() => setSearchCategoryKey("")}
          searchOnChange={(e) => setSearchCategoryKey(e.target.value)}
          autoManageRow={true}
          TableRowComponent={CategoryTableRow}
          titleComponent={
            <div className='mb-s20'>
              <CommonButton
                colorType="primary"
                btnLabel="Add Category"
                roundedFull={true}
                width="w-[155px]"
                onClick={() => { setAddingNewCategory(true) }}
                icon={<div className="mr-s5"><img className="w-s20 h-s15" src={iAddItem} alt="" /></div>}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};
export default Category;


// const TakeItem = () => {
//   const { setCategoryTakeList, categoryTakeList, setSchoolPageUrl } = useCategoryStore();
//   return (
//     <div className=''>
//       <span className='pr-s10'>Show</span>
//       <select
//         value={ categoryTakeList.take ?  categoryTakeList.take : 10}
//         onChange={async (e) => {
//           setSchoolPageUrl("")
//           await setCategoryTakeList("take", e.target.value);
//           // if (schoolSearchKey) {
//           //   searchSchoolList("", "")
//           // } else {
//           //   getSchoolList("", filterData);
//           // }
//           getCategoryList()
//         }}
//         className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
//         <option
//           className='py-s10 text-cHighlightedTexts'
//           selected={categoryTakeList.take === 10}
//           value={10}
//         >10</option>

//         <option
//           className='py-s10 text-cHighlightedTexts'
//           selected={ categoryTakeList.take === 25}
//           value={25}
//         >25</option>
//         <option
//           className='py-s10 text-cHighlightedTexts'
//           selected={ categoryTakeList.take === 50}
//           value={50}
//         >50</option>
//         <option
//           className='py-s10 text-cMainBlack'
//           selected={ categoryTakeList.take === 100}
//           value={100}
//         >100</option>
//       </select>   <span className='pl-s10'>Entries</span>

//     </div>
//   )
// }