import React, { useState } from "react";
import useInstructorStore from "../../../App/Stores/InstructorStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";

function InstructorAddPassModal() {

const {showInstructorAddPassModal,setShowInstructorAddPassModal}=useInstructorStore()

  const [cpForm, setCpForm] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const handleCpChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCpForm({ ...cpForm, [name]: value });
  };

  return (
    <div>
      <CommonModal
        showModal={showInstructorAddPassModal}
        setShowModal={setShowInstructorAddPassModal}
        modalTitle="Instructor Add Password"
        mainContent={
          <>
            <form onSubmit={(e) => e.preventDefault()}>
              <hr className="mt-s16 mb-s8" />
              <div className="py-5">
                <CommonInput
                  label={("New Password")}
                  name="newPassword"
                  placeholder={("Type New Password")}
                  value={cpForm?.newPassword}
                  onChange={handleCpChange}
                  type="password"
                  togglePasswordBtn={true}
                  required={true}
                />
              </div>
              <CommonInput
                label={("Confirm New Password")}
                name="confirmNewPassword"
                placeholder={("Type New Password")}
                value={cpForm?.confirmNewPassword}
                onChange={handleCpChange}
                type="password"
                togglePasswordBtn={true}
                required={true}
              />

              <div className="flex flex-row justify-center pt-5">
                <CommonButton
                  roundedFull="true"
                  type="submit"
                  btnLabel={("Add Password")}
                  onClick={async () => {
                    console.log("working");
                  }}
                  colorType={
                    cpForm?.newPassword &&
                    cpForm?.confirmNewPassword &&
                    cpForm?.newPassword === cpForm?.confirmNewPassword
                      ? "primary"
                      : "basic"
                  }
                  isDisabled={
                    cpForm?.newPassword &&
                    cpForm?.confirmNewPassword &&
                    cpForm?.newPassword === cpForm?.confirmNewPassword
                      ? false
                      : true
                  }
                  width="w-40"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
}

export default InstructorAddPassModal;