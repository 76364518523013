import React, { useEffect } from "react";
import { useState } from "react";
import useSettingsStore from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import ProfileImageUploader from "../../../Components/Image/ProfileImageUploader";
import SelectInput from "../../../Components/Input/SelectInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import RichTextEditor from "../../../Components/TextEditor/RichTextEditor";
import { editTermsCondition } from './../../../App/Stores/SettingsStore';
import { BaseUrlSrc } from './../../../App/Utility/Url';

const EditTermsAndConditionModal = () => {
  const { showEditTermsAndConditionModal,
    setShowEditTermsAndConditionModal,
    TermsConditionDetail,
    setTermsConditionEditFormData,
    updateTermsData,
    TermsConditionEditFormData
  } = useSettingsStore();

  const [addForm, setAddForm] = useState({
    id: "",
    app_type: '',
    image: ''
  });


  const userArray = [
    {
      title: 'student',
      value: 'student',
      selected: addForm?.app_type === 'student' ? true : false
    },

    {
      title: 'instructor',
      value: 'instructor',
      selected: addForm?.app_type === 'instructor' ? true : false
    },
    {
      title: 'school',
      value: 'school',
      selected: addForm?.app_type === 'school' ? true : false
    },
  ]


  const TermsConditionEdit = async (e) => {
    e.preventDefault();
    // setTermsConditionEditFormData("content",addForm.content)
    setTermsConditionEditFormData("app_type", addForm.app_type)
    setTermsConditionEditFormData("id", addForm.id)

    if (addForm?.id && addForm?.app_type) {

      let addSuccess = await editTermsCondition()
      if (addSuccess) {
        await setAddForm({ description: "", app_type: "" });
        await setShowEditTermsAndConditionModal(false)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setAddForm({
        id: TermsConditionDetail[0]?.id,
        content: TermsConditionDetail[0]?.content,
        app_type: TermsConditionDetail[0]?.app_type, image: TermsConditionDetail[0]?.image
      })


      updateTermsData(TermsConditionDetail[0]?.content)
    }, 500);

  }, [TermsConditionDetail])

  return (
    <div>
      <CommonModal
        showModal={showEditTermsAndConditionModal}
        setShowModal={setShowEditTermsAndConditionModal}
        modalTitle="Update Terms & Conditions"
        mainContent={
          <>
            <form onSubmit={TermsConditionEdit}>
              <div className="flex justify-center my-s20">
                <ProfileImageUploader rounded={false} iImage={addForm?.image ? BaseUrlSrc + addForm?.image : ''} imageUploader={setTermsConditionEditFormData} imageName="image" />
              </div>
              <div className="">
                <SelectInput
                  required={true}
                  label="Choose User"
                  placeholder="Choose User"
                  dataArray={userArray}
                  selectOptionOnChange={(e) => setAddForm({ ...addForm, app_type: e })}
                />
              </div>

              <div className="my-s20 h-[285px]">
                <RichTextEditor
                  required={true}
                  label="Write Description"
                  textarea="true"
                  placeholder="Write here..."
                  onChange={(e) => updateTermsData(e)}
                  value={TermsConditionEditFormData?.content}
                />
              </div>
              <div className="flex justify-center mt-s20">
                <CommonButton
                  type="submit"
                  btnLabel="Update"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditTermsAndConditionModal;
