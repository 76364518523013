import React from 'react';
import { useState } from 'react';
import { iExpired, iProfile } from '../../../App/Utility/source';
import useCategoryStore from '../../../App/Stores/CategoryStore';
import GreenSwitch from '../../../Components/Switch/GreenSwitch';
import { iEdiItIcon } from '../../../App/Utility/source';
import { iNotification } from './../../../App/Utility/source';

const LicenseInstructorProfile = () => {

    const {setShowInstructorEditModal}=useCategoryStore();

    const [enabled,setEnabled]=useState(true)

    return (
        <>
        <div className="bg-cBackgroundAndCategory rounded-br10 py-s20 px-s15 h-s330 ">
        <div className='flex justify-between'>
          <div className="flex">
            <div className="">
                <img className="w-s88 h-s88 rounded-full mb-s18" src={iProfile} alt=""/>
                <div className="md:ml-s22">
                <GreenSwitch enabled={enabled} setEnabled={setEnabled} />
                <div className={`${enabled?'text-cPassed':'text-cFailed'} mt-s5 text-fs14 font-fw400`}>{enabled?'Active':'Deactivate'} </div> 
                </div>
            </div>
  
            <div className="ml-s15 mt-25">
              <div className="font-fw600 text-fs24 text-cHighlighted mb-s2 leading-9">Maruf Mawla</div>
              <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">denmark@gmail.com</div>
              <div className="font-fw500 text-fs12 text-cImportantText leading-3 pb-s6">+44 000 000000</div>
              <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">joined:5 jun 2022</div>
              <div className='flex'><img  src={iExpired} alt=""/><span className='ml-s10 mt-s5 text-fs12 font-fw400 text-cFailed'>License will be expired in 48 days</span></div>
              
              <div className='bg-cFailed rounded-full flex py-s15 px-s32 w-[193px] mt-s35'>
                <img src={iNotification} alt=""/>
                <span className='ml-s5 text-cBrandColor2 font-fw600 text-fs12'>Send Notification</span>
                </div>
           </div>
          </div>
          <div className='cursor-pointer w-s26 h-s26'><img className="" onClick={() => {setShowInstructorEditModal(true)}} src={iEdiItIcon} alt=""></img></div>

          </div>
        </div>
        </>
    );
};

export default LicenseInstructorProfile;







