/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useContactStore from "../../App/Stores/ContactStore";
import useDashboardStore, { getDashboardData, getDashboardPendingActivityData, getNewSignUpData } from "../../App/Stores/DashboardStore";
import useInstructorStore from "../../App/Stores/InstructorStore";
import useInvoiceStore from "../../App/Stores/InvoiceStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useSchoolStore from "../../App/Stores/SchoolStore";
import useStudentStore from "../../App/Stores/StudentStore";
import useUtilityStore from "../../App/Stores/UtilityStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import DashboardCard from "../../Components/Card/DashboardCard";
import NoDataRow from "../../Components/Others/NoDataRow";
import ActivitiesTableRow from "../../Components/Table/ActivitiesTableRow";
import InstructorTableRow from "../../Components/Table/Instructor/InstructorTableRow";
import NewLicenseTableRow from "../../Components/Table/NewLicenseTableRow";
import NewMessageTableRow from "../../Components/Table/NewMessageTableRow";
import SchoolTableRow from "../../Components/Table/School/SchoolTableRow";
import StudentTableRow from "../../Components/Table/Student/StudentTableRow";
import CommonTable from './../../Components/Table/CommonTable';


const Dashboard = () => {
  const { t } = useTranslation();

  const { setLoading } = useUtilityStore();

  const location = useLocation();

  const {
    dashboard_data,
    pending_activity_data,
    pendingClickable,
    setPendingClickable,
    newSignUpData,
    selected_option,
    setSelectedOption
  } = useDashboardStore();

  const { setBarTitle } = useLayoutStore();

  const [signUpOption, setSignUpOption] = useState("school");

  useEffect(() => {
    PageTitle(t("Dashboard"));
    setBarTitle("Dashboard");

    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);

    await getDashboardData();
    await getNewSignUpData("school");
    await getDashboardPendingActivityData(selected_option);

    setPendingClickable(true);
    setLoading(false);
  };


  const schoolHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("CVR") },
    { index: 4, name: t("Email") },
    { index: 5, name: t("Phone") },
    { index: 6, name: t("License") },
    { index: 7, name: t("Status") },
  ];


  const InstructorHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Email") },
    { index: 4, name: t("Phone") },
    { index: 45, name: t("Expertise Area") },
    { index: 5, name: t("License") },
    { index: 6, name: t("Status") },
    // { index: 5, name: t("Expertise Area") },
  ];

  const studentHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Email") },
    { index: 4, name: t("Phone") },
    { index: 5, name: t("Curriculum") },
    { index: 6, name: t("Status") },
  ];

  const newLicenseHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("From") },
    { index: 3, name: t("Email") },
    { index: 4, name: t("User Type") },
    { index: 5, name: t("Created on") },
    { index: 6, name: t("Last Action") },
  ];

  const newMessageHeaders = [
    { index: 1, name: "#" },
    { index: 2, name: "Name" },
    { index: 3, name: "Subject" },
    { index: 4, name: "Message" },
    { index: 5, name: t("Created on") },
    { index: 6, name: "Last Action" },
  ];

  const activitiesHeaders = [
    { index: 1, name: "#" },
    { index: 2, name: pendingClickable ? "From" : "Lesson Name" },
    { index: 3, name: "User Type" },
    { index: 4, name: t("Created on") },
    { index: 5, name: t("last Action User") },
    { index: 6, name: "Last Action" },
  ];

  const { new_purchase_licenses, new_contact_us } = dashboard_data;

  const sign_up_chip_options = [
    {
      title: `school (${dashboard_data?.new_signup?.new_school_count ?? 0})`,
      selected: signUpOption === "school" ? true : false,
      action: async () => {
        setLoading(true);
        await setSignUpOption("school");
        await getNewSignUpData("school");
        setLoading(false);
      },
    },
    {
      title: `instructor (${dashboard_data?.new_signup?.new_instructor_count ?? 0})`,
      selected: signUpOption === "instructor" ? true : false,
      action: async () => {
        setLoading(true);
        await setSignUpOption("instructor");
        await getNewSignUpData("instructor");
        setLoading(false);
      },
    },
    {
      title: `student (${dashboard_data?.new_signup?.new_student_count ?? 0})`,
      selected: signUpOption === "student" ? true : false,
      action: async () => {
        setLoading(true);
        await setSignUpOption("student");
        await getNewSignUpData("student");
        setLoading(false);
      },
    },

  ];

  const chip_options = [
    {
      title: `Invoice (${dashboard_data?.pending_activity?.invoice})`,
      selected: selected_option === "invoice" ? true : false,
      action: async () => {
        setLoading(true);
        await setSelectedOption("invoice");
        await getDashboardPendingActivityData("invoice");
        await setPendingClickable(true);
        setLoading(false);
      },
    },
    {
      title: `Classroom Lessons (${dashboard_data?.pending_activity?.classroom})`,
      selected: selected_option === "classroom" ? true : false,
      action: async () => {
        setLoading(true);
        await setSelectedOption("classroom");
        await getDashboardPendingActivityData("classroom");
        await setPendingClickable(false);
        setLoading(false);
      },
    },
    {
      title: `Driving Lessons (${dashboard_data?.pending_activity?.driving})`,
      selected: selected_option === "driving" ? true : false,
      action: async () => {
        setLoading(true);
        await setPendingClickable(false);
        await setSelectedOption("driving");
        await getDashboardPendingActivityData("driving");
        setLoading(false);
      },
    },
    {
      title: `External Lessons (${dashboard_data?.pending_activity?.external})`,
      selected: selected_option === "external" ? true : false,
      action: async () => {
        setLoading(true);
        await setPendingClickable(false);
        await setSelectedOption("external");
        await getDashboardPendingActivityData("external");
        setLoading(false);
      },
    },
  ];

  const {
    setInstructorSearchKey,
    resetInstructorFilterData,
    setInstructorTakeList,
    setInstructorPageUrl
  } = useInstructorStore();

  const {
    setSchoolSearchKey,
    setSchoolPageUrl,
    resetSchoolFilterData,
    setFilterSchoolActive,
    setSchoolTakeList
  } = useSchoolStore();

  const {
    resetStudentFilterData,
    setStudentPaginationURL,
    setStudentTakeList,
    setStudentSearch,
  } = useStudentStore();

  const {
    setInvoiceIndexData,
    resetInvoiceFilterData,
    setInvoicePageUrl,
    setInvoiceUserType,
    setInVoiceTake,
    setInvoiceSearchKey
  } = useInvoiceStore();

  const { setContactUsTakeList, setContactUsSearch, setContactUsPageUrl, setCustomDateShow, setFilterForm } = useContactStore();


  const InvoiceClearData = () => {
    if (location.pathname !== "/invoice") { setInvoiceIndexData({}) }
    resetInvoiceFilterData();
    setInvoicePageUrl("");
    setInvoiceUserType("all")
    setInVoiceTake(10)
    setInvoiceSearchKey("")
  }

  const StudentClearData = () => {
    resetStudentFilterData();
    setStudentPaginationURL("");
    setStudentTakeList("take", 10);
    setStudentSearch("")
  }

  const SchoolClearData = () => {
    setSchoolSearchKey("")
    setSchoolPageUrl("")
    resetSchoolFilterData();
    setFilterSchoolActive(false);
    setSchoolTakeList("take", 10);
  }

  const InstructorClearData=()=>{
    setInstructorSearchKey("")
    resetInstructorFilterData();
    setInstructorTakeList("take", 10);
    setInstructorPageUrl("");
  }

  const contactUsDataClear=()=>{
    setCustomDateShow(false);
    setFilterForm({
      status: "", // user type [Schools,Instructors, Students], for all use "" empty string
      is_reply: null,
      is_seen: null,
      date: "", //[weekly, monthly, custom]
      custom_date: "",
      start_date: "",
      end_date: "",
    });
    setContactUsTakeList("take", 10)
    setContactUsSearch("")
    setContactUsPageUrl("url", "")
  }


  return (
    <>
      <div className='text-fs28 font-fw600 mr-s300 w-w160 pb-s15 text-cBlack mt-s20 ml-s32'>Dashboard</div>
      <div className="overflow-hidden bg-cBrandColor2 rounded-br10 mx-s12 md:mx-s32">
        <div className="grid grid-cols-1 gap-5 px-5 pt-5 w-full sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4">

          <NavLink
            onClick={() => {SchoolClearData()}}
            to="/school">
            <DashboardCard title={'Total School'} number={dashboard_data?.schools?.total_school ?? 0} note={`Pending ${dashboard_data?.schools?.pending_schools ?? 0}, will expire ${dashboard_data?.schools?.expire_soon_schools ?? 0}`} />
          </NavLink>

          <NavLink to="/instructor" onClick={() => {InstructorClearData()}}>
            <DashboardCard

              title={'Total Instructors'} number={dashboard_data?.instructors ?? 0} note={''} />
          </NavLink>

          <NavLink
            onClick={() => { StudentClearData() }}
            to="/student">
            <DashboardCard title={'Total Student'} number={dashboard_data?.student ?? 0} note={''} />
          </NavLink>
          <NavLink
            onClick={InvoiceClearData}
            to="/invoice">
            <DashboardCard
              title={'(Total Invoices)'}
              number={dashboard_data?.invoices?.total_invoices ?? 0} note={`Disputable ${dashboard_data?.invoices?.total_dispute_invoices ?? 0}, Paid ${dashboard_data?.invoices?.total_paid_invoices ?? 0}, Unpaid ${dashboard_data?.invoices?.total_unpaid_invoices ?? 0}`} />
          </NavLink>
        </div>


        <div className="px-5 pt-5">
          <hr />
        </div>

        {/* //! New Sign up Request */}
        <CommonTable
          headers={signUpOption === "school" ? schoolHeaders : signUpOption === "instructor" ? InstructorHeaders : signUpOption === "student" ? studentHeaders : ""}

          shoSearchBox={false}
          showPagination={false}
          showPageCountText={false}

          seeAllOnClick={() => {
            if (signUpOption === "school") {
              SchoolClearData();
            }
            if (signUpOption === "instructor") {
              InstructorClearData()
            }
            if (signUpOption === "student") {
              StudentClearData();
            }
          }}
          seeAllText={"See All"}
          seeAllLink={signUpOption === "school" ? "/school" : signUpOption === "instructor" ? "/instructor" : signUpOption === "student" ? "/student" : "/"}

          showChip={true}
          chipArray={sign_up_chip_options}
          tableTitle={`New Sign up Request (${dashboard_data?.new_signup ? (dashboard_data?.new_signup?.new_school_count +
            dashboard_data?.new_signup?.new_instructor_count +
            dashboard_data?.new_signup?.new_student_count) : 0

            })`}

          autoManageRow={true}
          TableRowComponent={signUpOption === "school" ? SchoolTableRow : signUpOption === "instructor" ? InstructorTableRow : signUpOption === "student" ? StudentTableRow : ""}
          paginationObject={newSignUpData}
        />

        <div className="px-5 pt-5">
          <hr />
        </div>

        {/* //! New License Request */}
        <CommonTable
          search_loading={true}
          headers={newLicenseHeaders}
          tableTitle={`New License Request (${new_purchase_licenses?.length ?? 0})`}
          seeAllText={"See All"}
          seeAllOnClick={() => { InvoiceClearData() }}
          showPageCountText={false}
          showPagination={false}
          seeAllLink={"/invoice"}
          shoSearchBox={false}
          items={
            <>
              {
                new_purchase_licenses?.length > 0 ? new_purchase_licenses?.map((item, index) => (
                  <NewLicenseTableRow key={index} data={item} index={index} />
                ))
                  : <NoDataRow columnNumber={newLicenseHeaders?.length ?? 3} />
              }
            </>
          }
        />

        <div className="px-5 pt-5">
          <hr />
        </div>

        {/* //! New Message */}
        <CommonTable
          shoSearchBox={false}
          headers={newMessageHeaders}
          showPagination={false}
          showPageCountText={false}
          seeAllText={"See All"}
          seeAllOnClick={()=>{contactUsDataClear()}}
          seeAllLink={"/contact-us"}
          tableTitle={`New Message (${new_contact_us?.length ?? 0})`}
          items={
            <>
              {
                new_contact_us?.length > 0 ? new_contact_us?.map((item, index) => (
                  <NewMessageTableRow key={index} data={item} index={index} />
                ))
                  : <NoDataRow columnNumber={newLicenseHeaders?.length ?? 3} />
              }
            </>
          }
        />

        <div className="px-5 pt-5">
          <hr />
        </div>

        {/* //! Pending Activities */}
        <CommonTable
          shoSearchBox={false}
          headers={activitiesHeaders}
          showPagination={false}
          showPageCountText={false}

          chipWidth="w-[250px]"
          // seeAllText={"See All"}
          showChip={false}
          chipArray={chip_options}
          tableTitle={`Pending Activities (${(dashboard_data?.pending_activity?.invoice +
            dashboard_data?.pending_activity?.classroom +
            dashboard_data?.pending_activity?.driving +
            dashboard_data?.pending_activity?.external) ?? 0
            })`}
          items={
            <>
              {pending_activity_data?.length === 0 ? (
                <NoDataRow columnNumber={5} />
              ) : (
                pending_activity_data?.map((item, index) => (
                  <ActivitiesTableRow key={index} data={item} index={index} />
                ))
              )}
            </>
          }
        />
      </div>
    </>
  );
};

export default Dashboard;
