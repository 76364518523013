import React, { useEffect } from 'react';
import { iEdiItIcon, iLicenseDetailsIcon } from '../../App/Utility/source';
import { useState } from 'react';
import GreenSwitch from '../../Components/Switch/GreenSwitch';
import useLicenseStore, { toggleLicense } from '../../App/Stores/LicenseStore';
import CommonEmptyStatus from '../../Components/CommonEmptyStatus/CommonEmptyStatus';
import { getFormatStringFromDays, htmlToPlainText } from '../../Utility/UtilityFunctions';
import Image from '../../Components/Image/Image';
import Clamp from 'react-multiline-clamp';
import { roughLicenseDurationFormatter } from '../../App/Utility/UtilityFunctions';


const LicenseProfile = ({ data }) => {

    const [enabled, setEnabled] = useState(false)

    const { setShowDeactivateLicense, setLicenseDeactivateID, setShowEditLicense, setLicenseUpdateData } = useLicenseStore()

    const HandleDeactivate = async () => {
        console.log("ID:::", data?.id);
        setLicenseDeactivateID(data?.id);
        if (enabled === true) {
            setShowDeactivateLicense(true);
        } else {
            let activateSuccess = await toggleLicense(data?.id);
            if (activateSuccess) setEnabled(true);
        }
    }

    useEffect(() => {
        setEnabled(data?.is_active);
    }, [data]);

    //console.log("data", data)

    return (
        <div className='flex justify-between bg-cBackgroundAndCategory rounded-br20  px-s20'>
            <div className='flex'>

                <div className='w-s88 h-s88 bg-cBrandColor rounded-full my-s35'>
                    <img className='w-s44 h-s44 flex mx-s22 my-s22 mb-s20' src={iLicenseDetailsIcon} alt="" />
                
                <div className="pt-s20">
                    <div className='flex justify-center'>
                        <GreenSwitch enabled={enabled} setEnabled={() => HandleDeactivate()} />
                    </div>
                    <div
                        className={`text-center ${enabled ? 'text-cPassed' : 'text-cFailed'} 
                               text-fs14 font-fw400 `}>
                        {enabled ? 'Active' : 'Deactivate'}
                    </div>
                    </div>
                </div>




                <div className='ml-s15 py-s20'>
                    <div className='text-cHighlighted text-fs24 font-fw600 capitalize'>{data?.title}</div>
                    <div className='text-cTextGray text-fs16 font-fw400 mt-s4'>
                        <span className='text-cBlack'>Duration : </span> {data?.duration === "null" || data?.duration === null ?
                            <CommonEmptyStatus size='text-fs16' fontWeight='font-fw400' textColor='text-cTextGray' />
                            : roughLicenseDurationFormatter(data?.duration)}
                    </div>
                    <div className='text-cTextGray text-fs16 font-fw400 capitalize'>
                        <span className='text-cBlack'>Type : </span>  {data?.type === "null" || data?.type === null ?
                            <CommonEmptyStatus size='text-fs16' fontWeight='font-fw400' textColor='text-cTextGray' />
                            : data?.type}
                    </div>

                    <div className='text-cTextGray text-fs16 font-fw400'>
                        <span className='text-cBlack'> Price :  </span> {data?.price === "null" || data?.price === null ?
                            <CommonEmptyStatus size='text-fs16' fontWeight='font-fw400' textColor='text-cTextGray' />
                            : `DKK ${data?.price}`}
                    </div>

                    <div className='text-cTextGray text-fs16 font-fw400'>
                        <span className='text-cBlack'> License Type :  </span> {data?.license_type === "null" || data?.license_type === null ?
                            <CommonEmptyStatus size='text-fs16' fontWeight='font-fw400' textColor='text-cTextGray' />
                            : <span className='capitalize'>{data?.license_type}</span>}
                    </div>

                    {data?.details ? <div className='mt-s20'>
                        <div className="text-fs16 text-cBlack font-fw600 mb-s5 ">Description</div>

                        <Clamp
                            lines={2}
                            maxLines={100}
                            withToggle
                            showMoreElement={({ toggle }) => (
                                <button className="cursor-pointer text-cBrandColor mt-s5 text-fs14 font-fw400" type="button" onClick={toggle}>
                                    Show more
                                </button>
                            )}
                            showLessElement={({ toggle }) => (
                                <span className="cursor-pointer text-cBrandColor mt-s5 text-fs14 font-fw400" type="button" onClick={toggle}>
                                    See Less
                                </span>
                            )}
                        >
                            <div className="text-fs14 font-fw400 text-cTextGray max-w-[700px]">
                                {htmlToPlainText(data?.details)}
                            </div>
                        </Clamp>

                    </div> : <div className="text-fs16 text-cBlack font-fw600 mb-s5 mt-s60"> No Description</div>}
                </div>
            </div>

            <div className='flex content-center mt-s20 '>
                <div className="">
                    <img onClick={() => { setLicenseUpdateData(data); setShowEditLicense(true) }}
                        className='cursor-pointer w-s28 h-s28 ml-s30' src={iEdiItIcon} alt="" />
                </div>
            </div>
        </div>

    );
};

export default LicenseProfile;
