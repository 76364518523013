import React from 'react';
import { Outlet } from 'react-router-dom';

const LicenseParent = () => {
    return (
        <>
         <Outlet/>   
        </>
    );
};

export default LicenseParent;