import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSchoolStore from '../../../../App/Stores/SchoolStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import InstructorCard from '../../../../Components/Card/InstructorCard';
import CommonModal from '../../../../Components/Modal/CommonModal';

const AddSchoolExpertiseAreaModal = () => {

    const {
        setShowSchoolInstructorsAddExpertiseArea,
        showSchoolInstructorsAddExpertiseArea,
        schoolInstructorsFindDetails,
        schoolInstructorAppliedCategoriesValue
    } = useSchoolStore();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    //console.log("schoolInstructorsFindDetails?.instructor_info", schoolInstructorsFindDetails?.instructor_info)

    return (
        <>
            <CommonModal
                showModal={showSchoolInstructorsAddExpertiseArea}
                setShowModal={setShowSchoolInstructorsAddExpertiseArea}
                modalTitle="Add Expertise Areas"
                mainContent={
                    <>
                        <div className='mt-s50'>
                            <div className='flex justify-between mb-s20'>

                                {schoolInstructorsFindDetails?.instructor_info &&
                                    schoolInstructorsFindDetails?.instructor_info?.expertise.map((item, index) => (
                                        <div className='mr-s20'>
                                            <InstructorCard
                                                click={true}
                                                title="Expertise Area"
                                                data={item}
                                            />
                                        </div>

                                    ))
                                }
                            </div>
                            <div className='flex justify-center'>
                                <CommonButton
                                    type="submit"
                                    btnLabel="Save Changes"
                                    colorType="primary"
                                    roundedFull="true"
                                />
                            </div>
                        </div>
                    </>
                }

            />
        </>
    );
};

export default AddSchoolExpertiseAreaModal;