import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useCategoryStore from '../../../App/Stores/CategoryStore';
import useLicenseStore from '../../../App/Stores/LicenseStore';
import useSchoolStore, { searchSchoolList } from '../../../App/Stores/SchoolStore';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import { Toastr } from '../../../App/Utility/UtilityFunctions';
import AsyncSingleCombobox from '../../../Components/Select/AsyncSingleCombobox';
import SelectMultipleCombo from '../../../Components/SelectMultipleCombo';
import useInstructorStore, { getInstructorIndex } from './../../../App/Stores/InstructorStore';
import CommonButton from './../../../Components/Button/CommonButton';
import SelectInput from './../../../Components/Input/SelectInput';
import CommonModal from './../../../Components/Modal/CommonModal';

const InstructorFilterModal = () => {
    const {
        setFilterInstructorActive,
        showInstructorFilterModal,
        setShowInstructorFilterModal,
        setFilterData,
        filterData,
        licenseArray,
        setLicenseArray,
        categoryArray,
        setCategoryArray,
        setFilterSchoolArray,
        FilterSchoolArray,
        setInstructorSearchKey,
    } = useInstructorStore()
    const { licenseIndex } = useLicenseStore();
    const { categoryListAll } = useCategoryStore();
    const { schoolListAll, setSchoolSearchKey, schoolSearchKey } = useSchoolStore();
    const { setLoading } = useUtilityStore();

    const [selectedSchoolName, setSelectedSchoolName] = useState("Select or search a school");

    const UpdateLicenseCategoryArray = () => {
        let t_array = [];
        licenseIndex?.length && licenseIndex?.map((item) => t_array.push({
            title: item?.title?.toLowerCase(),
            value: parseInt(item?.id),
            selected: parseInt(filterData?.license_id) === parseInt(item?.id) ? true : false
        }));
        setLicenseArray(t_array);

        let c_array = [];
        categoryListAll?.data?.data?.map((item) => c_array.push({
            label: item?.name,
            value: parseInt(item?.id)
        }));
        setCategoryArray(c_array);
    }

    const ResetLicenseArray = () => {
        let t_array = [];
        licenseIndex?.length && licenseIndex?.map((item) => t_array.push({
            title: item?.title.toLowerCase(),
            value: parseInt(item?.id),
            selected: false
        }));
        setLicenseArray(t_array);
    }

    const ResetFilterUI = () => {
        setSelectedSchoolName("Select or search a school");
        setFilterData({
            expertise: [],
            status: -1,
            license_id: -1,
            payment_status: [],
            school_ids: [],
        });

        ResetLicenseArray();
    }

    useEffect(() => {
        UpdateLicenseCategoryArray();
    }, [licenseIndex, categoryListAll]);

    const [searchValueSchool] = useDebounce(schoolSearchKey, 500);

    useEffect(() => {
        searchSchoolList(searchValueSchool, "", 500);
    }, [searchValueSchool]);

    useEffect(() => {
        let t_array = []
        if (schoolListAll?.data?.length) {
            schoolListAll?.data?.map((item) => t_array.push({ label: item?.name, value: item?.id }));
            setFilterSchoolArray(t_array);
        }
    }, [schoolListAll?.data]);

    return (
        <div>
            <CommonModal
                showModal={showInstructorFilterModal}
                setShowModal={setShowInstructorFilterModal}
                modalTitle="Filter Instructor"
                mainContent={
                    <>
                        <div>
                            <div className="mb-s20">
                                <SelectMultipleCombo
                                    label='Expertise Area'
                                    selectedValue={filterData?.expertise ?? []}
                                    onChange={(valueArray) => {
                                        // console.log(value);
                                        // let s_array = [];
                                        // s_array.push(value);                                        
                                        setFilterData({ ...filterData, expertise: valueArray });
                                    }}
                                    optionArray={categoryArray}

                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    label="Choose Status"
                                    placeholder="Choose Status"
                                    selectOptionOnChange={(e) => {
                                        if (e === '') {
                                            setFilterData({ ...filterData, status: -1 });
                                        } else {
                                            e === "2" && setFilterData({ ...filterData, status: parseInt(1) });
                                            e === "3" && setFilterData({ ...filterData, status: parseInt(0) });
                                        }
                                    }}
                                    dataArray={[
                                        {
                                            title: "Active",
                                            value: 2,
                                            selected: filterData?.status === 1 ? true : false
                                        },
                                        {
                                            title: "Deactivate",
                                            value: 3,
                                            selected: filterData?.status === 0 ? true : false
                                        },
                                    ]}
                                />
                            </div>

                            {/* e       filter by school name */}
                            <div className="mb-s20">
                                <AsyncSingleCombobox
                                    label="filter by school"
                                    placeholder={selectedSchoolName ? selectedSchoolName : 'Select or search a school'}
                                    selectedValue={{ label: selectedSchoolName ?? "Select or search a school", value: filterData.school_ids[0] ?? 0 }}
                                    onSearchItem={(value) => {                                        
                                        setSchoolSearchKey(value);
                                    }}
                                    optionArray={FilterSchoolArray}
                                    onChangeLabel={(label) => {
                                        console.log("SELECTED SCHOOL-LABEL: ", label);
                                        setSelectedSchoolName(label);
                                    }}
                                    onChange={(value) => {
                                        console.log("SELECTED SCHOOL-ID: ", value);
                                        if (value) {
                                            let data = [parseInt(value)];
                                            setFilterData({ ...filterData, school_ids: data });
                                        } else setFilterData({ ...filterData, school_ids: [] });
                                    }}
                                />

                                {/* <SelectInput
                                    label="Filter by School"
                                    placeholder="Choose School"
                                    selectOptionOnChange={(e) => {
                                        if (e === '') {
                                            setFilterData({ ...filterData, school_ids: [] });
                                        } else {
                                            let data = [parseInt(e)];
                                            setFilterData({ ...filterData, school_ids: data });
                                        }
                                    }}
                                    dataArray={FilterSchoolArray ?? []}
                                /> */}
                            </div>

                            {/* b           license data */}
                            <div className="mb-s20">
                                <SelectInput
                                    label="License Type"
                                    placeholder="Choose License Type"
                                    selectOptionOnChange={(e) => {
                                        if (e === '') setFilterData({ ...filterData, license_id: -1 });
                                        else setFilterData({ ...filterData, license_id: parseInt(e) });
                                    }}
                                    dataArray={licenseArray ?? []}
                                />
                            </div>

                            {/* g:          payment status */}
                            <div className="mb-s20">
                                <SelectInput
                                    label="Payment Status"
                                    placeholder="Choose Payment Status"
                                    selectOptionOnChange={(e) => {
                                        if (e === "paid") setFilterData({ ...filterData, payment_status: ["paid1", "paid2"] });
                                        else if (e === "missing") setFilterData({ ...filterData, payment_status: ["missing1", "missing2"] });
                                        else if (e === '') setFilterData({ ...filterData, payment_status: [] });
                                        else setFilterData({ ...filterData, payment_status: [e] });
                                    }}
                                    dataArray={[
                                        {
                                            title: "accepted",
                                            value: "accepted",
                                            selected: filterData?.payment_status[0] === "accepted" ? true : false
                                        },
                                        {
                                            title: "requested",
                                            value: "requested",
                                            selected: filterData?.payment_status[0] === "requested" ? true : false
                                        },
                                        // {
                                        //     title: "cancelled",
                                        //     value: "cancelled",
                                        //     selected: filterData?.payment_status[0] === "cancelled" ? true : false
                                        // },
                                        {
                                            title: "created",
                                            value: "created",
                                            selected: filterData?.payment_status[0] === "created" ? true : false
                                        },
                                        {
                                            title: "paid",
                                            value: "paid",
                                            selected: filterData?.payment_status[0] === "paid1" ? true : false
                                        },
                                        {
                                            title: "missing payment",
                                            value: "missing",
                                            selected: filterData?.payment_status[0] === "missing1" ? true : false
                                        },

                                        // {
                                        //     title: "rejected",
                                        //     value: "rejected",
                                        //     selected: filterData?.payment_status[0] === "rejected" ? true : false
                                        // },
                                        {
                                            title: "expired",
                                            value: "expired",
                                            selected: filterData?.payment_status[0] === "expired" ? true : false
                                        }
                                    ]}
                                />
                            </div>

                            <div className='flex justify-between mt-s20'>
                                <CommonButton onClick={() => {
                                    ResetFilterUI();
                                }} btnLabel="Clear" colorType="FilterClearButton" />
                                <CommonButton
                                    onClick={async () => {
                                        // console.log("categoryListAll:::", categoryListAll?.data?.data);
                                        console.log("filterData", filterData);
                                        // return
                                        setLoading(true);
                                        let filterSuccess = await getInstructorIndex("", filterData, "", false);
                                        if (filterSuccess) {
                                            Toastr({ message: "Your filter successfully Apply", type: "success" });
                                            setShowInstructorFilterModal(false);
                                            setInstructorSearchKey("");

                                            if (filterData.expertise.length === 0
                                                && isNaN(filterData.status) === true
                                                && filterData.license_id === -1
                                                && filterData.payment_status.length === 0
                                                && filterData.school_ids.length === 0) {
                                                setFilterInstructorActive(false);
                                            }
                                        }
                                        setLoading(false);
                                    }}
                                    type="submit"
                                    btnLabel="Apply"
                                    colorType="primary"
                                    roundedFull="true"
                                />
                            </div>

                        </div>

                    </>
                }

            />
        </div>
    );
};

export default InstructorFilterModal;