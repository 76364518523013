
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { iCategory, iRightArrow } from '../../../App/Utility/source';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';

const CategoryTableRow = ({ data, index }) => {

  const navigate = useNavigate();

  //console.log("CategoryData",data);

  return (
    <>
      <tr onClick={() => { navigate(`/category/details/${data?.id}`) }} className={`border-b border-collapse cursor-pointer  border-cNmSelect hover:bg-cGridView`} >

        <th className='m-2 font-normal text-center border-r py-s10'>
          <span className="">{index}</span>
        </th>

        <td className='border-r-[1px] py-s10 text-center px-s10 max-w-[120px] min-w-[100px] '>
          <div className='flex min-w-[80px] sm:flex-col md:flex-row'>
            <div className='rounded-full w-s44 h-s44 py-s11 pr-s10 pl-s9 bg-cBackgroundAndCategory'>
              <Image cursorPointerClass="cursor-pointer" className='h-s22 w-s24' src={data?.icon} dummyImage={iCategory} isCategoryImage={true} />
            </div>
            <div className='items-center flex justify-center font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12'>{data?.name}</div>
          </div>
        </td>

        <td className='py-s10 border-r-[1px] text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>{data?.schools_count ? data?.schools_count :<CommonEmptyStatus/>}</span>
        </td>

        <td className='py-s10 border-r-[1px] text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>{data?.instructors_count ? data?.instructors_count : <CommonEmptyStatus/>}</span>
        </td>

        {/* <td className='py-s10 border-r-[1px] pl-s10 text-fs14 font-fw400 text-center  text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>350</span>
        </td> */}

        <td className='py-s10 border-r-[1px] px-s10 text-center'>
          <span className='flex justify-between'>
            <span></span>
            <span className='justify-center my-s8 font-fw400 ml-s10 text-cImportantText lg:text-fs14 sm:text-fs12'>{data?.student_count ? data?.student_count : <CommonEmptyStatus/>}</span>
            <span className=""><img className="mt-s12" src={iRightArrow} alt=""></img> </span> </span>
        </td>
      </tr>
    </>
  )
}


export default CategoryTableRow;