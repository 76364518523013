import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useInvoiceStore, { getStudentInvoiceIndex } from '../../../App/Stores/InvoiceStore';
import useSchoolStore, { getSchoolList, searchSchoolList } from '../../../App/Stores/SchoolStore';
import { Toastr } from '../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';
import SelectInput from '../../../Components/Input/SelectInput';
import CommonModal from '../../../Components/Modal/CommonModal';
import AsyncSingleCombobox from '../../../Components/Select/AsyncSingleCombobox';

function StudentInvoiceFilter() {

    const {

        showStudentInvoiceFilterModal,
        setShowStudentInvoiceFilterModal,
        filterStudentInvoiceData,
        setFilterStudentInvoiceData,
        resetFilterStudentInvoiceData,
        studentID,
        studentInvoiceSearchKey,
        invoiceFilterInsSel,
        setInvoiceFilterInsSel
    } = useInvoiceStore();


    const { schoolSearchKey, setSchoolSearchKey, schoolListAll } = useSchoolStore();

    const [dataArray, setDataArray] = useState([]);

    const [searchValueSchool] = useDebounce(schoolSearchKey, 500);

    useEffect(() => {
        SmartSearchSchool();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValueSchool]);

    const SmartSearchSchool = async () => {
        if (searchValueSchool) {
            await searchSchoolList(searchValueSchool, "", 500);
        } else {
            await getSchoolList()
        }
    }

    useEffect(() => {
        let t_array = []
        if (schoolListAll?.data?.length) {
            schoolListAll?.data?.map((item) => t_array.push({ label: item?.name, value: item?.id }));
            setDataArray(t_array);
        }
    }, [schoolListAll?.data]);

    return (
        <div>
            <CommonModal
                showModal={showStudentInvoiceFilterModal}
                setShowModal={setShowStudentInvoiceFilterModal}
                modalTitle="Filter Student Invoice"
                mainContent={
                    <>
                        <form onSubmit={(e) => e.preventDefault()}>

                            {/* g:          payment status */}
                            <div className="my-s20">
                                <SelectInput
                                    label="Payment Status"
                                    placeholder="Choose Payment Status"
                                    selectOptionOnChange={(e) => {
                                        if (e === "paid") setFilterStudentInvoiceData({ ...filterStudentInvoiceData, status: ["paid1", "paid2"] });
                                        else if (e === "missing") setFilterStudentInvoiceData({ ...filterStudentInvoiceData, status: ["missing1", "missing2"] });
                                        else if (e === '') setFilterStudentInvoiceData({ ...filterStudentInvoiceData, status: [] });
                                        else setFilterStudentInvoiceData({ ...filterStudentInvoiceData, status: [e] });
                                    }}

                                    dataArray={[
                                        {
                                            title: "accepted",
                                            value: localStorage.getItem("studentInvoiceType") === "history" ? "accepted" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "accepted" ? true : false
                                        },
                                        {
                                            title: "requested",
                                            value: localStorage.getItem("studentInvoiceType") !== "history" ? "requested" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "requested" ? true : false
                                        },
                                        {
                                            title: "cancelled",
                                            value: localStorage.getItem("studentInvoiceType") === "history" ? "cancelled" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "cancelled" ? true : false
                                        },
                                        {
                                            title: "created",
                                            value: localStorage.getItem("studentInvoiceType") !== "history" ? "created" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "created" ? true : false
                                        },
                                        {
                                            title: "paid",
                                            value: localStorage.getItem("studentInvoiceType") !== "history" ? "paid" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "paid1" ? true : false
                                        },
                                        {
                                            title: "missing payment",
                                            value: localStorage.getItem("studentInvoiceType") !== "history" ? "missing" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "missing1" ? true : false
                                        },

                                        {
                                            title: "rejected",
                                            value: localStorage.getItem("studentInvoiceType") === "history" ? "rejected" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "rejected" ? true : false
                                        },
                                        {
                                            title: "expired",
                                            value: localStorage.getItem("studentInvoiceType") === "history" ? "expired" : "",
                                            selected: filterStudentInvoiceData?.status[0] === "expired" ? true : false
                                        }
                                    ]}
                                />
                            </div>

                            {/* y           school based filter */}
                            {localStorage.getItem("studentInvoiceType") === "history" ?
                                <div className="my-s20">
                                    <AsyncSingleCombobox
                                        label="filter by school"
                                        placeholder={invoiceFilterInsSel === "" ? 'select or search a school' : invoiceFilterInsSel}
                                        onSearchItem={(value) => setSchoolSearchKey(value)}
                                        optionArray={dataArray}
                                        onChangeLabel={(label) => {
                                            setInvoiceFilterInsSel(label);
                                            console.log("SELECTED INS-LABEL: ", label);
                                        }}
                                        onChange={(value) => {
                                            console.log("SELECTED INS-ID: ", value);
                                            setFilterStudentInvoiceData({ ...filterStudentInvoiceData, school_id: value });
                                        }}
                                    />
                                </div> : ""}

                            {/*b         start date and date */}
                            <div className='flex justify-between items-center w-full'>
                                <div className='p-0 rounded-full'>
                                    <CommonInput
                                        type='date'
                                        value={filterStudentInvoiceData?.start_date}
                                        startDate={"1901-01-01"}
                                        label="start date"
                                        allowPastDates={true}
                                        onChange={(e) => {
                                            // console.log((e.target.value));
                                            const startDate = new Date(e.target.value);
                                            const endDate = new Date(filterStudentInvoiceData?.end_date);

                                            // console.log("startDate::::", startDate, "; endDate::::", endDate);

                                            if (startDate > endDate) {
                                                setFilterStudentInvoiceData({ ...filterStudentInvoiceData, end_date: "", start_date: e.target.value });
                                            } else {
                                                setFilterStudentInvoiceData({ ...filterStudentInvoiceData, start_date: e.target.value });
                                            }
                                            // setFilterStudentInvoiceData({ ...filterStudentInvoiceData, start_date: e.target.value });
                                        }}
                                    />
                                </div>

                                <div className='flex items-center'>
                                    <div className='pr-0'>
                                        <CommonInput
                                            // required={filterStudentInvoiceData?.start_date ? true : false}
                                            type='date'
                                            label="end date"
                                            value={filterStudentInvoiceData?.end_date}
                                            // disabled={!filterStudentInvoiceData?.start_date}
                                            startDate={filterStudentInvoiceData?.start_date ? filterStudentInvoiceData?.start_date : "1901-01-01"}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setFilterStudentInvoiceData({ ...filterStudentInvoiceData, end_date: (e.target.value) });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*r         action buttons  */}
                            <div className='flex justify-between mt-s20'>
                                <CommonButton onClick={() => {
                                    resetFilterStudentInvoiceData();
                                }}
                                    fullRounded={true}
                                    btnLabel='Clear'
                                    colorType="FilterClearButton" text="fs16" />
                                <CommonButton
                                    onClick={async () => {
                                        console.log(filterStudentInvoiceData);

                                        localStorage.setItem("instructorInvoicePaginationURL", "");

                                        let filterSuccess = await getStudentInvoiceIndex(studentID, localStorage.getItem("studentInvoiceType") ?? "school_student", localStorage.getItem("instructorInvoicePaginationURL") ?? "", studentInvoiceSearchKey, filterStudentInvoiceData);
                                        if (filterSuccess) {
                                            Toastr({ message: "Your filter successfully Apply", type: "success" });
                                            setShowStudentInvoiceFilterModal(false);
                                        }
                                    }}
                                    type="submit"
                                    btnLabel="Apply"
                                    colorType="primary"
                                    roundedFull="true"
                                />
                            </div>

                        </form>

                    </>
                }

            />
        </div>
    );
};

export default StudentInvoiceFilter