import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useLayoutStore from "../../App/Stores/LayoutStore";
// pink: icons and logos

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCategoryStore from "../../App/Stores/CategoryStore";
import useContactStore from "../../App/Stores/ContactStore";
import useInstructorStore from "../../App/Stores/InstructorStore";
import useInvoiceStore from "../../App/Stores/InvoiceStore";
import useSchoolStore from "../../App/Stores/SchoolStore";
import useStudentStore from "../../App/Stores/StudentStore";
import {
  iAppNormal,
  iAppSelected,
  iAPP_LOGO,
  iAPP_LOGO_SM,
  iBannerWhite,
  iDashboard,
  iDownArrowWhite,
  iInstructor,
  iInstructorWhite,
  iInvoice,
  iInvoiceWhite,
  iLEFT_ARROW,
  iLicense,
  iLicenseWhite, iSchool,
  iStudent,
  iStudentWhite, iWebNormal, iWebSelected, iWhiteDashboard,
  iWhiteschool,
  iWhiteSettings
} from "../../App/Utility/source";
import {
  iBanner, iCategory, iContactUs, iContactWhite, iSettings, iWhiteCategory
} from "./../../App/Utility/source";
import NormalSideBarItem from "./NormalSideBarItem";
import { Tooltip } from "@mui/material";

const Sidebar = ({ isOpenSidebar, setIsSidebarOpen }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const location = useLocation();

  // store imports
  const { resetInvoiceFilterData, setInvoicePageUrl, setInvoiceUserType, setInVoiceTake, setInvoiceSearchKey, setInvoiceIndexData } = useInvoiceStore();
  const { resetSchoolFilterData, setFilterSchoolActive, setSchoolTakeList, setSchoolSearchKey, setSchoolPageUrl } = useSchoolStore();
  const { resetStudentFilterData, setStudentTakeList, setStudentSearch, setStudentPaginationURL } = useStudentStore();
  const { resetInstructorFilterData, setInstructorTakeList, setInstructorSearchKey, setInstructorPageUrl } = useInstructorStore();
  const { setContactUsTakeList, setContactUsSearch, setContactUsPageUrl, setCustomDateShow, setFilterForm } = useContactStore();

  // green: initialize modal states from zustand
  const { setExpandRole, activeSection, setActiveSection, setExpandSettings } = useLayoutStore();
  const { setCategoryPageUrl } = useCategoryStore();

  const [contactExpand, setContactExpand] = useState(false);


  const { invoice_id, school_id, school_user_id } = useParams()

  useEffect(() => {
    if (location.pathname) {
      switch (location.pathname) {
        case "/":
          setActiveSection("dashboard");
          break;

        case "/category":
          setActiveSection("category");
          break;

        case "/category/school/1":
          setActiveSection("category");
          break;

        case "/category/instructor/1":
          setActiveSection("category");
          break;

        case "/category/student/1":
          setActiveSection("category");
          break;

        case "/category/school/license/history":
          setActiveSection("category");
          break;

        case "/banner":
          setActiveSection("banner");
          break;

        case "/school":
          setActiveSection("school");
          break;


        case "/school/license/history":
          setActiveSection("school");
          break;

        case "/school/category":
          setActiveSection("school");
          break;

        case "/school/invoice":
          setActiveSection("school");
          break;

        case "/school/classes":
          setActiveSection("school");
          break;

        case "/school/classroom":
          setActiveSection("school");
          break;

        case "/school/instructor/detail":
          setActiveSection("school");
          break;

        case "/school/category/details":
          setActiveSection("school");
          break;

        case "/school/instructor/profile":
          setActiveSection("school");
          break;

        case "/school/student/detail":
          setActiveSection("school");
          break;

        case "/school/invoice/detail":
          setActiveSection("school");
          break;

        case "/instructor":
          setActiveSection("instructor");
          break;

        case "/instructor/profile":
          setActiveSection("instructor");
          break;

        case "/instructor/workplace":
          setActiveSection("instructor");
          break;

        case "/instructor/upcoming/bookings":
          setActiveSection("instructor");
          break;

        case "/instructor/driving/lessons":
          setActiveSection("instructor");
          break;

        case "/instructor/external/lessons":
          setActiveSection("instructor");
          break;

        case "/instructor/invoice":
          setActiveSection("instructor");
          break;

        case "/student":
          setActiveSection("student");
          break;

        case "/student/profile/1":
          setActiveSection("student");
          break;

        case "/student/curriculum/details":
          setActiveSection("student");
          break;

        case "/student/curriculum/history/details":
          setActiveSection("student");
          break;

        case "/student/school":
          setActiveSection("student");
          break;

        case "/student/school/detail":
          setActiveSection("student");
          break;

        case "/student/invoice":
          setActiveSection("student");
          break;

        case "/terms":
          setActiveSection("terms");
          break;

        case "/license":
          setActiveSection("license");
          break;

        case "/license/list":
          setActiveSection("license");
          break;

        case "/license/detail":
          setActiveSection("license");
          break;

        case "/license/instructor/profile":
          setActiveSection("license");
          break;

        case "/invoice":
          setActiveSection("invoice");
          break;

        case "/invoice/details/:invoice_id":
          setActiveSection("invoice");
          break;

        case "/invoice/details/:invoice_id/student":
          setActiveSection("invoice");
          break;

        case "/invoice/application/:id":
          setActiveSection("invoice/application/:id");
          break;

        case "/contact-us":
          setActiveSection("contact-us");
          break;

        case "/notification":
          setActiveSection("notification");
          break;

        case "/settings":
          setActiveSection("settings");
          break;

        default:
          setActiveSection("");
      }
      if (window.location.pathname.includes(`/contact-us/student-profile/`)) setActiveSection("contact-us")
      if (window.location.pathname.includes("/contact-us/instructor-profile/")) setActiveSection("contact-us")
      if (window.location.pathname.includes("/contact-us/school-profile/")) setActiveSection("contact-us")
      if (window.location.pathname.includes("/contact-us/web")) setActiveSection("contact-us")
      if (window.location.pathname.includes("/category/")) setActiveSection("category")
      if (window.location.pathname.includes("/school/")) setActiveSection("school")
      if (window.location.pathname.includes("/invoice/details/")) setActiveSection("invoice")
      if (window.location.pathname.includes(`/invoice/details/${invoice_id}/student`)) setActiveSection("student")
      if (window.location.pathname.includes(`/invoice/details/${invoice_id}/school`)) setActiveSection("school")

      if (window.location.pathname.includes(`/school/details/${school_id}/invoice/${invoice_id}/${school_user_id}`)) setActiveSection("invoice")

      if (window.location.pathname.includes(`/invoice/details/${invoice_id}/instructor`)) setActiveSection("instructor")
      // /school/details/1/invoice/115
      if (window.location.pathname.includes(`/school/invoice/details/${invoice_id}/school/${school_id}`)) setActiveSection("invoice")
      if (window.location.pathname.includes(`/invoice/details/${invoice_id}/instructor`)) setActiveSection("instructor")

      if (window.location.pathname.includes(`/settings/`)) setActiveSection("settings")
      if (window.location.pathname.includes(`/student/`)) setActiveSection("student")
      if (window.location.pathname.includes("/banner/")) setActiveSection("banner")
      if (window.location.pathname.includes("/instructor/")) setActiveSection("instructor")
      if (window.location.pathname.includes("license/details/")) setActiveSection("license")
      if (window.location.pathname.includes(`/school/details/${school_id}/instructor/`)) setActiveSection("school")
      if (window.location.pathname.includes(`/school/details/${school_id}/student/`)) setActiveSection("school")

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (activeSection && activeSection !== "role") {
      setExpandRole(false);
    } else if (activeSection && activeSection === "role") {
      setExpandRole(true);
    }
    if (activeSection && activeSection !== "settings") {
      setExpandSettings(false);
    } else if (activeSection && activeSection === "settings") {
      setExpandSettings(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  return (
    <div className="relative">
      {/* green: expand btn */}
      <div onClick={() => setIsSidebarOpen(!isOpenSidebar)} className={`absolute ${isOpenSidebar ? "-right-[210px] top-[157px]" : "-right-[60px] top-[57px]"} transition-all duration-150 z-index-130`}>
        <div className={`flex fixed justify-center items-center w-10 h-10 rounded-full cursor-pointer sidebar-expand-arrow-shadow bg-cBrand`}>
          <img
            src={iLEFT_ARROW}
            alt="right-arrow-icon"
            className={`${!isOpenSidebar ? "rotate-0" : "rotate-180"} transition-all duration-700 h-4`}
          />
        </div>
      </div>
      {/* green: Main side bar list items */}
      <aside
        className={`
        ${isOpenSidebar ? "w-s230" : "w-s80"}        
         fixed top-0 bottom-0 z-index-120 h-full overflow-y-auto overflow-x-visible md:shadow-md transform transition-all duration-150 ease-in bg-cSidebarDarkBg`}
      >
        <div
          className={`flex flex-col bg-cSidebarDarkBg ${isOpenSidebar ? "px-s8 pt" : "items-center px-0"
            } select-none outline-none`}
        >
          {/* blue:  brand logo */}
          <div
            onClick={() => navigateTo("/")}
            className={`${isOpenSidebar ? "mx-auto mb-s15 mt-s55" : "mt-s20 mb-s10"
              } cursor-pointer`}
          >
            <img
              src={isOpenSidebar === true ? iAPP_LOGO : iAPP_LOGO_SM}
              alt="BrandLogo"
              className={`${isOpenSidebar ? "h-s100 w-s122" : "pt-s10 w-s30"} `}
            />
          </div>

          <div className="pt-s40"></div>

          <NormalSideBarItem
            onClick={() => setActiveSection("dashboard")}
            title={t("dashboard")}
            linkTo="/"
            isActiveLink={activeSection === "dashboard" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={activeSection === "dashboard" ? iDashboard : iWhiteDashboard}
            selectedIcon={activeSection === "dashboard" ? iDashboard : iWhiteDashboard}
          />

          <NormalSideBarItem
            onClick={() => {
              setActiveSection("category")
              setCategoryPageUrl("")
            }}
            title={t("Category")}
            linkTo="/category"
            isActiveLink={activeSection === "category" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={
              activeSection === "category" ? iWhiteCategory : iCategory
            }
            selectedIcon={
              activeSection === "category" ? iWhiteCategory : iCategory
            }
          />

          <NormalSideBarItem
            onClick={() => setActiveSection("banner")}
            title={t("Banner")}
            linkTo="/banner"
            isActiveLink={activeSection === "banner" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={activeSection === "banner" ? iBannerWhite : iBanner}
            selectedIcon={activeSection === "banner" ? iBannerWhite : iBanner}
          />

          <NormalSideBarItem
            onClick={() => {
              setActiveSection("school");
              resetSchoolFilterData();
              setFilterSchoolActive(false);
              setSchoolTakeList("take", 10);
              setSchoolSearchKey("")
              setSchoolPageUrl("")
            }}
            title={t("School")}
            linkTo="/school"
            isActiveLink={activeSection === "school" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={activeSection === "school" ? iWhiteschool : iSchool}
            selectedIcon={activeSection === "school" ? iWhiteschool : iSchool}
          />

          <NormalSideBarItem
            onClick={() => {
              setInstructorSearchKey("")
              setActiveSection("instructor");
              resetInstructorFilterData();
              setInstructorTakeList("take", 10);
              setInstructorPageUrl("")
            }}
            title={t("Instructor")}
            linkTo="/instructor"
            isActiveLink={activeSection === "instructor" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={
              activeSection === "instructor" ? iInstructorWhite : iInstructor
            }
            selectedIcon={
              activeSection === "instructor" ? iInstructorWhite : iInstructor
            }
          />

          <NormalSideBarItem
            onClick={() => {
              setActiveSection("student");
              resetStudentFilterData();
              setStudentPaginationURL("");
              setStudentTakeList("take", 10);
              setStudentSearch("")
            }}
            title={t("Student")}
            linkTo="/student"
            isActiveLink={activeSection === "student" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={activeSection === "student" ? iStudentWhite : iStudent}
            selectedIcon={
              activeSection === "student" ? iStudentWhite : iStudent
            }
          />

          {/* <NormalSideBarItem
            onClick={() => setActiveSection("test")}
            title={t("Terms & Conditions")}
            linkTo="/terms"
            isActiveLink={activeSection === "terms" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={
              activeSection === "terms"
                ? iTermsConditionsWhite
                : iTermsConditions
            }
            selectedIcon={
              activeSection === "terms"
                ? iTermsConditionsWhite
                : iTermsConditions
            }
          /> */}

          <NormalSideBarItem
            onClick={() => setActiveSection("license")}
            title={t("License")}
            linkTo="/license"
            isActiveLink={activeSection === "license" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={activeSection === "license" ? iLicenseWhite : iLicense}
            selectedIcon={
              activeSection === "license" ? iLicenseWhite : iLicense
            }
          />

          <NormalSideBarItem
            onClick={() => {
              if (location.pathname !== "/invoice") {
                setInvoiceIndexData({});
              }
              resetInvoiceFilterData();
              setInvoicePageUrl("");
              setActiveSection("invoice");
              setInvoiceUserType("all")
              setInVoiceTake(10)
              setInvoiceSearchKey("")
            }}
            title={t("Invoice")}
            linkTo="/invoice"
            isActiveLink={activeSection === "invoice" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={activeSection === "invoice" ? iInvoiceWhite : iInvoice}
            selectedIcon={
              activeSection === "invoice" ? iInvoiceWhite : iInvoice
            }
          />

          {/* l  new contact us section [expandable]  */}
          <div
            className={`
            transition-height duration-500
            mt-s15 overflow-hidden capitalize
            ${isOpenSidebar ? "rounded-br5" : "rounded-none w-20"}
            ${contactExpand === true
                ? isOpenSidebar
                  ? "h-[145px] bg-[#0f3c77]"
                  : "h-[140px] bg-[#0f3c77]"
                : "h-s50"
              } `}
          >
            <div className="cursor-pointer">
              <div
                onClick={() => {
                  setContactExpand(!contactExpand);
                  setActiveSection("contact-us");
                }}
                className={`flex items-center h-s50 hover:bg-cBrand hover:text-white
                ${activeSection === "contact-us"
                    ? "bg-cBrand text-white"
                    : "bg-cSidebarDarkBg text-cSidebarText"
                  }
            ${isOpenSidebar
                    ? "pl-s5  rounded-br5 justify-between "
                    : "justify-center"
                  }
            `}
              >
                <Tooltip
                  title={isOpenSidebar ? "" : "Contact Us"}
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        textTransform: "capitalize"
                      }
                    }
                  }}
                >
                  <div className="flex items-center ">
                    <div
                      className={`flex justify-center items-center rounded-br5    ${activeSection === "contact-us"
                        ? ""
                        : " "
                        }   ${isOpenSidebar
                          ? "ml-s7 my-s7 p-s5 w-s30 h-s30 "
                          : "p-2 w-s30 h-s30"
                        }`}
                    >
                      <img
                        className="w-5"
                        src={
                          activeSection === "contact-us"
                            ? iContactWhite
                            : iContactUs
                        }
                        alt=""
                      />
                    </div>
                    {!isOpenSidebar ? (
                      <div
                        className={`rounded-br5 w-s5 h-s5 ml-1 ${activeSection === "contact-us"
                          ? ""
                          : ""
                          }`}
                      ></div>
                    ) : (
                      ""
                    )}
                    {isOpenSidebar ? (
                      <div className="font-semibold pl-5 text-fs14 text-left ">
                        {t("Contact Us")}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Tooltip>

                {isOpenSidebar ? (
                  <div className="pr-5">
                    <img
                      src={iDownArrowWhite}
                      alt=""
                      className={`${contactExpand === false ? "rotate-0" : "rotate-180"
                        } transition-all duration-500`}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* e  app contact us  */}
            <Tooltip
              title={isOpenSidebar ? "" : t("App")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              onClick={() => {
                setCustomDateShow(false);
                setFilterForm({
                  status: "", // user type [Schools,Instructors, Students], for all use "" empty string
                  is_reply: null,
                  is_seen: null,
                  date: "", //[weekly, monthly, custom]
                  custom_date: "",
                  start_date: "",
                  end_date: "",
                });
                setContactUsTakeList("take", 10)
                setContactUsSearch("")
                setContactUsPageUrl("url", "")
              }}
              to="/contact-us"
              className={`
                flex items-center hover:text-white font-bold
                ${isOpenSidebar ? "pl-s55" : "justify-center"}                  
               ${location.pathname === "/contact-us"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 py-2.5`}
            >
                <img
                  src={
                    location.pathname === "/contact-us"
                      ? iAppSelected
                      : iAppNormal
                  }
                  alt=""
                  className="w-5 h-auto object-cover"
                />
                {isOpenSidebar ? <div className="pl-s10">{t("App")}</div> : ""}
              </Link>
            </Tooltip>

            {/* e  web contact us  */}
            <Tooltip
              title={isOpenSidebar ? "" : t("Web")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/contact-us/web"

              className={`
                flex items-center hover:text-white font-bold
                ${isOpenSidebar ? " pl-s59" : "justify-center"}                  
               ${location.pathname === "/contact-us/web"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 py-2.5`}
            >
                <img
                  src={
                    location.pathname === "/contact-us/web"
                      ? iWebSelected
                      : iWebNormal
                  }
                  alt=""
                  className="w-s14 h-s14 object-cover"
                />
                {isOpenSidebar ? <div className="pl-s10">{t("Web")}</div> : ""}
              </Link>
            </Tooltip>

          </div>
          {/* l   ends new contact us [expanded] */}


          {/* e   old contact us */}
          {/* 
          <NormalSideBarItem
            onClick={() => {
              setCustomDateShow(false);
              setFilterForm({
                status: "", // user type [Schools,Instructors, Students], for all use "" empty string
                is_reply: null,
                is_seen: null,
                date: "", //[weekly, monthly, custom]
                custom_date: "",
                start_date: "",
                end_date: "",
              });

              setActiveSection("contact-us")
              setContactUsTakeList("take", 10)
              setContactUsSearch("")
              setContactUsPageUrl("url", "")
            }

            }
            title={t("Contact Us")}
            linkTo="/contact-us"
            isActiveLink={activeSection === "contact-us" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={
              activeSection === "contact-us" ? iContactWhite : iContactUs
            }
            selectedIcon={
              activeSection === "contact-us" ? iContactWhite : iContactUs
            }
          /> */}

          {/* 

        <NormalSideBarItem
            onClick={() => setActiveSection("test")}
            title={t("License")}
            linkTo="/license"
            isActiveLink={activeSection === "license" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={iLicense}
            selectedIcon={iLicense}
          />


        <NormalSideBarItem
            onClick={() => setActiveSection("test")}
            title={t("Invoice")}
            linkTo="/invoice/application/:id"
            isActiveLink={activeSection === "invoice/application/:id" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={iInvoice}
            selectedIcon={iInvoice}
          />

      // <NormalSideBarItem
      //       onClick={() => setActiveSection("test")}
      //       title={t("Contact Us")}
      //       linkTo="/contact"
      //       isActiveLink={activeSection === "contact" ? true : false}
      //       isOpenSidebar={isOpenSidebar}
      //       normalIcon={iContactUs}
      //       selectedIcon={iContactUs}
      //     />

       <NormalSideBarItem
            onClick={() => setActiveSection("test")}
            title={t("Notification")}
            linkTo="/notification"
            isActiveLink={activeSection === "notification" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={iNotification}
            selectedIcon={iNotification}
          />
          */}

          <NormalSideBarItem
            onClick={() => setActiveSection("settings")}
            title={t("Settings")}
            linkTo="/settings"
            isActiveLink={activeSection === "settings" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={activeSection === "settings" ? iWhiteSettings : iSettings}
            selectedIcon={activeSection === "settings" ? iWhiteSettings : iSettings}
          />


          {/* red ends sidebar */}

          {/* <NormalSideBarItem
            onClick={() => handleUserLogout()}
            title={t("Logout")}
            linkTo={"/login"}
            isActiveLink={activeSection === "logout" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={iSettings}
            selectedIcon={iSettings}
          /> */}
        </div>
      </aside>

    </div>
  );
};

export default Sidebar;
