import create from "zustand";
import axios from "axios";
import useUtilityStore from "./UtilityStore";
import AxiosHeader from "../Utility/AxiosHeader";
import { Toastr } from "../Utility/UtilityFunctions";
import { userLoginUrl, userLogoutUrl, userVerifyUrl } from "../Utility/Url";
// import i18next from "i18next";

const { setLoading, setLoggedUser } = useUtilityStore.getState();

const useAuthStore = create((set) => ({
  isLoggedIn: false,
  setIsLoggedIn: (value) =>
    set((state) => {
      state.isLoggedIn = value;
    }),

  //login form
  login_form: { email: "", password: "" },
  changeLoginFormValue: (e) =>
    set((state) => (state.login_form[e.target.name] = e.target.value)),
  resetLoginFormValue: () =>
    set((state) => (state.login_form = { email: "", password: "" })),

  //register form
  register_form: {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    role: "customer",
  },
  changeRegisterFormValue: (e) =>
    set((state) => (state.register_form[e.target.name] = e.target.value)),
  resetRegisterFormValue: () =>
    set(
      (state) =>
      (state.register_form = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "customer",
      })
    ),

  //otp form
  otp_form: { otp: "" },
  changeOtpFormValue: (e) =>
    set((state) => (state.otp_form[e.target.name] = e.target.value)),
  resetOtpForm: () => set((state) => (state.otp_form = { otp: "" })),
  otp_email: "",
  setOtpEmail: (value) =>
    set((state) => {
      state.otp_email = value;
      localStorage.setItem("otp_email", value);
    }),

  // forget password form
  fp_form: { otp: "" },
  changeFpFormValue: (e) =>
    set((state) => (state.fp_form[e.target.name] = e.target.value)),
  resetFpForm: () => set((state) => (state.fp_form = { otp: "" })),
  reset_password_token: null,
  setResetPasswordToken: (value) =>
    set((state) => {
      state.reset_password_token = value;
      localStorage.setItem("reset_password_token", value);
    }),

  // user profile from local storage
  user_profile: { name: "", email: "", phone: "", image: "" },
  changeUserProfileValue: (e) =>
    set((state) => (state.user_profile[e.target.name] = e.target.value)),
  changeUserProfileValueWithoutEvent: (name, value) =>
    set((state) => (state.user_profile[name] = value)),

  // change password
  change_password_form: {
    old_password: "",
    password: "",
    password_confirmation: "",
  },
  changePasswordFormValue: (e) =>
    set(
      (state) => (state.change_password_form[e.target.name] = e.target.value)
    ),
  resetChangePasswordFormValue: () =>
    set(
      (state) =>
      (state.change_password_form = {
        old_password: "",
        password: "",
        password_confirmation: "",
      })
    ),

  // user profile from api
  user_profile_api_data: {},
  setUserProfileData: (value) =>
    set((state) => (state.user_profile_api_data = value)),
}));

export const handleUserLogin = async (e) => {
  try {
    e.preventDefault();
    setLoading(true);
    const { login_form, setIsLoggedIn } = useAuthStore.getState();
    console.log("login_form:::", login_form);

    const res = await axios.post(userLoginUrl, login_form);

    console.log("handleUserLogin res.data:::: ", res.data);

    if (res.data.success) {
      localStorage.setItem("user", JSON.stringify(res.data.data.user));
      setLoggedUser(res.data.data.user);
      // i18next.changeLanguage(res.data.data.user?.lang_code);
      localStorage.setItem("is_verified", res.data.data.user.is_verified);
      localStorage.setItem("maway_token", res.data.data.token);
      AxiosHeader(res.data.data.token);
      setIsLoggedIn(true);
      Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("handleUserLogin: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

export const verifyAuthUser = async () => {
  try {
    setLoading(true);
    const res = await axios.get(userVerifyUrl);
    console.log("verifyAuthUser : ", res.data);

    if (res.data.success) {
      const data = {
        name: res.data.data.user_name,
        email: res.data.data.email,
        moms: res.data.data.moms,
      }
      localStorage.setItem("user", JSON.stringify(data));
    } else {
      localStorage.setItem("user", "");
      localStorage.setItem("maway_token", "");
      useAuthStore.getState().setIsLoggedIn(false);
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("verifyAuthUser: ", error);

    localStorage.setItem("user", "");
    localStorage.setItem("maway_token", "");
    useAuthStore.getState().setIsLoggedIn(false);

    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};


export const handleUserLogout = async () => {
  const { setIsLoggedIn } = useAuthStore.getState()

  setIsLoggedIn(true);
  try {
    const res = await axios.post(userLogoutUrl);
    if (res?.data?.success) {
      localStorage.setItem("user", "");
      localStorage.setItem("maway_token", "");
      AxiosHeader(null);
    }

  } catch (error) {
    console.log("Logout error", error);
  }
  setIsLoggedIn(false);
};

export default useAuthStore;