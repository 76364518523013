import { Tooltip } from '@mui/material';
import React from 'react';
import { iRightArrow } from '../../../App/Utility/source';
import Image from '../../Image/Image';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import { useNavigate } from 'react-router-dom';

const StudentSchoolTableRow = ({ data, index }) => {

    const navigate = useNavigate();

    return (
        <>
            <tr onClick={() => { navigate(`/school/details/${data?.id}`) }} className={`border-b my-s10 border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >
                <th className='m-2 font-normal text-center border-r py-s10'>
                    {index}
                </th>

                <td className='border-r-[1px] p-s10 text-left min-w-[150px] max-w-[150px]'>
                    <div className='flex items-center sm:flex-col md:flex-row'>
                        <div className='ml-s4 min-w-[45px] max-w-[45px]'>
                            <Image cursorPointerClass="cursor-pointer" className='rounded-full w-w44 h-h44 grow-0' src={data?.cover} />
                        </div>
                        <Tooltip title={data?.name}>
                            <div className='capitalize font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12 truncate'>
                                {data?.name ?? <CommonEmptyStatus />}</div>
                        </Tooltip>
                    </div>
                </td>

                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody min-w-[100px]'>
                    {data?.cvr ? data.cvr : <CommonEmptyStatus />}
                </td>


                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody truncate min-w-[150px] max-w-[150px]'>
                    {data?.user_email ? data.user_email
                        : <CommonEmptyStatus />}
                </td>


                <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody min-w-[150px] max-w-[150px]'>
                    {data?.phone_number ? data.phone_number : <CommonEmptyStatus />}
                </td>
            </tr>
        </>
    );
};

export default StudentSchoolTableRow;