import React, { useEffect } from 'react'
import CommonTitle from '../../../Components/Title/CommonTitle';
import useStudentStore, { getStudentDetails, getStudentHistoryCategoryLesson } from './../../../App/Stores/StudentStore';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import { PageTitle } from './../../../App/Utility/UtilityFunctions';
import { t } from 'i18next';
import BackLink from '../../../Components/Pagination/BackLink';
import { useLocation, useParams } from 'react-router-dom';
import CommonTable from '../../../Components/Table/CommonTable';
import CurriculumHistoryLessonTableRow from '../../../Components/Table/Student/CurriculumHistoryLessonTableRow';
import CommonStudentBasicDetails from '../../../Components/Profile/CommonStudentBasicDetails';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import CommonCategoryDetails from '../../../Components/Profile/CommonCategoryDetails';


function CurriculumHistoryDetails() {

    const { studentHistoryCategoryLesson, historyLessonCategory, studentDetails } = useStudentStore();

    const { setLoading } = useUtilityStore();

    const { setBarTitle } = useLayoutStore();

    const { student_id, category_id } = useParams();

    const location = useLocation();

    useEffect(() => {
        PageTitle(t("Curriculum History"));
        setBarTitle("Curriculum History");
        fetchCurriculumHistory()
    }, [setBarTitle]);

    const fetchCurriculumHistory = async () => {
        setLoading(true)
        await getStudentHistoryCategoryLesson(student_id)
        await getStudentDetails(student_id)
    }

    //console.log("historyLessonCategory", historyLessonCategory)

    const curriculumHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("School Name") },
        { index: 4, name: t("Duration") },
        { index: 5, name: t("Type") },
        { index: 6, name: t("Status") },
    ];

    const student_info = {
        name: studentDetails?.name,
        email: studentDetails?.email,
        phone_number: studentDetails?.phone_number,
        joined_date: studentDetails?.joined_date
    }

    //   const schools_info={
    //     school_name: studentCurriculumList?.schools_info[0]?.school_name,
    //     email: studentCurriculumList?.schools_info[0]?.school_all_info?.contact_mail,
    //     phone_number: studentCurriculumList?.schools_info[0]?.school_all_info?.phone_number,
    //     cvr: studentCurriculumList?.schools_info[0]?.school_all_info?.cvr,
    //     joined_date: studentCurriculumList?.schools_info[0]?.school_all_info?.created_at
    //    }
    return (
        <div>
            <div className='mx-s12 md:mx-s32'>

                <CommonTitle title="Curriculum History Details" >
                    {
                        location.pathname === `/student/details/${student_id}/curriculum/history/details/${category_id}` && <BackLink linksArray={[
                            { label: "Student", linkTo: "/student" },
                            { label: "Student Details", linkTo: `/student/details/${student_id}` },
                            { label: "Curriculum History", linkTo: `/student/details/${student_id}/curriculum/history` },
                            { label: "Curriculum History Details", linkTo: `` }
                        ]} />
                    }
                </CommonTitle>
                <div className="bg-cBrandColor2 rounded-br20 py-s20 overflow-hidden">
                    <div className='px-s20'>
                        <CommonStudentBasicDetails
                            student_info={student_info}
                        />
                        <div className="py-s20 px-1">
                            <hr />
                        </div>
                        <CommonCategoryDetails
                            categoriesImage={historyLessonCategory?.category_icon}
                            categoryName={historyLessonCategory?.category_name}
                            lessonDuration={historyLessonCategory?.lesson_durations}
                            totalLesson={historyLessonCategory?.lesson_count}
                        />
                        <div className="pt-s20 px-1">
                            <hr />
                        </div>

                        {/* <div className='flex justify-between mt-s20 mb-s10'>
                            <span className='text=fs14 font-fw600'>Lessons</span>
                            <div className='border-2 border-cBrand rounded-full'>
                                <CommonButton
                                    onClick={() => { setShowAddCurriculumLessonModal(true) }}
                                    colorType="BackgroundAndCategory"
                                    btnLabel="Add Lesson"
                                    roundedFull="true"
                                    width="w-[130px]"
                                    icon={<div className="mr-s5"><img className="w-s20 h-s15" src={iBlueAddItem} alt="" /></div>}
                                />
                            </div>
                        </div> */}
                    </div>

                    <div>
                        <CommonTable
                            headers={curriculumHeaders}
                            tableTitle="Lessons"
                            shoSearchBox={false}
                            pagination={false}
                            showPageCountText={false}
                            showPagination={false}
                            items={
                                <>
                                    {
                                        studentHistoryCategoryLesson?.lessons?.length > 0 ?
                                            studentHistoryCategoryLesson?.lessons?.map((item, index) => (
                                                <CurriculumHistoryLessonTableRow data={item} index={index} key={index}/>
                                            )) :
                                            <tr className='w-full'>
                                                <th colSpan={5} className="py-s10">
                                                    No Data Found !
                                                </th>
                                            </tr>
                                    }
                                </>
                            }
                        /> </div>


                </div>
            </div>
        </div>
    )
}

export default CurriculumHistoryDetails
