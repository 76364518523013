// export const BaseUrlSrc = "https://api-maway.genieinfo.tech/";
// export const BaseUrlSrc = "https://dev-api.maway.dk/";
export const BaseUrlSrc = "https://stg.maway.dk/";
// export const BaseUrlSrc = "http://192.168.0.212:8004/";
//export const BaseUrlSrc = "https://api-maway.genieinfo.tech/";
// export const BaseUrlSrc = "http://192.168.0.212:8004/";
// export const BaseUrlSrc = "http://192.168.0.212:8001/";
//export const BaseUrlSrc = "http://192.168.1.110:8000/";
//export const BaseUrlSrc = "http://192.168.31.235:8000/";!

//export const BaseUrlSrc = "http://192.168.31.235:8000/";
//export const BaseUrlSrc = "http://192.168.0.212:8004/"
//export const BaseUrlSrc = "http://192.168.0.212:8001/";

const BaseUrl = BaseUrlSrc + "api/v1.1";
export default BaseUrl;

export const userLoginUrl = "/auth/login";
export const userVerifyUrl = "/auth/user";
export const userLogoutUrl = "/auth/logout";

// b: Dashboard Urls
export const dashboardDataUrl = "/admin/dashboard";
export const dashboardPendingActivityUrl = "/admin/dashboard/pending-activity";

// blue: shop urls
export const getAllShopListUrl = "/admin/shop/get-all-shops";
export const searchAllShopListUrl = "/admin/shop/get-shops-filter";
export const getShopDetailsUrl = "/admin/shop/show-shop";
export const addNewShopUrl = "/admin/shop/add-new-shop";
export const updateShopUrl = "/admin/shop/update-shop";
export const deleteShopUrl = "/admin/shop/delete-shop";

//green: category urls
export const getAllCategoryUrl = "/admin/category/get-list";
export const addCategoryUrl = "/admin/category/create";
export const deleteCategoryUrl = "/admin/category/delete";
export const editCategoryUrl = "/admin/category/update";
export const toggleStatusCategoryUrl = "/admin/category/status-toggle";
export const detailCategoryUrl = "/admin/category/details";

// blue: school urls
export const getAllSchoolListUrl = "/admin/school/filter";
export const schoolAdditionalUrl = "/admin/school/additional-info";
export const detailSchoolUrl = "/admin/school/show";
export const toggleSchoolCategoryUrl = "/admin/school/status-toggle";
export const editSchoolUrl = "/admin/school/update";
export const schoolInvoiceIndexUrl = "/admin/school/invoice/filter";
export const schoolCategoryUrl = "/admin/school/categories/index"
export const schoolCategoryShowUrl = "/admin/school/categories/show"
export const schoolCategoriesToggleUrl = "/admin/school/categories/status-toggle"
export const schoolCategoriesLessonDeleteUrl = "/admin/school/categories/lesson/delete"
export const schoolCategoriesUpdateUrl = "/admin/school/categories/update"
export const schoolCategoriesLessonAddUrl = "/admin/school/categories/lesson/create"
export const schoolCategoriesLessonEditUrl = "/admin/school/categories/lesson/update"
export const schoolInstructorListUrl = "/admin/school/instructors/index"
export const schoolInstructorShowUrl = "/admin/school/instructors/show"
export const schoolInstructorsFindUrl = "admin/school/instructors/find"
export const schoolInstructorsFindShowUrl = "/admin/school/instructors/find-show"
export const schoolInstructorsAddUrl = "/admin/school/instructors/add"
export const schoolInstructorsRemoveUrl = "/admin/school/instructors/remove"
export const schoolInstructorsRejectUrl = "/admin/school/instructors/reject"
export const schoolInstructorAcceptUrl = "/admin/school/instructors/accept"
export const schoolStudentListUrl = "/admin/school/student/index"

// green: voucher urls
export const getAllVoucherUrl = "/admin/voucher/index";
export const searchVoucherUrl = "/admin/voucher/filter";
export const addVoucherUrl = "/admin/voucher/add";
export const toggleVoucherUrl = "/admin/voucher/status-toggle";
export const getVoucherDetailsUrl = "/admin/voucher/details";
export const deleteVoucherUrl = "/admin/voucher/delete";

// blue: orders urls
export const getAllOrdersUrl = "/shop/orders/filter";
export const updateOrderActionUrl = "/shop/orders/action";
export const getOrderDetailsUrl = "/shop/orders/show";

// yellow: gift card urls
export const getAllGiftCardsUrl = "/common/post-card/index";
export const addGiftCardUrl = "/shop/post-card/add";
export const deleteGiftCardUrl = "/shop/post-card/delete";
export const updateGiftCardUrl = "/shop/post-card/update";
export const getGiftCardsDetailsUrl = "/common/post-card/show";
export const getGiftCardsOrderUrl = "/shop/orders/filter";

// green: boosted shop urls
export const getAllBoostedShopsUrl = "/admin/boosted-shop/index";
export const addBoostedShopsUrl = "/admin/boosted-shop/add";
export const searchBoostedShopsUrl = "/admin/boosted-shop/filter";
export const deleteBoostedShopsUrl = "/admin/boosted-shop/delete";
export const updateBoostedShopsUrl = "/admin/boosted-shop/edit";

//blue: settings api urls
export const allFeesUrl = "/admin/settings/get";
export const updateFeesUrl = "/admin/settings/update";
export const toggleAllNotificationUrl = "/common/auth/profile/toggle-settings";
export const changeLanguageUrl = "/common/auth/profile/change-language";

// recommended category
export const updateRecommendedCategoryUrl =
  "/admin/category/update-recommended-category";

// privacy policy
export const getPrivacyUrl = "/admin/privacy-policy/get";
export const updatePrivacyUrl = "/admin/privacy-policy/update";

// terms
export const getTermsUrl = "/admin/terms-and-condition/get";
export const updateTermsUrl = "/admin/terms-and-condition/update";

//blue: update profile
export const updateProfileUrl = "/common/auth/profile/update";
export const getProfileUrl = "/common/auth/user";
export const changePasswordUrl = "/common/auth/profile/change-password";
export const deleteAccountUrl = "/common/auth/profile/delete";

//contact us app  
export const getContactUsListUrl = "/admin/contact-us/get-chat-list";
export const getChatDetailUrl = "/admin/contact-us/get-chat-details";
export const messageSendUrl = "/admin/contact-us/send";
export const messageSeenUrl = "/admin/contact-us/seen";

// contact us web  
export const getWebContactUsListUrl = "/admin/contact-us/guest-index";
export const updateContactWebSeenUrl = "/admin/contact-us/seen";
export const sendContactWebReplyUrl = "/admin/contact-us/guest-reply";

// b: License
export const getLicenseIndexUrl = "/admin/license/index";
export const toggleLicenseUrl = "/admin/license/toggle-status";
export const addNewLicenseUrl = "/admin/license/add";
export const updateLicenseUrl = "/admin/license/update";
export const deleteLicenseUrl = "/admin/license/delete";
export const filterLicenseUrl = "/admin/license/filter";
export const updateLicenseDurationUrl = "/admin/invoices/license_purchase/edit-license-dates";
export const licenseDetailsUrl = "/admin/license/details"

// b:     instructor
export const instructorIndexUrl = "/admin/instructor/all";
export const instructorDetailsUrl = "/admin/instructor/show";
export const instructorAdditionalUrl = "/admin/instructor/additional-info";
export const instructorToggleStatusUrl = "/admin/instructor/status-toggle";
export const instructorFilterUrl = "/admin/instructor/filter";
export const editInstructorDetailUrl = "/admin/instructor/update";

// g:   invoice
export const getInvoiceIndexUrl = "/admin/invoices/license_purchase/index";
export const getInvoiceDetailsUrl = "/admin/invoices/license_purchase/show";
export const changeInvoiceStatusUrl = "admin/invoices/license_purchase/status-change";
export const shareInvoiceDetailsUrl = "/share";
export const secondaryInvoiceDetailsUrl = "/admin/school/invoice/show";

// banner section
export const getAllBannerListUrl = "/admin/banner/index";
export const addNewBannerUrl = "/admin/banner/create";
export const deleteBannerUrl = "/admin/banner/delete";
export const editBannerUrl = "/admin/banner/update";
export const toggleStatusBannerUrl = "/admin/banner/status-toggle";

//student
export const getStudentListUrl = "/admin/student/all";
export const getStudentDetailsUrl = "/admin/student/show";
export const getStudentAdditionalUrl = "/admin/student/additional-info";
export const toggleStatusStudentUrl = "/admin/student/status-toggle";
export const studentFilterSearchUrl = "/admin/student/filter";
export const editStudentDetailUrl = "/admin/student/update"
export const getStudentCurriculumIndex = "/admin/student/curriculum/index";
export const studentHistoryCategoryUrl = "/admin/student/curriculum/history/category-index"
export const studentHistoryCategoryLessonUrl = "/admin/student/curriculum/history/category-lessons"
export const studentSchoolListUrl = "/admin/student/schools/index"
export const studentFindSchoolListUrl = "/admin/student/schools/find-school/index"
export const studentFindSchoolInfoUrl = "/admin/student/schools/find-school/school-info"
export const studentFindSchoolAddUrl = "/admin/student/schools/find-school/add"
export const studentFindSchoolFilterOptionsUrl = "/admin/student/schools/find-school/get-filter-options"

// FAQ urls
export const allFaqListUrl = "/admin/faq/filter";
export const addFaqUrl = "/admin/faq/create";
export const updateFaqUrl = "/admin/faq/update";
export const deleteFaqUrl = "/admin/faq/delete";
export const faqDetailUrl = "/admin/faq/show";
export const toggleStatusFaqUrl = "/admin/faq/status-toggle";

//Terms & Condition
export const allTermsConditionUrl = "/admin/terms-conditions/index";
export const addTermsConditionUrl = "/admin/terms-conditions/create";
export const detailTermsConditionUrl = "/admin/terms-conditions/show";
export const editTermsConditionUrl = "/admin/terms-conditions/update";
export const deleteTermsConditionUrl = "/admin/terms-conditions/delete";
export const toggleStatusTermsConditionUrl =
  "/admin/terms-conditions/status-toggle";

//Delete message
export const deleteMessageUrl = "/admin/delete-message/index";
export const createDeleteMessageUrl = "/admin/delete-message/create";
export const editDeleteMessageUrl = "/admin/delete-message/update";
export const toggleStatusDeleteMessageUrl = "/admin/delete-message/status-toggle";

//Moms
export const generalSettingsIndexUrl = "/admin/general-settings/index";
export const editGeneralSettingsIndexUrl = "/admin/general-settings/update";

//city
export const CityIndexUrl = "/admin/city/index";
export const addCityUrl = "/admin/city/add";
export const editCityUrl = "/admin/city/update";
export const deleteCityUrl = "/admin/city/delete";
export const toggleStatusCityUrl = "/admin/city/toggle";

//pass change url
export const settingsChangePasswordUrl = "/admin/profile/change-password";
