import React from 'react'
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonButton from './../../../Components/Button/CommonButton';
import { schoolToggleStatus } from '../../../App/Stores/SchoolStore';
import useSchoolStore from './../../../App/Stores/SchoolStore';


function SchoolDeactivateModal() {

    const { showDeactivateSchool , setShowDeactivateSchool, schoolDetails } = useSchoolStore();

   // console.log("SchoolDeactivateModal",schoolDetails)

    return (
        <>
            <CommonModal
                showModal={showDeactivateSchool}
                setShowModal={setShowDeactivateSchool}
                modalTitle="Confirmation"
                mainContent={
                    <div className='flex flex-col items-center'>
                        <div className="pt-5">Are you sure you want to deactivate this school?</div>
                        <div className="pt-5">
                            <CommonButton
                                btnLabel='Deactivate'
                                colorType='danger'
                                onClick={async() => {
                                    let deactivateSuccess = await schoolToggleStatus(schoolDetails?.data?.school?.id);
                                    if (deactivateSuccess) setShowDeactivateSchool(false);
                                }} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default SchoolDeactivateModal