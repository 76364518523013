import React, { useState } from 'react';
import useContactStore, { getWebContactUsList } from '../../../../App/Stores/ContactStore';
import { iCloseRed } from '../../../../App/Utility/source';
import { Toastr } from '../../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';

const FilterContactWebMessage = () => {
    const [customDateShow, setCustomDateShow] = useState(false);

    const {
        showFilterWebMessage,
        setShowFilterWebMessage,
        filterFormWeb,
        setFilterFormWeb,
        FilterContactWebActive,
        setFilterContactWebActive,
        contactWebPageUrl,
        contactWebSearch,
    } = useContactStore();
    return (
        <>
            <CommonModal
                showModal={showFilterWebMessage}
                setShowModal={setShowFilterWebMessage}
                modalTitle="Filter Contact Us"
                widthClass='w-[700px]'

                mainContent={
                    <form onSubmit={(e) => e.preventDefault()} className='text-cChipText'>

                        {/* g: message type */}
                        <div className="pt-5 font-semibold text-fs14">Select Message Type</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, is_seen: null });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterFormWeb.is_seen === null ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, is_seen: 1 });
                                    console.log("read");
                                }}
                                label={"read"}
                                active={filterFormWeb.is_seen === 1 ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, is_seen: 0 });
                                    console.log("unread");
                                }}
                                label={"unread"}
                                active={filterFormWeb.is_seen === 0 ? true : false}
                            />
                        </div>

                        {/* g: reply status */}
                        <div className="pt-5 font-semibold text-fs14">Select Reply Status</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, is_reply: null });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterFormWeb.is_reply === null ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, is_reply: 1 });
                                    console.log("replied");
                                }}
                                label={"replied"}
                                active={filterFormWeb.is_reply === 1 ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, is_reply: 0 });
                                    console.log("not_replied");
                                }}
                                label={"not replied"}
                                active={filterFormWeb.is_reply === 0 ? true : false}
                            />
                        </div>

                        {/* g: select date */}
                        <div className="pt-5 font-semibold text-fs14 pb-s5">Select Date</div>
                        {!customDateShow ? <div className="flex pt-s10 space-x-s10">
                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, date: "" });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterFormWeb.date === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, date: "weekly" });
                                    console.log("weekly");
                                }}
                                label={"weekly"}
                                active={filterFormWeb.date === "weekly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, date: "monthly" });
                                    console.log("monthly");
                                }}
                                label={"monthly"}
                                active={filterFormWeb.date === "monthly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterFormWeb({ ...filterFormWeb, date: "custom" });
                                    setCustomDateShow(true)
                                }}
                                label={"custom date"}
                            />
                        </div>
                            :
                            <div className='flex justify-between items-center w-full bg-cLine rounded-br10'>
                                <div className='p-5 rounded-full'>
                                    <CommonInput
                                        // required={customDateShow ? true : false}
                                        type='date'
                                        value={filterFormWeb.start_date}
                                        startDate={"1901-01-01"}
                                        label="start date"
                                        allowPastDates={true}
                                        onChange={(e) => {
                                            // console.log((e.target.value));
                                            const startDate = new Date(e.target.value);
                                            const endDate = new Date(filterFormWeb.end_date);

                                            // console.log("startDate::::", startDate, "; endDate::::", endDate);

                                            if (startDate > endDate) {
                                                setFilterFormWeb({ ...filterFormWeb, end_date: "", start_date: e.target.value });
                                            } else {
                                                setFilterFormWeb({ ...filterFormWeb, start_date: e.target.value });
                                            }
                                            // setFilterFormWeb({ ...filterFormWeb, start_date: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className='flex items-center'>
                                    <div className='pr-s15'>
                                        <CommonInput
                                            // required={customDateShow ? true : false}
                                            type='date'
                                            label="end date"
                                            value={filterFormWeb.end_date}
                                            // disabled={!filterFormWeb.start_date}
                                            startDate={filterFormWeb.start_date ? filterFormWeb?.start_date : "1901-01-01"}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setFilterFormWeb({ ...filterFormWeb, end_date: (e.target.value) });
                                            }}
                                        />
                                    </div>
                                    <img
                                        onClick={() => {
                                            setFilterFormWeb({ ...filterFormWeb, start_date: "", end_date: (""), date: "" });
                                            setCustomDateShow(false)
                                        }}
                                        src={iCloseRed}
                                        alt=""
                                        className='cursor-pointer pr-s15 w-s40 h-s30'
                                    />
                                </div>
                            </div>
                        }

                        <div className="flex justify-between pt-5">
                            <CommonButton
                                onClick={() => {
                                    setCustomDateShow(false);
                                    setFilterFormWeb({
                                        status: "", // user type [Schools,Instructors, Students], for all use "" empty string
                                        is_reply: null,
                                        is_seen: null,
                                        date: "", //[weekly, monthly, custom]
                                        custom_date: "",
                                        start_date: "",
                                        end_date: "",
                                    });
                                }}
                                fullRounded={true}
                                btnLabel='Clear'
                                colorType="FilterClearButton"
                                text="fs16"
                            />
                            <CommonButton
                                onClick={async () => {

                                    if (!FilterContactWebActive) setFilterContactWebActive(true);
                                    if (filterFormWeb) {
                                        Toastr({ message: "Your filter successfully Apply", type: "success" });
                                        let filterSuccess = await getWebContactUsList(contactWebPageUrl, filterFormWeb, contactWebSearch);
                                        // let filterSuccess = await getContactUsList("", filterFormWeb);
                                        if (filterSuccess) setShowFilterWebMessage(false);
                                    }
                                }}
                                type="submit"
                                fullRounded={true}
                                btnLabel='apply'
                                colorType='primary'
                                roundedFull="true" />
                        </div>

                    </form>
                }
            />
        </>
    )
}

export default FilterContactWebMessage


const FilterChip = ({ label, active = false, onclick }) => {

    return (
        <div
            onClick={onclick}
            className={`
                capitalize rounded-full border border-cChipBorder hover:border-cBrand py-s5 px-5 text-medium hover:text-white hover:bg-cBrand select-none cursor-pointer
                ${active ? "text-white bg-cBrand border-cBrand" : "bg-white text-cChipText border-cChipBorder"}
            `}
        >
            {label}
        </div>
    )
}
