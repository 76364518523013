import React from 'react';
import CommonModal from '../../../Components/Modal/CommonModal';
import CommonInput from './../../../Components/Input/CommonInput';
import CommonButton from '../../../Components/Button/CommonButton';
import useSchoolStore from './../../../App/Stores/SchoolStore';
import SelectInput from './../../../Components/Input/SelectInput';

const EditClassRoomDetailModal = () => {

    const { showEditClassRoomDetailModal, setShowEditClassRoomDetailModal } = useSchoolStore()

    return (
        <div>
            <CommonModal
                showModal={showEditClassRoomDetailModal}
                setShowModal={setShowEditClassRoomDetailModal}
                modalTitle="Edit ClassRoom"
                mainContent={
                    <>
                        <from>

                            <div className="mb-s20">
                                <CommonInput type="text"label="Classroom Name" placeholder="Write Name" />
                            </div>

                            <div className="mb-s20">
                                <SelectInput label="Select Address" placeholder="Select Address" />
                            </div>

                            <div className="mb-s20">
                                <SelectInput label="Select Classroom" placeholder="3" />
                            </div>

                            <div className='flex justify-between my-s28'>
                                <CommonInput className2='w-s325' label="Zip" placeholder="Zip Code" />
                                <CommonInput className2='w-s325' label="Capacity" placeholder="Capacity" />
                            </div>

                            <CommonInput label="Capacity" placeholder="Capacity" />

                            <div className='flex justify-between mt-s40'>
                                <CommonButton onClick={() => { setShowEditClassRoomDetailModal(false) }} type="submit" btnLabel="Delete User" colorType="warning" roundedFull="true"  />
                                <CommonButton onClick={() => { setShowEditClassRoomDetailModal(false) }} type="submit" btnLabel="Update Class" colorType="primary" roundedFull="true" />
                            </div>

                        </from>
                    </>
                }
            />
        </div>
    );
};

export default EditClassRoomDetailModal;