import React, { useEffect } from 'react';
import CommonTitle from './../../../Components/Title/CommonTitle';
import CommonTable from './../../../Components/Table/CommonTable';
import CurriculumHistoryTableRow from './../../../Components/Table/Student/CurriculumHistoryTableRow';
import { t } from 'i18next';
import { useLocation, useParams } from 'react-router-dom';
import BackLink from '../../../Components/Pagination/BackLink';
import useStudentStore, { getStudentHistoryCategoryList } from '../../../App/Stores/StudentStore';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import { iShareBlue, iShareWhite } from '../../../App/Utility/source';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import { PageTitle } from '../../../Utility/UtilityFunctions';

const CurriculumHistory = () => {

  const {
    setStudentCurriculumHistoryShareId,
    studentHistoryCategoryList,
    setShowShareCurriculumHistoryModal
  } = useStudentStore()

  const { student_id } = useParams();

  const location = useLocation();

  const CurriculumHistoryHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Lessons") },
    { index: 4, name: t("Durations") },
  ];

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("Curriculum History"));
    setBarTitle("Curriculum History");
    fetchCurriculumHistory()
  }, [student_id])

  const fetchCurriculumHistory = async () => {
    await getStudentHistoryCategoryList(student_id)
  }

  //console.log("studentHistoryCategoryList",studentHistoryCategoryList )

  return (
    <div className='mx-s12 md:mx-s32'>

      <div className='flex flex-col sm:flex sm:flex-row justify-between'>
        <CommonTitle title="Curriculum History" >
          {
            location.pathname === `/student/details/${student_id}/curriculum/history` && <BackLink linksArray={[
              { label: "Student", linkTo: "/student" },
              { label: "Student Details", linkTo: `/student/details/${student_id}` },
              { label: "Curriculum History", linkTo: `` }
            ]} />
          }
        </CommonTitle>

        {studentHistoryCategoryList.length > 0 && <div className='mb-s20 sm:mt-s20'>
          <CommonButtonOutlined
            btnLabel='share Curriculum History'
            onClick={() => {
              // resetInvoiceShareForm();
              setShowShareCurriculumHistoryModal(true);
              setStudentCurriculumHistoryShareId("")
            }}
            width={"w-[250px]"}
            colorType='primary'
            iconRight={iShareBlue}
            iconRightHover={iShareWhite}
          />
        </div>}
      </div>

      {studentHistoryCategoryList.length > 0 ? <div className='bg-cBrandColor2 rounded-br20 overflow-hidden'>
        <CommonTable
          headers={CurriculumHistoryHeaders}
          tableTitle=""
          shoSearchBox={false}
          pagination={false}
          showPageCountText={true}
          showPagination={false}
          items={
            <>
              {
                studentHistoryCategoryList.length > 0 ?
                  studentHistoryCategoryList?.map((item, index) => (
                    <CurriculumHistoryTableRow key={index} index={index} data={item} />
                  ))
                  : <tr className='w-full'>
                    <th colSpan={5} className="py-s10">
                      No Data Found !
                    </th>
                  </tr>
              }
            </>
          }
        />
      </div>

        :

        <div className="h-[350px] flex items-center justify-center bg-cBrandColor2 rounded-br20 overflow-hidden">
          <div className=''>
            <div className='text-fs18 font-fw600 text-cHighlightedTexts mb-s10'>
              No Curriculum History available
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default CurriculumHistory;