import { t } from "i18next";
import React, { useEffect } from "react";
import useBannerStore, { getAllBannerList } from "../../App/Stores/bannerStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { iAddItem } from "../../App/Utility/source";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonBannerCard from "../../Components/bannerCard/CommonBannerCard";
import CommonButton from "../../Components/Button/CommonButton";
import CommonTitle from "../../Components/Title/CommonTitle";

const Banner = () => {
  const { setShowAddBannerModal, allBannerList } = useBannerStore();

  const { setBarTitle } = useLayoutStore();

  //console.log("All Banner:", allBanner);

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("Banner"));
    setBarTitle("Banner");
    fetchBannerData();
  }, [setBarTitle]);

  const fetchBannerData = async () => {
    await getAllBannerList();
  };

  //console.log("allBannerList",allBannerList)

  return (
    <div>
      <div className="mx-s12 md:mx-s32">
        <CommonTitle title="Banner" />
        <div className="bg-cBrandColor2 rounded-br20 px-s20 pt-s20">
          <div className="flex flex-row-reverse gap-5 justify-between">
            <div className="flex flex-row-reverse gap-5">
              <div className="">
                <CommonButton
                  colorType="primary"
                  btnLabel="Add Banner"
                  roundedFull="true"
                  //width="w-[199px]"
                  //className1="h-[48px]"
                  onClick={() => {
                    setShowAddBannerModal(true);
                  }}
                  icon={
                    <div className="mr-s5">
                      <img className="w-s20 h-s15" src={iAddItem} alt="" />
                    </div>
                  }
                />
              </div>
              {/* <div className="flex items-center rounded-full border-2 w-s90 hs-12 border-cBrand">
                <FreeDropDown
                  button={
                    <div className="flex items-center text-[#2257AA] gap-1">
                      <img
                        className="w-s20 h-s24"
                        src={iBannerAllIcon}
                        alt=""
                      />
                      <h2 className="text-base leading-6 w-s20">All</h2>
                    </div>
                  }
                  body={
                    <div className="flex flex-col">
                      <button className="p-2 text-left hover:bg-cBtnHoverColor">
                        All
                      </button>
                      <button className="p-2 text-left hover:bg-cBtnHoverColor">
                        Student
                      </button>
                      <button className="p-2 text-left hover:bg-cBtnHoverColor">
                        School
                      </button>
                      <button className="p-2 text-left hover:bg-cBtnHoverColor">
                        Instructor
                      </button>
                    </div>
                  }
                />
              </div> */}
            </div>
            {/* <div>
              <CommonSearchBox />
            </div> */}
          </div>
          <div>
            <div>
              <h1 className="font-fw600 text-fs24">School’s Banner</h1>
            </div>
            <div className="grid gap-x-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
              {allBannerList?.data?.length > 0 ? (
                allBannerList?.data?.map((item) => {
                  if (item.app_type === "school") {
                    return <CommonBannerCard key={item.id} item={item} />;
                  } else return null;
                })
              ) : (
                <span className="my-s20">No Banner Found</span>
              )}
            </div>
          </div>
          <hr className="mb-s10" />
          <div>
            <div>
              <h1 className="font-fw600 text-fs24">Instructor’s Banner</h1>
            </div>
            <div className="grid gap-x-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
              {allBannerList?.data?.length > 0 ? (
                allBannerList?.data?.map((item) => {
                  if (item.app_type === "instructor") {
                    return <CommonBannerCard key={item.id} item={item} />;
                  } else return null;
                })
              ) : (
                <span className="my-s20">No Banner Found</span>
              )}
            </div>
          </div>
          <hr className="mb-s10" />
          <div>
            <div>
              <h1 className="font-fw600 text-fs24">Student’s Banner</h1>
            </div>
            <div className="grid gap-x-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
              {allBannerList?.data?.length > 0 ? (
                allBannerList?.data?.map((item) => {
                  if (item.app_type === "student") {
                    return <CommonBannerCard key={item.id} item={item} />;
                  } else return null;
                })
              ) : (
                <span className="my-s20">No Banner Found</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
