import React from "react";
import { useState } from "react";
import useSettingsStore, { createFaq } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import SelectInput from './../../../Components/Input/SelectInput';

const AddFaqModal = () => {

  const { showAddFaqModal, setShowAddFaqModal } = useSettingsStore();

  const [addForm, setAddForm] = useState({
    title: "",
    description: "",
    app_type: ''
  });

  const formSubmit = async (e) => {
    e.preventDefault();
    if (addForm?.title && addForm?.description && addForm?.app_type) {
      let addSuccess = await createFaq(addForm)
      if (addSuccess) {
        await setAddForm({
          title: "",
          description: "",
          app_type: "",
        });
        await setShowAddFaqModal(false)
      }
    }
  };

  const durationArray = [
    {
      title: "Student",
      value: 'student',
      selected: false
    },
    {
      title: "instructor",
      value: 'instructor',
      selected: false
    },
    {
      title: "school",
      value: 'school',
      selected: false
    },
  ]


  return (
    <div>
      <CommonModal
        showModal={showAddFaqModal}
        setShowModal={setShowAddFaqModal}
        modalTitle="Add FAQ"
        mainContent={
          <>
            <form onSubmit={formSubmit}>

              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Write Question "
                  className3="cHighlightedTexts"
                  placeholder="Write Question"
                  value={addForm?.title}
                  max_input={255}
                  onChange={(e) => setAddForm({ ...addForm, title: e.target.value })}
                />
              </div>

              <div className="">
                <SelectInput
                  required={true}
                  label="Choose User"
                  placeholder="Choose User"
                  dataArray={durationArray}
                  selectOptionOnChange={(e) => setAddForm({ ...addForm, app_type: e })}
                />
              </div>

              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Write Answer"
                  rows="4"
                  textarea="true"
                  placeholder="Write here..."
                  value={addForm?.description}
                  onChange={(e) => setAddForm({ ...addForm, description: e.target.value })}
                />
              </div>

              <div className="flex justify-center mt-s20">
                <CommonButton
                  type="submit"
                  btnLabel="Add"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default AddFaqModal;
