import React from 'react';
import CommonInput from '../../../Components/Input/CommonInput';
import { iProfile2 } from '../../../App/Utility/source';

const ChangeInstructor = ({onClick}) => {
    return (
        <div className='mb-s12 '>
        <div className='bg-cBackgroundAndCategory rounded-br10 flex justify-between'>
          <div className='flex'>
           <img className='w-s44 h-s44 m-s14' src={iProfile2} alt=""/>
            <span className='ml-s10 m-s20'>Maruf Mawla</span>
         </div>

       <div className='m-s15 cursor-pointer'>
       <CommonInput className2='cursor-pointer' onClick={onClick}  type="radio"/>
       </div>
       </div>
      </div>
    );
};

export default ChangeInstructor;