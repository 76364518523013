import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { settingsChangePassword } from "../../../App/Stores/SettingsStore";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import Settings from "../Settings";

const ChangePassword = () => {

  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);

  const [cpForm, setCpForm] = useState({
    old_password: "",
    password: "",
    password_confirmation: ""
  });

  const handleCpChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCpForm({ ...cpForm, [name]: value });
  };

  
  const formSubmit = async (e) => {
    e.preventDefault();

     if (cpForm?.old_password && cpForm?.password && cpForm?.password_confirmation) {
      if(cpForm?.password === cpForm?.password_confirmation){
      let addSuccess = await settingsChangePassword(cpForm)
      if (addSuccess) {
        await setCpForm({ old_password: "",password: "",password_confirmation: ""});
      }
    }
  }else{
    return  Toastr({ message: "Your Password don't match", type: "warning" });
  }
  };

  // settingsChangePassword
  return (
    <Settings>
    <form onSubmit={formSubmit}>
      <div className="text-2xl font-bold mb-5">{t("Change Password")}</div>
      <hr className="mt-s16 mb-s8" />

      <div className="">
        <CommonInput
          label={t("Current Password")}
          name="old_password"
          placeholder={t("Type Current Password")}
          value={cpForm?.old_password}
          onChange={handleCpChange}
          type="password"
          togglePasswordBtn={true}
          required={true}
          onFocus={() => setFocused(true)}
        />
      </div>
      <div className="py-s20">
        <CommonInput
          label={t("New Password")}
          name="password"
          placeholder={t("Type New Password")}
          value={cpForm?.password}
          onChange={handleCpChange}
          type="password"
          togglePasswordBtn={true}
          required={true}
        />
      </div>
      <CommonInput
        label={t("Confirm New Password")}
        name="password_confirmation"
        placeholder={t("Type New Password")}
        value={cpForm?.password_confirmation}
        onChange={handleCpChange}
        type="password"
        togglePasswordBtn={true}
        required={true}
      />

      <div className="flex flex-row justify-center pt-s20">
        <CommonButton
          roundedFull="true"
          type="submit"
          btnLabel={t("Change Password")}
          onClick={async () => {
            console.log("working");
            // const is_success = await submitChangePassword(cpForm);
            // if (is_success)
            //     setCpForm({
            //         currentPassword: "",
            //         newPassword: "",
            //         confirmNewPassword: "",
            //     });
          }}
          colorType={
            cpForm?.old_password &&
              cpForm?.password &&
              cpForm?.password_confirmation &&
              cpForm?.password === cpForm?.password_confirmation
              ? "primary"
              : "basic"
          }
          isDisabled={
            cpForm?.old_password &&
              cpForm?.password &&
              cpForm?.password_confirmation &&
              cpForm?.password=== cpForm?.password_confirmation
              ? false
              : true
          }
          width="w-40"
        />
      </div>
    </form>
    </Settings>
  );
};

export default ChangePassword;
