import React from 'react';
import useSchoolStore, { schoolInstructorsFindFn } from '../../../../App/Stores/SchoolStore';
import { iRightArrow } from '../../../../App/Utility/source';
import Image from '../../../../Components/Image/Image';
import CommonSearchBox from '../../../../Components/Input/CommonSearchBox';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { useDebounce } from 'use-debounce';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const AddSchoolInstructorModal = () => {

    const {
        showAddSchoolInstructorModal,
        setShowAddSchoolInstructorModal,
        setSchoolInstructorsSearchValue,
        schoolInstructorsSearchValue,
        schoolInstructorsFindValue,
        setSchoolInstructorsFindValue
    } = useSchoolStore();

    const { school_id } = useParams();

    const [searchValue] = useDebounce(schoolInstructorsSearchValue, 500);

    const navigate = useNavigate()

    useEffect(() => {
        fetchSchoolInstructorSearch()
    }, [searchValue])

    const fetchSchoolInstructorSearch = async () => {
        const body = { "school_id": school_id, "search": searchValue }
        if (searchValue === "") {
            setSchoolInstructorsFindValue([])
        } else {
            await schoolInstructorsFindFn(body)
        }
    }

    //console.log("schoolInstructorsFindValue", schoolInstructorsFindValue?.id)

    return (
        <div>
            <CommonModal
                showModal={showAddSchoolInstructorModal}
                setShowModal={setShowAddSchoolInstructorModal}
                modalTitle="Add Instructor"
                mainContent={
                    <>
                        <div className="mt-s20">
                            <div className='text-cHighlightedTexts text-fs14 font-fw600 mb-s5 leading-6'>Search Instructor</div>
                            <CommonSearchBox
                                fullWidth={true}
                                value={schoolInstructorsSearchValue}
                                rounded="rounded-full"
                                onChange={async (e) => {
                                    await setSchoolInstructorsSearchValue(e.target.value)
                                }}
                                onSearchClear={() => {
                                    setSchoolInstructorsFindValue([]);
                                    setSchoolInstructorsSearchValue("")
                                }}
                                withClearSearch={true}
                            />


                            {schoolInstructorsFindValue?.length > 0 ?
                                schoolInstructorsFindValue?.map((item, index) => (
                                    <div className='mt-s20'>
                                        <div onClick={() => {
                                            setShowAddSchoolInstructorModal(false)
                                            navigate(`/school/details/${school_id}/instructor/${item.id}/find`)
                                        }}
                                            className='flex flex-col md:flex-row justify-between bg-cTextFieldGrey p-s10 rounded-br10 cursor-pointer'>
                                            <div className='flex'>
                                                <Image
                                                    src={item?.user?.image}
                                                    className='rounded-full w-w44 h-h44 grow-0'
                                                />
                                                <div className='flex items-center ml-s10 leading-5 text-cHighlightedTexts
                                               text-fs14 font-fw600'>{item?.name}</div>
                                            </div>
                                            <img className="" src={iRightArrow} alt="" />
                                        </div>
                                    </div>
                                )) : <div
                                    className='
                                        mt-s20
                                        text-cBlack
                                        text-fs14 
                                        font-fw600 
                                        flex 
                                        justify-center
                                        '>
                                    <span>No Data !</span>
                                </div>}
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default AddSchoolInstructorModal;