import React from 'react';
import CommonTitle from '../../../Components/Title/CommonTitle';
import LicenseInstructorProfile from './LicenseInstructorProfile';
import LicenseInstructorPremiumCard from './LicenseInstructorPremiumCard';
import InstructorExpertiseArea from './../../../Components/CommonDetailPage/CommonInstructor/InstructorExpertiseArea';
import InstructorAdditionalInfo from '../../../Components/CommonDetailPage/CommonInstructor/InstructorAdditionalInfo';

const LicenseInstructor = () => {

    return (
        <div className='mx-s12 md:mx-s32'>
        <CommonTitle title="Instructor Profile" /> 
        <div className="bg-cBrandColor2 rounded-br20 px-s20 py-s20">
         <div className="grid grid-flow-row sm:grid-flow-row lg:grid-flow-col">   
         <div className="w-full sm:col-span-12 lg:pr-s50">
         <LicenseInstructorProfile/>
         </div>
 
         <LicenseInstructorPremiumCard/>

         </div>
 
         <InstructorExpertiseArea/>
         <InstructorAdditionalInfo/>
         </div>
 
         </div>
    );
};

export default LicenseInstructor;