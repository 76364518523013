import React from 'react';
import { useState } from 'react';
import useSchoolStore, { schoolCategoriesLessonAddFn } from '../../../../App/Stores/SchoolStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import SelectInput from '../../../../Components/Input/SelectInput';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { DownOutlined } from '@ant-design/icons';
import { TimePicker } from 'antd';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import { Toastr } from '../../../../App/Utility/UtilityFunctions';
import CommonTimePicker from '../../../../Components/CommonTimePicker';

const AddSchoolCategoryLesson = () => {

    const { setShowAddSchoolCategoryModal, showAddSchoolCategoryModal } = useSchoolStore();

    const { school_id, school_category_id } = useParams();

    const [addForm, setAddForm] = useState({
        school_category_id: "",
        school_id: "",
        name: "",
        type: "",
        duration: "00:00",
        is_mandatory: true,
        price: "",
        description: "",
        requirements: ""
    });

    const dataArray = [
        {
            title: "Driving",
            value: "driving",
            selected: false,
        },
        {
            title: "Classroom",
            value: "classroom",
            selected: false,
        },
        {
            title: "External",
            value: "external",
            selected: false,
        },
    ]
    // console.log("school_category_id",school_category_id)

    const submitSchoolCategory = async (e) => {
        e.preventDefault()
        console.log("ddForm", addForm)
        if (addForm.school_category_id === ""
            && addForm.school_id === "" &&
            addForm.name === "" && addForm.type === "" &&
            // addForm.is_mandatory === ""
            // &&
            addForm.price === ""
        ) {
            Toastr({ message: "error", type: "error" });
        }
        else {
            if (addForm.duration === "00:00") {
                Toastr({ message: "Time is required", type: "error" });
            } else {
                const value = schoolCategoriesLessonAddFn(addForm)
                if (value) {
                    setAddForm({
                        school_category_id: "",
                        school_id: "",
                        name: "",
                        type: "",
                        duration: "00:00",
                        is_mandatory: false,
                        price: "",
                        description: "",
                        requirement: ""
                    })
                    setAddForm({ duration: "00:00", school_category_id: school_category_id, school_id: school_id })
                    setShowAddSchoolCategoryModal(false)
                }
            }
        }
    }


    useEffect(() => {
        setAddForm({ ...addForm, school_category_id: school_category_id, school_id: school_id })
    }, [school_id, school_category_id])

    return (
        <div>
            <CommonModal
                showModal={showAddSchoolCategoryModal}
                setShowModal={setShowAddSchoolCategoryModal}
                modalTitle="Add Lesson "
                mainContent={
                    <>
                        <div className='mt-s20 '>
                            <form onSubmit={submitSchoolCategory} >
                                {/* <div className="my-s20">
                                    <CommonInput
                                        required={true}
                                        type="text"
                                        label="Category"
                                        placeholder="Category"
                                        value={addForm?.duration}
                                        onChange={(e) => setAddForm({ ...addForm, duration: e.target.value })}
                                    />
                                </div> */}

                                <div className="my-s20">
                                    <SelectInput
                                        required={true}
                                        type="text"
                                        label="Choose Lesson Type"
                                        placeholder="Choose Lesson Type"
                                        dataArray={dataArray}
                                        value={addForm?.type}
                                        selectOptionOnChange={(e) => setAddForm({ ...addForm, type: e })}
                                    />
                                </div>

                                <div className="my-s20">
                                    <CommonInput
                                        required={true}
                                        type="text"
                                        label="Lesson Name"
                                        max_input={50}
                                        placeholder="Lesson Name"
                                        value={addForm?.name}
                                        onChange={(e) => setAddForm({ ...addForm, name: e.target.value })}
                                    />
                                </div>

                                <div className="my-s20">
                                    <CommonInput
                                        required={true}
                                        type="number"
                                        label="Price"
                                        placeholder="Price"
                                        value={addForm?.price}
                                        onChange={(e) => setAddForm({ ...addForm, price: e.target.value })}
                                    />
                                </div>

                                <div className="my-s20">
                                    {/* <TimePicker
                                        style={{ width: '100%', backgroundColor: "#F4F4F4" }}
                                        format="HH:mm"
                                        showNow={false}
                                        size='large'
                                        value={moment(addForm?.duration, "HH:mm")}
                                        suffixIcon={<DownOutlined />}
                                        onSelect={(value) => {
                                            const timeString = moment(value).format("HH:mm");
                                            setAddForm({ ...addForm, duration: timeString })
                                        }} /> */}

                                    <CommonTimePicker
                                        required={true}
                                        label="Duration"
                                        format='HH:mm'
                                        showNow={false}
                                        size='large'
                                        value={addForm?.duration}
                                        onChange={(value) => {
                                            const timeString = moment(value).format("HH:mm");
                                            setAddForm({ ...addForm, duration: timeString })
                                        }}
                                    />
                                </div>

                                <div className="my-s20">
                                    <CommonInput
                                        // required={true}
                                        type="text"
                                        label="Description"
                                        placeholder="Description"
                                        textarea={true}
                                        rows={4}
                                        value={addForm?.description}
                                        onChange={(e) => setAddForm({ ...addForm, description: e.target.value })}

                                    />
                                </div>

                                <div className="my-s20">
                                    <CommonInput
                                        // required={true}
                                        type="text"
                                        label="Requirement"
                                        placeholder="Requirement"
                                        textarea={true}
                                        rows={4}
                                        value={addForm?.requirements}
                                        onChange={(e) => setAddForm({ ...addForm, requirements: e.target.value })}
                                    />
                                </div>


                                <FormControl className="w-ful">
                                    {/* <FormLabel id="demo-row-radio-buttons-group-label"> */}
                                    <h1 className="w-full text-cHighlighted text-fs14 font-fw600 mb-s5">
                                        Is lesson mandatory for package? <span className="text-cWarning">*</span>
                                    </h1>
                                    {/* </FormLabel> */}
                                    <RadioGroup
                                        className="w-full"
                                        required={true}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <div className="flex w-full">
                                            <div className=" flex justify-center">
                                                <FormControlLabel
                                                    value="yes"
                                                    control={<Radio required={true} />}
                                                    label="Yes"
                                                    onChange={(e) =>
                                                        setAddForm({ ...addForm, is_mandatory: true })
                                                    }
                                                />
                                            </div>

                                            <div className="flex justify-center">
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio required={true} />}
                                                    label="No"
                                                    onChange={(e) =>
                                                        setAddForm({ ...addForm, is_mandatory: false })
                                                    }
                                                />
                                            </div>

                                        </div>
                                    </RadioGroup>
                                </FormControl>

                                <div className='flex justify-between mt-s20'>
                                    <CommonButton colorType='warning' btnLabel="Delete" roundedFull="true" />
                                    <CommonButton
                                        type="submit"
                                        btnLabel="Add"
                                        colorType="primary"
                                        roundedFull="true"
                                    // onClick={async () => {
                                    //     if (addForm.school_category_id === ""
                                    //         && addForm.school_id === "" &&
                                    //         addForm.name === "" && addForm.type === "" &&
                                    //         addForm.duration == "00:00" &&
                                    //         // addForm.is_mandatory === ""
                                    //         // &&
                                    //         addForm.price === ""
                                    //     ) {
                                    //         Toastr({ message: "error", type: "error" });
                                    //     }
                                    //     else {
                                    //         const value = schoolCategoriesLessonAddFn(addForm)
                                    //         if (value) {
                                    //             setAddForm({
                                    //                 school_category_id: "",
                                    //                 school_id: "",
                                    //                 name: "",
                                    //                 type: "",
                                    //                 duration: "00:00",
                                    //                 is_mandatory: false,
                                    //                 price: "",
                                    //                 description: "",
                                    //                 requirement: ""
                                    //             })
                                    //             setShowAddSchoolCategoryModal(false)
                                    //         }
                                    //     }
                                    // }}
                                    />
                                </div>
                            </form>
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default AddSchoolCategoryLesson;