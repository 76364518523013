import React from 'react';
import CommonLabel from './../../../Components/Label/CommonLabel';
import useSchoolStore from './../../../App/Stores/SchoolStore';
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonInput from './../../../Components/Input/CommonInput';
import CommonButton from './../../../Components/Button/CommonButton';
import { iProfile2 } from './../../../App/Utility/source';
import { useState } from 'react';
import ChangeInstructor from './../Classes/ChangeInstructor';
import SelectInput from './../../../Components/Input/SelectInput';

const EditClassDetailModal = () => {

    const { showEditClassDetailModal, setShowEditClassDetailModal } = useSchoolStore()

    const [value, setValue] = useState(true)

    return (
        <div>
            <CommonModal
                showModal={showEditClassDetailModal}
                setShowModal={setShowEditClassDetailModal}
                modalTitle="Edit Class"
                mainContent={
                    <>

                        <from>
                            {value ?
                                <div>
                                    <div className="mb-s20">
                                        <SelectInput  label="Select Category" placeholder="Select Category" />
                                    </div>
                                    <div className="mb-s20">
                                      <SelectInput type="text" label="Select Lesson" placeholder="This is Lesson Title" />
                                    </div>

                                    <SelectInput label="Select Classroom" className2="mb-s20" placeholder="3" />

                                    <div className="mb-s20"> <CommonInput label="Date" type="date" /> </div>

                                    <div className='flex justify-between'>
                                        <CommonInput label="Time" className2="w-s300" type="time" />
                                        <CommonInput label="Time" className2="w-s300" type="time" />
                                    </div>


                                    <div>
                                        <CommonLabel labelText="Start Time" />
                                        <div className='bg-cBackgroundAndCategory rounded-br10 flex justify-between'>
                                            <div className='flex'>
                                                <img className='w-s44 h-s44 m-s14' src={iProfile2} alt="" />
                                                <span className='ml-s10 m-s20'>Maruf Mawla</span>
                                            </div>
                                            <div className='mb-s20 border-2 border-cBrand rounded-full m-s20'>
                                                <CommonButton
                                                    colorType="BackgroundAndCategory"
                                                    btnLabel="Change"
                                                    roundedFull="true"
                                                    onClick={() => setValue(false)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <>
                                    <ChangeInstructor onClick={() => setValue(true)} />
                                    <ChangeInstructor onClick={() => setValue(true)} />
                                    <ChangeInstructor onClick={() => setValue(true)} />
                                    <ChangeInstructor onClick={() => setValue(true)} />
                                    <ChangeInstructor onClick={() => setValue(true)} />
                                    <ChangeInstructor onClick={() => setValue(true)} />

                                </>
                            }

                            <div className='flex justify-between mt-s40'>
                                <CommonButton type="submit" btnLabel="Delete User" colorType="warning" roundedFull="true" />
                                <CommonButton type="submit" btnLabel="Update Class" colorType="primary" roundedFull="true" />
                            </div>

                        </from>

                    </>
                }

            />
        </div>
    );
};

export default EditClassDetailModal;