import React from 'react';
import useCategoryStore from '../../../../App/Stores/CategoryStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import InstructorCard from '../../../../Components/Card/InstructorCard';
import CommonModal from '../../../../Components/Modal/CommonModal';

const ExpertiseAreaModal= () => {

    const {showExpertiseAreaEditModal,setShowExpertiseAreaEditModal}=useCategoryStore()

    return (
        <>
            <CommonModal
            showModal={showExpertiseAreaEditModal}
            setShowModal={setShowExpertiseAreaEditModal}  
            modalTitle="Edit Expertise Areas"
            mainContent={
               <>
                <div className='mt-s50 '>
                <div className='flex justify-between flex-wrap mb-s20'> 
                  <InstructorCard/> 
                  <InstructorCard/> 
                  <InstructorCard/>
                  <InstructorCard/> 
                  <InstructorCard/> 
                  <InstructorCard/>
                  <InstructorCard/> 
                  <InstructorCard/> 
                  <InstructorCard/>
                </div>
                <div className='flex justify-center'>
                    <CommonButton type="submit" btnLabel="Save Changes" colorType="primary" roundedFull="true" />
                </div>
                </div>
               </>
            }
                 
            />
        </>
    );
};

export default ExpertiseAreaModal;