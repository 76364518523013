import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useStudentStore, { studentFindSchoolIndex } from '../../../App/Stores/StudentStore';
import { iGrayReviewIcon, iProfile2, iRightArrow, iYellowReviewIcon } from '../../../App/Utility/source';
import Image from '../../../Components/Image/Image';
import CommonSearchBox from '../../../Components/Input/CommonSearchBox';
import CommonModal from '../../../Components/Modal/CommonModal';
import { useDebounce } from 'use-debounce';
import { useState } from 'react';

const AddStudentSchoolModal = () => {

    const [state, setState] = useState(false)

    const {
        showAddStudentSchoolModal,
        setShowAddStudentSchoolModal,
        setShowStudentSchoolFilterModal,
        setStudentFindSchoolValue,
        studentFindSchoolValue,
        studentSchoolFindListAll,
        setStudentSchoolFindListAll
    } = useStudentStore();

    const navigate = useNavigate();

    const { student_id } = useParams();

    const [SearchValue] = useDebounce(studentFindSchoolValue, 500);

    useEffect(() => {
        SearchData()
    }, [SearchValue])

    const SearchData = async () => {

        // if (studentFindSchoolValue === "") {
        //     await setStudentSchoolFindListAll([])
        // } else {
        //     await studentFindSchoolIndex({ "search": SearchValue })
        // }

        if(state){
            await studentFindSchoolIndex({ "search": SearchValue })
        }
    }

    // console.log("studentSchoolFindListAll", studentSchoolFindListAll)


    return (
        <div>
            <CommonModal
                showModal={showAddStudentSchoolModal}
                setShowModal={setShowAddStudentSchoolModal}
                modalTitle="Search School"
                mainContent={
                    <>
                        <div className="mt-s20">
                            <div className='flex justify-between'>
                                <div className='text-cHighlightedTexts text-fs14 font-fw600 mb-s5 leading-6'>
                                    Search School
                                </div>
                                <div
                                    onClick={() => {
                                        setShowStudentSchoolFilterModal(true)
                                    }} className='text-cBrand cursor-pointer text-fs14 font-fw600'>Filter</div>
                            </div>
                            <CommonSearchBox
                                fullWidth={true}
                                value={studentFindSchoolValue}
                                rounded="rounded-full"
                                onChange={async (e) => {
                                    await setStudentFindSchoolValue(e.target.value)
                                    setState(true)
                                }}
                                onSearchClear={() => {
                                    // setSchoolInstructorsFindValue([]);
                                    // setSchoolInstructorsSearchValue("")
                                    setStudentFindSchoolValue("")
                                }}
                                withClearSearch={true}
                            />


                            {studentSchoolFindListAll?.length > 0 ?
                                studentSchoolFindListAll?.map((item, index) => (
                                    <div className='mt-s20'>
                                        <div onClick={async () => {
                                            await setShowAddStudentSchoolModal(false)
                                            await navigate(`/student/details/${student_id}/school/${item.id}`)
                                        }}
                                            className='flex flex-col md:flex-row justify-between bg-cTextFieldGrey p-s10 rounded-br10 cursor-pointer'>
                                            <div className='flex'>
                                                <Image
                                                    src={item?.cover}
                                                    className='rounded-full w-w44 h-h44 grow-0'
                                                />

                                                <div className='flex flex-col ml-s10'>
                                                    <div className='flex items-center leading-5 text-cHighlightedTexts
                                               text-fs14 font-fw600'>{item?.name}</div>
                                                    <div className='flex'>

                                                        {
                                                            item.rate === 0 &&
                                                            <>
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                            </>
                                                        }
                                                        {
                                                            item.rate === 1 &&
                                                            <>
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                            </>
                                                        }

                                                        {
                                                            item.rate === 2 &&
                                                            <>
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                            </>
                                                        }

                                                        {
                                                            item.rate === 3 &&
                                                            <>
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                            </>
                                                        }

                                                        {
                                                            item.rate === 4 &&
                                                            <>
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iGrayReviewIcon} alt="" />
                                                            </>
                                                        }

                                                        {
                                                            item.rate === 5 &&
                                                            <>
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                                <img className='mr-s8' src={iYellowReviewIcon} alt="" />
                                                            </>
                                                        }
                                                        <span className='mr-s8'>{item.rate}.0</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <img className="" src={iRightArrow} alt="" />
                                        </div>
                                    </div>
                                )) :
                                <div
                                    className='
                                        mt-s20
                                        text-cBlack
                                        text-fs14 
                                        font-fw600 
                                        flex 
                                        justify-center
                                        '>
                                    <span>No Data !</span>
                                </div>
                            }
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default AddStudentSchoolModal;