import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { iFilter, iFilterWhite } from '../../App/Utility/source';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonButtonOutlined from '../../Components/Button/CommonButtonOutlined';
import CommonTable from '../../Components/Table/CommonTable';
import useContactStore, { getContactUsList, searchContactUsList } from './../../App/Stores/ContactStore';
import useLayoutStore from './../../App/Stores/LayoutStore';
import ContactUsTableRow from './../../Components/Table/ContactUs/ContactUsTableRow';
import CommonTitle from './../../Components/Title/CommonTitle';

function ContactUs() {

  const contactUsHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Subject") },
    { index: 4, name: t("Message") },
    { index: 5, name: t("User Type") },
    { index: 6, name: t("Date & Time") },
    { index: 7, name: t("Last Action") },
  ];

  const {contactUsPageUrl, setContactUsPageUrl, contactUsSearch, setShowFilterMessage, setContactUsSearch, filterForm, FilterContactActive } = useContactStore();

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("ContactUs"));
    setBarTitle("ContactUs");

    fetchContactUsData();

  }, [setBarTitle]);

  const fetchContactUsData = async () => {
    await getContactUsList(contactUsPageUrl.url, filterForm);
  }

  const { contactUsListAll } = useContactStore()

  const [searchValue] = useDebounce(contactUsSearch, 500);

  useEffect(() => {
    SmartSearchContactUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const SmartSearchContactUs = async () => {
    if (searchValue) {
      await searchContactUsList(searchValue);
    } else {
      if (contactUsListAll?.current_page) {
        let urlToLoad = contactUsListAll?.data?.path + "?page=" + contactUsListAll?.data?.current_page;
        // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        setContactUsSearch("")
        await getContactUsList(urlToLoad, filterForm);
      } else {
        setContactUsSearch("")
        await getContactUsList(contactUsPageUrl.url, filterForm);
      }
    }
  }

  return (
    <div className='mx-s12 md:mx-s32'>
      <CommonTitle title="Contact Us App" ></CommonTitle>
      <div className="overflow-hidden bg-cBrandColor2 rounded-br20">
        <CommonTable
          topRightComponent={<TakeItem />}
          headers={contactUsHeaders}
          tableTitle=""
          showPageCountText={true}
          autoManageRow={true}
          paginationObject={contactUsListAll?.data}
          TableRowComponent={ContactUsTableRow}
          paginationOnClick={async (url) => {
            await setContactUsPageUrl("url", url)
            console.log("url", url);
            if (searchValue) {
              await searchContactUsList(searchValue, url);
            } else {
              await getContactUsList(url, filterForm)
            }
          }}
          shoSearchBox={true}
          searchValue={contactUsSearch}
          withClearSearch={true}
          onSearchClear={() => setContactUsSearch("")}
          searchOnChange={(e) => { setContactUsSearch(e.target.value) }}

          titleComponent={
            <>
              <CommonButtonOutlined
                iconLeft={iFilterWhite}
                iconLeftHover={iFilter}
                isFilterDot={FilterContactActive}
                colorType='primary'
                btnLabel="Filter"
                onClick={() => { setShowFilterMessage(true) }}
              />
            </>
          }
        />
      </div>


    </div>
  )
}

export default ContactUs


const TakeItem = () => {
  const { setContactUsTakeList, contactUsTakeList, contactUsSearch, filterForm } = useContactStore();
  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id="cars"
        value={contactUsTakeList.take ? contactUsTakeList.take : 10}
        onChange={async (e) => {
          await setContactUsTakeList("take", e.target.value);
          if (contactUsSearch) {
            searchContactUsList()
          } else {
            getContactUsList("", filterForm);
          }

        }} className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={contactUsTakeList.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={contactUsTakeList.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={contactUsTakeList.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={contactUsTakeList.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>

    </div>
  )
}

