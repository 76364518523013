import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CommonButton from '../../Components/Button/CommonButton';
import InstructorExpertiseArea from '../../Components/CommonDetailPage/CommonInstructor/InstructorExpertiseArea';
import BackLink from '../../Components/Pagination/BackLink';
import useInstructorStore, { getInstructorDetails } from './../../App/Stores/InstructorStore';
import useLayoutStore from './../../App/Stores/LayoutStore';
import { PageTitle } from './../../App/Utility/UtilityFunctions';
import CommonTitle from './../../Components/Title/CommonTitle';
import AdditionalInfoInstructor from './AdditionalInfoInstructor';
import InstructorBasicDetails from './InstructorBasicDetails';

const InstructorDetail = () => {
  const { instructorDetails } = useInstructorStore();
  const { setBarTitle } = useLayoutStore();

  const location = useLocation();

  const { instructor_id, invoice_id } = useParams()

  useEffect(() => {
    FetchInstructorDetails();
  }, [instructor_id]);

  const FetchInstructorDetails = async () => {
    if (instructor_id) await getInstructorDetails(instructor_id);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("Instructor Profile"));
    setBarTitle("Instructor Profile");

  }, []);

  console.log("nstructorDetails",instructorDetails?.expertise
  )

  return (
    <div>
      <div onClick={() => console.log("instructorDetails:: ", instructorDetails)} className='mx-s12 md:mx-s32'>
        <CommonTitle title="Instructor Profile" >
          {location.pathname === `/instructor/details/${instructor_id}` && <BackLink linksArray={[
            { label: "Instructor", linkTo: "/instructor" },
            { label: "Instructor Profile", linkTo: "" },
          ]} />}

          {location.pathname === `/contact-us/instructor-profile/details/${instructor_id}` && <BackLink linksArray={[
            { label: "Contact Us", linkTo: "/contact-us" },
            { label: "Instructor Details", linkTo: `` }

          ]} />

          }

          {location.pathname === `/invoice/${invoice_id}/${instructor_id}` && <BackLink linksArray={[
            { label: "Invoice", linkTo: "/invoice" },
            { label: "Invoice Details", linkTo: `/invoice/details/${invoice_id}` },
            { label: "Instructor Profile", linkTo: "" },
          ]} />}
        </CommonTitle>

        {/* <div className="bg-cBrandColor p-s20 rounded-br10 mb-s20  flex flex-col md:flex-row justify-between">
          <div className='text-cBrandColor2 text-fs18 font-fw600 leading-6 flex items-center'>
            Add this instructor to “Denmark Driving School”.
          </div>
          <div className='mt-s20 sm:mt-0'><CommonButton btnLabel='Add Instructor' colorType='white' /></div>
        </div> */}


        <div className="bg-cBrandColor2 rounded-br20 px-s20 py-s20">
          {<InstructorBasicDetails data={instructorDetails} />}
          {instructorDetails?.expertise?.length>0 &&
            <InstructorExpertiseArea
              title="Expertise Area"
              edit={false}
              // editModal={() => { setShowSchoolInstructorsAddExpertiseArea(true) }}
              data={instructorDetails?.expertise}
            />
          }
          <AdditionalInfoInstructor />
        </div>

      </div>
    </div>
  );
};

export default InstructorDetail;