import { t } from "i18next";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import useInvoiceStore, { getSchoolInvoiceIndex } from "../../../App/Stores/InvoiceStore";
import useLayoutStore from "../../../App/Stores/LayoutStore";
import { getSchoolDetail } from "../../../App/Stores/SchoolStore";
import { iFilter, iFilterWhite } from "../../../App/Utility/source";
import CommonButtonOutlined from "../../../Components/Button/CommonButtonOutlined";
import BackLink from "../../../Components/Pagination/BackLink";
import CommonTable from "../../../Components/Table/CommonTable";
import CommonTitle from "../../../Components/Title/CommonTitle";
import { PageTitle } from "../../../Utility/UtilityFunctions";
import SchoolInvoiceFilter from "./SchoolInvoiceFilter";
import SchoolInvoiceTableRow from "./SchoolInvoiceTableRow";

function SchoolInvoiceTable() {
    const {
        setShowSchoolInvoiceFilterModal,
        invoiceIndexDataSchool,
        invoiceSearchKey,
        setInvoiceSearchKey,
        filterSchoolInvoiceData,
        setSchoolUID,
        setSchoolID,
        setSchoolInvoiceHistoryMode,
        resetFilterSchoolInvoiceData,
        schoolInvoiceFilterActive
    } = useInvoiceStore();

    const InvoiceHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: localStorage.getItem("schoolInvoiceType") === "school_instructor" ? "Instructor" : localStorage.getItem("schoolInvoiceType") === "school_student" ? "Student" : localStorage.getItem("schoolInvoiceType") === "school_admin" ? "School" : "Name" },
        { index: 3, name: t("Email") },
        { index: 4, name: t("Invoice ID") },
        { index: 5, name: t("User Type") },
        { index: 6, name: t("Created On") },
        { index: 7, name: t("Last Action") },
        { index: 8, name: t("Status") },
    ];

    const [searchValue] = useDebounce(invoiceSearchKey, 500);

    const { setBarTitle } = useLayoutStore();

    const { school_id, school_user_id } = useParams();

    const location = useLocation();

    useEffect(() => {
        SmartSearchSchoolInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const SmartSearchSchoolInvoice = async () => {
        if (searchValue) {
            await getSchoolInvoiceIndex(school_id, localStorage.getItem("schoolInvoiceType") ?? "school_instructor", localStorage.getItem("schoolInvoicePaginationURL") ?? "", searchValue, filterSchoolInvoiceData);
        } else {
            setInvoiceSearchKey("")
            await getSchoolInvoiceIndex(school_id, localStorage.getItem("schoolInvoiceType") ?? "school_instructor", localStorage.getItem("schoolInvoicePaginationURL") ?? "", "", filterSchoolInvoiceData);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setSchoolInvoiceHistoryMode(false);
        if (!localStorage.getItem("schoolInvoiceType")) localStorage.setItem("schoolInvoiceType", "school_instructor");

        PageTitle(t("School Invoice"));
        setBarTitle("School Invoice");

        fetchInvoice();

    }, []);

    useEffect(() => {
        setSchoolUID(school_user_id);
        setSchoolID(school_id);

        GetSchoolDetailsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetSchoolDetailsData = async () => {
        await getSchoolDetail(school_id);
    }

    const fetchInvoice = async () => {
        await setSchoolUID(school_user_id);
        if (invoiceSearchKey) {
            await getSchoolInvoiceIndex(school_id, localStorage.getItem("schoolInvoiceType") ?? "school_instructor", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, filterSchoolInvoiceData);
        } else {
            await getSchoolInvoiceIndex(school_id, localStorage.getItem("schoolInvoiceType") ?? "school_instructor", localStorage.getItem("schoolInvoicePaginationURL") ?? "", "", filterSchoolInvoiceData);
        }
    }

    return (
        <div className='mx-s12 md:mx-s32'>
            <CommonTitle title="School Invoice">

                {
                    location.pathname === `/school/details/${school_id}/invoice/${school_user_id}` && <BackLink linksArray={[
                        { label: "School", linkTo: "/school" },
                        { label: "School Profile", linkTo: `/school/details/${school_id}` },
                        { label: "School Invoice", linkTo: `` }
                    ]}
                    />
                }

            </CommonTitle>

            {/* idea:       api call issue */}
            <SchoolInvoiceFilter />

            <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
                <CommonTable
                    currentTakeAmount={localStorage.getItem("schoolInvoiceTake") ?? 10}
                    topRightComponent={<TakeItem />}
                    headers={InvoiceHeaders}
                    tableTitle=""
                    showPagination={true}
                    showChip={true}
                    chipWidth="min-w-[150px]"
                    chipArray={[
                        {
                            title: `Instructor `,
                            selected: localStorage.getItem("schoolInvoiceType") === "school_instructor" ? true : false,
                            action: async () => {
                                console.log("Instructor");
                                localStorage.setItem("schoolInvoiceType", "school_instructor");
                                localStorage.setItem("schoolInvoicePaginationURL", "");
                                setSchoolInvoiceHistoryMode(false);
                                await resetFilterSchoolInvoiceData();
                                if (localStorage.getItem("schoolInvoiceType") === "school_instructor") await getSchoolInvoiceIndex(school_id, "school_instructor", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, {});
                                else await getSchoolInvoiceIndex(school_id, "school_instructor", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, filterSchoolInvoiceData);
                            },
                        },
                        {
                            title: `Student`,
                            selected: localStorage.getItem("schoolInvoiceType") === "school_student" ? true : false,
                            action: async () => {
                                console.log("Student");
                                localStorage.setItem("schoolInvoiceType", "school_student");
                                localStorage.setItem("schoolInvoicePaginationURL", "");
                                setSchoolInvoiceHistoryMode(false);

                                await resetFilterSchoolInvoiceData();
                                if (localStorage.getItem("schoolInvoiceType") === "school_student") await getSchoolInvoiceIndex(school_id, "school_student", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, {});
                                else await getSchoolInvoiceIndex(school_id, "school_student", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, filterSchoolInvoiceData);

                            },
                        }, {
                            title: "Admin",
                            selected: localStorage.getItem("schoolInvoiceType") === "school_admin" ? true : false,
                            action: async () => {
                                await resetFilterSchoolInvoiceData();
                                console.log("Admin");
                                localStorage.setItem("schoolInvoiceType", "school_admin");
                                localStorage.setItem("schoolInvoicePaginationURL", "");
                                setSchoolInvoiceHistoryMode(false);
                                if (localStorage.getItem("schoolInvoiceType") === "school_admin") await getSchoolInvoiceIndex(school_id, "school_admin", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, {});
                                else await getSchoolInvoiceIndex(school_id, "school_admin", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, filterSchoolInvoiceData);
                            },
                        },
                        {
                            title: `History`,
                            selected: localStorage.getItem("schoolInvoiceType") === "history" ? true : false,
                            action: async () => {
                                console.log("History");
                                localStorage.setItem("schoolInvoiceType", "history");
                                localStorage.setItem("schoolInvoicePaginationURL", "");
                                setSchoolInvoiceHistoryMode(true);
                                await resetFilterSchoolInvoiceData();
                                if (localStorage.getItem("schoolInvoiceType") === "history") await getSchoolInvoiceIndex(school_id, "history", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, {});
                                else await getSchoolInvoiceIndex(school_id, "history", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, filterSchoolInvoiceData);
                            },
                        },
                    ]}

                    shoSearchBox={true}
                    searchValue={invoiceSearchKey}
                    withClearSearch={true}
                    onSearchClear={() => {
                        setInvoiceSearchKey("");
                        localStorage.setItem("schoolInvoicePaginationURL", "");
                    }}
                    searchOnChange={(e) => {
                        console.log("SEARCH", e.target.value);
                        localStorage.setItem("schoolInvoicePaginationURL", "");
                        setInvoiceSearchKey(e.target.value);
                        // searchInvoiceList(e.target.value);
                    }}

                    titleComponent={
                        <>
                            <CommonButtonOutlined
                                iconLeft={iFilterWhite}
                                iconLeftHover={iFilter}
                                isFilterDot={schoolInvoiceFilterActive}
                                colorType='primary'
                                btnLabel="Filter"
                                onClick={() => { setShowSchoolInvoiceFilterModal(true) }}
                            />
                        </>
                    }

                    autoManageRow={true}
                    TableRowComponent={SchoolInvoiceTableRow}
                    paginationObject={invoiceIndexDataSchool}

                    paginationOnClick={async (url) => {
                        console.log(url);
                        await localStorage.setItem("schoolInvoicePaginationURL", url);
                        getSchoolInvoiceIndex(school_id, localStorage.getItem("schoolInvoiceType") ?? "school_instructor", url, invoiceSearchKey, filterSchoolInvoiceData);
                    }}

                />
            </div>
        </div>
    );
};

export default SchoolInvoiceTable



const TakeItem = () => {

    const { invoiceSearchKey, filterSchoolInvoiceData } = useInvoiceStore();
    const { school_id } = useParams();

    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                defaultValue={localStorage.getItem("schoolInvoiceTake") ?? 10}
                onChange={async (e) => {
                    await localStorage.setItem("schoolInvoicePaginationURL", "");
                    await localStorage.setItem("schoolInvoiceTake", e.target.value);
                    getSchoolInvoiceIndex(school_id, localStorage.getItem("schoolInvoiceType") ?? "school_instructor", localStorage.getItem("schoolInvoicePaginationURL") ?? "", invoiceSearchKey, filterSchoolInvoiceData);

                }}
                className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("schoolInvoiceTake") === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("schoolInvoiceTake") === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("schoolInvoiceTake") === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={localStorage.getItem("schoolInvoiceTake") === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}

