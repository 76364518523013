import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import useSchoolStore, { getSchoolCategoryIndex } from '../../../App/Stores/SchoolStore';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonTable from '../../../Components/Table/CommonTable';
import SchoolCategoryTableRow from '../../../Components/Table/School/SchoolCategoryTableRow';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { PageTitle } from '../../../Utility/UtilityFunctions';

const SchoolCategory = () => {

    const { school_id } = useParams();

    const {schoolCategoryListAll}=useSchoolStore()

    const schoolCategoryHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Lesson") },
        { index: 4, name: t("Total Hours") },
        { index: 5, name: t("Price") },
        { index: 6, name: t("Status") },
    ];

    useEffect(() => {
        fetchSchoolCategory()
    }, [school_id])

    const fetchSchoolCategory=async()=>{
       await getSchoolCategoryIndex (school_id)
    }

    const { setBarTitle } = useLayoutStore();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle(t("School Categories"));
        setBarTitle("School Categories");
    }, [])
    

    return (
        <div className='mx-s12 md:mx-s32'>

            <CommonTitle title="School Categories" >
                <BackLink linksArray={[
                    { label: "school", linkTo: "/school" },
                    { label: "School Profile", linkTo: `/school/details/${school_id}` },
                    { label: "School Categories", linkTo: "" },
                ]} />

            </CommonTitle>
            <div className='overflow-hidden bg-cBrandColor2 rounded-br10'>
                <CommonTable
                    headers={schoolCategoryHeaders}
                    tableTitle=""
                    shoSearchBox={false}
                    pagination={false}
                    showPageCountText={true}
                    showPagination={false}
      
                    items={
                        <>
                          {schoolCategoryListAll.length>0 ?
                          schoolCategoryListAll.map((item,index)=>(
                            <SchoolCategoryTableRow key={index} data={item}  index={index}/>
                          ))
                          :<tr className='w-full'>
                          <th colSpan={5} className="py-s10">
                            No Data Found !
                          </th>
                        </tr>}
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default SchoolCategory;