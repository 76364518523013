import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSchoolStore, { schoolInstructorAcceptFn, schoolInstructorShowFn, schoolInstructorsRejectFn, schoolInstructorsRemoveFn } from '../../../App/Stores/SchoolStore';
import { dateDiffCalendar, roughLicenseDurationFormatter } from '../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../Components/Button/CommonButton';
import InstructorExpertiseArea from '../../../Components/CommonDetailPage/CommonInstructor/InstructorExpertiseArea';
import LessonsCard from '../../../Components/CommonDetailPage/LessonsCard';
import NoLicense from '../../../Components/CommonDetailPage/NoLicense';
import CommonEmptyStatus from '../../../Components/CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../../Components/Image/Image';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { formatDate, NACheck } from '../../../Utility/UtilityFunctions';
const SchoolInstructorDetails = () => {

  const { schoolInstructorDetailShow } = useSchoolStore();

  const { school_id, school_instructor_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchDataSchoolInstructor()
  }, [school_id, school_instructor_id])


  const fetchDataSchoolInstructor = async () => {
    await schoolInstructorShowFn(school_instructor_id)
  }

  //console.log("schoolInstructorDetailShow", schoolInstructorDetailShow)

  return (
    <div className='mx-s12 md:mx-s32'>
      <CommonTitle title="Instructor School Details" >
        <BackLink linksArray={[
          { label: "School", linkTo: "/school" },
          { label: "School Details", linkTo: `/school/details/${school_id}` },
          { label: "Instructor School", linkTo: `/school/details/${school_id}/instructor` },
          { label: "Instructor School Details", linkTo: `` }
        ]} />

      </CommonTitle>
      <div className="bg-cBrandColor2 rounded-br20 px-s20 py-s20 ">
        {schoolInstructorDetailShow ?
          <div className="flex w-full ">
            <div className="w-full pr-5">
              <div className="bg-cBackgroundAndCategory min-h-[350px] rounded-br10 py-s20 px-s20">


                <div className='flex justify-between'>
                  <div className="flex w-full">
                    <div className='mt-s5'>
                      <Image className='rounded-full w-w88 h-h88 grow-0 mb-s18'
                        src={schoolInstructorDetailShow?.instructor?.profile_photo} />

                      <div className='mt-s15'>

                        {
                          schoolInstructorDetailShow?.status === 'request' ?
                            <div>
                              <CommonButton
                                onClick={() => {
                                  const successAccept = schoolInstructorAcceptFn(school_instructor_id)
                                  if(successAccept){
                                    fetchDataSchoolInstructor()
                                  }
                                }}
                                width='w-[80px]'
                                // colorType='warning'
                                btnLabel="Accept"
                                roundedFull="true" />
                              <div className='mt-s20'>
                                <CommonButton
                                  onClick={() => {
                                    const successReject = schoolInstructorsRejectFn(school_instructor_id)
                                    if (successReject) {
                                      setTimeout(() => {
                                        navigate(`/school/details/${school_id}/instructor`)
                                      }, 1500);
                                    }
                                  }}
                                  width='w-[80px]'
                                  colorType='warning'
                                  btnLabel="Reject"
                                  roundedFull="true" />
                              </div>
                            </div> : ''
                        }

                        {schoolInstructorDetailShow?.status === 'accepted' &&
                          <div className='mt-s20'>
                            <CommonButton
                              onClick={() => {
                                const successRemove = schoolInstructorsRemoveFn(school_instructor_id)
                                if (successRemove) {
                                  setTimeout(() => {
                                    navigate(`/school/details/${school_id}/instructor`)
                                  }, 1500);
                                 
                                }
                              }}
                              width='w-[86px]'
                              colorType='warning'
                              btnLabel="Remove"
                              roundedFull="true" />
                          </div>
                        }


                      </div>

                    </div>

                    <div className="ml-s15">
                      <div className="capitalize leading-9 font-fw600 text-fs24 text-cHighlighted mb-s2">
                        {schoolInstructorDetailShow?.instructor?.name ?
                          NACheck(schoolInstructorDetailShow?.instructor?.name)
                          : <CommonEmptyStatus
                            leading='leading-9'
                            size="text-fs24"
                            fontWeight="font-fw600"
                            textColor='text-cHighlighted'
                          />}
                      </div>
                      <div className="leading-3 font-fw500 text-fs12 text-cImportantText mb-s6">
                        Email : {schoolInstructorDetailShow?.instructor?.email ?
                          NACheck(schoolInstructorDetailShow?.instructor?.email)
                          : <CommonEmptyStatus
                            leading='leading-3'
                            size="text-fs12"
                            fontWeight="font-fw500"
                            textColor='text-cImportantText'
                          />}
                      </div>
                      <div className="leading-3 font-fw500 text-fs12 text-cImportantText pb-s6">
                        Phone : {schoolInstructorDetailShow?.instructor?.phone_number ?
                          NACheck(schoolInstructorDetailShow?.instructor?.phone_number)
                          : <CommonEmptyStatus
                            leading='leading-3'
                            size="text-fs12"
                            fontWeight="font-fw500"
                            textColor='text-cImportantText'
                          />}
                      </div>

                      <div className="capitalize leading-3 font-fw500 text-fs12 text-cImportantText">
                        Status : {schoolInstructorDetailShow?.status ?
                          NACheck(schoolInstructorDetailShow?.status)
                          : <CommonEmptyStatus
                            leading='leading-3'
                            size="text-fs12"
                            fontWeight="font-fw500"
                            textColor='text-cImportantText'
                          />}
                      </div>

                      <div className="capitalize leading-3 font-fw500 text-fs12 text-cImportantText pt-s6">
                        Address : {schoolInstructorDetailShow?.instructor?.address
                          ? NACheck(schoolInstructorDetailShow?.instructor?.address)
                          : <CommonEmptyStatus
                            leading='leading-3'
                            size="text-fs12"
                            fontWeight="font-fw500"
                            textColor='text-cImportantText'
                          />}
                      </div>

                      <div className="capitalize leading-3 font-fw500 text-fs12 text-cImportantText pt-s6">
                        About : {schoolInstructorDetailShow?.instructor?.about
                          ? NACheck(schoolInstructorDetailShow?.instructor?.about)
                          : <CommonEmptyStatus
                            leading='leading-3'
                            size="text-fs12"
                            fontWeight="font-fw500"
                            textColor='text-cImportantText'
                          />}
                      </div>

                      <div className="capitalize leading-3 font-fw500 text-fs12 text-cImportantText pb-s6 pt-s6">
                        Type : {schoolInstructorDetailShow?.type
                          ? NACheck(schoolInstructorDetailShow?.type)
                          : <CommonEmptyStatus
                            leading='leading-3'
                            size="text-fs12"
                            fontWeight="font-fw500"
                            textColor='text-cImportantText'
                          />}
                      </div>

                    </div>
                  </div>

                  <div className=''>
                    <CommonButton
                      onClick={() => { navigate(`/instructor/details/${schoolInstructorDetailShow?.instructor?.id}`) }}
                      width='w-[120px]'
                      btnLabel="View Profile"
                      roundedFull="true" />
                  </div>
                </div>


              </div>
            </div>

            {
              !schoolInstructorDetailShow?.instructor?.active_license ?
                <NoLicense /> :
                <div className='min-w-[400px]'>
                  <LessonsCard
                    licenseOf="instructor"
                    withEditOption={false}
                    title={schoolInstructorDetailShow?.instructor?.active_license?.lisence?.title}
                    price={schoolInstructorDetailShow?.instructor?.active_license?.lisence?.price}
                    packageDuration={roughLicenseDurationFormatter
                      (schoolInstructorDetailShow?.instructor?.active_license?.lisence?.duration)}
                    momsValue={parseInt(schoolInstructorDetailShow?.instructor?.active_license?.lisence?.price) * ((25) / 100)}
                    startDate={formatDate(schoolInstructorDetailShow?.instructor?.active_license?.start_time)}
                    endDate={formatDate(schoolInstructorDetailShow?.instructor?.active_license?.end_time)}
                    purchaseDuration={dateDiffCalendar(schoolInstructorDetailShow?.instructor?.active_license?.start_time
                      ?? new Date(), schoolInstructorDetailShow?.instructor?.active_license?.end_time
                    )}
                    licenseDetails={schoolInstructorDetailShow?.instructor?.active_license?.lisence?.details}
                    status={schoolInstructorDetailShow?.instructor?.active_license?.status}
                  />
                </div>
            }
          </div>
          : ''}

        {schoolInstructorDetailShow &&
          <InstructorExpertiseArea
            title="Applied Area"
            edit={false}
            data={schoolInstructorDetailShow?.application_categories}
          />
        }

        {schoolInstructorDetailShow &&
          <InstructorExpertiseArea
            title="Expertise Area"
            edit={false}
            data={schoolInstructorDetailShow?.instructor?.expertise}
          />
        }

      </div>

    </div>
  );
};

export default SchoolInstructorDetails;