import React from 'react'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';
import useSettingsStore, { deleteMessageToggleStatus} from '../../../App/Stores/SettingsStore';
import { cityToggleStatus } from './../../../App/Stores/SettingsStore';


function CityDeactivateModal() {

    const {setDeleteMessageDeactivateID,showCityDeactivateModal, setShowCityDeactivateModal,cityDeactivateID, } = useSettingsStore();

    return (
        <>
            <CommonModal
                showModal={showCityDeactivateModal}
                setShowModal={setShowCityDeactivateModal}
                modalTitle="Confirmation"
                mainContent={
                    <>
                        <div className="flex justify-center pt-5">Are you sure you want to deactivate this city?</div>
                        <div className="flex justify-center pt-5">
                            <CommonButton
                                colorType='danger'
                                btnLabel='Deactivate'
                                onClick={async() => {
                                    let deactivateSuccess = await cityToggleStatus(cityDeactivateID);
                                    if(deactivateSuccess) {
                                       await  setDeleteMessageDeactivateID("")
                                        await setShowCityDeactivateModal(false);
                                    } 
                                }}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default CityDeactivateModal