import { t } from 'i18next';
import React from 'react';
import CommonTable from '../../../Components/Table/CommonTable';
import CommonTitle from '../../../Components/Title/CommonTitle';
import CommonButton from '../../../Components/Button/CommonButton';
import { iAddItem } from '../../../App/Utility/source';
import SchoolClassRoomTableRow from '../../../Components/Table/School/SchoolClassRoomTableRow.jsx';
import useSchoolStore from './../../../App/Stores/SchoolStore';

const SchoolClassroom = () => {

  const {setShowAddClassRoomDetailModal}=useSchoolStore()

    const schoolClassRoomHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Address") },
        { index: 4, name: t("Capacity") },
        { index: 5, name: t("Status") },
        { index: 6, name: t("Classroom Lesson") },
      ];

    return (
        <div className='mx-s12 md:mx-s32'>
            <CommonTitle  title="Classrooms"/>
            <div className='bg-cBrandColor2 rounded-br20 overflow-hidden'>

            <CommonTable
            headers={schoolClassRoomHeaders}
            tableTitle=""
            showPageCountText={false}
            showPagination={true}
            shoSearchBox={true}
            titleComponent={
              <div className='mb-s20'>
              <CommonButton 
                onClick={()=>{setShowAddClassRoomDetailModal(true)}}
                colorType="primary" 
                btnLabel="Create Classroom"
                roundedFull="true"
                icon={<div className="mr-s5"><img className="w-s20 h-s15" src={iAddItem} alt=""/></div>}
                />
              </div>
             }
          items={
            <>
              <SchoolClassRoomTableRow/>
              <SchoolClassRoomTableRow/>
              <SchoolClassRoomTableRow/>
              <SchoolClassRoomTableRow/>
              <SchoolClassRoomTableRow/>
              <SchoolClassRoomTableRow/>
            </>
          }
        /> 
            </div>
        </div>
    );
};

export default SchoolClassroom;