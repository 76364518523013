import { t } from "i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import useInvoiceStore, { getInstructorInvoiceIndex } from "../../App/Stores/InvoiceStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { iFilter, iFilterWhite } from "../../App/Utility/source";
import CommonButtonOutlined from "../../Components/Button/CommonButtonOutlined";
import BackLink from "../../Components/Pagination/BackLink";
import CommonTable from "../../Components/Table/CommonTable";
import CommonTitle from "../../Components/Title/CommonTitle";
import { PageTitle } from "../../Utility/UtilityFunctions";
import InstructorInvoiceFilter from "./InstructorInvoiceFilter";
import InstructorInvoiceRow from "./InstructorInvoiceRow";

function InstructorInvoiceTable() {

    const {
        setShowInstructorInvoiceFilterModal,
        invoiceIndexDataInstructor,
        instructorInvoiceSearchKey,
        setInstructorInvoiceSearchKey,
        filterInstructorInvoiceData,
        setInstructorInvoiceType,
        setInstructorID,
        setInstructorUID,
        setInstructorInvoiceHistoryMode,
        resetFilterInstructorInvoiceData,
        instructorInvoiceFilterActive
    } = useInvoiceStore();

    const InvoiceHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: localStorage.getItem("instructorInvoiceType") === "history" ? "Name" : "School Name" },
        { index: 3, name: t("Email") },
        { index: 4, name: t("Invoice ID") },
        { index: 5, name: t("User Type") },
        { index: 6, name: t("Created On") },
        { index: 7, name: t("Last Action") },
        { index: 8, name: t("Status") },
    ];

    const [searchValue] = useDebounce(instructorInvoiceSearchKey, 500);

    const { setBarTitle } = useLayoutStore();

    const { instructor_id, instructor_user_id } = useParams();

    useEffect(() => {
        SmartSearchInstructorInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const SmartSearchInstructorInvoice = async () => {
        if (searchValue) {
            await getInstructorInvoiceIndex(instructor_id, localStorage.getItem("instructorInvoiceType") ?? "school_instructor", "", searchValue, filterInstructorInvoiceData);
        } else {
            setInstructorInvoiceType("school_instructor");
            setInstructorInvoiceSearchKey("")
            await getInstructorInvoiceIndex(instructor_id, localStorage.getItem("instructorInvoiceType") ?? "school_instructor", "", "", filterInstructorInvoiceData);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (localStorage.getItem("instructorInvoiceType") === "history") setInstructorInvoiceHistoryMode(true);
        else setInstructorInvoiceHistoryMode(false);

        if (!localStorage.getItem("instructorInvoiceType")) localStorage.setItem("instructorInvoiceType", "school_instructor");

        fetchInvoice();
        PageTitle(t("Instructor Invoice"));
        setBarTitle("Instructor Invoice");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("instructorInvoiceType")]);

    useEffect(() => {
        console.log("setting up instructor user id::: ", instructor_user_id);
        setInstructorUID(instructor_user_id);
        setInstructorID(instructor_id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchInvoice = async () => {
        await setInstructorUID(instructor_user_id);
        // await console.log("setting up instructor user id::: ", instructor_user_id);

        if (instructorInvoiceSearchKey) {
            await getInstructorInvoiceIndex(instructor_id, localStorage.getItem("instructorInvoiceType") ?? "school_instructor", localStorage.getItem("instructorInvoicePaginationURL") ?? "", instructorInvoiceSearchKey, filterInstructorInvoiceData);
        } else {
            await getInstructorInvoiceIndex(instructor_id, localStorage.getItem("instructorInvoiceType") ?? "school_instructor", localStorage.getItem("instructorInvoicePaginationURL") ?? "", "", filterInstructorInvoiceData);
        }
    }

    return (
        <div className='mx-s12 md:mx-s32'>
            <CommonTitle title="Instructor Invoice">
                <BackLink linksArray={[
                    { label: "Instructor", linkTo: "/instructor" },
                    { label: "Instructor Profile", linkTo: `/instructor/details/${instructor_id}` },
                    { label: "Instructor Invoice", linkTo: `` }
                ]} />
            </CommonTitle>

            {/* idea:       api call issue */}
            <InstructorInvoiceFilter />

            <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
                <CommonTable
                    currentTakeAmount={localStorage.getItem("instructorInvoiceTake") ?? 10}
                    topRightComponent={<TakeItem />}
                    headers={InvoiceHeaders}
                    tableTitle=""
                    showPagination={true}
                    showChip={true}
                    chipWidth="min-w-[150px]"
                    chipArray={[
                        {
                            title: "School",
                            selected: localStorage.getItem("instructorInvoiceType") === "school_instructor" ? true : false,
                            action: async () => {
                                console.log("School");
                                setInstructorInvoiceType("school_instructor");
                                setInstructorInvoiceHistoryMode(false);
                                localStorage.setItem("instructorInvoicePaginationURL", "");
                                localStorage.setItem("instructorInvoiceType", "school_instructor");
                                await resetFilterInstructorInvoiceData();
                                if (localStorage.getItem("instructorInvoiceType") === "school_instructor") await getInstructorInvoiceIndex(instructor_id, "school_instructor", localStorage.getItem("instructorInvoicePaginationURL") ?? "", "", {});
                                else await getInstructorInvoiceIndex(instructor_id, "school_instructor", localStorage.getItem("instructorInvoicePaginationURL") ?? "", "", filterInstructorInvoiceData);
                            },
                        },
                        {
                            title: "Admin",
                            selected: localStorage.getItem("instructorInvoiceType") === "instructor_admin" ? true : false,
                            action: async () => {
                                console.log("Admin");
                                setInstructorInvoiceType("instructor_admin");
                                setInstructorInvoiceHistoryMode(false);
                                localStorage.setItem("instructorInvoicePaginationURL", "");
                                localStorage.setItem("instructorInvoiceType", "instructor_admin");
                                await resetFilterInstructorInvoiceData();
                                if (localStorage.getItem("instructorInvoiceType") === "instructor_admin") await getInstructorInvoiceIndex(instructor_id, "instructor_admin", localStorage.getItem("instructorInvoicePaginationURL") ?? "", "", {}, instructor_user_id);
                                else await getInstructorInvoiceIndex(instructor_id, "instructor_admin", localStorage.getItem("instructorInvoicePaginationURL") ?? "", "", filterInstructorInvoiceData, instructor_user_id);
                            },
                        },
                        {
                            title: "History",
                            selected: localStorage.getItem("instructorInvoiceType") === "history" ? true : false,
                            action: async () => {
                                console.log("History");
                                setInstructorInvoiceType("history");
                                setInstructorInvoiceHistoryMode(true);
                                localStorage.setItem("instructorInvoicePaginationURL", "");
                                localStorage.setItem("instructorInvoiceType", "history");
                                await resetFilterInstructorInvoiceData();
                                if (localStorage.getItem("instructorInvoiceType") === "history") await getInstructorInvoiceIndex(instructor_id, "history", localStorage.getItem("instructorInvoicePaginationURL") ?? "", "", {});
                                else await getInstructorInvoiceIndex(instructor_id, "history", localStorage.getItem("instructorInvoicePaginationURL") ?? "", "", filterInstructorInvoiceData);
                            },
                        },
                    ]}

                    shoSearchBox={true}
                    searchValue={instructorInvoiceSearchKey}
                    withClearSearch={true}
                    onSearchClear={() => {
                        setInstructorInvoiceSearchKey("");
                    }}
                    searchOnChange={(e) => {
                        console.log("SEARCH", e.target.value);
                        localStorage.setItem("instructorInvoicePaginationURL", "");
                        setInstructorInvoiceSearchKey(e.target.value);
                    }}

                    titleComponent={
                        <>
                            <CommonButtonOutlined
                                iconLeft={iFilterWhite}
                                iconLeftHover={iFilter}
                                isFilterDot={instructorInvoiceFilterActive}
                                colorType='primary'
                                btnLabel="Filter"
                                onClick={() => { setShowInstructorInvoiceFilterModal(true) }}
                            />
                        </>
                    }

                    autoManageRow={true}
                    TableRowComponent={InstructorInvoiceRow}
                    paginationObject={invoiceIndexDataInstructor}

                    paginationOnClick={async (url) => {
                        console.log(url);
                        await localStorage.setItem("instructorInvoicePaginationURL", url);
                        await getInstructorInvoiceIndex(instructor_id, localStorage.getItem("instructorInvoiceType") ?? "school_instructor", url, instructorInvoiceSearchKey, filterInstructorInvoiceData);
                    }}

                />
            </div>

        </div>
    );
};

export default InstructorInvoiceTable



const TakeItem = () => {

    const { instructorInvoiceSearchKey, filterInstructorInvoiceData } = useInvoiceStore();
    const { instructor_id } = useParams();

    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                defaultValue={localStorage.getItem("instructorInvoiceTake") ?? 10}
                onChange={async (e) => {
                    await localStorage.setItem("instructorInvoicePaginationURL", "");
                    await localStorage.setItem("instructorInvoiceTake", e.target.value);
                    console.log("instructorInvoiceTake", e.target.value);
                    await getInstructorInvoiceIndex(instructor_id, localStorage.getItem("instructorInvoiceType") ?? "school_instructor", "", instructorInvoiceSearchKey, filterInstructorInvoiceData);
                }}
                className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("instructorInvoiceTake") === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("instructorInvoiceTake") === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    selected={localStorage.getItem("instructorInvoiceTake") === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={localStorage.getItem("instructorInvoiceTake") === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}

