import { t } from 'i18next';
import React from 'react';
import CommonTable from '../../../Components/Table/CommonTable';
import CommonTitle from '../../../Components/Title/CommonTitle';
import CommonButton from '../../../Components/Button/CommonButton';
import { iAddItem } from '../../../App/Utility/source';
import SchoolClassesTableRow from '../../../Components/Table/School/SchoolClassesTableRow';
import useSchoolStore from './../../../App/Stores/SchoolStore';

const SchoolClasses = () => {

    const schoolClassHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("From") },
        { index: 3, name: t("Email") },
        { index: 4, name: t("User Type") },
        { index: 5, name: t("Date & time") },
        { index: 6, name: t("Last Action") },
        { index: 6, name: t("Status") },
      ];

      const {setShowAddClassModal}=useSchoolStore()

    return (
        <div className='mx-s12 md:mx-s32'>
            <CommonTitle  title="Classes"/>
            <div className='bg-cBrandColor2 rounded-br20 overflow-hidden'>

            <CommonTable
            headers={schoolClassHeaders}
            tableTitle=""
            showPageCountText={false}
            showPagination={true}
            shoSearchBox={true}
            titleComponent={
              <div className='mb-s20'>
              <CommonButton 
              onClick={()=>{setShowAddClassModal(true)}}
                colorType="primary" 
                btnLabel="Create Class"
                roundedFull="true"
                icon={<div className="mr-s5"><img className="w-s20 h-s15" src={iAddItem} alt=""/></div>}
                />
              </div>
             }
          items={
            <>
              <SchoolClassesTableRow/>
              <SchoolClassesTableRow/>
              <SchoolClassesTableRow/>
              <SchoolClassesTableRow/>
              <SchoolClassesTableRow/>
              <SchoolClassesTableRow/>
            </>
          }
        /> 
            </div>
        </div>
    );
};

export default SchoolClasses;