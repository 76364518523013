import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getCategoryList } from '../../App/Stores/CategoryStore';
import useLayoutStore from '../../App/Stores/LayoutStore';
import { getSchoolList } from '../../App/Stores/SchoolStore';
import { getStudentList, searchStudentList } from '../../App/Stores/StudentStore';
import useUtilityStore from '../../App/Stores/UtilityStore';
import { iFilter, iFilterWhite } from '../../App/Utility/source';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonButtonOutlined from '../../Components/Button/CommonButtonOutlined';
import BackLink from '../../Components/Pagination/BackLink';
import useStudentStore from './../../App/Stores/StudentStore';
import CommonTable from './../../Components/Table/CommonTable';
import StudentTableRow from './../../Components/Table/Student/StudentTableRow';
import CommonTitle from './../../Components/Title/CommonTitle';
import FilterStudentTable from './StudentModal/FilterStudentTable';

const Student = () => {
  const { studentPaginationURL, setStudentPaginationURL, studentSearch, setStudentSearch, studentListAll, setShowStudentFilterModal, filterData, FilterStudentActive } = useStudentStore();

  const location = useLocation();
  const { setLoading } = useUtilityStore();

  const { id } = useParams()

  const studentHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("Email") },
    { index: 4, name: t("Phone") },
    { index: 5, name: t("Curriculum") },
    { index: 6, name: t("Status") },
  ];

  const [searchValue] = useDebounce(studentSearch, 500);

  useEffect(() => {
    SmartSearchAllStudent(studentPaginationURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const SmartSearchAllStudent = async () => {
    setLoading(true);
    if (searchValue) {
      setLoading(false);
      await searchStudentList(studentPaginationURL);
    } else {
      if (studentListAll.current_page) {
        let urlToLoad = studentListAll.path + "?page=" + studentListAll.current_page;
        // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        setStudentSearch("")
        await getStudentList(urlToLoad, filterData, false);
      } else {
        setStudentSearch("")
        await getStudentList("", filterData, false);
      }
      setLoading(false);
    }
  }

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("Student"));
    setBarTitle("Student");

    fetchStudentData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStudentData = async () => {
    setLoading(true);
    await getCategoryList("", 500, false);
    await getSchoolList("", {}, 5, false);

    if (studentSearch) {
      setLoading(false);
      await searchStudentList(studentPaginationURL)
    } else {
      await getStudentList(studentPaginationURL, filterData, false);
    }
    setLoading(false);
  }

  // const DataList = studentListAll?.data

  return (
    <div className='mx-s12 md:mx-s32'>

      <CommonTitle title="Student Management" >
        {
          location.pathname === `/category/details/${id}/student` && <BackLink linksArray={[
            { label: "Category", linkTo: "/category" },
            { label: "Category Details", linkTo: `/category/details${id}` },
            { label: "Student Management", linkTo: `` }
          ]} />
        }


      </CommonTitle>

      {/* idea    api call issue */}
      <FilterStudentTable />

      <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
        <CommonTable
          topRightComponent={<TakeItem />}
          headers={studentHeaders}
          tableTitle=""
          paginationObject={studentListAll}
          paginationOnClick={async (url) => {
            setLoading(true);
            console.log("url", url);
            // save pagination url    
            setStudentPaginationURL(url);
            if (searchValue) {
              setLoading(false);
              await searchStudentList(url);
            } else await getStudentList(url, filterData, false);
            setLoading(false);
          }}

          shoSearchBox={true}
          searchValue={studentSearch}
          withClearSearch={true}
          onSearchClear={() => setStudentSearch("")}
          searchOnChange={(e) => setStudentSearch(e.target.value)}

          autoManageRow={true}
          TableRowComponent={StudentTableRow}
          titleComponent={
            <>
              <CommonButtonOutlined
                iconLeft={iFilterWhite}
                iconLeftHover={iFilter}
                isFilterDot={FilterStudentActive}
                colorType='primary'
                btnLabel="Filter"
                onClick={() => { setShowStudentFilterModal(true) }}
              />
            </>
          }
        />
      </div>
    </div>

  );
};

export default Student;


const TakeItem = () => {
  const { setLoading } = useUtilityStore();

  const { setStudentTakeList, studentTakeList, studentSearch, filterData, setStudentPaginationURL } = useStudentStore();
  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select
        value={studentTakeList.take ? studentTakeList.take : 10}
        onChange={async (e) => {
          setLoading(true);
          await setStudentTakeList("take", e.target.value);
          setStudentPaginationURL("");
          if (studentSearch) {
            setLoading(false);
            await searchStudentList("")
          } else {
            await getStudentList("", filterData, false);
          }
          setLoading(false);

        }} className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={studentTakeList.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={studentTakeList.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={studentTakeList.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={studentTakeList.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>

    </div>
  )
}