

import React, { useEffect, useState } from 'react';
import useLicenseStore, { updateLicense } from '../../../App/Stores/LicenseStore';
import { Toastr } from '../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';
import SelectInput from '../../../Components/Input/SelectInput';
import CommonModal from '../../../Components/Modal/CommonModal';
import RichTextEditor from '../../../Components/TextEditor/RichTextEditor';
import { htmlToPlainText } from '../../../Utility/UtilityFunctions';

const EditLicenseModal = () => {
    const [updateForm, setUpdateForm] = useState({
        id: 0,
        title: "",
        duration: "",
        price: "",
        details: "",
        type: "",
        license_type: ""
    });

    const { showEditLicense, setShowEditLicense, licenseUpdateData, setShowDeleteLicenseModal, setLicenseDeleteID } = useLicenseStore();



    const HandleSubmit = () => {

        // if (
        //     updateForm?.title &&
        //     updateForm?.duration &&
        //     // updateForm?.license_type &&
        //     updateForm?.price &&
        //     updateForm?.price > 0 &&
        //     updateForm?.price < 99999999 &&
        //     updateForm?.type
        // ) {
        // console.log(updateForm);
        if (htmlToPlainText(updateForm?.details) === "") {
            // console.log("License Description is required !");
            Toastr({ message: "License Description is required !", type: "error" });
        } else {
            let addSuccess = updateLicense(updateForm);
            if (addSuccess) {
                setShowEditLicense(false);
            }
        }
        // }
    }

    const durationArray = [
        // all are distributed into months and then multiplied by 30.
        // ex: 1 year 6 months = 12+6 = 18 months = 18*30 = 540 days
        // ex: 1 year 0 months = 12 months = 12*30 = 360 days
        {
            title: "3 Months",
            value: 90,
            selected: licenseUpdateData?.duration === 90 ? true : false
        },
        {
            title: "6 Months",
            value: 180,
            selected: licenseUpdateData?.duration === 180 ? true : false
        },
        {
            title: "1 Year ",
            value: 360,
            selected: licenseUpdateData?.duration === 360 ? true : false
        },
        {
            title: "1 Year 6 Months",
            value: 540,
            selected: licenseUpdateData?.duration === 540 ? true : false
        },
        {
            title: "2 Years",
            value: 720,
            selected: licenseUpdateData?.duration === 720 ? true : false
        },
    ];


    // let durationArray = [
    //     {
    //         title: "3 Months",
    //         value: 90,
    //         selected: licenseUpdateData?.duration === 90 ? true : false
    //     },
    //     {
    //         title: "6 Months",
    //         value: 182,
    //         selected: licenseUpdateData?.duration === 182 ? true : false
    //     },
    //     {
    //         title: "1 Year ",
    //         value: 365,
    //         selected: licenseUpdateData?.duration === 365 ? true : false
    //     },
    //     {
    //         title: "1 Year 6 Months",
    //         value: 547,
    //         selected: (licenseUpdateData?.duration === 547 || licenseUpdateData?.duration === 545) ? true : false
    //     },
    //     {
    //         title: "2 Years",
    //         value: 730,
    //         selected: licenseUpdateData?.duration === 730 ? true : false
    //     },
    // ];

    let userTypeArray = [
        {
            title: "School",
            value: "school",
            selected: licenseUpdateData?.type === "school" ? true : false
        },
        {
            title: "Instructor",
            value: "instructor",
            selected: licenseUpdateData?.type === "instructor" ? true : false
        },
    ]

    const licenseTypeArray = [
        {
            title: "Basic",
            value: "basic",
            selected: licenseUpdateData?.license_type === "basic" ? true : false
        },
        {
            title: "Premium",
            value: "premium",
            selected: licenseUpdateData?.license_type === "premium" ? true : false
        },
        {
            title: "Listing",
            value: "listing",
            selected: licenseUpdateData?.license_type === "listing" ? true : false
        },
    ]

    useEffect(() => {
        setUpdateForm({
            id: licenseUpdateData?.id,
            title: licenseUpdateData?.title,
            duration: licenseUpdateData?.duration,
            price: licenseUpdateData?.price,
            details: licenseUpdateData?.details,
            type: licenseUpdateData?.type,
            license_type: licenseUpdateData?.license_type
        });

        let target = durationArray.findIndex((e) => { if (e.duration === licenseUpdateData?.duration) { return e } else return null });


    }, [licenseUpdateData]);

    return (
        <>
            <CommonModal
                showModal={showEditLicense}
                setShowModal={setShowEditLicense}
                modalSpace={true}
                modalTitle="Update License Details"
                mainContent={
                    <>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            HandleSubmit()
                        }
                        }>
                            <div className="my-s20">
                                <CommonInput
                                    // required={true}
                                    type="text"
                                    label="Title"
                                    placeholder="Title"
                                    value={updateForm?.title}
                                    onChange={(e) => setUpdateForm({ ...updateForm, title: e.target.value })}
                                />
                            </div>
                            <div className="mb-s20">
                                <SelectInput
                                    // required={true}
                                    label="Choose Duration"
                                    placeholder="Choose Duration"
                                    dataArray={durationArray}
                                    selectOptionOnChange={(e) => setUpdateForm({ ...updateForm, duration: e })}
                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    // required={true}
                                    dataArray={userTypeArray}
                                    label="Select User Type"
                                    placeholder="User Type"
                                    selectOptionOnChange={(e) => setUpdateForm({ ...updateForm, type: e })}
                                />
                            </div>

                            <div className="mb-s20">
                                <SelectInput
                                    // required={true}
                                    label="Select License Type"
                                    placeholder="License Type"
                                    dataArray={licenseTypeArray}
                                    selectOptionOnChange={(e) => setUpdateForm({ ...updateForm, license_type: e })}
                                />
                            </div>

                            <div className="">
                                <CommonInput
                                    // required={true}
                                    type="number"
                                    label="Price"
                                    className2="mb-s20"
                                    placeholder="Price"
                                    value={updateForm?.price}
                                    min_number={1}
                                    max_number={99999999}
                                    onChange={(e) => setUpdateForm({ ...updateForm, price: e.target.value })}
                                />
                            </div>

                            <div className="h-[297px] mt-5">
                                <RichTextEditor
                                    label={"Details"}
                                    placeholder="Write how to..."
                                    // required={true}
                                    value={updateForm?.details}
                                    // onChange={(e) => setRichText(e)}
                                    onChange={(e) => setUpdateForm({ ...updateForm, details: e })}
                                />
                            </div>

                            {/* <div>
                                <CommonInput
                                    // required={true}
                                    textarea={true}
                                    label="Write Details"
                                    className2="mb-s20"
                                    placeholder="Details"
                                    value={updateForm?.details}
                                    cols={4}
                                    onChange={(e) => setUpdateForm({ ...updateForm, details: e.target.value })}
                                />
                            </div> */}

                            <div className='flex justify-between'>
                                <CommonButton
                                    btnLabel="delete"
                                    colorType="danger"
                                    roundedFull="true"
                                    onClick={() => {
                                        setShowDeleteLicenseModal(true);
                                        setLicenseDeleteID(licenseUpdateData?.id)
                                    }}
                                />
                                <CommonButton
                                    type="submit"
                                    btnLabel="save changes"
                                    colorType="primary"
                                    roundedFull="true"
                                // onClick={HandleSubmit}
                                />
                            </div>

                        </form>
                    </>
                }

            />
        </>
    );
};

export default EditLicenseModal;