import React from 'react'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';
import useSettingsStore, { deleteMessageToggleStatus} from '../../../App/Stores/SettingsStore';

function DeleteMessageDeactivateModal() {

    const {setDeleteMessageDeactivateID,showDeleteMessageDeactivateModal, setShowDeleteMessageDeactivateModal,DeleteMessageDeactivateID } = useSettingsStore();

    return (
        <>
            <CommonModal
                showModal={showDeleteMessageDeactivateModal}
                setShowModal={setShowDeleteMessageDeactivateModal}
                modalTitle="Confirmation"
                mainContent={
                    <>
                        <div className="flex justify-center pt-5">Are you sure you want to deactivate this?</div>
                        <div className="flex justify-center pt-5">
                            <CommonButton
                                colorType='danger'
                                btnLabel='Deactivate'
                                onClick={async() => {
                                    let deactivateSuccess = await deleteMessageToggleStatus(DeleteMessageDeactivateID);
                                    if(deactivateSuccess) {
                                       await  setDeleteMessageDeactivateID("")
                                        await setShowDeleteMessageDeactivateModal(false);
                                    } 
                                }}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default DeleteMessageDeactivateModal