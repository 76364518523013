import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useCategoryStore from '../../../App/Stores/CategoryStore';
import useSchoolStore, { searchSchoolList } from '../../../App/Stores/SchoolStore';
import useStudentStore, { getStudentList } from '../../../App/Stores/StudentStore';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import { Toastr } from '../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonSearchBox from '../../../Components/Input/CommonSearchBox';
import SelectInput from '../../../Components/Input/SelectInput';
import CommonModal from '../../../Components/Modal/CommonModal';
import AsyncSingleCombobox from '../../../Components/Select/AsyncSingleCombobox';
import SelectMultipleCombo from '../../../Components/SelectMultipleCombo';

function FilterStudentTable() {
    const { setFilterStudentActive, showStudentFilterModal, setShowStudentFilterModal, categoryArray, setCategoryArray, filterData, setFilterData, FilterSchoolArray, setFilterSchoolArray, setStudentSearch } = useStudentStore();
    const { categoryListAll } = useCategoryStore();
    const { schoolSearchKey, setSchoolSearchKey, schoolListAll } = useSchoolStore();
    const { setLoading } = useUtilityStore();

    const [selectedSchoolName, setSelectedSchoolName] = useState("Select or search a school");


    const UpdateCategoryArray = () => {
        let c_array = [];
        categoryListAll?.data?.data?.map((item) => c_array.push({
            label: item?.name,
            value: parseInt(item?.id)
        }));
        setCategoryArray(c_array);

    }

    const formSubmit = async (e) => {
        e.preventDefault();
        console.log(filterData);
        // return
        setLoading(true);
        let filterSuccess = await getStudentList("", filterData, false);
        if (filterSuccess) {
            Toastr({ message: "Your filter successfully Apply", type: "success" });
            setShowStudentFilterModal(false);
            setStudentSearch("");
            console.log("filterData", filterData)
            if (filterData.search === '' &&
                isNaN(filterData.status) === true
                && filterData.category.length === 0 &&
                filterData.school_ids.length === 0
            ) {
                setFilterStudentActive(false)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        // console.log("categoryArray::::", categoryArray, categoryListAll);
        UpdateCategoryArray();
        // console.log("categoryArray::::", categoryArray, categoryListAll);
    }, [categoryListAll]);

    const [searchValueSchool] = useDebounce(schoolSearchKey, 500);

    useEffect(() => {
        searchSchoolList(searchValueSchool, "", 500);
    }, [searchValueSchool]);

    useEffect(() => {
        let t_array = []
        if (schoolListAll?.data?.length) {
            schoolListAll?.data?.map((item) => t_array.push({ label: item?.name, value: item?.id }));
            setFilterSchoolArray(t_array);
        }
    }, [schoolListAll?.data]);

    return (
        <div>
            <CommonModal
                showModal={showStudentFilterModal}
                setShowModal={setShowStudentFilterModal}
                modalTitle="Filter Student"
                mainContent={
                    <>
                        <form onSubmit={formSubmit}>
                            <div>
                                <div className="my-5">
                                    <CommonSearchBox
                                        fullWidth={true}
                                        value={filterData?.search}
                                        onChange={(e) => setFilterData({ ...filterData, search: e.target.value })}
                                        withClearSearch={true}
                                        onSearchClear={() => setFilterData({ ...filterData, search: "" })}
                                    />
                                </div>


                                <div className="mb-s20">
                                    <SelectInput
                                        label="Choose Status"
                                        placeholder="Choose Status"
                                        selectOptionOnChange={(e) => {
                                            if (e === '') {
                                                setFilterData({ ...filterData, status: -1 })
                                            } else {
                                                e === '2' && setFilterData({ ...filterData, status: parseInt(1) })
                                                e === '3' && setFilterData({ ...filterData, status: parseInt(0) })
                                            }
                                        }}
                                        dataArray={[
                                            {
                                                title: "Active",
                                                value: 2,
                                                selected: filterData?.status === 1 ? true : false
                                            },
                                            {
                                                title: "Deactivate",
                                                value: 3,
                                                selected: filterData?.status === 0 ? true : false
                                            },
                                        ]}
                                    />
                                </div>


                                <div
                                    // onClick={() => { console.log("FilterSchoolArray", FilterSchoolArray); }} 
                                    className="mb-s20">
                                    <AsyncSingleCombobox
                                        label="filter by school"
                                        placeholder={selectedSchoolName ? selectedSchoolName : 'Select or search a school'}
                                        selectedValue={{ label: selectedSchoolName ?? "Select or search a school", value: filterData?.school_ids?.length ? filterData?.school_ids[0] : 0 }}
                                        onSearchItem={(value) => {
                                            setSchoolSearchKey(value);
                                        }}
                                        optionArray={FilterSchoolArray}
                                        onChangeLabel={(label) => {
                                            console.log("SELECTED SCHOOL-LABEL: ", label);
                                            setSelectedSchoolName(label);
                                        }}
                                        onChange={(value) => {
                                            console.log("SELECTED SCHOOL-ID: ", value);
                                            if (value) {
                                                let data = [parseInt(value)];
                                                setFilterData({ ...filterData, school_ids: data });
                                            } else setFilterData({ ...filterData, school_ids: [] });
                                        }}
                                    />


                                    {/* <SelectInput
                                        label="Choose School"
                                        placeholder="Choose School"
                                        selectOptionOnChange={(e) => {
                                            if (e === '') {
                                                setFilterData({ ...filterData, school_ids: [] });
                                            } else {
                                                let data = [parseInt(e)];
                                                setFilterData({ ...filterData, school_ids: data });
                                            }
                                        }}
                                        dataArray={FilterSchoolArray ?? []}
                                    /> */}
                                </div>

                                <div className="mb-s20">
                                    <SelectMultipleCombo
                                        placeholder='Choose Lesson Category'
                                        label='Lesson Category'
                                        selectedValue={filterData?.category ?? []}
                                        onChange={(valueArray) => {
                                            // console.log(value);
                                            // let s_array = [];
                                            // s_array.push(value);                                        
                                            setFilterData({ ...filterData, category: valueArray });
                                        }}
                                        optionArray={categoryArray}

                                    />
                                </div>



                                <div className='flex justify-between mt-s20'>
                                    <CommonButton onClick={() => {
                                        setSelectedSchoolName("Select or search a school");
                                        setFilterData({
                                            search: "",
                                            status: -1,
                                            category: [],
                                            school_ids: []
                                        });
                                    }} btnLabel="Clear" colorType="FilterClearButton" />

                                    <CommonButton
                                        type="submit"
                                        btnLabel="Apply"
                                        colorType="primary"
                                        roundedFull="true"
                                    />
                                </div>

                            </div>
                        </form>

                    </>
                }
            />
        </div>
    );
}

export default FilterStudentTable