import React from 'react';
import useCategoryStore, {categoryToggleStatus } from '../../../App/Stores/CategoryStore';
import CommonModal from '../../../Components/Modal/CommonModal';
import CommonButton from '../../../Components/Button/CommonButton';
import { useParams } from 'react-router-dom';

const CategoryDeactivateModal = () => {

    const {showDeactivateModal,setShowDeactivateModal}=useCategoryStore();

    const{id}=useParams();

    const toggleStatus=async ()=>{
        let value=await categoryToggleStatus(id)
        if(value) return setShowDeactivateModal(false)
    }

    return (
        <div>
            <CommonModal
            showModal={showDeactivateModal}
            setShowModal={setShowDeactivateModal}  
            modalTitle="Conformation"
            mainContent={
               <>
                 <div className='text-fs14 font-fw600 text-cHighlighted leading-5 flex justify-center my-s20'>
                    Are you sure you want to deactivate this category?
                </div>
               <div className='flex justify-center'>
               <CommonButton onClick={()=>{toggleStatus()}} btnLabel="Deactivate" colorType="warning" roundedFull="true" />
               </div>
               </>
            }
                 
            />
        </div>
    );
};

export default CategoryDeactivateModal ;