import React from "react";
import { useState } from "react";
import useStudentStore from "../../../App/Stores/StudentStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import ProfileImageUploader from "../../../Components/Image/ProfileImageUploader";

import { BaseUrlSrc } from "../../../App/Utility/Url";

const AddStudentModal = () => {
  const {
    showAddStudentModal,
    setShowAddStudentModal,
    setShowStudentAddPassModal
  } = useStudentStore();

  const [id, setID] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [student_name, setStudent_name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [address, setAddress] = useState("");


  const formSubmit = async (e) => {

    e.preventDefault()
    // const data={id: id,student_name: student_name,phone_number: phone_number,address: address,image:image }
    // console.log("data",data)
    // if(id && email &&  student_name && phone_number && address){

    //   let value=await editStudentDetail(data)
    //   if(value){
    //     setID("")
    //     setStudent_name("")
    //     setEmail("")
    //     setPhone_number("")
    //     setImage("")
    //     setAddress("") 
    //     setShowStudentEditModal(false)
    //   }
    // }

  }


  //console.log("studentDetails", studentDetails);

  return (
    <>
      <CommonModal
        showModal={showAddStudentModal}
        setShowModal={setShowAddStudentModal}
        modalTitle="Add Student"
        mainContent={
          <>
            <div className="flex justify-center">
              <div>
                <div className="my-s20">
                  <ProfileImageUploader iImage={image ? BaseUrlSrc + image : ''} setImage={setImage} />
                </div>
              </div>
            </div>

            <form >
              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  label="Name"
                  value={student_name}
                  //   onChange={(e) => {setStudent_name(e.target.value);}}
                  placeholder="Name"
                />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="email"
                  label="Email"
                  value={email}
                  placeholder="Email"
                />
              </div>


              <div className="mb-s20">
                <CommonInput
                  required={true}
                  value={phone_number}
                  // onChange={(e) => {
                  //   setPhone_number(e.target.value);
                  // }}
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                />
              </div>


              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  label="Address"
                  value={address}
                  //   onChange={(e) => {
                  //     setAddress(e.target.value);
                  //   }}
                  placeholder="Address"
                />
              </div>

              <div className="flex justify-center mt-s40">
                <CommonButton
                  onClick={() => { setShowStudentAddPassModal(true) }}
                  type="button"
                  btnLabel="Next"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};

export default AddStudentModal;