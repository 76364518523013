import React from 'react';
import { iProfile } from '../../../App/Utility/source';
import useInstructorStore from '../../../App/Stores/InstructorStore';
import BookingDetails from '../BookingDetails';
import CommonModal from '../../../Components/Modal/CommonModal';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';

const UpcomingDrivingBookingModal = () => {

    const {showDrivingLessonsModal,setShowDrivingLessonsModal}=useInstructorStore()

    return (
        <div>
             <CommonModal
                showModal={showDrivingLessonsModal}
                setShowModal={setShowDrivingLessonsModal}
                modalTitle="Upcoming Driving Booking"
                mainContent={
                    <>
                        <div className=" mt-s35">

                            <div className="flex">
                                <img className="w-s88 h-s88 rounded-full mb-s18" src={iProfile} alt="" />
                                <div className="ml-s15 mt-0">
                                    <div className="font-fw600 text-fs20 text-cHighlighted mb-s2 leading-9">Shahid Sumon</div>
                                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">Denmark Driving School</div>
                                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">shahidsumon@gmail.com</div>
                                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">joined:5 jun 2022</div>
                                    <div className="font-fw500 text-fs12 text-cImportantText leading-3 mb-s6">joined:5 jun 2022</div>
                                </div>
                            </div>

                            <div className="py-s20 px-0">
                              <hr />
                            </div>


                            <span >Booking Details</span>
                            <div className='bg-cBackgroundAndCategory rounded-br10 p-s20 my-s20'>
                             <BookingDetails Status="Status" result="Accepted"/>
                             <BookingDetails Status="Category" result="A1"/>
                             <BookingDetails Status="Lesson Type" result="Classroom"/>
                             <BookingDetails Status="Lesson Name" result="Training to ride two-wheeler"/>
                             <BookingDetails Status="Classroom Address" result="Thorsvej 32A, 8680 Ry, Denmark"/>
                             <BookingDetails Status="Start Date" result="21 Nov, 2022"/>
                             <BookingDetails Status="Start Time" result="08:00  - 09:00"/>
                             <BookingDetails Status="Students" result="22/22 Students"/>
                            </div>

                            <span className='text-fs14 font-fw600 text-cBlack mb-s10'>Comments</span>
                            <div>
                            <span className='text-fs14 font-fw600 text-cBlack'>Instructor Comment</span>
                            <div className='text-fs14 font-fw400 text-cTextGray mt-s5 mb-s15'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at blandit habitasse vel, sagittis vel pellentesque. Mi rutrum quam a mauris quis scelerisque nisl. Urna rutrum ultricies sit enim dictumst pellentesque enim imperdiet auctor. Et vehicula nec dapibus in dolor hendrerit sit adipiscing.</div>
                            </div>
                            <CommonInput type="text" textarea="true" label="Write Comment" placeholder="Write Comment"/>
                        </div>

                        <div className='flex justify-between mt-s40'>
                            <CommonButton onClick={() => { setShowDrivingLessonsModal(false) }} type="submit" btnLabel="Cancel" colorType="warning" roundedFull="true"  />
                            <CommonButton onClick={() => { setShowDrivingLessonsModal(false) }} type="submit" btnLabel="Start Lesson" colorType="primary" roundedFull="true" />
                        </div>
                    </>
                }

            />
        </div>
    );
};

export default UpcomingDrivingBookingModal;