import React, { useEffect, useState } from 'react';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonModal from '../../../Components/Modal/CommonModal';
import useSchoolStore from './../../../App/Stores/SchoolStore';
import { BaseUrlSrc } from './../../../App/Utility/Url';
import ProfileImageUploader from './../../../Components/Image/ProfileImageUploader';

const AddSchoolModal = () => {

  const { showAddSchoolModal, setShowAddSchoolModal, setShowSchoolAddPassModal } = useSchoolStore()

  //console.log("schoolDetail",schoolDetails?.data);

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [cvr, setCvr] = useState("")
  const [address, setAddress] = useState("")
  const [website, setWebsite] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [contact_mail, setContact_mail] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("");


  return (
    <>
      <CommonModal
        showModal={showAddSchoolModal}
        setShowModal={setShowAddSchoolModal}
        modalTitle="Add School"
        mainContent={
          <>

            <div className='flex justify-center'>
              <div>
                <div className='my-s20'>
                  <ProfileImageUploader iImage={image ? BaseUrlSrc + image : ''} setImage={setImage} />
                </div>
              </div>
            </div>

            <form onSubmit={async (e) => {
              e.preventDefault()

            }}>
              <div className="my-s20">
                <CommonInput type="text"
                  required={true}
                  value={name}
                  onChange={(e) => { setName(e.target.value) }}
                  label="Name"
                  placeholder="Name" />
              </div>

              <div className="mb-s20">
                <CommonInput type="email"
                  required={true}
                  value={email}
                  label="Email"
                  placeholder="Email" />
              </div>

              <div className="flex justify-between mb-s20">
                <div>
                  <CommonInput value={cvr}
                    required={true}
                    type="number"
                    onChange={(e) => { setCvr(e.target.value) }}
                    className2='w-s300' label="CVR"
                    placeholder="cvr" />
                </div>
                <div>
                  <CommonInput
                    required={true}
                    value={phone_number}
                    type="tel"
                    onChange={(e) => { setPhone_number(e.target.value) }}
                    className2='w-s300' label="Phone Number"
                    placeholder="Phone Number" />
                </div>
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  value={address}
                  label="Address"
                  onChange={(e) => { setAddress(e.target.value) }}
                  placeholder="Address" />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  value={website}
                  label="Website url"
                  onChange={(e) => { setWebsite(e.target.value) }}
                  placeholder="Website url" />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  type="text"
                  onChange={(e) => { setContact_mail(e.target.value) }}
                  value={contact_mail}
                  label="Contact Mail"
                  placeholder="Contact Mail" />
              </div>

              <div className="mb-s20">
                <CommonInput
                  required={true}
                  value={description}
                  onChange={(e) => { setDescription(e.target.value) }}
                  type="text" label="Description" textarea="true"
                  placeholder="Description" />
              </div>

              <div className='flex justify-center mt-s20'>
                <CommonButton onClick={() => { setShowSchoolAddPassModal(true) }} type="submit" btnLabel="Next" colorType="primary" roundedFull="true" />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};

export default AddSchoolModal;