import React from 'react'
import { useParams } from 'react-router';
import useLicenseStore, { toggleLicense } from '../../../App/Stores/LicenseStore'
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal'

function DeactivateLicense() {
    const { showDeactivateLicense, setShowDeactivateLicense } = useLicenseStore();

    const { license_id}=useParams();

    return (
        <>
            <CommonModal
                showModal={showDeactivateLicense}
                setShowModal={setShowDeactivateLicense}
                modalTitle="Confirmation"
                mainContent={
                    <div className='flex flex-col items-center'>
                        <div className="pt-5">Are you sure you want to deactivate this license?</div>
                        <div className="pt-5">
                            <CommonButton
                                btnLabel='Deactivate'
                                colorType='danger'
                                onClick={async() => {
                                    let deactivateSuccess = await toggleLicense(license_id);
                                    if (deactivateSuccess) setShowDeactivateLicense(false);
                                }} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default DeactivateLicense