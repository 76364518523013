import React from 'react';
import GreenSwitch from './../../../Components/Switch/GreenSwitch';
import { useState } from 'react';
import { iVector } from './../../../App/Utility/source';
import CommonButton from './../../../Components/Button/CommonButton';

const LicenseInstructorPremiumCard = () => {

    const [redUi, setRedUi] = useState(true);

    const [enabled,setEnabled]=useState(false)

    return (
        <div>
       <div className={`w-[280px] overflow-hidden relative  border p-s15 ${redUi ? "border-white" : "border-cBrand"} h-[317px] rounded-br10`}>

      {redUi && <div className="absolute top-0 left-0 z-20 w-full h-full backdrop-blur-sm"></div>}
        <div className={`absolute w-full h-full top-0 left-0 rounded-br10 z-30 text-white ${redUi ? "bg-black opacity-80 backdrop-blur-sm" : ""}`}>
         {redUi &&
         <div className='flex flex-col justify-center items-center w-full h-full opacity-100'>
            <div className='pb-s10 text-fs20 font-fw600'>
            No Active License
            </div>
            <CommonButton roundedFull="true" fullRounded={true} colorType="white" btnLabel='Apply For License' dropShadow={true} />
          </div>
        }
       </div>
       <div className="bg-cBrandColor rounded-br10 w-s280 lg:w-s320 h-[317px]">
           <div className='flex justify-between'>
             <div className="text-fs20 font-fw600 text-cBrandColor2 mb-s10">Premium</div>
           
             <div className='flex'>
             <GreenSwitch  enabled={enabled} setEnabled={setEnabled}/>
             <div className='ml-s25 rounded-full bg-cBrandColor2 w-w28 h-h28'><img className="m-s7 w-w14" src={iVector} alt=""/></div>
             </div>
             
           </div>
           <div className="text-cBrandColor2 text-fs32 font-fw600">DKK 5.000</div>
           <span className="text-cBrandColor2 text-fs14 font-fw400">(Including MOMS: DKK 250)</span>
         <div className="my-s20">
           <div className="text-cBrandColor2 text-fs14 font-fw400">Start Date: 22 July, 2022</div>
           <div className="text-cBrandColor2 text-fs14 font-fw400">End Date: 22 July, 2024</div>
         </div>
         <div>
           <div className="text-cBrandColor2 text-fs14 mb-s10 font-fw600">Duration: 2 Years</div>
           <div className="text-cBrandColor2 text-fs14 font-fw400">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt quis nec morbi mauris cras dui</div>
         </div>
         </div> 
       </div>
    </div>

    );
};

export default LicenseInstructorPremiumCard;