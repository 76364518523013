import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useUtilityStore from '../../../App/Stores/UtilityStore';
import { iShareBlue, iShareWhite } from '../../../App/Utility/source';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import BackLink from '../../../Components/Pagination/BackLink';
import CommonCategoryDetails from '../../../Components/Profile/CommonCategoryDetails';

import CommonTable from '../../../Components/Table/CommonTable';
import CurriculumTableRow from '../../../Components/Table/Student/CurriculumTableRow';
import CommonTitle from '../../../Components/Title/CommonTitle';
import useLayoutStore from './../../../App/Stores/LayoutStore';
import useStudentStore, { getStudentCurriculumList, getStudentDetails } from './../../../App/Stores/StudentStore';
import { PageTitle } from './../../../App/Utility/UtilityFunctions';
import CommonStudentBasicDetails from './../../../Components/Profile/CommonStudentBasicDetails';

function CurriculumDetails() {

    const curriculumHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Duration") },
        { index: 4, name: t("Status") },
    ];

    const {
        setStudentCurriculumDetailShareId,
        studentCurriculumList,
        studentDetails,
        setShowShareCurriculumDetailsModal
    } = useStudentStore();


    const { setLoading } = useUtilityStore();

    const { setBarTitle } = useLayoutStore();

    const { student_id } = useParams();

    const location = useLocation();

    useEffect(() => {
        PageTitle(t("Curriculum Details"));
        setBarTitle("Curriculum Details");
        fetchStudentCurriculum();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBarTitle, student_id]);

    const fetchStudentCurriculum = async () => {
        setLoading(true)
        await getStudentCurriculumList(student_id)
        if (student_id) getStudentDetails(student_id);
    }

    //console.log("studentDetails", studentDetails)

    const student_info = {
        image: studentDetails?.profile_photo,
        name: studentDetails?.name,
        email: studentDetails?.email,
        address: studentDetails?.address,
        phone_number: studentDetails?.phone_number,
        joined_date: studentDetails?.joined_date
    }

    const schools_info = {
        image: studentCurriculumList?.schools_info?.length > 0 && studentCurriculumList?.schools_info[0]?.school_all_info?.cover,
        school_name: studentCurriculumList?.schools_info?.length > 0 && studentCurriculumList?.schools_info[0]?.school_name,
        email: studentCurriculumList?.schools_info?.length > 0 && studentCurriculumList?.schools_info[0]?.school_all_info?.contact_mail,
        phone_number: studentCurriculumList?.schools_info?.length > 0 && studentCurriculumList?.schools_info[0]?.school_all_info?.phone_number,
        cvr: studentCurriculumList?.schools_info?.length > 0 && studentCurriculumList?.schools_info[0]?.school_all_info?.cvr,
        joined_date: studentCurriculumList?.schools_info?.length > 0 && studentCurriculumList?.schools_info[0]?.school_all_info?.created_at
    }


    return (
        <div>
            <div className='mx-s12 md:mx-s32'>
                <div className='flex flex-col sm:flex sm:flex-row justify-between'>
                    <CommonTitle title="Curriculum Details" >
                        {
                            location.pathname === `/student/details/${student_id}/curriculum/details` && <BackLink linksArray={[
                                { label: "Student", linkTo: "/student" },
                                { label: "Student Details", linkTo: `/student/details/${student_id}` },
                                { label: "Curriculum Details", linkTo: `` }
                            ]} />
                        }
                    </CommonTitle>

                  {studentCurriculumList?.lessons?.length > 0 && <div className='mb-s20 sm:mt-s20'><CommonButtonOutlined
                        btnLabel='share Curriculum Details'
                        onClick={() => {
                            setStudentCurriculumDetailShareId(studentDetails?.user_id)
                            setShowShareCurriculumDetailsModal(true);
                        }}
                        width={"w-[250px]"}
                        colorType='primary'
                        iconRight={iShareBlue}
                        iconRightHover={iShareWhite}
                    />
                    </div>}
                </div>

                {studentCurriculumList?.lessons?.length > 0 ? <div className="bg-cBrandColor2 rounded-br20 py-s20 overflow-hidden">
                    <div className='px-s20'>
                        {studentCurriculumList?.schools_info?.length > 0 &&
                            <CommonStudentBasicDetails
                                student_info={student_info}
                                schools_info={schools_info}
                            />}

                        {studentCurriculumList?.categories_info?.length > 0 &&
                            <div className='mt-s20'>
                                <CommonCategoryDetails
                                    categoriesImage={studentCurriculumList?.categories_info?.length > 0 && studentCurriculumList?.categories_info[0]?.category_icon}
                                    categoryName={studentCurriculumList?.categories_info?.length > 0 && studentCurriculumList?.categories_info[0]?.category_name}
                                    lessonDuration={studentCurriculumList?.category_curriculum_summary?.total_lesson_duration}
                                    totalLesson={studentCurriculumList?.category_curriculum_summary?.total_lesson}
                                />
                            </div>}

                        <div className="pt-s20 px-1">
                            <hr />
                        </div>

                    </div>

                    <div>
                        <CommonTable
                            headers={curriculumHeaders}
                            tableTitle="Lessons"
                            shoSearchBox={false}
                            pagination={false}
                            showPageCountText={false}
                            showPagination={false}
                            items={
                                <>
                                    {studentCurriculumList?.lessons?.length > 0 ?
                                        studentCurriculumList?.lessons?.map((item, index) => (
                                            <CurriculumTableRow key={index} data={item} index={index} />
                                        )) : <tr className='w-full'>
                                            <th colSpan={5} className="py-s10">
                                                No Data Found !
                                            </th>
                                        </tr>
                                    }
                                </>
                            }
                        />
                    </div>
                </div> :
                    <div className="h-[350px] flex items-center justify-center bg-cBrandColor2 rounded-br20 overflow-hidden">
                        <div className=''>
                            <div className='text-fs18 font-fw600 text-cHighlightedTexts mb-s10'>
                                No Curriculum Details available
                            </div>
                        </div>
                    </div>
                }


            </div>
        </div>
    )
}

export default CurriculumDetails
