
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { iCategory, iInformation, iRightArrow } from '../../../App/Utility/source';
import Image from '../../Image/Image';
import Tooltip from '@mui/material/Tooltip';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import useStudentStore from '../../../App/Stores/StudentStore';

const StudentSchoolCategoryTableRow = ({ data, index,moms_percentage }) => {

  const {
    setStudentFindSchoolCategoryInfo,
    setFullPackageChecked,
    setStudentFindSchoolLessonId,
    setStudentSchoolPackagePrice,
    setStudentSchoolMomsPrice,
    setStudentFindSchoolApply
  }=useStudentStore();

  const { student_id,school_id } = useParams();

  const navigateTo = useNavigate();

  //console.log("data", data)

  return (
    <>
      <tr onClick={async() => {
          await setStudentFindSchoolCategoryInfo(data)
          await setStudentFindSchoolLessonId([])
          await setFullPackageChecked(false)
          await setStudentSchoolPackagePrice("0")
          await setStudentFindSchoolApply(true)
          await setStudentSchoolMomsPrice(moms_percentage)
          data?.school_id &&
          await navigateTo(`/student/details/${student_id}/school/${data?.school_id}/category`)
      }}
        className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >
        <th className='m-2 font-normal text-center border-r py-s10 pl-s10'>
          <span className='mr-s12 dm:mrs12 md:mr-0'>{index + 1}</span>
        </th>

        <td className='border-r-[1px] py-s10 text-left px-s10 max-w-[100px] min-w-[120px] '>
          <div className='flex items-center min-w-[80px] sm:flex-col md:flex-row'>

            <div className='rounded-full w-s44 h-s44 py-s7 pr-s5 pl-s7 bg-cBackgroundAndCategory'>
              <Image cursorPointerClass="cursor-pointer" className='h-s27 w-s30'
                src={data?.icon} dummyImage={iCategory} isCategoryImage={true}
              />
            </div>

            <div className='justify-center my-s8 font-fw600 ml-s8 text-cMainBlack lg:text-fs14 sm:text-fs12'>
              {data?.category_name ? data?.category_name : <CommonEmptyStatus />}</div>
          </div>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>
            {data?.lessons ? data?.lessons : <CommonEmptyStatus />}
          </span>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>
            {data?.duration?data?.duration:<CommonEmptyStatus/>}</span>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
          <span className='font-fw400 text-cImportantText text-fs14'>
            {/* {data?.price ? data?.price : <CommonEmptyStatus />}</span> */}
            {
              data?.price === 0 ? data?.total_lesson_price === 0 ||
                data?.total_lesson_price === null ? 'NA' : `${data?.total_lesson_price} DKK` : `${data?.price} DKK`
            }
          </span>
        </td>

        <td className='py-s10 border-r-[1px] px-s10 text-center'>
          <span className='flex justify-between'>
            <span></span>
            <span className='justify-center my-s8 font-fw400 ml-s10 text-cImportantText lg:text-fs14 sm:text-fs12'>
              <span className={`font-fw600 text-fs14 ${data?.is_active ? "text-cPassed" : "text-cCancelled"}`}>
                {data?.is_active ? "Active" : "Deactivate"}</span>
            </span>

            <span className=""><img className="mt-s12" src={iRightArrow} alt=""></img> </span> </span>
        </td>


      </tr>
    </>
  )
}


export default StudentSchoolCategoryTableRow;